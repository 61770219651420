import * as actionTypes from '../actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../../settings';

export const getTransactionStart = () => {
  return {
    type: actionTypes.GET_TRANSACTION_START,
  };
};

export const getTransactionSuccess = (data) => {
  return {
    type: actionTypes.GET_TRANSACTION_SUCCESS,
    payload: data,
  };
};

export const getTransactionsSuccess = (data) => {
  return {
    type: actionTypes.GET_TRANSACTIONS_SUCCESS,
    payload: data,
  };
};

export const getUnallocatedTransactionsSuccess = (data) => {
  return {
    type: actionTypes.GET_UNALLOCATED_TRANSACTIONS_SUCCESS,
    payload: data,
  };
};

export const getTransactionFaild = (error) => {
  return {
    type: actionTypes.GET_TRANSACTION_FAIL,
    error: error,
  };
};

export const getTransactions = (token) => {
  return (dispatch) => {
    dispatch(getTransactionStart());
    console.log('getting all Transactions');
    axios
      .get(`${HOST_URL}/transactions/transaction/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTransactionsSuccess(response.data)))
      .catch((error) => dispatch(getTransactionFaild(error)));
  };
};

export const getTransaction = (token, id) => {
  return (dispatch) => {
    dispatch(getTransactionStart());

    axios
      .get(`${HOST_URL}/transactions/transaction/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTransactionSuccess(response.data)))
      .catch((error) => dispatch(getTransactionFaild(error)));
  };
};

export const deleteTransaction = (token, id, financial_account) => {
  return (dispatch) => {
    dispatch(getTransactionStart());

    axios
      .delete(`${HOST_URL}/transactions/transaction/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        dispatch(getTransactions(token));
        dispatch(getUnallocatedTransactions(token, financial_account));
      })
      .catch((error) => dispatch(getTransactionFaild(error)));
  };
};

export const getTransactionsByDateRange = (
  token,
  startDate,
  endDate,
  financial_account
) => {
  return (dispatch) => {
    dispatch(getTransactionStart());
    console.log('getting all cards');
    axios
      .get(
        `${HOST_URL}/transactions/transaction/date_${startDate}_${endDate}_${financial_account}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getTransactionsSuccess(response.data)))
      .catch((error) => dispatch(getTransactionFaild(error)));
  };
};

export const getUnallocatedTransactions = (token, financial_account) => {
  return (dispatch) => {
    dispatch(getTransactionStart());
    console.log('getting all cards');
    axios
      .get(
        `${HOST_URL}/transactions/transaction/unallocated_${financial_account}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) =>
        dispatch(getUnallocatedTransactionsSuccess(response.data))
      )
      .catch((error) => dispatch(getTransactionFaild(error)));
  };
};

export const postTransactionData = (
  token,
  financial_account,
  order,
  code,
  type,
  method,
  status,
  paymentAmount,
  content
) => {
  return (dispatch) => {
    dispatch(getTransactionStart());
    let fd = new FormData();
    fd.append('financial_account', financial_account);
    fd.append('order', order);
    fd.append('code', code);
    fd.append('type', type);
    fd.append('method', method);
    fd.append('status', status);
    fd.append('amount', paymentAmount);
    fd.append('content', content);

    axios
      .post(`${HOST_URL}/transactions/transaction/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTransactionSuccess(response.data));
        dispatch(getTransactions(token));
        dispatch(getUnallocatedTransactions(token, financial_account));
      })
      .catch((error) => dispatch(getTransactionFaild(error)));
  };
};

export const updateTransactionOrder = (
  token,
  transaction_id,
  invoiceNumber,
  financialAccount,
  amountToAllocate
) => {
  return (dispatch) => {
    dispatch(getTransactionStart());
    let fd = new FormData();
    fd.append('invoice_number', invoiceNumber);
    fd.append('amount_to_allocate', amountToAllocate);

    axios
      .patch(`${HOST_URL}/transactions/transaction/${transaction_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTransactionSuccess(response.data));
        dispatch(getUnallocatedTransactions(token, financialAccount));
      })
      .catch((error) => dispatch(getTransactionFaild(error)));
  };
};

export const updateTransactionData = (
  token,
  transaction_id,
  financial_account,
  order,
  code,
  type,
  method,
  status,
  paymentAmount,
  content
) => {
  return (dispatch) => {
    dispatch(getTransactionStart());
    let fd = new FormData();
    fd.append('financial_account', financial_account);
    fd.append('order', order);
    fd.append('code', code);
    fd.append('type', type);
    fd.append('method', method);
    fd.append('status', status);
    fd.append('amount', paymentAmount);
    fd.append('content', content);

    axios
      .put(`${HOST_URL}/transactions/transaction/${transaction_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTransactionSuccess(response.data));
      })
      .catch((error) => dispatch(getTransactionFaild(error)));
  };
};
