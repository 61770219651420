import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  jobTechReport: null,
  jobTechReports: [],
  jobDamageReport: null,
  jobDamageReports: [],
};

const getJobReportStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getJobTechReportSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobTechReport: action.payload,
  });
};

const getJobTechReportsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobTechReports: action.payload,
  });
};

const getJobDamageReportSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobDamageReport: action.payload,
  });
};

const getJobDamageReportsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobDamageReports: action.payload,
  });
};

const getJobReportFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const jobReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_JOB_REPORT_START:
      return getJobReportStart(state, action);
    case actionTypes.GET_JOB_TECH_REPORT_SUCCESS:
      return getJobTechReportSuccess(state, action);
    case actionTypes.GET_JOB_TECH_REPORTS_SUCCESS:
      return getJobTechReportsSuccess(state, action);
    case actionTypes.GET_JOB_DAMAGE_REPORT_SUCCESS:
      return getJobDamageReportSuccess(state, action);
    case actionTypes.GET_JOB_DAMAGE_REPORTS_SUCCESS:
      return getJobDamageReportsSuccess(state, action);
    case actionTypes.GET_JOB_REPORT_FAIL:
      return getJobReportFaild(state, action);
    default:
      return state;
  }
};

export default jobReportReducer;
