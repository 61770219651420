import * as actionTypes from '../actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../../settings';

export const getCategoryStart = () => {
  return {
    type: actionTypes.GET_CATEGORY_START,
  };
};

export const getCategorySuccess = (data) => {
  return {
    type: actionTypes.GET_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const getCategoriesSuccess = (data) => {
  return {
    type: actionTypes.GET_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const getCategoryFaild = (error) => {
  return {
    type: actionTypes.GET_CATEGORY_FAIL,
    error: error,
  };
};

export const getCategories = (token) => {
  return (dispatch) => {
    dispatch(getCategoryStart());
    console.log('getting all Categorys');
    axios
      .get(`${HOST_URL}/categories/category/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCategoriesSuccess(response.data)))
      .catch((error) => dispatch(getCategoryFaild(error)));
  };
};

export const getCategory = (token, id) => {
  return (dispatch) => {
    dispatch(getCategoryStart());

    axios
      .get(`${HOST_URL}/categories/category/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCategorySuccess(response.data)))
      .catch((error) => dispatch(getCategoryFaild(error)));
  };
};

export const updateCategory = (token, categoryID, title, content) => {
  return (dispatch) => {
    dispatch(getCategoryStart());
    let fd = new FormData();
    fd.append('title', title);
    fd.append('content', content);

    axios
      .patch(`${HOST_URL}/categories/category/${categoryID}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getCategorySuccess(response.data));
        dispatch(getCategories(token));
      })
      .catch((error) => dispatch(getCategoryFaild(error)));
  };
};

export const saveCategory = (token, category, title, content) => {
  return (dispatch) => {
    dispatch(getCategoryStart());
    let fd = new FormData();
    fd.append('category', category);
    fd.append('title', title);
    fd.append('content', content);

    axios
      .post(`${HOST_URL}/categories/category/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getCategorySuccess(response.data));
        dispatch(getCategories(token));
      })
      .catch((error) => dispatch(getCategoryFaild(error)));
  };
};

export const deleteCategory = (token, categoryID) => {
  return (dispatch) => {
    dispatch(getCategoryStart());

    axios
      .delete(`${HOST_URL}/categories/category/${categoryID}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getCategorySuccess(response.data));
        dispatch(getCategories(token));
      })
      .catch((error) => dispatch(getCategoryFaild(error)));
  };
};
