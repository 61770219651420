import React, { Component } from 'react';
import './styles/company-form.css';
import TextField from '@mui/material/TextField';

export default class CompanyForm extends Component {
  state = {
    name: '',
    country: '',
    size: '',
    number_branches: 1,
    address: '',
    email: '',
    telephone_number: '',
    fax_number: '',
    website: '',
    registraion_number: '',
    vat_registraion_number: '',
  };

  componentDidMount() {
    const savedData = JSON.parse(localStorage.getItem('company_form'));
    if (savedData) {
      this.setState({ ...savedData });
      if (savedData.name !== '' && savedData.country !== '') {
        this.props.updateAllowNext(true);
      } else {
        this.props.updateAllowNext(false);
      }
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      localStorage.setItem('company_form', JSON.stringify(this.state));
      const { name, country } = this.state;
      if (name !== '' && country !== '') {
        this.props.updateAllowNext(true);
      } else {
        this.props.updateAllowNext(false);
      }
    });
  };
  render() {
    return (
      <div className="COMPAN_DETAILS_FORMS_CONTAINER">
        <div className="COMPANY_FORM_CONTAINER">
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="company-name"
              label="Company Name"
              variant="outlined"
              name="name"
              fullWidth
              required
              value={this.state.name}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="country"
              label="Country"
              variant="outlined"
              fullWidth
              required
              name="country"
              value={this.state.country}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="company-address"
              label="Full address"
              variant="outlined"
              fullWidth
              name="address"
              value={this.state.address}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="company-size"
              label="Company Size"
              variant="outlined"
              fullWidth
              name="size"
              value={this.state.size}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="registration-number"
              label="Registration number"
              variant="outlined"
              fullWidth
              name="registraion_number"
              value={this.state.registraion_number}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
        </div>
        <div className="COMPANY_FORM_CONTAINER">
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="company-email"
              label="Company Email"
              variant="outlined"
              name="email"
              fullWidth
              value={this.state.email}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="company-phone"
              label="Phone number"
              variant="outlined"
              fullWidth
              name="telephone_number"
              value={this.state.telephone_number}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="fax-number"
              label="Fax number"
              variant="outlined"
              fullWidth
              name="fax_number"
              value={this.state.fax_number}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="website"
              label="Company website"
              variant="outlined"
              fullWidth
              name="website"
              value={this.state.website}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="vat-number"
              label="Vat number"
              variant="outlined"
              name="vat_registraion_number"
              fullWidth
              value={this.state.vat_registraion_number}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
        </div>
      </div>
    );
  }
}
