import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getJobTaskStart = () => {
  return {
    type: actionTypes.JOB_TASK_START,
  };
};

export const getJobTaskSuccess = (data) => {
  return {
    type: actionTypes.JOB_TASK_SUCCESS,
    payload: data,
  };
};

export const getTargetJobTaskSuccess = (data) => {
  return {
    type: actionTypes.TARGET_JOB_TASK_SUCCESS,
    payload: data,
  };
};

export const getJobTasksSuccess = (data) => {
  return {
    type: actionTypes.JOB_TASKS_SUCCESS,
    payload: data,
  };
};

export const getJobFilteredTasksSuccess = (data) => {
  return {
    type: actionTypes.FILTERED_JOB_TASK_SUCCESS,
    payload: data,
  };
};

export const getOverdueTasksSuccess = (data) => {
  return {
    type: actionTypes.OVERDUE_JOB_TASK_SUCCESS,
    payload: data,
  };
};

export const getJobTaskFaild = (error) => {
  return {
    type: actionTypes.JOB_TASK_FAIL,
    error: error,
  };
};

export const getJobTasks = (token) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    console.log('getting all tasks');
    axios
      .get(`${HOST_URL}/tasks/job-task/user_1/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const getLastNJobTasks = (token, N) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    axios
      .get(`${HOST_URL}/tasks/job-task/user_${N}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const getFilteredJobTasks = (token, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    console.log('getting all tasks');
    axios
      .get(`${HOST_URL}/tasks/job-task/date_${startDate}_${endDate}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobFilteredTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const getFilteredJobTasksByKeywork = (token, keyword) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    console.log('getting all tasks');
    axios
      .get(`${HOST_URL}/tasks/job-task/keyword_${keyword.replace(/ /g, '')}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobFilteredTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const getOverdueJobTasks = (token) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    console.log('getting over due tasks');
    axios
      .get(`${HOST_URL}/tasks/overdue-job-task/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOverdueTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const getJobTasksByEmployee = (token, employee, name) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    console.log('getting all tasks');
    axios
      .get(`${HOST_URL}/tasks/job-task/${employee}_${name}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const getNJobTasksByEmployee = (token, employee, name, N) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    console.log('getting all tasks');
    axios
      .get(`${HOST_URL}/tasks/job-task/${employee}_${name}_${N}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const getJobTask = (token, id) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());

    axios
      .get(`${HOST_URL}/tasks/job-task/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobTaskSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

// used when getting job task data for an invoice
export const getJobTaskByNumber = (token, jobTaskNumber) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());

    axios
      .get(`${HOST_URL}/tasks/job-task/jt-number_${jobTaskNumber}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTargetJobTaskSuccess(response.data)))
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const postJobTask = (
  token,
  task_number,
  assigned_by,
  assigned_to,
  due_date,
  job_card,
  task_status,
  priority
) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    let fd = new FormData();
    fd.append('task_number', task_number);
    fd.append('assigned_by', assigned_by);
    fd.append('assigned_to', assigned_to);
    fd.append('due_date', due_date);
    fd.append('job_card', job_card);
    fd.append('status', task_status);
    fd.append('priority', priority);

    axios
      .post(`${HOST_URL}/tasks/job-task/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobTaskSuccess(response.data));
        dispatch(getLastNJobTasks(token, 10));
      })
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const updateJobTask = (
  token,
  job_task_id,
  task_number,
  assigned_by,
  assigned_to,
  due_date,
  job_card,
  task_status,
  priority
) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    let fd = new FormData();
    fd.append('task_number', task_number);
    fd.append('assigned_by', assigned_by);
    fd.append('assigned_to', assigned_to);
    fd.append('due_date', due_date);
    fd.append('job_card', job_card);
    fd.append('status', task_status);
    fd.append('priority', priority);

    axios
      .put(`${HOST_URL}/tasks/job-task/${job_task_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobTaskSuccess(response.data));
        dispatch(getLastNJobTasks(token, 10));
      })
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};

export const updateJobTaskStatus = (token, job_task_id, task_status) => {
  return (dispatch) => {
    dispatch(getJobTaskStart());
    let fd = new FormData();
    fd.append('status', task_status);

    axios
      .patch(`${HOST_URL}/tasks/job-task/${job_task_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobTaskSuccess(response.data));
        dispatch(getJobTask(token, job_task_id));
      })
      .catch((error) => dispatch(getJobTaskFaild(error)));
  };
};
