import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  error: null,
  loading: false,
  transaction: null,
  transactions: [],
  unallocated: [],
};

const getTransactionStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getTransactionSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    transaction: action.payload,
  });
};

const getTransactionsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    transactions: action.payload,
  });
};

const getUnallocatedTransactionsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    unallocated: action.payload,
  });
};

const getTransactionFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSACTION_START:
      return getTransactionStart(state, action);
    case actionTypes.GET_TRANSACTION_SUCCESS:
      return getTransactionSuccess(state, action);
    case actionTypes.GET_TRANSACTIONS_SUCCESS:
      return getTransactionsSuccess(state, action);
    case actionTypes.GET_UNALLOCATED_TRANSACTIONS_SUCCESS:
      return getUnallocatedTransactionsSuccess(state, action);
    case actionTypes.GET_TRANSACTION_FAIL:
      return getTransactionFaild(state, action);
    default:
      return state;
  }
};

export default transactionReducer;
