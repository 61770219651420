import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './styles/job-task-form.css';
import Fab from '@mui/material/Fab';
import JobComment from '../job-comment/JobComment';
import TextField from '@mui/material/TextField';
import JobImagesForm from '../job-images-form/JobImagesForm';
import OthersJobComment from '../job-comment/OthersJobComment';
import {
  deleteJobComment,
  getJobComments,
  postJobComment,
} from '../../store/actions/jobComment';
import { getJobCard, getJobCards } from '../../store/actions/jobCard';
import JobTechReport from '../jobReports/JobTechReport';
import JobDamageReport from '../jobReports/JobDamageReport';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import MenuItem from '@mui/material/MenuItem';
import CircleIcon from '@mui/icons-material/Circle';
import { updateJobTaskStatus, getJobTask } from '../../store/actions/jobTask';
import { getTaskStatuses } from '../../store/actions/taskStatus';
import { postNotificationData } from '../../store/actions/notification';

class JobTaskForm extends Component {
  state = {
    comment: '',
    showUpdatedComments: false,
    showStatusMenu: false,
  };

  componentDidMount() {
    const token = this.props.token;
    if (this.props.jobTask) {
      const id = this.props.jobTask.job_card;
      this.props.getJobCard(token, id);
    } else {
      const taskID = localStorage.getItem('activeTask');
      this.props.getActiveTaskDetails(token, taskID);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.jobComments !== prevProps.jobComments) {
      this.setState({ showUpdatedComments: true, comment: '' });
    }

    if (this.props.jobTask && this.props.jobCard === null) {
      const token = this.props.token;
      const id = this.props.jobTask.job_card;
      this.props.getJobCard(token, id);
    }
    if (this.props.taskStatuses.length === 0) {
      const token = this.props.token;
      this.props.getTaskStatuses(token);
    }
    if (this.props.jobTask !== prevProps.jobTask) {
      this.setState({ showUpdatedComments: false });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  toggleStatusMenu = () => {
    this.setState({ showStatusMenu: !this.state.showStatusMenu });
  };

  postComment = () => {
    console.log('ssssss');
    const token = this.props.token;
    const task_id = this.props.jobTask.id;
    const { comment } = this.state;
    const author = this.props.userDetails.username;

    this.props.postComment(token, task_id, author, comment);

    const taskNumber = this.props.jobTask.task_number;
    const title = `New comment from ${author}`;
    const description = `${author} commented on the Task ${taskNumber}`;
    const generated_to = this.props.jobTask.assigned_to;
    const generated_to_user = generated_to;
    const job_card = this.props.jobCard.id;
    this.createNotification(
      title,
      description,
      generated_to,
      generated_to_user,
      task_id,
      job_card
    );
  };

  handleDeleteComment = (comment_id) => {
    const token = this.props.token;
    const task = this.props.jobTask.id;
    this.props.deleteJobComment(token, comment_id, task);
  };

  updateTaskStatus = (statusID) => {
    const token = this.props.token;
    const taskID = this.props.jobTask.id;
    this.props.updateTaskStatus(token, taskID, statusID);
    this.setState({ showStatusMenu: false });
  };

  createNotification = (
    title,
    description,
    generated_to,
    generated_to_user,
    task,
    job_card
  ) => {
    const token = this.props.token;
    const generated_by = this.props.userDetails.username;

    this.props.generateNotification(
      token,
      title,
      description,
      false,
      generated_by,
      generated_to,
      generated_to_user,
      task,
      job_card
    );
  };

  render() {
    const mode = this.props.mode;
    return (
      <div className="JOB_TASK_FORM_CONTAINER" id={mode}>
        {!this.props.jobTaskLoading && this.props.jobTask ? (
          <div className="JOB_TASK_HOT_ACTIONS_CONTAINER">
            <div style={{ color: '#30475E', fontFamily: 'Arial' }}>
              <Fab
                sx={{ bgcolor: '#787A91', ml: 1 }}
                variant="extended"
                size="small"
                color="inherit"
                aria-label="add"
              >
                Task No : {this.props.jobTask.task_number}
              </Fab>
            </div>
            <div style={{ color: '#222831', fontFamily: 'Arial' }}>
              <Fab
                sx={{ bgcolor: '#595B83', ml: 1 }}
                variant="extended"
                size="small"
                color="inherit"
                aria-label="add"
              >
                {moment(this.props.jobTask.created).calendar()}
              </Fab>
            </div>
            <div className="TASK_STATUS_MAIN_CONTAINER">
              <div
                style={{ color: '#30475E', fontFamily: 'Arial' }}
                onClick={this.toggleStatusMenu}
              >
                <Fab
                  sx={{ bgcolor: this.props.jobTask.status.colour, ml: 1 }}
                  variant="extended"
                  size="small"
                  color="inherit"
                  aria-label="add"
                >
                  {this.props.jobTask.status.status}
                </Fab>
              </div>
              {this.state.showStatusMenu ? (
                <div className="TASK_SELECT_STATUS_MENU">
                  {this.props.taskStatuses.length !== 0
                    ? this.props.taskStatuses.map((status, index) => (
                        <MenuItem
                          sx={{ width: '100px', color: status.colour }}
                          onClick={() => this.updateTaskStatus(status.id)}
                        >
                          <CircleIcon sx={{ mr: 1 }} />
                          {status.status}
                        </MenuItem>
                      ))
                    : null}
                </div>
              ) : null}
            </div>

            <div
              style={{
                color: '#283149',
                fontFamily: 'Arial',
              }}
            >
              <Fab
                sx={{ bgcolor: '#787A91', ml: 1 }}
                variant="extended"
                size="small"
                color="inherit"
                aria-label="add"
              >
                {this.props.jobTask.priority}
              </Fab>
            </div>
          </div>
        ) : (
          <CircularProgress></CircularProgress>
        )}

        <div className="JOB_TASK_DEVICE_INFO_CONTAINER">
          {this.props.jobTask ? (
            <>
              <div className="TECH_DEVICE_DETAILS">
                <span>Device</span>
                {this.props.jobCard ? (
                  <h4>
                    {this.props.jobCard.device_details.device_description}
                  </h4>
                ) : (
                  <CircularProgress></CircularProgress>
                )}
              </div>
              <div className="TECH_DEVICE_FAULT">
                <span>Fault</span>
                {this.props.jobCard ? (
                  <h4>{this.props.jobCard.device_details.device_fault}</h4>
                ) : (
                  <CircularProgress></CircularProgress>
                )}
              </div>
              <div className="TECH_DEVICE_FAULT_DESCRIPTION">
                <span>Fault Description</span>
                {this.props.jobCard ? (
                  <h4>{this.props.jobCard.device_details.fault_description}</h4>
                ) : (
                  <CircularProgress></CircularProgress>
                )}
              </div>
            </>
          ) : (
            <CircularProgress></CircularProgress>
          )}
        </div>
        <div className="JOB_TASK_IMAGES_CONTAINER">
          <JobImagesForm jobCard={this.props.jobCard}></JobImagesForm>
        </div>
        <div className="JOB_TASK_REPORTS_COMMENTS_CONTAINER">
          <div className="TECHNICIAN_WORKSPACE_JOB_COMMENTS_CONTAINER">
            {this.state.showUpdatedComments ? (
              <div>
                {this.props.jobComments
                  ? this.props.jobComments.map((comment, index) =>
                      comment.author === this.props.userDetails.username ? (
                        <JobComment
                          key={index}
                          deleteComment={() =>
                            this.handleDeleteComment(comment.id)
                          }
                          comment={comment}
                        ></JobComment>
                      ) : (
                        <OthersJobComment
                          key={index}
                          comment={comment}
                        ></OthersJobComment>
                      )
                    )
                  : null}
              </div>
            ) : (
              <div>
                {this.props.jobTask && this.props.jobTask.job_comment
                  ? this.props.jobTask.job_comment.map((comment, index) =>
                      comment.author === this.props.userDetails.username ? (
                        <JobComment
                          key={index}
                          deleteComment={() =>
                            this.handleDeleteComment(comment.id)
                          }
                          comment={comment}
                        ></JobComment>
                      ) : (
                        <OthersJobComment
                          key={index}
                          comment={comment}
                        ></OthersJobComment>
                      )
                    )
                  : this.props.jobComments &&
                    this.props.jobComments.length !== 0
                  ? this.props.jobComments.map((comment, index) =>
                      comment.author === this.props.userDetails.username ? (
                        <JobComment
                          key={index}
                          deleteComment={() =>
                            this.handleDeleteComment(comment.id)
                          }
                          comment={comment}
                        ></JobComment>
                      ) : (
                        <OthersJobComment
                          key={index}
                          comment={comment}
                        ></OthersJobComment>
                      )
                    )
                  : null}
              </div>
            )}
            <div className="TECHNICIAN_ADD_COMMENT_CONTAINER">
              <div className="TECHNICIAN_ADD_COMMENT_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-multiline-static"
                  label="Comment"
                  multiline
                  rows={2}
                  defaultValue=""
                  variant="outlined"
                  name="comment"
                  value={this.state.comment}
                  onChange={this.onChange}
                  // color="secondary"
                  type="text"
                  fullWidth
                  required
                  InputProps={{
                    className: 'JOB_TASK_COMMENT_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_TASK_COMMENT_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="TECHNICIAN_ADD_COMMENT_POST_BUTTON">
                <IconButton color="inherit" onClick={this.postComment}>
                  <SendIcon color="inherit" />
                </IconButton>
              </div>
            </div>
          </div>
          <div className="WORKSPACE_JOB_REPORTS_CONTAINER">
            {this.props.jobTask ? (
              <JobTechReport task={this.props.jobTask}></JobTechReport>
            ) : null}
            {this.props.jobCard ? (
              <JobDamageReport jobCard={this.props.jobCard}></JobDamageReport>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    userDetails: state.users.userDetails,
    jobComments: state.jobComment.comments,
    taskStatuses: state.taskStatus.taskStatuses,
    jobTask: state.jobTask.jobTask,
    jobTasks: state.jobTask.jobTasks,
    jobTaskLoading: state.jobTask.loading,
    jobCard: state.jobCard.jobCard,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getActiveTaskDetails: (token, task_id) =>
      dispatch(getJobTask(token, task_id)),
    getTaskComments: (token, task) => dispatch(getJobComments(token, task)),
    postComment: (token, task, author, comment) =>
      dispatch(postJobComment(token, task, author, comment)),
    deleteJobComment: (token, comment_id, task) =>
      dispatch(deleteJobComment(token, comment_id, task)),
    getJobCard: (token, id) => dispatch(getJobCard(token, id)),
    getTaskStatuses: (token) => dispatch(getTaskStatuses(token)),
    updateTaskStatus: (token, taskID, statusID) =>
      dispatch(updateJobTaskStatus(token, taskID, statusID)),
    generateNotification: (
      token,
      title,
      description,
      read,
      generated_by,
      generated_to,
      generated_to_user,
      task,
      job_card
    ) =>
      dispatch(
        postNotificationData(
          token,
          title,
          description,
          read,
          generated_by,
          generated_to,
          generated_to_user,
          task,
          job_card
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTaskForm);
