import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';
// import { getJobTask } from './jobTask';

export const getJobCommentStart = () => {
  return {
    type: actionTypes.GET_JOB_COMMENT_START,
  };
};

export const getJobCommentSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_COMMENT_SUCCESS,
    payload: data,
  };
};

export const getJobCommentsSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_COMMENTS_SUCCESS,
    payload: data,
  };
};

export const getJobCommentFaild = (error) => {
  return {
    type: actionTypes.GET_JOB_COMMENT_FAIL,
    error: error,
  };
};

export const getJobComments = (token, task) => {
  return (dispatch) => {
    dispatch(getJobCommentStart());
    console.log('getting all comments');
    axios
      .get(`${HOST_URL}/job-comments/job-comment/task-${task}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobCommentsSuccess(response.data)))
      .catch((error) => dispatch(getJobCommentFaild(error)));
  };
};

export const getJobComment = (token, id) => {
  return (dispatch) => {
    dispatch(getJobCommentStart());

    axios
      .get(`${HOST_URL}/job-comments/job-comment/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobCommentSuccess(response.data)))
      .catch((error) => dispatch(getJobCommentFaild(error)));
  };
};

export const deleteJobComment = (token, id, task) => {
  return (dispatch) => {
    dispatch(getJobCommentStart());

    axios
      .delete(`${HOST_URL}/job-comments/job-comment/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobComments(token, task)))
      .catch((error) => dispatch(getJobCommentFaild(error)));
  };
};

export const postJobComment = (token, task, author, comment) => {
  return (dispatch) => {
    dispatch(getJobCommentStart());
    let fd = new FormData();
    fd.append('task', task);
    fd.append('author', author);
    fd.append('comment', comment);

    axios
      .post(`${HOST_URL}/job-comments/job-comment/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobCommentSuccess(response.data));
        dispatch(getJobComments(token, task));
        // dispatch(getJobTask(token, task));
      })
      .catch((error) => dispatch(getJobCommentFaild(error)));
  };
};

export const updateJobComment = (
  token,
  job_comment_id,
  task,
  author,
  comment
) => {
  return (dispatch) => {
    dispatch(getJobCommentStart());
    let fd = new FormData();
    fd.append('job_comment_id', job_comment_id);
    fd.append('task', task);
    fd.append('author', author);
    fd.append('comment', comment);

    axios
      .patch(`${HOST_URL}/job-comments/job-comment/${job_comment_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobCommentSuccess(response.data));
        dispatch(getJobComments(token, task));
      })
      .catch((error) => dispatch(getJobCommentFaild(error)));
  };
};
