import React, { Component } from 'react';
import './styles/job-card-item.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import PrintIcon from '@mui/icons-material/Print';
import ImageCard from '../image-card/ImageCard';
import { HOST_URL } from '../../settings';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
// import { connect } from 'react-redux';
// import { deleteRegisteredDocument } from '../../store/actions/documents';

export default class JobCardItem extends Component {
  state = {
    value: '1',
    openImagePreview: false,
    previewFile: null,
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  // deleteSavedImage = (id) => {
  //   const token = this.props.token;
  //   const job_card_id = this.props.jobCard.id;

  //   this.props.deleteDocument(token, id, job_card_id);
  // };

  handleImagePreviewOpen = (file) => {
    console.log('opening preview');
    this.setState({ openImagePreview: true, previewFile: file });
  };

  handleImagePreviewClose = () => {
    this.setState({ openImagePreview: false });
  };

  render() {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: 1000,
      height: '80%',
      bgcolor: 'none',
      border: '2px solid #2c3e50',
      borderRadius: 4,
      boxShadow: 24,
      p: 2,
    };

    const jobCard = this.props.jobCard;
    const mode = this.props.mode;
    const tabStyle =
      mode === 'dark' ? { color: '#dddddd' } : { color: '#17293e' };
    return (
      <div className="JOB_CARD_ITEM_CONTAINER">
        <TabContext value={this.state.value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              onChange={this.handleChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Job Card" value="1" sx={tabStyle} />
              <Tab label="Device" value="2" sx={tabStyle} />
              <Tab label="Client" value="3" sx={tabStyle} />
              <Tab label="Insurance" value="4" sx={tabStyle} />
              <Tab label="Task" value="5" sx={tabStyle} />
              <Tab label="Image" value="6" sx={tabStyle} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Tooltip title="Print">
                  <Link
                    underline="none"
                    href={`${HOST_URL}/booking/job-card-to-pdf/${this.props.jobCard.id}/${this.props.memberKey}/`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <PrintIcon sx={tabStyle} />
                  </Link>
                </Tooltip>
              </Box>
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ width: '100%' }}>
            <div className="JOB_CARD_ITEM_CARD_DETAILS_CONTAINER">
              <div className="JOB_CARD_ITEM">
                <span>Job Card NO</span>
                <p>{jobCard.job_card_number}</p>
              </div>
              <div className="JOB_CARD_ITEM">
                <span>Booking Type</span>
                <p>{jobCard.booking_type}</p>
              </div>
              <div className="JOB_CARD_ITEM">
                <span>Booked By</span>
                <p>{jobCard.booked_by.username}</p>
              </div>
              <div className="JOB_CARD_ITEM">
                <span>Notes</span>
                <p>{jobCard.notes}</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2" sx={{ width: '100%' }}>
            <div className="JOB_CARD_ITEM_DEVICE_DETAILS_CONTAINER">
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Device Type</span>
                {/* <p>{jobCard.device_details.device_type}</p> */}
                <p>{jobCard.device_details.device_description}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Make</span>
                <p>{jobCard.device_details.make}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Model</span>
                <p>{jobCard.device_details.model}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Device Color</span>
                <p>{jobCard.device_details.colour}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Serial NO</span>
                <p>{jobCard.device_details.serial_number}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Password</span>
                <p>{jobCard.device_details.passcode}</p>
              </div>
            </div>
            <div className="JOB_CARD_ITEM_DEVICE_DETAILS_CONTAINER">
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Make</span>
                <p>{jobCard.device_details.make}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Device Condition</span>
                <p>{jobCard.device_details.device_condition}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Device Fault</span>
                <p>{jobCard.device_details.device_fault}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Fault Description</span>
                <p>{jobCard.device_details.fault_description}</p>
              </div>
              <div className="JOB_CARD_DEVICE_ITEM">
                <span>Accessories</span>
                <p>{jobCard.device_details.accessories}</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3" sx={{ width: '100%' }}>
            <div className="JOB_CARD_ITEM_CLIENT_DETAILS_CONTAINER">
              <div className="JOB_CARD_CLIENT_ITEM">
                <span>Name</span>
                <p>{jobCard.client_details.client_name}</p>
              </div>
              <div className="JOB_CARD_CLIENT_ITEM">
                <span>Email</span>
                <p>{jobCard.client_details.client_email}</p>
              </div>
              <div className="JOB_CARD_CLIENT_ITEM">
                <span>Phone NO</span>
                <p>{jobCard.client_details.client_number}</p>
              </div>
              <div className="JOB_CARD_CLIENT_ITEM">
                <span>Address</span>
                <p>{jobCard.client_details.client_address}</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="4" sx={{ width: '100%' }}>
            <div className="JOB_CARD_ITEM_INSURANCE_DETAILS_CONTAINER">
              <div className="JOB_CARD_INSURANCE_ITEM">
                <span>Company Name</span>
                <p>
                  {jobCard.insurance_details
                    ? jobCard.insurance_details.insurance_company_name
                    : ''}
                </p>
              </div>
              <div className="JOB_CARD_INSURANCE_ITEM">
                <span>Claim Tech</span>
                <p>{jobCard.claim_consultant}</p>
              </div>
              <div className="JOB_CARD_INSURANCE_ITEM">
                <span>Contact No</span>
                <p>{jobCard.consultant_contact_number}</p>
              </div>
              <div className="JOB_CARD_INSURANCE_ITEM">
                <span>Claim No</span>
                <p>{jobCard.claim_number}</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="5" sx={{ width: '100%' }}>
            {jobCard.task ? (
              <div className="JOB_CARD_ITEM_TASK_DETAILS_CONTAINER">
                <div className="JOB_CARD_TASK_ITEM">
                  <span>Task No</span>
                  <p>{jobCard.task.task_number}</p>
                </div>
                <div className="JOB_CARD_TASK_ITEM">
                  <span>Assigned By</span>
                  <p>{jobCard.task.assigned_by}</p>
                </div>
                <div className="JOB_CARD_TASK_ITEM">
                  <span>Assigned To</span>
                  <p>{jobCard.task.assigned_to}</p>
                </div>
                <div className="JOB_CARD_TASK_ITEM">
                  <span>Status</span>
                  <p>{jobCard.task.status.status}</p>
                </div>
                <div className="JOB_CARD_TASK_ITEM">
                  <span>Priority</span>
                  <p>{jobCard.task.priority}</p>
                </div>
                <div className="JOB_CARD_TASK_ITEM">
                  <span>Assigned By</span>
                  <p>{jobCard.task.assigned_by}</p>
                </div>
              </div>
            ) : null}
          </TabPanel>
          <TabPanel value="6" sx={{ width: '100%' }}>
            <div className="JOB_CARD_ITEM_IMAGES_CONTAINER">
              {jobCard
                ? jobCard.documet.map((document, index) => (
                    <div
                      className="JOB_CARD_UPLOADED_IMAGE_CONTAINER"
                      key={index}
                    >
                      <ImageCard
                        file={document.document_image}
                        deleteImage={null}
                        openPreview={() =>
                          this.handleImagePreviewOpen(document.document_image)
                        }
                      ></ImageCard>
                    </div>
                  ))
                : null}
            </div>
          </TabPanel>
        </TabContext>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openImagePreview}
          onClose={this.handleImagePreviewClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openImagePreview}>
            <Box sx={style}>
              <div className="PREVIEW_MODAL_IMAGE_HEADER">
                <Avatar
                  sx={{ bgcolor: '#393E46' }}
                  onClick={this.handleImagePreviewClose}
                >
                  <CloseIcon></CloseIcon>
                </Avatar>
              </div>
              <div className="PREVIEW_MODAL_IMAGE_CONTAINER">
                {this.state.previewFile ? (
                  <img src={this.state.previewFile} alt="repair cocument"></img>
                ) : null}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

// const mapStateToProps = (state) => {
//   return {
//     token: state.users.token,
//     documents: state.document.documents,
//     progress: state.document.progress,
//     activeJobCard: state.jobCard.jobCard,
//     loadingActiveJobCard: state.jobCard.loading,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     deleteDocument: (token, id, job_card_id) =>
//       dispatch(deleteRegisteredDocument(token, id, job_card_id)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(JobCardItem);
