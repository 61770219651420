import * as actionTypes from '../actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../../settings';

export const getProductStart = () => {
  return {
    type: actionTypes.GET_PRODUCT_START,
  };
};

export const getProductSuccess = (data) => {
  return {
    type: actionTypes.GET_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const getProductsSuccess = (data) => {
  return {
    type: actionTypes.GET_PRODUCTS_SUCCESS,
    payload: data,
  };
};

export const getProductFaild = (error) => {
  return {
    type: actionTypes.GET_PRODUCT_FAIL,
    error: error,
  };
};

export const getProducts = (token) => {
  return (dispatch) => {
    dispatch(getProductStart());
    console.log('getting all Products');
    axios
      .get(`${HOST_URL}/products/product/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getProductsSuccess(response.data)))
      .catch((error) => dispatch(getProductFaild(error)));
  };
};

export const getProduct = (token, id) => {
  return (dispatch) => {
    dispatch(getProductStart());

    axios
      .get(`${HOST_URL}/products/product/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getProductSuccess(response.data)))
      .catch((error) => dispatch(getProductFaild(error)));
  };
};
