import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/suppliers-form.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SupplierTable from './supplier-table/SupplierTable';
import {
  deleteSupplier,
  getSupplier,
  getSuppliers,
  saveSupplier,
  updateSupplier,
} from '../../../store/actions/inventory/supplier';

class SuppliresForm extends Component {
  state = {
    supplierID: null,
    name: '',
    address: '',
    email: '',
    alternativeEmail: '',
    phoneNumber: '',
    alternativePhoneNumber: '',
    vatNumber: '',
    salesPerson: '',
    nameError: false,
    addressError: false,
    emailError: false,
    alternativeEmailError: false,
    phoneNumberError: false,
    alternativePhoneNumberError: false,
    vatNumberError: false,
    salesPersonError: false,
  };

  componentDidMount() {
    const token = this.props.token;
    this.props.getSuppliers(token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.suppliers !== prevProps.suppliers) {
      this.setState({
        name: '',
        address: '',
        email: '',
        alternativeEmail: '',
        phoneNumber: '',
        alternativePhoneNumber: '',
        vatNumber: '',
        salesPerson: '',
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + `Error`]: false,
    });
  };

  validateSupplierData = () => {
    const { name, address, email, phoneNumber } = this.state;
    if (name === '' || address === '' || email === '' || phoneNumber === '') {
      this.setState({
        nameError: true,
        addressError: true,
        emailError: true,
        phoneNumberError: true,
      });
      return false;
    } else {
      return true;
    }
  };

  handleSaveSupplier = () => {
    const valid = this.validateSupplierData();
    if (valid) {
      const token = this.props.token;
      console.log('save');
      const {
        name,
        address,
        email,
        alternativeEmail,
        phoneNumber,
        alternativePhoneNumber,
        vatNumber,
        salesPerson,
      } = this.state;
      this.props.saveSupplier(
        token,
        name,
        address,
        email,
        alternativeEmail,
        phoneNumber,
        alternativePhoneNumber,
        vatNumber,
        salesPerson
      );
    }
  };

  handleEditSupplierDetails = (supplier) => {
    this.setState({
      supplierID: supplier.id,
      name: supplier.name,
      salesPerson: supplier.sales_name,
      address: supplier.address,
      email: supplier.email,
      alternativeEmail: supplier.alternative_email,
      phoneNumber: supplier.phone_number,
      alternativePhoneNumber: supplier.alternative_phone_number,
      vatNumber: supplier.vat_number,
    });
  };

  updateSupplierDetails = () => {
    const token = this.props.token;
    console.log('update');
    const {
      supplierID,
      name,
      address,
      email,
      alternativeEmail,
      phoneNumber,
      alternativePhoneNumber,
      vatNumber,
      salesPerson,
    } = this.state;
    this.props.updateSupplier(
      token,
      supplierID,
      name,
      address,
      email,
      alternativeEmail,
      phoneNumber,
      alternativePhoneNumber,
      vatNumber,
      salesPerson
    );
  };

  clearSupplierDetails = () => {
    this.setState({
      supplierID: null,
      name: '',
      address: '',
      email: '',
      alternativeEmail: '',
      phoneNumber: '',
      alternativePhoneNumber: '',
      vatNumber: '',
      salesPerson: '',
      nameError: false,
      addressError: false,
      emailError: false,
      alternativeEmailError: false,
      phoneNumberError: false,
      alternativePhoneNumberError: false,
      vatNumberError: false,
      salesPersonError: false,
    });
  };

  handleDeleteSupplier = (supplierID) => {
    const token = this.props.token;
    this.props.deleteSupplier(token, supplierID);
  };

  render() {
    return (
      <div className="SUPPLIER_FORM_MAIN_CONTAINER">
        <div className="SUPPLIER_FORM_HEADER_CONTAINER">
          <h1>SUPPLIERS</h1>
        </div>
        <div className="SUPPLIER_FORM_FIELDS_MAIN_CONTAINER">
          <div className="SUPPLIER_FORM_INPUT_GROUP_MAIN_CONTAINER">
            <div className="SUPPLIER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="supplier-name"
                label="Supplier Name"
                variant="outlined"
                name="name"
                fullWidth
                value={this.state.name}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton aria-label="info">
                  //       <InfoOutlinedIcon sx={{ color: '#719FB0' }} size={20} />
                  //     </IconButton>
                  //   </React.Fragment>
                  // ),
                  className: 'SUPPLIER_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SUPPLIER_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="SUPPLIER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="supplier-address"
                label="Supplier Address"
                variant="outlined"
                name="address"
                fullWidth
                value={this.state.address}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton aria-label="info">
                  //       <InfoOutlinedIcon sx={{ color: '#719FB0' }} size={20} />
                  //     </IconButton>
                  //   </React.Fragment>
                  // ),
                  className: 'SUPPLIER_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SUPPLIER_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="SUPPLIER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="supplier-email"
                label="Supplier Email"
                variant="outlined"
                name="email"
                fullWidth
                value={this.state.email}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton aria-label="info">
                  //       <InfoOutlinedIcon sx={{ color: '#719FB0' }} size={20} />
                  //     </IconButton>
                  //   </React.Fragment>
                  // ),
                  className: 'SUPPLIER_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SUPPLIER_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="SUPPLIER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="supplier-alternative-email"
                label="Alternative Email"
                variant="outlined"
                name="alternativeEmail"
                fullWidth
                value={this.state.alternativeEmail}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton aria-label="info">
                  //       <InfoOutlinedIcon sx={{ color: '#719FB0' }} size={20} />
                  //     </IconButton>
                  //   </React.Fragment>
                  // ),
                  className: 'SUPPLIER_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SUPPLIER_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="SUPPLIER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="sales-person-name"
                label="Sales Person"
                variant="outlined"
                name="salesPerson"
                fullWidth
                value={this.state.salesPerson}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton aria-label="info">
                  //       <InfoOutlinedIcon sx={{ color: '#719FB0' }} size={20} />
                  //     </IconButton>
                  //   </React.Fragment>
                  // ),
                  className: 'SUPPLIER_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SUPPLIER_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="SUPPLIER_FORM_INPUT_GROUP_MAIN_CONTAINER">
            <div className="SUPPLIER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="phone-number"
                label="Phone Number"
                variant="outlined"
                name="phoneNumber"
                fullWidth
                value={this.state.phoneNumber}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton aria-label="info">
                  //       <InfoOutlinedIcon sx={{ color: '#719FB0' }} size={20} />
                  //     </IconButton>
                  //   </React.Fragment>
                  // ),
                  className: 'SUPPLIER_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SUPPLIER_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="SUPPLIER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="alternative-phone-number"
                label="Alternative Phone Number"
                variant="outlined"
                name="alternativePhoneNumber"
                fullWidth
                value={this.state.alternativePhoneNumber}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton aria-label="info">
                  //       <InfoOutlinedIcon sx={{ color: '#719FB0' }} size={20} />
                  //     </IconButton>
                  //   </React.Fragment>
                  // ),
                  className: 'SUPPLIER_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SUPPLIER_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="SUPPLIER_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="vat-number"
                label="VAT Number"
                variant="outlined"
                name="vatNumber"
                fullWidth
                value={this.state.vatNumber}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  // endAdornment: (
                  //   <React.Fragment>
                  //     <IconButton aria-label="info">
                  //       <InfoOutlinedIcon sx={{ color: '#719FB0' }} size={20} />
                  //     </IconButton>
                  //   </React.Fragment>
                  // ),
                  className: 'SUPPLIER_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'SUPPLIER_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            {this.loadingSuppliers ? (
              <div className="SUPPLIER_FORM_ACTIONS_CONTAINER">
                <CircularProgress></CircularProgress>
              </div>
            ) : (
              <div className="SUPPLIER_FORM_ACTIONS_CONTAINER">
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ margin: 2, backgroundColor: '#162447', color: 'white' }}
                  onClick={this.handleSaveSupplier}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ margin: 2, backgroundColor: '#162447', color: 'white' }}
                  onClick={this.updateSupplierDetails}
                >
                  Update
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="error"
                  sx={{ margin: 2 }}
                  onClick={this.clearSupplierDetails}
                >
                  Reset
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="SUPPLIER_TABLE_MAIN_CONTAINER">
          <div className="SUPPLIER_TABLE_CONTAINER">
            <SupplierTable
              suppliers={
                this.props.suppliers && this.props.suppliers[0] !== undefined
                  ? this.props.suppliers
                  : []
              }
              editSupplier={(supplier) =>
                this.handleEditSupplierDetails(supplier)
              }
              deleteSupplier={this.handleDeleteSupplier}
              mode={this.props.mode}
            ></SupplierTable>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    suppliers: state.supplier.suppliers,
    loadingSuppliers: state.supplier.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSuppliers: (token) => dispatch(getSuppliers(token)),
    updateSupplier: (
      token,
      supplierID,
      name,
      address,
      email,
      alternativeEmail,
      phoneNumber,
      alternativePhoneNumber,
      vatNumber,
      salesPerson
    ) =>
      dispatch(
        updateSupplier(
          token,
          supplierID,
          name,
          address,
          email,
          alternativeEmail,
          phoneNumber,
          alternativePhoneNumber,
          vatNumber,
          salesPerson
        )
      ),
    saveSupplier: (
      token,
      name,
      address,
      email,
      alternativeEmail,
      phoneNumber,
      alternativePhoneNumber,
      vatNumber,
      salesPerson
    ) =>
      dispatch(
        saveSupplier(
          token,
          name,
          address,
          email,
          alternativeEmail,
          phoneNumber,
          alternativePhoneNumber,
          vatNumber,
          salesPerson
        )
      ),
    deleteSupplier: (token, supplierID) =>
      dispatch(deleteSupplier(token, supplierID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SuppliresForm);
