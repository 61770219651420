import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/user-profile.css';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import {
  deleteUserProfileImage,
  postUserProfileImage,
  updateUserProfileImage,
} from '../../store/actions/auth';

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profilePicture: null,
      profilePictureDetails: [],
      firstName: '',
      lastName: '',
      email: '',
      jobTitle: '',
      userPermissions: [],
      popUpModalMessageTitle: '',
      popUpModalMessageBody: '',
      openPopUpModal: false,
    };

    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.userDetails && this.props.userDetails.team_member) {
      this.handleSetUserDetails();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.userDetails &&
      this.props.userDetails.team_member
    ) {
      this.handleSetUserDetails();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onImgChange = (e) => {
    const image = e.target.files;
    let reader = new FileReader();
    console.log(image);

    reader.readAsDataURL(image[0]);
    reader.onloadend = () => {
      this.setState({
        profilePicture: reader.result,
        profilePictureDetails: image,
      });
    };
  };

  saveProfilePicture = () => {
    if (this.state.profilePictureDetails.length > 0) {
      const token = this.props.token;
      const { profilePicture, profilePictureDetails } = this.state;
      const picture_name = profilePictureDetails[0].name;
      const picture_type = profilePictureDetails[0].type;
      const picture_size = profilePictureDetails[0].size;
      console.log(picture_name, picture_type, picture_size);
      if (this.props.userDetails.team_member.profile_picture) {
        const imageID = this.props.userDetails.team_member.profile_picture.id;
        this.props.updateProfilePicture(
          token,
          imageID,
          profilePictureDetails[0]
        );
      } else {
        this.props.saveProfilePicture(token, profilePictureDetails[0]);
      }
    }
  };

  handleDeletingImage = () => {
    this.handlePopUpOpen(
      'Warning',
      'Are you sure you want to remove your profile picture?'
    );
  };

  removeProfilePicture = () => {
    this.setState({
      popUpModalMessageTitle: '',
      popUpModalMessageBody: '',
      openPopUpModal: false,
    });
    const token = this.props.token;
    if (this.props.userDetails.team_member.profile_picture) {
      const imageID = this.props.userDetails.team_member.profile_picture.id;
      this.props.removeProfilePicture(token, imageID);
    }
  };

  handleSetUserDetails = () => {
    const { team_member } = this.props.userDetails;
    const { permissions } = this.props.userDetails.team_member;

    this.setState({
      userPermissions: permissions,
      firstName: team_member.first_name,
      lastName: team_member.last_name,
      email: team_member.email,
      jobTitle: team_member.job_title,
    });
  };

  handlePopUpClose = () => {
    this.setState({ openPopUpModal: false });
  };

  handlePopUpOpen = (title, body) => {
    this.setState({
      popUpModalMessageTitle: title,
      popUpModalMessageBody: body,
      openPopUpModal: true,
    });
  };
  render() {
    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    return (
      <div className="USER_PROFILE_MAIN_CONTAINER">
        <div className="USER_PROFILE_PICTURE_CONTAINER">
          <div className="USER_PROFILE_PICTURE">
            {this.props.loading ? (
              <Avatar sx={{ height: '300px', width: '300px' }}>
                <CircularProgress color="warning" size={50} />
              </Avatar>
            ) : (
              <>
                {!this.state.profilePicture &&
                this.props.userDetails &&
                this.props.userDetails.team_member.profile_picture ? (
                  <Avatar
                    sx={{ height: '300px', width: '300px' }}
                    alt={this.props.userDetails.team_member.first_name}
                    src={
                      this.props.userDetails.team_member.profile_picture.picture
                    }
                  />
                ) : this.state.profilePicture ? (
                  <Avatar
                    sx={{ height: '300px', width: '300px' }}
                    alt="No Image"
                    src={this.state.profilePicture}
                  />
                ) : (
                  <Avatar
                    sx={{ height: '300px', width: '300px' }}
                    alt="No Image"
                    src=""
                  />
                )}
                :
              </>
            )}
            <div className="USER_PROFILE_PICTURE_BUTTON">
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
                sx={{ width: 50, height: 50 }}
              >
                <input
                  hidden
                  accept="image/*"
                  type="file"
                  ref={this.fileInputRef}
                  onChange={this.onImgChange}
                />
                <PhotoCamera
                  sx={{ fontSize: 30, color: '#E2703A' }}
                ></PhotoCamera>
              </IconButton>
              {this.props.userDetails &&
              this.props.userDetails.team_member.profile_picture ? (
                <IconButton
                  aria-label="delete picture"
                  component="label"
                  sx={{ width: 50, height: 50 }}
                  onClick={this.handleDeletingImage}
                >
                  <DeleteIcon
                    sx={{ fontSize: 30, color: '#F05454' }}
                  ></DeleteIcon>
                </IconButton>
              ) : null}
            </div>
          </div>
          <div className="USER_PROFILE_SAVE_BUTTON_CONTAINER">
            {this.props.loading ? (
              <CircularProgress color="warning" size={30} />
            ) : (
              <Button
                variant="outlined"
                size="small"
                color="warning"
                onClick={this.saveProfilePicture}
              >
                Save
              </Button>
            )}
          </div>
        </div>
        <div className="USER_PROFILE_CONTACT_DETAILS_CONTAINER">
          <div className="USER_PROFILE_CONTACT_DETAILS_HEADER_CONTAINER">
            <h1>My Details</h1>
          </div>
          <div className="USER_PROFILE_CONTACT_DETAILS_BODY_CONTAINER">
            <div className="USER_PROFILE_INPUT_GROUP_CONTAINER">
              <div className="USER_PROFILE_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="First Name"
                  variant="outlined"
                  name="firstName"
                  fullWidth
                  contentEditable={false}
                  value={this.state.firstName}
                  // onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="USER_PROFILE_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Last Name"
                  variant="outlined"
                  name="lastName"
                  fullWidth
                  contentEditable={false}
                  value={this.state.lastName}
                  // onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="USER_PROFILE_INPUT_GROUP_CONTAINER">
              <div className="USER_PROFILE_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="outlined"
                  name="email"
                  fullWidth
                  contentEditable={false}
                  value={this.state.email}
                  // onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="USER_PROFILE_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Report Number"
                  variant="outlined"
                  name="jobTitle"
                  fullWidth
                  contentEditable={false}
                  value={this.state.jobTitle}
                  // onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'USER_PROFILE_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'USER_PROFILE_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
          </div>
        </div>
        <div className="USER_PROFILE_PERMISSIONS_CONTAINER">
          <div className="USER_PROFILE_CONTACT_DETAILS_HEADER_CONTAINER">
            <h1>My Permissions</h1>
          </div>
          <div className="USER_PROFILE_PERMISSION_BODY_CONTAINER">
            {this.state.userPermissions.map((permission, index) => (
              <div key={permission.permission_title}>
                <Chip
                  sx={{ bgcolor: '#bbbbbb' }}
                  label={permission.permission_title}
                  variant="outlined"
                  onDelete={() => console.log('clicked')}
                  deleteIcon={
                    <Tooltip
                      TransitionComponent={Zoom}
                      TransitionProps={{ timeout: 600 }}
                      title={permission.permission_description}
                      placement="top"
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: '#dddddd',
                            color: '#0b0f19',
                            lineHeight: '20px',
                            fontSize: '0.85rem',
                          },
                        },
                      }}
                    >
                      <InfoOutlinedIcon sx={{ color: '#719FB0' }} />
                    </Tooltip>
                  }
                />
              </div>
            ))}
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openPopUpModal}
          onClose={this.handlePopUpClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openPopUpModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.popUpModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.popUpModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={this.removeProfilePicture}
                >
                  Confirm
                </Button>
                <Button variant="outlined" onClick={this.handlePopUpClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    loading: state.users.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveProfilePicture: (token, picture) =>
      dispatch(postUserProfileImage(token, picture)),
    updateProfilePicture: (token, imageID, picture) =>
      dispatch(updateUserProfileImage(token, imageID, picture)),
    removeProfilePicture: (token, imageID) =>
      dispatch(deleteUserProfileImage(token, imageID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
