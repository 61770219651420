import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/job-card-form.css';
import TextField from '@mui/material/TextField';
import * as documentAction from '../../store/actions/documents';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { postJobCard, getJobCard } from '../../store/actions/jobCard';
import { getClients } from '../../store/actions/client';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImageCard from '../../components/image-card/ImageCard';
import CircularProgress from '@mui/material/CircularProgress';
import ProgressCircle from '../../components/progress-circle/ProgressCircle';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { getClaims, getInsurances } from '../../store/actions/insurance';

class JobCardForm extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }

  state = {
    date: null,
    jobCardNumber: '',
    bookingType: '',
    notes: '',
    clientName: '',
    clientEmail: '',
    clientNumber: '',
    clientAlternativeNumber: '',
    clientAddress: '',
    insurance: '',
    insuranceCompany: '',
    claimTech: '',
    insuranceContactNumber: '',
    insuranceClaimNumber: '',
    claim: '',
    deviceType: '',
    itemDescreption: '',
    deviceMake: '',
    deviceModel: '',
    deviceSN: '',
    deviceColour: '',
    deviceAccessories: '',
    devicePassCode: '',
    deviceCondetion: '',
    deviceFault: '',
    deviceFaultDescription: '',
    allowedFileTypes: 'png,jpeg',
    documents: [],
    documentsDetails: [],
    showProgress: false,
    newJobCard: true,
    showClientMenu: false,
    getClientsActive: true,
    loadingClients: true,
    filteredClient: [],
    virtical: 'bottom',
    horizontal: 'left',
    alertOpen: false,
    alertMessage: '',
    alertBgColor: '#1B262C',
    openImagePreview: false,
    previewFile: null,
    loadingInsurances: false,
    loadingClaims: false,
    openMessageModal: false,
    messageModalMessageTitle: '',
    messageModalMessageBody: '',
  };

  componentDidMount() {
    this.generateNewJobCardNUmber();
  }
  componentDidUpdate(prevProps) {
    if (this.props.jobCard !== prevProps.jobCard) {
      if (
        this.state.documentsDetails &&
        this.state.documentsDetails.length === 1
      ) {
        this.saveDocument();
      } else if (
        this.state.documentsDetails &&
        this.state.documentsDetails.length > 1
      ) {
        this.saveAllDocuments();
      } else {
        this.handleClearState();
        this.handleAlertFeddback('Job Card has been saved', '#17B794');
      }
    }
    if (
      this.props.documents !== prevProps.documents &&
      this.state.showProgress
    ) {
      this.props.getJobCard(this.props.token, this.props.jobCard.id);
      this.setState({
        showProgress: false,
        documents: [],
        documentsDetails: [],
        newJobCard: true,
      });
      this.handleClearState();
      this.handleAlertFeddback('Job Card & Images saved', '#17B794');
    }
    if (
      this.props.jobCard !== prevProps.jobCard &&
      this.state.documentsDetails.length === 0 &&
      this.state.showProgress
    ) {
      this.props.getJobCard(this.props.token, this.props.jobCard.id);
      this.setState({
        showProgress: false,
        documents: [],
        documentsDetails: [],
      });
    }
    if (this.props.jobCards !== prevProps.jobCards) {
      this.generateNewJobCardNUmber();
    }

    if (this.props.clients !== prevProps.clients) {
      this.setState({ loadingClients: false });
    }
    if (this.props.insuranceCompanies !== prevProps.insuranceCompanies) {
      this.setState({ loadingInsurances: false });
    }
    if (this.props.claims !== prevProps.claims) {
      this.setState({ loadingClaims: false });
    }
    if (
      this.props.jobCardError !== prevProps.jobCardError &&
      this.props.jobCardError
    ) {
      this.setState({ showProgress: false });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleAlertFeddback = (message, backgroundColor) => {
    this.setState({
      alertOpen: true,
      alertMessage: message,
      alertBgColor: backgroundColor,
    });
    setTimeout(
      () =>
        this.setState({
          alertOpen: false,
          alterMessage: '',
          alertBgColor: '#1B262C',
        }),
      4000
    );
  };

  handleAlertClose = () => {
    this.setState({ alertOpen: false });
  };

  TransitionLeft = (props) => {
    return <Slide {...props} direction="right" />;
  };

  handleImagePreviewClose = () => {
    this.setState({ openImagePreview: false });
  };

  handleMessageClose = () => {
    this.setState({ openMessageModal: false });
  };

  handleImagePreviewOpen = (file) => {
    console.log('opening preview');
    this.setState({ openImagePreview: true, previewFile: file });
  };

  generateNewJobCardNUmber = () => {
    if (this.props.jobCards[0] !== undefined) {
      const newCardNumber =
        Number(this.props.jobCards[0].job_card_number.slice(2)) + 1;
      const newJobCardNumber =
        this.props.jobCards[0].job_card_number.substring(0, 2) + newCardNumber;
      console.log(newJobCardNumber);
      this.setState({ jobCardNumber: newJobCardNumber });
    }
  };

  // Client Handling
  onCleintNameChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ showClientMenu: true });
    if (this.state.getClientsActive) {
      this.props.getClients(this.props.token);
      this.setState({ getClientsActive: false, loadingClients: true });
    }
    setTimeout(() => this.filteringClient(), 200);
  };

  filteringClient = (e) => {
    let clientList = [];
    console.log(this.state.clientName);
    this.props.clients.map((client, index) => {
      if (
        client.client_name
          .toLowerCase()
          .includes(this.state.clientName.toLowerCase())
      ) {
        clientList.push(client);
      }
    });
    this.setState({ filteredClient: clientList });
  };

  handleClientMenuClose = () => {
    this.setState({ showClientMenu: false });
  };
  handleFillClientDetails = (client) => {
    this.setState({
      clientName: client.client_name,
      clientEmail: client.client_email,
      clientNumber: client.client_number,
      clientAlternativeNumber: client.client_alternative_number,
      clientAddress: client.client_address,
      showClientMenu: false,
    });
  };
  ////////

  handleClose = () => {
    this.setState({ addDocumentModalOpen: false });
  };

  handleClearState = () => {
    this.setState({
      date: null,
      newJobCard: true,
      bookingType: '',
      notes: '',
      clientName: '',
      clientEmail: '',
      clientNumber: '',
      clientAlternativeNumber: '',
      clientAddress: '',
      insuranceCompany: '',
      claimTech: '',
      insuranceContactNumber: '',
      insuranceClaimNumber: '',
      deviceType: '',
      itemDescreption: '',
      deviceMake: '',
      deviceModel: '',
      deviceSN: '',
      deviceColour: '',
      deviceAccessories: '',
      devicePassCode: '',
      deviceCondetion: '',
      deviceFault: '',
      deviceFaultDescription: '',
    });
  };

  validateJobCardFields = () => {
    const {
      jobCardNumber,
      clientName,
      deviceType,
      itemDescreption,
      deviceSN,
      deviceFault,
    } = this.state;

    if (
      jobCardNumber === '' ||
      clientName === '' ||
      deviceType === '' ||
      itemDescreption === '' ||
      deviceSN === '' ||
      deviceFault === ''
    ) {
      this.handleMessageOpen(
        'Error',
        "[ Client Name, Item Type, Item Descreption, SN/IMEI, Device Fault ] are required to save a Job Card. If any of the mentioned fields is not available, use 'none' instead."
      );
      return false;
    } else {
      return true;
    }
  };

  handleMessageOpen = (title, body) => {
    this.setState({
      messageModalMessageTitle: title,
      messageModalMessageBody: body,
      openMessageModal: true,
    });
  };

  handleJobCardSave = () => {
    const valid = this.validateJobCardFields();
    if (valid) {
      this.setState({ showProgress: true });
      const token = this.props.token; //this.props.token;
      const {
        date,
        jobCardNumber,
        bookingType,
        notes,
        clientName,
        clientEmail,
        clientNumber,
        clientAlternativeNumber,
        clientAddress,
        insuranceCompany,
        claimTech,
        insuranceContactNumber,
        insuranceClaimNumber,
        deviceType,
        itemDescreption,
        deviceMake,
        deviceModel,
        deviceSN,
        deviceColour,
        deviceAccessories,
        devicePassCode,
        deviceCondetion,
        deviceFault,
        deviceFaultDescription,
      } = this.state;

      this.props.saveJobCard(
        token,
        date,
        jobCardNumber,
        bookingType,
        notes,
        clientName,
        clientEmail,
        clientNumber,
        clientAlternativeNumber,
        clientAddress,
        insuranceCompany,
        claimTech,
        insuranceContactNumber,
        insuranceClaimNumber,
        deviceType,
        itemDescreption.replace('"', '-inch'),
        deviceMake,
        deviceModel,
        deviceSN,
        deviceColour,
        deviceAccessories,
        devicePassCode,
        deviceCondetion,
        deviceFault,
        deviceFaultDescription
      );
    } else {
      console.log('Please fill the necessary fields');
    }
  };
  onImgChange = (e) => {
    const image = e.target.files;

    const addedImageCount = this.state.documents.length;
    const imageCountCanBeAdded = 30 - addedImageCount;
    var imageNumberLimit = 0;
    if (image.length > imageCountCanBeAdded) {
      imageNumberLimit = imageCountCanBeAdded;
    } else if (image.length <= imageCountCanBeAdded) {
      imageNumberLimit = image.length;
    }

    for (var index = 0; index < imageNumberLimit; index++) {
      let reader = new FileReader();
      const img = image[index];
      if (
        // this.state.allowedFileTypes.includes(
        //   img.type.split('/')[1] || img.name.split('.')[1] // use this to restrict file type
        // )
        img.type.split('/')[0] === 'image'
      ) {
        reader.readAsDataURL(img);
        reader.onloadend = () => {
          this.setState((state) => ({
            documents: [...state.documents, reader.result],
          }));
        };

        this.setState((state) => ({
          documentsDetails: [...state.documentsDetails, img],
        }));
      }
    }
  };

  saveDocument = () => {
    // this.setState({ showProgress: true });
    const { documentsDetails } = this.state;
    const document_name = documentsDetails[0].name;
    const document_type = documentsDetails[0].type;
    const document_size = documentsDetails[0].size;
    const job_card_id = this.props.jobCard.id;

    if (document_type.includes('image')) {
      this.props.saveDocument(
        this.props.token,
        job_card_id,
        document_name,
        document_type,
        document_size,
        documentsDetails[0]
      );
    }
  };

  saveAllDocuments = () => {
    // this.setState({ showProgress: true });
    const documentList = this.state.documentsDetails;
    const job_card_id = this.props.jobCard.id;
    this.props.saveDocuments(this.props.token, job_card_id, documentList);
  };

  deleteImageFromState = (id) => {
    let documents = this.state.documents;
    let documentsDetails = this.state.documentsDetails;
    documents.splice(id, 1);
    documentsDetails.splice(id, 1);
    this.setState({ documents: documents });
    this.setState({ documentsDetails: documentsDetails });
    const node = this.fileInputRef.current;
    node.value = null;
  };

  deleteSavedImage = (id) => {
    const token = this.props.token;
    const job_card_id = this.props.jobCard.id;

    this.props.deleteDocument(token, id, job_card_id);
  };

  onSelectInsurance = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        insuranceCompany: option ? option.insurance_company_name : '',
      });
    } else {
      this.setState({
        insuranceCompany: '',
      });
    }
  };

  onSelectClaim = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        insuranceClaimNumber: option ? option.claim_number : '',
        claimTech: option ? option.claim_consultant : '',
        insuranceContactNumber: option ? option.consultant_contact_number : '',
        insuranceCompany: option
          ? option.insurance_company.insurance_company_name
          : '',
      });
    } else {
      this.setState({
        insuranceClaimNumber: '',
        claimTech: '',
        insuranceContactNumber: '',
        insuranceCompany: '',
      });
    }
  };

  getInsuranceCompanies = () => {
    this.setState({ loadingInsurances: true });
    const token = this.props.token;
    this.props.getInsurances(token);
  };

  getInsuranceClaims = () => {
    this.setState({ loadingClaims: true });
    const token = this.props.token;
    this.props.getClaims(token);
  };

  render() {
    const { virtical, horizontal } = this.state;
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: 1000,
      height: '80%',
      bgcolor: 'none',
      border: '2px solid #2c3e50',
      borderRadius: 4,
      boxShadow: 24,
      p: 2,
    };

    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };

    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.insurance_company_name,
    });

    const filterClaimOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.claim_number,
    });
    const mode = this.props.mode;
    return (
      <div className="JOB_CARD_FORM_CONTAINER">
        <Snackbar
          // anchorOrigin={{ virtical, horizontal }}
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          message={this.state.alertMessage}
          key={virtical + horizontal}
          TransitionComponent={this.TransitionLeft}
          ContentProps={{
            sx: { backgroundColor: this.state.alertBgColor },
          }}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={this.handleAlertClose}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        />
        <div className="JOB_CARD_FIELDS_FORM_CONTAINER">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div className="JOB_CARD_FORM_S">
              {/* <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Date"
                    value={this.state.date}
                    onChange={(newValue) => {
                      this.setState({ date: newValue });
                    }}
                    InputProps={{
                      className: 'JOB_CARD_INPUT_FIELD',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          // shrink: true,
                          className: 'JOB_CARD_INPUT_FIELD_LABEL',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div> */}
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Job Number"
                  variant="outlined"
                  name="jobCardNumber"
                  fullWidth
                  value={this.state.jobCardNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Booking Type"
                  variant="outlined"
                  name="bookingType"
                  fullWidth
                  value={this.state.bookingType}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="JOB_CARD_FORM_CLIENT">
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic-client-name"
                  label="Client Name"
                  variant="outlined"
                  name="clientName"
                  fullWidth
                  value={this.state.clientName}
                  onChange={this.onCleintNameChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
                {this.state.showClientMenu ? (
                  <div className="JOB_CARD_SELECT_CLIENT_MENU">
                    {this.state.loadingClients ? (
                      <CircularProgress></CircularProgress>
                    ) : (
                      <div className="JOB_CARD_SELECT_CLIENT_MENU_CONTAINER">
                        <div className="CLIENTS_MENU_CLOSE_BUTTON">
                          <IconButton
                            aria-label="close"
                            size="small"
                            sx={{ color: '#f05454' }}
                          >
                            <CloseIcon
                              onClick={() =>
                                this.setState({ showClientMenu: false })
                              }
                            ></CloseIcon>
                          </IconButton>
                        </div>
                        <div>
                          {this.state.filteredClient
                            ? this.state.filteredClient.map((client, index) => (
                                <MenuItem
                                  key={index}
                                  sx={
                                    mode === 'dark'
                                      ? {
                                          width: '100%',
                                          color: '#AFB2B4',
                                        }
                                      : { width: '100%', color: '#17293e' }
                                  }
                                  onClick={() =>
                                    this.handleFillClientDetails(client)
                                  }
                                >
                                  {client.client_name}
                                </MenuItem>
                              ))
                            : null}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Client Number"
                  variant="outlined"
                  name="clientNumber"
                  fullWidth
                  value={this.state.clientNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Client Email"
                  variant="outlined"
                  name="clientEmail"
                  fullWidth
                  value={this.state.clientEmail}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Alternative Number"
                  variant="outlined"
                  name="clientAlternativeNumber"
                  fullWidth
                  value={this.state.clientAlternativeNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  name="clientAddress"
                  fullWidth
                  value={this.state.clientAddress}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
          </div>
          <div className="JOB_CARD_FORM_INSURANCE">
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-insurance"
                freeSolo
                options={
                  this.props.insuranceCompanies
                    ? this.props.insuranceCompanies
                    : []
                }
                getOptionLabel={(option) =>
                  option.insurance_company_name
                    ? option.insurance_company_name
                    : this.state.insuranceCompany
                }
                filterOptions={filterOptions}
                sx={{ color: '#fff' }}
                onOpen={() => {
                  this.getInsuranceCompanies();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                onChange={(e, option) => this.onSelectInsurance(e, option)}
                value={this.state.insuranceCompany}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Insurance Company"
                    name="insuranceCompany"
                    fullWidth
                    value={this.state.insuranceCompany}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingInsurances ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'JOB_CARD_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'JOB_CARD_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            {/* <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Insurance Company"
                variant="outlined"
                name="insuranceCompany"
                fullWidth
                value={this.state.insuranceCompany}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div> */}
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Claim Consultant"
                variant="outlined"
                name="claimTech"
                fullWidth
                value={this.state.claimTech}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Consultant Number"
                variant="outlined"
                name="insuranceContactNumber"
                fullWidth
                value={this.state.insuranceContactNumber}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            {/* <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Claim Number"
                variant="outlined"
                name="insuranceClaimNumber"
                fullWidth
                value={this.state.insuranceClaimNumber}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div> */}
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-claims"
                freeSolo
                options={this.props.claims ? this.props.claims : []}
                getOptionLabel={(option) =>
                  option.claim_number
                    ? option.claim_number
                    : this.state.insuranceClaimNumber
                }
                filterOptions={filterClaimOptions}
                sx={{ color: '#fff' }}
                onOpen={() => {
                  this.getInsuranceClaims();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                value={this.state.insuranceClaimNumber}
                onChange={(e, option) => this.onSelectClaim(e, option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Claim Number"
                    name="insuranceClaimNumber"
                    fullWidth
                    value={this.state.insuranceClaimNumber}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingClaims ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'JOB_CARD_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'JOB_CARD_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="job-card-note"
                label="Notes"
                multiline
                rows={7}
                variant="outlined"
                // color="secondary"
                type="text"
                name="notes"
                fullWidth
                required
                // error={this.state.questionError ? true : false}
                value={this.state.notes}
                onChange={this.onChange}
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              />
            </div>
          </div>
          <div className="JOB_CARD_FORM_DEVICE">
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Item Type"
                variant="outlined"
                name="deviceType"
                fullWidth
                value={this.state.deviceType}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Item Description"
                variant="outlined"
                name="itemDescreption"
                fullWidth
                value={this.state.itemDescreption}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Make"
                variant="outlined"
                name="deviceMake"
                fullWidth
                value={this.state.deviceMake}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Model"
                variant="outlined"
                name="deviceModel"
                fullWidth
                value={this.state.deviceModel}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="SN / IMEI"
                variant="outlined"
                name="deviceSN"
                fullWidth
                value={this.state.deviceSN}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Colour"
                variant="outlined"
                name="deviceColour"
                fullWidth
                value={this.state.deviceColour}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Accessories"
                variant="outlined"
                name="deviceAccessories"
                fullWidth
                value={this.state.deviceAccessories}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Unlock Code"
                variant="outlined"
                name="devicePassCode"
                fullWidth
                value={this.state.devicePassCode}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="JOB_CARD_FORM_DESCRIPTION_CONTAINER">
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-multiline-static"
                label="Device Condition"
                multiline
                rows={8}
                variant="outlined"
                // color="secondary"
                type="text"
                name="deviceCondetion"
                fullWidth
                required
                // error={this.state.questionError ? true : false}
                value={this.state.deviceCondetion}
                onChange={this.onChange}
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              />
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Fault"
                variant="outlined"
                name="deviceFault"
                fullWidth
                value={this.state.deviceFault}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-multiline-static"
                label="Fault Description"
                multiline
                rows={8}
                variant="outlined"
                // color="secondary"
                type="text"
                name="deviceFaultDescription"
                fullWidth
                required
                // error={this.state.questionError ? true : false}
                value={this.state.deviceFaultDescription}
                onChange={this.onChange}
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              />
            </div>
          </div>
        </div>
        <div className="BOOK_DEVICE_IMAGE">
          {this.state.showProgress ? (
            <div className="PROGRESS_CIRCLE_CONTAINER">
              <ProgressCircle progress={this.props.progress}></ProgressCircle>
            </div>
          ) : (
            <div
              className="ADD_IMAGE_BUTTON"
              // data-toggle="modal"
              // data-target="#imgModal"
              // onClick={this.generateAllowedFileTypesList}
            >
              <input
                ref={this.fileInputRef}
                type="file"
                multiple={true}
                accept={this.state.allowedFileTypes}
                onChange={this.onImgChange}
              />
              <div className="UPLOAD_FILE_ICON">
                <AddPhotoAlternateIcon
                  fontSize="inherit"
                  color="inherit"
                ></AddPhotoAlternateIcon>
              </div>
            </div>
          )}
          <div className="JOB_CARD_UPLOADED_IMAGES_CONTAINER">
            {this.state.documents
              ? this.state.documents.map((document, index) => (
                  <div
                    className="JOB_CARD_UPLOADED_IMAGE_CONTAINER"
                    key={index}
                  >
                    <ImageCard
                      file={document}
                      deleteImage={() => this.deleteImageFromState(index)}
                      openPreview={() => this.handleImagePreviewOpen(document)}
                    ></ImageCard>
                  </div>
                ))
              : null}
            {this.props.jobCard &&
            this.props.jobCard.documet &&
            !this.state.newJobCard
              ? this.props.jobCard.documet.map((document, index) => (
                  <div
                    className="JOB_CARD_UPLOADED_IMAGE_CONTAINER"
                    key={index}
                  >
                    <ImageCard
                      file={document.document_image}
                      deleteImage={() => this.deleteSavedImage(document.id)}
                      openPreview={() =>
                        this.handleImagePreviewOpen(document.document_image)
                      }
                    ></ImageCard>
                  </div>
                ))
              : null}
          </div>
        </div>
        <div className="BOOK_DEVICE_FORM_ACTION">
          {this.state.showProgress ? (
            <div className="PROGRESS_CIRCLE_CONTAINER">
              <CircularProgress></CircularProgress>
            </div>
          ) : (
            <div
              className="BOOK_DEVICE_FORM_ACTION_SAVE_BUTTON"
              onClick={this.handleJobCardSave}
            >
              <h5>Save</h5>
            </div>
          )}
          <div
            className="BOOK_DEVICE_FORM_ACTION_CLEAR_BUTTON"
            onClick={this.handleClearState}
          >
            <h5>Clear</h5>
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openImagePreview}
          onClose={this.handleImagePreviewClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openImagePreview}>
            <Box sx={style}>
              <div className="PREVIEW_MODAL_IMAGE_HEADER">
                <Avatar
                  sx={{ bgcolor: '#393E46' }}
                  onClick={this.handleImagePreviewClose}
                >
                  <CloseIcon></CloseIcon>
                </Avatar>
              </div>
              <div className="PREVIEW_MODAL_IMAGE_CONTAINER">
                {this.state.previewFile ? (
                  <img src={this.state.previewFile} alt="repair cocument"></img>
                ) : null}
              </div>
            </Box>
          </Fade>
        </Modal>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openMessageModal}
          onClose={this.handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.messageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.messageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button variant="outlined" onClick={this.handleMessageClose}>
                  Okay
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    jobCard: state.jobCard.jobCard,
    jobCards: state.jobCard.jobCards,
    jobCardError: state.jobCard.error,
    jobCardLoading: state.jobCard.loading,
    documents: state.document.documents,
    progress: state.document.progress,
    clients: state.client.clients,
    insuranceCompanies: state.insurance.insurances,
    claims: state.insurance.claims,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getJobCard: (token, id) => dispatch(getJobCard(token, id)),
    saveJobCard: (
      token,
      date,
      jobCardNumber,
      bookingType,
      notes,
      clientName,
      clientEmail,
      clientNumber,
      clientAlternativeNumber,
      clientAddress,
      insuranceCompany,
      claimTech,
      insuranceContactNumber,
      insuranceClaimNumber,
      deviceType,
      itemDescreption,
      deviceMake,
      deviceModel,
      deviceSN,
      deviceColour,
      deviceAccessories,
      devicePassCode,
      deviceCondetion,
      deviceFault,
      deviceFaultDescription
    ) =>
      dispatch(
        postJobCard(
          token,
          date,
          jobCardNumber,
          bookingType,
          notes,
          clientName,
          clientEmail,
          clientNumber,
          clientAlternativeNumber,
          clientAddress,
          insuranceCompany,
          claimTech,
          insuranceContactNumber,
          insuranceClaimNumber,
          deviceType,
          itemDescreption,
          deviceMake,
          deviceModel,
          deviceSN,
          deviceColour,
          deviceAccessories,
          devicePassCode,
          deviceCondetion,
          deviceFault,
          deviceFaultDescription
        )
      ),
    getDocument: (token, id) =>
      dispatch(documentAction.getRegisteredDocument(token, id)),
    getDocuments: (token, workGroupID) =>
      dispatch(documentAction.getRegisteredDocuments(token, workGroupID)),
    saveDocument: (
      token,
      job_card,
      document_name,
      document_type,
      document_size,
      document_image
    ) =>
      dispatch(
        documentAction.registerDocument(
          token,
          job_card,
          document_name,
          document_type,
          document_size,
          document_image
        )
      ),
    saveDocuments: (token, job_card, documentList) =>
      dispatch(documentAction.registerDocuments(token, job_card, documentList)),
    deleteDocument: (token, id, job_card_id) =>
      dispatch(documentAction.deleteRegisteredDocument(token, id, job_card_id)),
    getClients: (token) => dispatch(getClients(token)),
    getInsurances: (token) => dispatch(getInsurances(token)),
    getClaims: (token) => dispatch(getClaims(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobCardForm);
