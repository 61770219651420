import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/client-form.css';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import {
  getClients,
  deleteClient,
  postClientData,
  updateClientData,
} from '../../store/actions/client';
import ClientTable from './client-table/ClientTable';
import {
  getInsurances,
  deleteInsurance,
  postInsuranceData,
  updateInsuranceData,
} from '../../store/actions/insurance';

class ClientForm extends Component {
  state = {
    clientName: '',
    clientEmail: '',
    clientNumber: '',
    clientAlternativeNumber: '',
    clientAddress: '',
    clientVatRegNumber: '',
    clientNameError: false,
    clientNumberError: false,
    clientAlternativeNumberError: false,
    clientAddressError: false,
    saveClientLoading: false,
    updatingClientDetails: false,
    clientID: null, // this id referes to client and insurance
    activateClient: true,
    activateInsurance: false,
    popUpModalMessageTitle: '',
    popUpModalMessageBody: '',
    openPopUpModal: false,
    clientObj: null, // this object referes to client and insurance
  };

  componentDidMount() {
    const token = this.props.token;
    this.props.getClients(token);
    this.props.getInsurances(token);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.clients !== prevProps.clients &&
      this.state.saveClientLoading === true
    ) {
      this.setState({
        clientName: '',
        clientEmail: '',
        clientNumber: '',
        clientAlternativeNumber: '',
        clientAddress: '',
        clientVatRegNumber: '',
        saveClientLoading: false,
        updatingClientDetails: false,
        clientID: null,
        popUpModalMessageTitle: '',
        popUpModalMessageBody: '',
        openPopUpModal: false,
        clientObj: null,
      });
    }
    if (
      this.props.insurances !== prevProps.insurances &&
      this.state.saveClientLoading === true
    ) {
      this.setState({
        clientName: '',
        clientEmail: '',
        clientNumber: '',
        clientAlternativeNumber: '',
        clientAddress: '',
        clientVatRegNumber: '',
        saveClientLoading: false,
        updatingClientDetails: false,
        clientID: null,
        popUpModalMessageTitle: '',
        popUpModalMessageBody: '',
        openPopUpModal: false,
        clientObj: null,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + `Error`]: false,
    });
  };

  activateClient = (e) => {
    this.setState({
      activateClient: !this.state.activateClient,
      activateInsurance: !this.state.activateInsurance,
    });
  };

  activateInsurance = (e) => {
    this.setState({
      activateInsurance: !this.state.activateInsurance,
      activateClient: !this.state.activateClient,
    });
  };

  validateClientData = () => {
    const { clientName, clientEmail, clientNumber } = this.state;
    if (clientName === '' || clientEmail === '' || clientNumber === '') {
      this.setState({
        clientNameError: true,
        clientNumberError: true,
        clientEmailError: true,
      });
      return false;
    } else {
      return true;
    }
  };

  handleSaveClient = () => {
    const valid = this.validateClientData();
    if (valid) {
      this.setState({ saveClientLoading: true });
      const token = this.props.token;
      console.log('save');
      const {
        clientName,
        clientEmail,
        clientNumber,
        clientAlternativeNumber,
        clientAddress,
        clientVatRegNumber,
        activateClient,
        activateInsurance,
      } = this.state;
      if (activateClient) {
        this.props.saveClient(
          token,
          clientName,
          clientEmail,
          clientNumber,
          clientAlternativeNumber,
          clientAddress,
          clientVatRegNumber
        );
      } else if (activateInsurance) {
        this.props.saveInsurance(
          token,
          clientName,
          clientEmail,
          clientNumber,
          clientAlternativeNumber,
          clientAddress,
          clientVatRegNumber
        );
      }
    }
  };

  handleEditClientDetails = (client) => {
    this.setState({
      clientName: client.client_name,
      clientEmail: client.client_email,
      clientNumber: client.client_number,
      clientAlternativeNumber: client.client_alternative_number,
      clientAddress: client.client_address,
      clientVatRegNumber: client.client_vat_reg_number,
      updatingClientDetails: true,
      clientID: client.id,
    });
  };

  handleEditInsuranceDetails = (client) => {
    this.setState({
      clientName: client.insurance_company_name,
      clientEmail: client.insurance_email,
      clientNumber: client.insurance_number,
      clientAlternativeNumber: client.insurance_alternative_number,
      clientAddress: client.insurance_address,
      clientVatRegNumber: client.insurance_vat_reg_number,
      updatingClientDetails: true,
      clientID: client.id,
    });
  };

  updateClientDetails = () => {
    this.setState({ saveClientLoading: true });
    const token = this.props.token;
    console.log('update');
    const {
      clientName,
      clientEmail,
      clientNumber,
      clientAlternativeNumber,
      clientAddress,
      clientID,
      clientVatRegNumber,
      activateClient,
    } = this.state;
    if (activateClient) {
      this.props.updateClient(
        token,
        clientID,
        clientName,
        clientEmail,
        clientNumber,
        clientAlternativeNumber,
        clientAddress,
        clientVatRegNumber
      );
    } else {
      this.props.updateInsurance(
        token,
        clientID,
        clientName,
        clientEmail,
        clientNumber,
        clientAlternativeNumber,
        clientAddress,
        clientVatRegNumber
      );
    }
  };

  confirmDeleteClient = (client) => {
    this.handlePopUpOpen(
      'Warning',
      'Are you sure you want to delete this client?.'
    );
    this.setState({ clientObj: client });
  };

  handleDeleteClient = () => {
    const token = this.props.token;
    if (this.state.activateClient && this.state.clientObj) {
      this.setState({ saveClientLoading: true, openPopUpModal: false });
      this.props.deleteClient(token, this.state.clientObj.id);
    } else if (this.state.activateInsurance && this.state.clientObj) {
      this.setState({ saveClientLoading: true, openPopUpModal: false });
      this.props.deleteInsurance(token, this.state.clientObj.id);
    }
  };

  clearClientDetails = () => {
    this.setState({
      clientName: '',
      clientEmail: '',
      clientNumber: '',
      clientAlternativeNumber: '',
      clientAddress: '',
      clientVatRegNumber: '',
      saveClientLoading: false,
      updatingClientDetails: false,
      clientID: null,
      clientObj: null,
    });
  };

  handlePopUpClose = () => {
    this.setState({ openPopUpModal: false });
  };

  handlePopUpOpen = (title, body) => {
    this.setState({
      popUpModalMessageTitle: title,
      popUpModalMessageBody: body,
      openPopUpModal: true,
    });
  };

  render() {
    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    return (
      <div className="CLIENT_FORM_MAIN_CONTAINER">
        <div className="CLIENT_INSURANCE_SWITCH_CONTAINER">
          <div>
            <Switch
              color="warning"
              checked={this.state.activateClient}
              onChange={this.activateClient}
              label="Client"
            />
            <span>Client</span>
          </div>
          <div>
            <Switch
              color="warning"
              checked={this.state.activateInsurance}
              onChange={this.activateInsurance}
              label="Insurance"
            />
            <span>Insurance</span>
          </div>
        </div>
        <div className="ADD_CLIENT_CONTAINER">
          <div className="ADD_CLIENT_FORM_CLIENT">
            <div className="ADD_CLIENT_FORM_INPUT_GROUP">
              <div className="ADD_CLIENT_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="client-name"
                  label="Name"
                  variant="standard"
                  name="clientName"
                  required
                  error={this.state.clientNameError}
                  fullWidth
                  value={this.state.clientName}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_CLIENT_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="standard"
                  fullWidth
                  required
                  name="clientEmail"
                  error={this.state.clientEmailError}
                  value={this.state.clientEmail}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_CLIENT_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Phone Number"
                  variant="standard"
                  fullWidth
                  name="clientNumber"
                  required
                  error={this.state.clientNumberError}
                  value={this.state.clientNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="ADD_CLIENT_FORM_INPUT_GROUP">
              <div className="ADD_CLIENT_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Alternative Number"
                  variant="standard"
                  fullWidth
                  name="clientAlternativeNumber"
                  value={this.state.clientAlternativeNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_CLIENT_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="standard"
                  fullWidth
                  name="clientAddress"
                  value={this.state.clientAddress}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_CLIENT_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Client VAT reg No"
                  variant="standard"
                  fullWidth
                  name="clientVatRegNumber"
                  value={this.state.clientVatRegNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    className: 'ADD_CLIENT_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>

            <div className="ADD_CLIENT_FORM_INPUT_GROUP">
              {!this.state.saveClientLoading ? (
                this.state.updatingClientDetails ? (
                  <div
                    className="ADD_CLIENT_BUTTON"
                    onClick={this.updateClientDetails}
                  >
                    <div>Update</div>
                  </div>
                ) : (
                  <div
                    className="ADD_CLIENT_BUTTON"
                    onClick={this.handleSaveClient}
                  >
                    <div>Add Client</div>
                  </div>
                )
              ) : (
                <div className="ADD_CLIENT_BUTTON">
                  <CircularProgress></CircularProgress>
                </div>
              )}
              {this.state.updatingClientDetails ? (
                <div
                  className="ADD_CLIENT_BUTTON"
                  onClick={this.clearClientDetails}
                >
                  <div>Clear</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="SAVED_CLIENT_CONTAINER">
          {this.props.clients
            ? this.props.clients[0] !== undefined
              ? this.props.clients.map((client, index) => (
                  <div className="SAVED_CLIENT_ITEM_CONTAINER" key={index}>
                    <div className="SAVED_CLIENT_ITEM">
                      <Chip
                        label={client.client_name}
                        variant="outlined"
                        color="primary"
                      />

                      <Chip
                        label={client.client_email}
                        variant="outlined"
                        color="info"
                      />

                      <Chip
                        label={client.client_number}
                        variant="outlined"
                        color="primary"
                      />
                    </div>
                    <IconButton
                      aria-label="Edit"
                      onClick={() => this.handleEditClientDetails(client)}
                    >
                      <EditIcon color="primary"></EditIcon>
                    </IconButton>
                  </div>
                ))
              : null
            : null}
        </div>
        <div className="CLIENT_TABLE_MAIN_CONTAINER">
          <ClientTable
            clients={
              this.props.clients &&
              this.props.clients[0] !== undefined &&
              this.state.activateClient
                ? this.props.clients
                : []
            }
            insurances={
              this.props.insurances &&
              this.props.insurances[0] !== undefined &&
              this.state.activateInsurance
                ? this.props.insurances
                : []
            }
            activateClient={this.state.activateClient}
            activateInsurance={this.state.activateInsurance}
            editClient={(client) => this.handleEditClientDetails(client)}
            editInsurance={(insurance) =>
              this.handleEditInsuranceDetails(insurance)
            }
            deleteClient={this.confirmDeleteClient}
            mode={this.props.mode}
          ></ClientTable>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openPopUpModal}
          onClose={this.handlePopUpClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openPopUpModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.popUpModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.popUpModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={this.handleDeleteClient}
                >
                  Confirm
                </Button>
                <Button variant="outlined" onClick={this.handlePopUpClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    clients: state.client.clients,
    insurances: state.insurance.insurances,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClients: (token) => dispatch(getClients(token)),
    getInsurances: (token) => dispatch(getInsurances(token)),
    saveClient: (
      token,
      client_name,
      client_email,
      client_number,
      client_alternative_number,
      client_address,
      client_vat_reg_number
    ) =>
      dispatch(
        postClientData(
          token,
          client_name,
          client_email,
          client_number,
          client_alternative_number,
          client_address,
          client_vat_reg_number
        )
      ),
    saveInsurance: (
      token,
      client_name,
      client_email,
      client_number,
      client_alternative_number,
      client_address,
      client_vat_reg_number
    ) =>
      dispatch(
        postInsuranceData(
          token,
          client_name,
          client_email,
          client_number,
          client_alternative_number,
          client_address,
          client_vat_reg_number
        )
      ),
    updateClient: (
      token,
      client_id,
      client_name,
      client_email,
      client_number,
      client_alternative_number,
      client_address,
      client_vat_reg_number
    ) =>
      dispatch(
        updateClientData(
          token,
          client_id,
          client_name,
          client_email,
          client_number,
          client_alternative_number,
          client_address,
          client_vat_reg_number
        )
      ),
    updateInsurance: (
      token,
      client_id,
      client_name,
      client_email,
      client_number,
      client_alternative_number,
      client_address,
      client_vat_reg_number
    ) =>
      dispatch(
        updateInsuranceData(
          token,
          client_id,
          client_name,
          client_email,
          client_number,
          client_alternative_number,
          client_address,
          client_vat_reg_number
        )
      ),
    deleteClient: (token, id) => dispatch(deleteClient(token, id)),
    deleteInsurance: (token, id) => dispatch(deleteInsurance(token, id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientForm);
