import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getInsuranceStart = () => {
  return {
    type: actionTypes.GET_INSURANCE_START,
  };
};

export const getClaimStart = () => {
  return {
    type: actionTypes.GET_CLAIM_START,
  };
};

export const getInsuranceSuccess = (data) => {
  return {
    type: actionTypes.GET_INSURANCE_SUCCESS,
    payload: data,
  };
};

export const getClaimSuccess = (data) => {
  return {
    type: actionTypes.GET_CLAIM_SUCCESS,
    payload: data,
  };
};

export const getInsurancesSuccess = (data) => {
  return {
    type: actionTypes.GET_INSURANCES_SUCCESS,
    payload: data,
  };
};

export const getClaimsSuccess = (data) => {
  return {
    type: actionTypes.GET_CLAIMS_SUCCESS,
    payload: data,
  };
};

export const getInsuranceFaild = (error) => {
  return {
    type: actionTypes.GET_INSURANCE_FAIL,
    error: error,
  };
};

export const getClaimFaild = (error) => {
  return {
    type: actionTypes.GET_CLAIM_FAIL,
    error: error,
  };
};

export const getInsurances = (token) => {
  return (dispatch) => {
    dispatch(getInsuranceStart());
    console.log('getting all insurances');
    axios
      .get(`${HOST_URL}/insurances/insurance/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getInsurancesSuccess(response.data)))
      .catch((error) => dispatch(getInsuranceFaild(error)));
  };
};

export const getInsurance = (token, id) => {
  return (dispatch) => {
    dispatch(getInsuranceStart());

    axios
      .get(`${HOST_URL}/insurances/insurance/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getInsuranceSuccess(response.data)))
      .catch((error) => dispatch(getInsuranceFaild(error)));
  };
};

export const deleteInsurance = (token, id) => {
  return (dispatch) => {
    dispatch(getInsuranceStart());

    axios
      .delete(`${HOST_URL}/insurances/insurance/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getInsurances(token)))
      .catch((error) => dispatch(getInsuranceFaild(error)));
  };
};

export const getInsuranceByName = (token, name) => {
  return (dispatch) => {
    dispatch(getInsuranceStart());

    axios
      .get(`${HOST_URL}/insurances/insurance/name-${name}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getInsuranceSuccess(response.data)))
      .catch((error) => dispatch(getInsuranceFaild(error)));
  };
};

export const postInsuranceData = (
  token,
  insurance_company_name,
  insurance_email,
  insurance_number,
  insurance_alternative_number,
  insurance_address,
  insurance_vat_reg_number
) => {
  return (dispatch) => {
    dispatch(getInsuranceStart());
    let fd = new FormData();
    fd.append('insurance_company_name', insurance_company_name);
    fd.append('insurance_email', insurance_email);
    fd.append('insurance_number', insurance_number);
    fd.append('insurance_alternative_number', insurance_alternative_number);
    fd.append('insurance_address', insurance_address);
    fd.append('insurance_vat_reg_number', insurance_vat_reg_number);

    axios
      .post(`${HOST_URL}/insurances/insurance/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getInsuranceSuccess(response.data));
        dispatch(getInsurances(token));
      })
      .catch((error) => dispatch(getInsuranceFaild(error)));
  };
};

export const updateInsuranceData = (
  token,
  insurance_id,
  insurance_company_name,
  insurance_email,
  insurance_number,
  insurance_alternative_number,
  insurance_address,
  insurance_vat_reg_number
) => {
  return (dispatch) => {
    dispatch(getInsuranceStart());
    let fd = new FormData();
    fd.append('insurance_company_name', insurance_company_name);
    fd.append('insurance_email', insurance_email);
    fd.append('insurance_number', insurance_number);
    fd.append('insurance_alternative_number', insurance_alternative_number);
    fd.append('insurance_address', insurance_address);
    fd.append('insurance_vat_reg_number', insurance_vat_reg_number);

    axios
      .patch(`${HOST_URL}/insurances/insurance/${insurance_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getInsuranceSuccess(response.data));
        dispatch(getInsurances(token));
      })
      .catch((error) => dispatch(getInsuranceFaild(error)));
  };
};

//  Claim Actions

export const getClaims = (token) => {
  return (dispatch) => {
    dispatch(getClaimStart());
    console.log('getting all claims');
    axios
      .get(`${HOST_URL}/insurances/claim/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getClaimsSuccess(response.data)))
      .catch((error) => dispatch(getClaimFaild(error)));
  };
};

export const getClaim = (token, id) => {
  return (dispatch) => {
    dispatch(getClaimStart());

    axios
      .get(`${HOST_URL}/insurances/claim/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getClaimSuccess(response.data)))
      .catch((error) => dispatch(getClaimFaild(error)));
  };
};

export const getClaimByNumber = (token, claim_number) => {
  return (dispatch) => {
    dispatch(getClaimStart());

    axios
      .get(`${HOST_URL}/insurances/claim/claim-${claim_number}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getClaimSuccess(response.data)))
      .catch((error) => dispatch(getClaimFaild(error)));
  };
};
