import React, { Component } from 'react';
import './styles/others-job-comment.css';
import moment from 'moment';

export default class OthersJobComment extends Component {
  render() {
    return (
      <div className="OTHERS_JOB_COMMENT_MAIN_CONTAINER">
        {this.props.comment ? (
          <div className="OTHERS_JOB_COMMENT_CONTAINER">
            <div className="OTHERS_JOB_COMMENT_HEADER">
              <span>
                {this.props.comment.author.charAt(0).toUpperCase()}
                {this.props.comment.author.slice(1)}
              </span>
            </div>
            <div className="OTHERS_JOB_COMMENT_BODY">
              <p>{this.props.comment.comment}</p>
            </div>
            <div className="OTHERS_JOB_COMMENT_FOOTER">
              <span>{moment(this.props.comment.created).calendar()}</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
