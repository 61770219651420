import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles/add-item-form.css';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import { getBrands } from '../../../store/actions/inventory/brand';
import { getCategories } from '../../../store/actions/inventory/category';
import { getSuppliers } from '../../../store/actions/inventory/supplier';
import {
  getItemByCode,
  postItemData,
  updateItemData,
} from '../../../store/actions/item';

class AddItemForm extends Component {
  state = {
    purchaseOrder: null,
    inventoryID: '',
    productTitle: '',
    productCode: '',
    productType: '',
    productSKU: '',
    quantity: 1,
    sold: 0,
    available: 1,
    defective: 0,
    productKeyFeatures: '',
    productDescription: '',
    productBarcode: '',
    productCost: parseFloat(0).toFixed(2),
    markup: parseFloat(10),
    vat: parseFloat(15),
    productPrice: parseFloat(0).toFixed(2),
    // discount:parseFloat(0).toFixed(2),
    brand: 'none',
    brandID: null,
    category: 'none',
    categoryID: null,
    supplier: 'none',
    supplierID: null,
    openMessageModal: false,
    messageModalMessageTitle: '',
    messageModalMessageBody: '',
    virtical: 'bottom',
    horizontal: 'left',
    alertOpen: false,
    alertMessage: '',
    alertBgColor: '#1B262C',
    editItem: false,
    startSavingItem: false,
  };

  componentDidMount() {
    const { key } = this.props.match.params;
    if (key !== undefined) {
      this.props.getItemByCode(this.props.token, key);
      console.log(key);
    }
  }

  componentDidUpdate(prevProps) {
    const { key } = this.props.match.params;
    if (key !== undefined && this.props.itemToEdit === null) {
      this.props.getItemByCode(this.props.token, key);
    }
    if (
      this.props.itemToEdit !== prevProps.itemToEdit &&
      this.props.itemToEdit !== null
    ) {
      this.fillItemFields();
    }
    if (
      this.props.itemToEdit !== prevProps.itemToEdit &&
      this.props.itemToEdit !== null &&
      this.state.editItem
    ) {
      this.handleAlertFeddback('Item updated successfully', '#17B794');
      this.setState({ editItem: false });
    }
    if (
      this.props.item !== prevProps.item &&
      this.props.item &&
      this.state.startSavingItem
    ) {
      this.handleAlertFeddback('Item saved successfully', '#17B794');
      this.setState({ startSavingItem: false });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onCostChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout(() => this.calculatePrice(), 100);
  };

  onMarkupChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    setTimeout(() => this.calculatePrice(), 100);
  };

  handleMessageClose = () => {
    this.setState({ openMessageModal: false });
  };

  handleMessageOpen = (title, body) => {
    this.setState({
      messageModalMessageTitle: title,
      messageModalMessageBody: body,
      openMessageModal: true,
    });
  };

  handleAlertFeddback = (message, backgroundColor) => {
    this.setState({
      alertOpen: true,
      alertMessage: message,
      alertBgColor: backgroundColor,
    });
    setTimeout(
      () =>
        this.setState({
          alertOpen: false,
          alterMessage: '',
          alertBgColor: '#1B262C',
        }),
      4000
    );
  };

  calculatePrice = () => {
    const brofit = (this.state.productCost * this.state.markup) / 100;
    const price = parseFloat(brofit) + parseFloat(this.state.productCost);

    this.setState({ productPrice: parseFloat(price).toFixed(2) });
  };

  getBrands = () => {
    const token = this.props.token;
    this.props.getBrands(token);
  };
  getSuppliers = () => {
    const token = this.props.token;
    this.props.getSuppliers(token);
  };
  getCategories = () => {
    const token = this.props.token;
    this.props.getCategories(token);
  };

  onSelectBrand = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        brand: option ? option.title : '',
        brandID: option ? option.id : null,
      });
    } else {
      this.setState({
        brand: '',
        brandID: null,
      });
    }
  };

  onSelectSupplier = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        supplier: option ? option.name : '',
        supplierID: option ? option.id : null,
      });
    } else {
      this.setState({
        supplier: '',
        supplierID: null,
      });
    }
  };

  onSelectCategory = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        category: option ? option.title : '',
        categoryID: option ? option.id : null,
      });
    } else {
      this.setState({
        category: '',
        categoryID: null,
      });
    }
  };

  handleSaveItem = () => {
    this.setState({ startSavingItem: true });
    const {
      purchaseOrder,
      inventoryID,
      productTitle,
      productCode,
      productType,
      productSKU,
      quantity,
      sold,
      available,
      defective,
      productKeyFeatures,
      productDescription,
      productBarcode,
      productCost,
      markup,
      productPrice,
      brandID,
      categoryID,
      supplierID,
    } = this.state;

    const token = this.props.token;

    this.props.saveItem(
      token,
      inventoryID,
      productTitle,
      productType,
      productCode,
      productKeyFeatures,
      productDescription,
      brandID,
      categoryID,
      supplierID,
      purchaseOrder,
      productSKU,
      productBarcode,
      productCost,
      markup,
      // mrp,
      // discount,
      productPrice,
      quantity,
      sold,
      available,
      defective
    );
  };

  fillItemFields = () => {
    const {
      item_code,
      product,
      brand,
      category,
      supplier,
      order,
      sku,
      barcode,
      cost,
      markup,
      price,
      quantity,
      sold,
      available,
      defective,
    } = this.props.itemToEdit;

    this.setState({
      inventoryID: item_code,
      productTitle: product.title,
      productCode: product.code,
      productType: product.type,
      productSKU: sku,
      quantity: quantity,
      sold: sold,
      available: available,
      defective: defective,
      productKeyFeatures: product.key_features,
      productDescription: product.description,
      productBarcode: barcode,
      productCost: cost,
      markup: markup,
      vat: parseFloat(15),
      productPrice: price,
      brand: brand ? brand.name : 'none',
      brandID: brand ? brand.id : null,
      category: category ? category.title : 'none',
      categoryID: category ? category.id : null,
      supplier: supplier ? supplier.name : 'none',
      supplierID: supplier ? supplier.id : null,
    });
  };

  handleSuppliersOpen = () => {
    this.props.history.push('/inventory-manager/suppliers');
    localStorage.setItem('prevPath', '/inventory-manager/suppliers');
  };

  handleBrandsOpen = () => {
    this.props.history.push('/inventory-manager/brands');
    localStorage.setItem('prevPath', '/inventory-manager/brands');
  };

  handleCategoriesOpen = () => {
    this.props.history.push('/inventory-manager/categories');
    localStorage.setItem('prevPath', '/inventory-manager/categories');
  };

  handleClearFields = () => {
    this.setState({
      purchaseOrder: null,
      inventoryID: '',
      productTitle: '',
      productCode: '',
      productType: '',
      productSKU: '',
      quantity: 1,
      sold: 0,
      available: 1,
      defective: 0,
      productKeyFeatures: '',
      productDescription: '',
      productBarcode: '',
      productCost: parseFloat(0).toFixed(2),
      markup: parseFloat(10),
      vat: parseFloat(15),
      productPrice: parseFloat(0).toFixed(2),
      // discount:parseFloat(0).toFixed(2),
      brand: 'none',
      brandID: null,
      category: 'none',
      categoryID: null,
      supplier: 'none',
      supplierID: null,
      openMessageModal: false,
      messageModalMessageTitle: '',
      messageModalMessageBody: '',
    });
  };

  handleItemUpdate = () => {
    const {
      purchaseOrder,
      inventoryID,
      productTitle,
      productCode,
      productType,
      productSKU,
      quantity,
      sold,
      available,
      defective,
      productKeyFeatures,
      productDescription,
      productBarcode,
      productCost,
      markup,
      productPrice,
      brandID,
      categoryID,
      supplierID,
    } = this.state;

    if (this.props.itemToEdit) {
      const item_id = this.props.itemToEdit.id;
      const product_id = this.props.itemToEdit.product.id;
      const token = this.props.token;
      this.setState({ editItem: true });

      this.props.updateItem(
        token,
        item_id,
        product_id,
        inventoryID,
        productTitle,
        productType,
        productCode,
        productKeyFeatures,
        productDescription,
        brandID,
        categoryID,
        supplierID,
        purchaseOrder,
        productSKU,
        productBarcode,
        productCost,
        markup,
        productPrice,
        quantity,
        sold,
        available,
        defective
      );
    }
  };

  TransitionLeft = (props) => {
    return <Slide {...props} direction="right" />;
  };

  handleAlertClose = () => {
    this.setState({ alertOpen: false });
  };

  render() {
    const filterBrands = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.name,
    });
    const filterSuppliers = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.name,
    });
    const filterCategories = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.title,
    });

    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    return (
      <div className="ADD_ITEM_FORM_MAIN_CONTAINER">
        <div className="ADD_ITEM_FORM_HEADER_CONTAINER">
          <h1>ADD ITEM</h1>
        </div>
        {this.props.loadingItem ? (
          <CircularProgress sx={{ marginTop: 20 }}></CircularProgress>
        ) : (
          <div className="ADD_ITEM_FORM_CONTAINER">
            <div className="ADD_ITEM_INPUT_GROUP_MAIN_CONTAINER">
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Inventory ID"
                  label="Inventory ID"
                  variant="outlined"
                  name="inventoryID"
                  fullWidth
                  value={this.state.inventoryID}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="A unique code to identify Item"
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Product Title"
                  label="Product Title"
                  variant="outlined"
                  name="productTitle"
                  fullWidth
                  value={this.state.productTitle}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The product title to be displayed on the Inventory."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Product Code"
                  label="Product Code"
                  variant="outlined"
                  name="productCode"
                  fullWidth
                  value={this.state.productCode}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The product code provided by the supplier."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Product Type"
                  label="Product Type"
                  variant="outlined"
                  name="productType"
                  fullWidth
                  value={this.state.productType}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The type to distinguish between the different product types (consumer, industrial)."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Product Barcode"
                  label="Product Barcode"
                  variant="outlined"
                  name="productBarcode"
                  fullWidth
                  value={this.state.productBarcode}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="Barcode to identify the item on stock."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="ADD_ITEM_INPUT_GROUP_MAIN_CONTAINER">
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Product Cost"
                  label="Product Cost"
                  variant="outlined"
                  name="productCost"
                  fullWidth
                  value={this.state.productCost}
                  onChange={this.onCostChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The price at which the product was purchased."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="markup"
                  label="Markup %"
                  variant="outlined"
                  name="markup"
                  fullWidth
                  value={this.state.markup}
                  onChange={this.onMarkupChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The percentage to markup item for retail."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Product Price"
                  label="Product Price"
                  variant="outlined"
                  name="productPrice"
                  fullWidth
                  value={this.state.productPrice}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The price calculated using the markup percentage."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Vat-applied"
                  label="VAT %"
                  variant="outlined"
                  name="vat"
                  fullWidth
                  value={this.state.vat}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The VAT percentage applied."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="ADD_ITEM_INPUT_GROUP_MAIN_CONTAINER">
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Product SKU"
                  label="Product SKU"
                  variant="outlined"
                  name="productSKU"
                  fullWidth
                  value={this.state.productSKU}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="Stock Keeping Unit The id to identify the item on stock."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="quantity"
                  label="Quantity"
                  variant="outlined"
                  name="quantity"
                  fullWidth
                  value={this.state.quantity}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The total quantity received at the inventory."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="Product Key Features"
                  label="Product Key Features"
                  variant="outlined"
                  name="productKeyFeatures"
                  fullWidth
                  value={this.state.productKeyFeatures}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="The summary to mention the key highlights."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton aria-label="info">
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="product description"
                  label="Product Description"
                  multiline
                  rows={5}
                  variant="outlined"
                  // color="secondary"
                  type="text"
                  name="productDescription"
                  fullWidth
                  // required
                  // error={this.state.questionError ? true : false}
                  value={this.state.productDescription}
                  onChange={this.onChange}
                  InputProps={{
                    endAdornment: (
                      <React.Fragment>
                        <Tooltip
                          TransitionComponent={Zoom}
                          TransitionProps={{ timeout: 600 }}
                          title="Used to store the additional details of the product."
                          placement="left"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                bgcolor: '#dddddd',
                                color: '#0b0f19',
                                lineHeight: '20px',
                                fontSize: '0.85rem',
                              },
                            },
                          }}
                        >
                          <IconButton
                            aria-label="info"
                            sx={{ marginBottom: 'auto' }}
                          >
                            <InfoOutlinedIcon
                              sx={{ color: '#719FB0' }}
                              size={20}
                            />
                          </IconButton>
                        </Tooltip>
                      </React.Fragment>
                    ),
                    className: 'ADD_ITEM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                  }}
                />
              </div>
            </div>
            <div className="ADD_ITEM_INPUT_GROUP_MAIN_CONTAINER">
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <Autocomplete
                  id="filter-brands"
                  freeSolo
                  options={this.props.brands ? this.props.brands : []}
                  getOptionLabel={(option) =>
                    option.name ? option.name : this.state.brand
                  }
                  filterOptions={filterBrands}
                  sx={{ color: '#fff' }}
                  onOpen={() => {
                    this.getBrands();
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  clearIcon={<CloseIcon color="error"></CloseIcon>}
                  openOnFocus={true}
                  onChange={(e, option) => this.onSelectBrand(e, option)}
                  value={this.state.brand}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Brand"
                      name="brand"
                      fullWidth
                      value={this.state.brand}
                      onChange={this.onChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.props.loadingBrands ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <Tooltip
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 600 }}
                                title="Choose the item brand from the list. Click the 'Add Brand' button to add more brands"
                                placement="left"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: '#dddddd',
                                      color: '#0b0f19',
                                      lineHeight: '20px',
                                      fontSize: '0.85rem',
                                    },
                                  },
                                }}
                              >
                                <IconButton aria-label="info">
                                  <InfoOutlinedIcon
                                    sx={{ color: '#719FB0' }}
                                    size={20}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        className: 'ADD_ITEM_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <Autocomplete
                  id="filter-suppliers"
                  freeSolo
                  options={this.props.suppliers ? this.props.suppliers : []}
                  getOptionLabel={(option) =>
                    option.name ? option.name : this.state.supplier
                  }
                  filterOptions={filterSuppliers}
                  sx={{ color: '#fff' }}
                  onOpen={() => {
                    this.getSuppliers();
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  clearIcon={<CloseIcon color="error"></CloseIcon>}
                  openOnFocus={true}
                  onChange={(e, option) => this.onSelectSupplier(e, option)}
                  value={this.state.supplier}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Supplier"
                      name="supplier"
                      fullWidth
                      value={this.state.supplier}
                      onChange={this.onChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.props.loadingSuppliers ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <Tooltip
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 600 }}
                                title="Choose the item supplier from the list. Click the 'Add Supplier' button to add more suppliers"
                                placement="left"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: '#dddddd',
                                      color: '#0b0f19',
                                      lineHeight: '20px',
                                      fontSize: '0.85rem',
                                    },
                                  },
                                }}
                              >
                                <IconButton aria-label="info">
                                  <InfoOutlinedIcon
                                    sx={{ color: '#719FB0' }}
                                    size={20}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        className: 'ADD_ITEM_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              </div>
              <div className="ADD_ITEM_INPUT_FIELD_CONTAINER">
                <Autocomplete
                  id="filter-categories"
                  freeSolo
                  options={this.props.categories ? this.props.categories : []}
                  getOptionLabel={(option) =>
                    option.title ? option.title : this.state.category
                  }
                  filterOptions={filterCategories}
                  sx={{ color: '#fff' }}
                  onOpen={() => {
                    this.getCategories();
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  clearIcon={<CloseIcon color="error"></CloseIcon>}
                  openOnFocus={true}
                  onChange={(e, option) => this.onSelectCategory(e, option)}
                  value={this.state.category}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Category"
                      name="category"
                      fullWidth
                      value={this.state.category}
                      onChange={this.onChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.props.loadingCategories ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : (
                              <Tooltip
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 600 }}
                                title="Choose the item category from the list. Click the 'Add Category' button to add more categories"
                                placement="left"
                                componentsProps={{
                                  tooltip: {
                                    sx: {
                                      bgcolor: '#dddddd',
                                      color: '#0b0f19',
                                      lineHeight: '20px',
                                      fontSize: '0.85rem',
                                    },
                                  },
                                }}
                              >
                                <IconButton aria-label="info">
                                  <InfoOutlinedIcon
                                    sx={{ color: '#719FB0' }}
                                    size={20}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        className: 'ADD_ITEM_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              </div>
              <div
                className="ADD_ITEM_ACTIONS_CONTAINER"
                style={{ justifyContent: 'space-around' }}
              >
                <div
                  className="ADD_ITEM_ACTION_BUTTON"
                  onClick={this.handleBrandsOpen}
                >
                  <span>Add</span>
                  <span>Brand</span>
                </div>
                <div
                  className="ADD_ITEM_ACTION_BUTTON"
                  onClick={this.handleSuppliersOpen}
                >
                  <span>Add</span>
                  <span>Supplier</span>
                </div>
                <div
                  className="ADD_ITEM_ACTION_BUTTON"
                  onClick={this.handleCategoriesOpen}
                >
                  <span>Add</span>
                  <span>Category</span>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="ADD_ITEM_FORM_ACTIONS_CONTAINER">
          <Button
            variant="outlined"
            size="large"
            sx={{ margin: 2, backgroundColor: '#162447', color: 'white' }}
            onClick={this.handleSaveItem}
          >
            Save
          </Button>
          {this.props.match.params.data ? (
            <Button
              variant="outlined"
              size="large"
              sx={{ margin: 2, backgroundColor: '#162447', color: 'white' }}
              onClick={this.handleItemUpdate}
            >
              Update
            </Button>
          ) : null}
          <Button
            variant="outlined"
            size="large"
            color="error"
            sx={{ margin: 2 }}
            onClick={this.handleClearFields}
          >
            Reset
          </Button>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openMessageModal}
          onClose={this.handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.messageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.messageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button variant="outlined" onClick={this.handleMessageClose}>
                  Okay
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Snackbar
          // anchorOrigin={{ virtical, horizontal }}
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          message={this.state.alertMessage}
          key={this.state.virtical + this.state.horizontal}
          TransitionComponent={this.TransitionLeft}
          ContentProps={{
            sx: { backgroundColor: this.state.alertBgColor },
          }}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={this.handleAlertClose}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    brands: state.brand.brands,
    loadingBrands: state.brand.loading,
    categories: state.category.categories,
    loadingCategories: state.category.loading,
    suppliers: state.supplier.suppliers,
    loadingSuppliers: state.supplier.loading,
    item: state.item.item,
    loadingItem: state.item.loading,
    itemToEdit: state.item.itemToEdit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrands: (token) => dispatch(getBrands(token)),
    getCategories: (token) => dispatch(getCategories(token)),
    getSuppliers: (token) => dispatch(getSuppliers(token)),
    getItemByCode: (token, item_code) =>
      dispatch(getItemByCode(token, item_code)),
    saveItem: (
      token,
      item_code,
      product_title,
      product_type,
      product_code,
      key_features,
      description,
      brand,
      category,
      supplier,
      order,
      sku,
      barcode,
      cost,
      markup,
      // mrp,
      // discount,
      price,
      quantity,
      sold,
      available,
      defective
    ) =>
      dispatch(
        postItemData(
          token,
          item_code,
          product_title,
          product_type,
          product_code,
          key_features,
          description,
          brand,
          category,
          supplier,
          order,
          sku,
          barcode,
          cost,
          markup,
          // mrp,
          // discount,
          price,
          quantity,
          sold,
          available,
          defective
        )
      ),
    updateItem: (
      token,
      item_id,
      product_id,
      item_code,
      product_title,
      product_type,
      product_code,
      key_features,
      description,
      brand,
      category,
      supplier,
      order,
      sku,
      barcode,
      cost,
      markup,
      price,
      quantity,
      sold,
      available,
      defective
    ) =>
      dispatch(
        updateItemData(
          token,
          item_id,
          product_id,
          item_code,
          product_title,
          product_type,
          product_code,
          key_features,
          description,
          brand,
          category,
          supplier,
          order,
          sku,
          barcode,
          cost,
          markup,
          price,
          quantity,
          sold,
          available,
          defective
        )
      ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AddItemForm));
