import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  jobCard: null,
  targetJobCard: null,
  jobCards: [],
  filteredJobCards: [],
};

const JobCardStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const JobCardSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobCard: action.payload,
  });
};

const targetJobCardSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    targetJobCard: action.payload,
  });
};

const JobCardsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobCards: action.payload,
  });
};

const FilteredJobCardsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    filteredJobCards: action.payload,
  });
};

const JobCardFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    filteredJobCards: [],
  });
};

const jobCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.JOB_CARD_START:
      return JobCardStart(state, action);
    case actionTypes.JOB_CARD_SUCCESS:
      return JobCardSuccess(state, action);
    case actionTypes.TARGET_JOB_CARD_SUCCESS:
      return targetJobCardSuccess(state, action);
    case actionTypes.JOB_CARDS_SUCCESS:
      return JobCardsSuccess(state, action);
    case actionTypes.FILTERED_JOB_CARD_SUCCESS:
      return FilteredJobCardsSuccess(state, action);
    case actionTypes.JOB_CARD_FAIL:
      return JobCardFaild(state, action);
    default:
      return state;
  }
};

export default jobCardReducer;
