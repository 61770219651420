import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  companyDetails: null,
  bankingDetails: null,
  branchesDetails: [],
};

const getCompanyStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

export const getCompanyDetailsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    companyDetails: action.payload,
  });
};

export const getBranchesDetailsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    branchesDetails: action.payload,
  });
};

const getCompanyBankingDetailsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    bankingDetails: action.payload,
  });
};

const getCompanyFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_START:
      return getCompanyStart(state, action);
    case actionTypes.GET_COMPANY_DETAILS_SUCCESS:
      return getCompanyDetailsSuccess(state, action);
    case actionTypes.GET_BRANCHES_DETAILS_SUCCESS:
      return getBranchesDetailsSuccess(state, action);
    case actionTypes.GET_COMPANY_BANKING_DETAILS_SUCCESS:
      return getCompanyBankingDetailsSuccess(state, action);
    case actionTypes.GET_COMPANY_FAIL:
      return getCompanyFaild(state, action);
    default:
      return state;
  }
};

export default companyReducer;
