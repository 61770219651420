import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import './styles/style.css';
import * as actions from '../../store/actions/auth';
import classnames from 'classnames';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

class PasswordResetConfirm extends Component {
  state = {
    new_password1: '',
    new_password2: '',
    password1FieldsCheck: false,
    password2FieldsCheck: false,
    P1P2Match: false,
    passwordMatchAlert: false,
    errors: {},
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const response = this.props.response;
      if (response.name !== 'Error') {
        this.setState({ loading: false });
        this.props.history.push('/login');
      }
      if (response.name === 'Error') {
        this.setState({
          loading: false,
          passwordMatchAlert: true,
          errors: {
            request: 'Operation Failed, Please contact our support team.',
          },
        });
        setTimeout(
          () =>
            this.setState({
              passwordMatchAlert: false,
              errors: {},
              new_password1: '',
              new_password2: '',
            }),
          3000
        );
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { new_password1, new_password2, P1P2Match } = this.state;

    if (new_password1 === '') {
      this.setState({
        errors: { new_password1: 'New Password field is required' },
      });
      this.setState({ password1FieldsCheck: true });
      return;
    }
    if (new_password2 === '') {
      this.setState({
        errors: { new_password2: 'Confirm Password field is required' },
      });
      this.setState({ password2FieldsCheck: true });
      return;
    }
    if (P1P2Match === true) {
      this.setState({ passwordMatchAlert: true });
      return;
    }
    this.setState({ loading: true });

    this.setState({ errors: {} });
    this.changeIt();
  };

  changeIt() {
    const { new_password1, new_password2 } = this.state;
    this.props.PasswordResetConfirmChange(
      new_password1,
      new_password2,
      this.props
    );
  }

  // onChange = e => this.setState({ [e.target.name]: e.target.value });

  onPassword1Change = (e) => {
    this.setState({ passwordMatchAlert: false });
    if (e.target.value !== this.state.new_password2) {
      this.setState({ P1P2Match: true });
    } else {
      this.setState({ P1P2Match: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  onPassword2Change = (e) => {
    this.setState({ passwordMatchAlert: false });
    if (e.target.value !== this.state.new_password1) {
      this.setState({ P1P2Match: true });
    } else {
      this.setState({ P1P2Match: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className="container">
        <div className="CONFIRM_RESET_PASSWORD_PAGE_CONTAINER">
          <form
            className="CONFIRM_RESET_PASSWORD_FORM"
            onSubmit={this.onSubmit}
          >
            <div className="FORGOT_PASSWORD_LOADING_CONTAINER">
              {this.state.loading ? (
                <CircularProgress color="success"></CircularProgress>
              ) : (
                <LockIcon fontSize="inherit" color="inherit"></LockIcon>
              )}
            </div>

            <div className="CONFIRM_PASSWORD_INPUT_FIELD_FRAME">
              <TextField
                id="outlined-secondary-2"
                label="Your New Password"
                variant="outlined"
                // color="secondary"
                type="password"
                name="new_password1"
                fullWidth
                required
                placeholder={
                  this.state.password1FieldsCheck
                    ? this.state.errors.new_password1
                    : ''
                }
                error={this.state.password1FieldsCheck ? true : false}
                value={this.state.new_password1}
                onChange={(e) => this.onPassword1Change(e)}
                InputProps={{
                  className: 'CONFIRM_PASSWORD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'FLOATING_LABLE_STYLE',
                }}
              />
            </div>
            <div className="CONFIRM_PASSWORD_INPUT_FIELD_FRAME">
              <TextField
                id="outlined-secondary-2"
                label="Verify Your Password"
                variant="outlined"
                // color="secondary"
                type="password"
                name="new_password2"
                fullWidth
                required
                placeholder={
                  this.state.password2FieldsCheck
                    ? this.state.errors.new_password2
                    : ''
                }
                error={this.state.password2FieldsCheck ? true : false}
                value={this.state.new_password2}
                onChange={(e) => this.onPassword2Change(e)}
                InputProps={{
                  className: 'CONFIRM_PASSWORD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'FLOATING_LABLE_STYLE',
                }}
              />
            </div>

            <div
              className="CONFIRM_PASSWORD_ERROR_CONTAINER"
              style={
                this.state.passwordMatchAlert
                  ? { display: 'flex', marginTop: '15px' }
                  : { display: 'none' }
              }
            >
              <p>{this.state.errors.request}</p>
            </div>
            <div className="CONFIRM_PASSWORD_FORM_BUTTON">
              <h6 onClick={this.onSubmit}>Confirm</h6>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    token: state.users.token,
    response: state.users.changePWResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PasswordResetConfirmChange: (new_password1, new_password2, props) =>
      dispatch(
        actions.confirmPasswordChange(new_password1, new_password2, props)
      ),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PasswordResetConfirm);
