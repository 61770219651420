import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  lastQuotation: null,
  quotation: null,
  quotations: [],
};

const getQuotationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getQuotationSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    quotation: action.payload,
  });
};

const getLastQuotationSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    lastQuotation: action.payload,
  });
};

const getQuotationsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    quotations: action.payload,
  });
};

const getQuotationFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clearQuotationProps = (state, action) => {
  return updateObject(state, {
    loading: false,
    quotation: null,
  });
};

const quotationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_QUOTATION_START:
      return getQuotationStart(state, action);
    case actionTypes.GET_QUOTATION_SUCCESS:
      return getQuotationSuccess(state, action);
    case actionTypes.GET_LAST_QUOTATION_SUCCESS:
      return getLastQuotationSuccess(state, action);
    case actionTypes.GET_QUOTATIONS_SUCCESS:
      return getQuotationsSuccess(state, action);
    case actionTypes.GET_QUOTATION_FAIL:
      return getQuotationFaild(state, action);
    case actionTypes.CLEAR_QUOTATION_DATA:
      return clearQuotationProps(state, action);
    default:
      return state;
  }
};

export default quotationReducer;
