import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getCollectedDeviceStart = () => {
  return {
    type: actionTypes.GET_COLLECTED_DEVICE_START,
  };
};

export const getCollectedItemStart = () => {
  return {
    type: actionTypes.GET_COLLECTED_ITEM_START,
  };
};

export const getCollectedDeviceSuccess = (data) => {
  return {
    type: actionTypes.GET_COLLECTED_DEVICE_SUCCESS,
    payload: data,
  };
};

export const getCollectedDevicesSuccess = (data) => {
  return {
    type: actionTypes.GET_COLLECTED_DEVICES_SUCCESS,
    payload: data,
  };
};

export const getCollectedItemSuccess = (data) => {
  return {
    type: actionTypes.GET_COLLECTED_ITEM_SUCCESS,
    payload: data,
  };
};

export const getCollectedItemsSuccess = (data) => {
  return {
    type: actionTypes.GET_COLLECTED_ITEMS_SUCCESS,
    payload: data,
  };
};

export const getCollectedDeviceFaild = (error) => {
  return {
    type: actionTypes.GET_COLLECTED_DEVICE_FAIL,
    error: error,
  };
};

export const getCollectedItemFaild = (error) => {
  return {
    type: actionTypes.GET_COLLECTED_ITEM_FAIL,
    error: error,
  };
};

export const getCollectedDevices = (token) => {
  return (dispatch) => {
    dispatch(getCollectedDeviceStart());
    console.log('getting all collected devices detailes');
    axios
      .get(`${HOST_URL}/collect-device/collected-devices-detailes/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCollectedDevicesSuccess(response.data)))
      .catch((error) => dispatch(getCollectedDeviceFaild(error)));
  };
};

// get the items collected with one collection details
export const getCollectedItems = (token) => {
  return (dispatch) => {
    dispatch(getCollectedDeviceStart());
    console.log('getting all collected items');
    axios
      .get(`${HOST_URL}/collect-device/collected-devices/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCollectedItemsSuccess(response.data)))
      .catch((error) => dispatch(getCollectedItemFaild(error)));
  };
};

export const getCollectedDevice = (token, id) => {
  return (dispatch) => {
    dispatch(getCollectedDeviceStart());

    axios
      .delete(`${HOST_URL}/collect-device/collected-devices-detailes/${id}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCollectedDeviceSuccess(response.data)))
      .catch((error) => dispatch(getCollectedDeviceFaild(error)));
  };
};

// get the item only
export const getCollectedItem = (token, id) => {
  return (dispatch) => {
    dispatch(getCollectedDeviceStart());
    console.log('getting all collected item');
    axios
      .get(`${HOST_URL}/collect-device/collected-devices/${id}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCollectedItemSuccess(response.data)))
      .catch((error) => dispatch(getCollectedItemFaild(error)));
  };
};

export const getCollectedDevicesByName = (token, name) => {
  return (dispatch) => {
    dispatch(getCollectedDeviceStart());

    axios
      .delete(
        `${HOST_URL}/collect-device/collected-devices-detailes/name-${name}`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getCollectedDeviceSuccess(response.data)))
      .catch((error) => dispatch(getCollectedDeviceFaild(error)));
  };
};

export const postCollectedDeviceData = (
  token,
  client_name,
  client_email,
  client_number,
  client_address,
  special_instruction,
  number_of_devices,
  claim_tech,
  collection_date,
  insurance_company,
  item,
  comment
) => {
  return (dispatch) => {
    dispatch(getCollectedDeviceStart());
    let fd = new FormData();
    fd.append('client_name', client_name);
    fd.append('client_email', client_email);
    fd.append('client_number', client_number);
    fd.append('client_address', client_address);
    fd.append('special_instruction', special_instruction);
    fd.append('number_of_devices', number_of_devices);
    fd.append('claim_tech', claim_tech);
    fd.append('collection_date', collection_date);
    fd.append('insurance_company', insurance_company);
    fd.append('item', item);
    fd.append('comment', comment);

    axios
      .post(`${HOST_URL}/collect-device/collected-devices-detailes/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getCollectedDeviceSuccess(response.data));
        dispatch(getCollectedDevices(token));
      })
      .catch((error) => dispatch(getCollectedDeviceFaild(error)));
  };
};

export const postCollectedDevicesData = (
  token,
  client_name,
  client_email,
  client_number,
  client_address,
  special_instruction,
  number_of_devices,
  claim_tech,
  collection_date,
  insurance_company
) => {
  return (dispatch) => {
    dispatch(getCollectedDeviceStart());
    let fd = new FormData();
    fd.append('client_name', client_name);
    fd.append('client_email', client_email);
    fd.append('client_number', client_number);
    fd.append('client_address', client_address);
    fd.append('special_instruction', special_instruction);
    fd.append('number_of_devices', number_of_devices);
    fd.append('claim_tech', claim_tech);
    fd.append('collection_date', collection_date);
    fd.append('insurance_company', insurance_company);

    axios
      .post(`${HOST_URL}/collect-device/collected-devices-detailes/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getCollectedDeviceSuccess(response.data));
        dispatch(getCollectedDevices(token));
      })
      .catch((error) => dispatch(getCollectedDeviceFaild(error)));
  };
};

export const updateCollectedDevicesData = (
  token,
  id,
  special_instruction,
  number_of_devices,
  claim_tech,
  collection_date,
  insurance_company
) => {
  return (dispatch) => {
    dispatch(getCollectedDeviceStart());
    let fd = new FormData();
    fd.append('special_instruction', special_instruction);
    fd.append('number_of_devices', number_of_devices);
    fd.append('claim_tech', claim_tech);
    fd.append('collection_date', collection_date);
    fd.append('insurance_company', insurance_company);

    axios
      .patch(
        `${HOST_URL}/collect-device/collected-devices-detailes/${id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getCollectedDeviceSuccess(response.data));
        dispatch(getCollectedDevices(token));
      })
      .catch((error) => dispatch(getCollectedDeviceFaild(error)));
  };
};

export const postCollectedItemData = (
  token,
  collected_device_details,
  item,
  comment
) => {
  return (dispatch) => {
    dispatch(getCollectedItemStart());
    let fd = new FormData();
    fd.append('collected_device_details', collected_device_details);
    fd.append('item', item);
    fd.append('comment', comment);

    axios
      .post(`${HOST_URL}/collect-device/collected-devices/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getCollectedItemSuccess(response.data));
        dispatch(getCollectedItems(token));
      })
      .catch((error) => dispatch(getCollectedItemFaild(error)));
  };
};
