// Auth Action Types //
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

//USER ACTION TYPES//
export const AUTH_DETAILS_SUCCESS = 'AUTH_DETAILS_SUCCESS';
export const AUTH_PROFILE_PIC_SUCCESS = 'AUTH_PROFILE_PIC_SUCCESS';
export const AUTH_VERIFICATION_INFO_SUCCESS = 'AUTH_VERIFICATION_INFO_SUCCESS';
export const CHANGE_PASSWORD_RESPONSE = 'CHANGE_PASSWORD_RESPONSE';
export const PROFILE_CHANGE_PASSWORD_RESPONSE =
  'PROFILE_CHANGE_PASSWORD_RESPONSE';
export const RESET_PASSWORD_RESPONSE = 'RESET_PASSWORD_RESPONSE';

// UI THEME
export const UI_MODE_SUCCESS = 'UI_MODE_SUCCESS';

// JOB CARD Action Types //
export const JOB_CARD_START = 'JOB_CARD_START';
export const JOB_CARD_SUCCESS = 'JOB_CARD_SUCCESS';
export const TARGET_JOB_CARD_SUCCESS = 'TARGET_JOB_CARD_SUCCESS';
export const JOB_CARDS_SUCCESS = 'JOB_CARDS_SUCCESS';
export const FILTERED_JOB_CARD_SUCCESS = 'FILTERED_JOB_CARD_SUCCESS';
export const JOB_CARD_FAIL = 'JOB_CARD_FAIL';

// JOB TASK Action Types //
export const JOB_TASK_START = 'JOB_TASK_START';
export const JOB_TASK_SUCCESS = 'JOB_TASK_SUCCESS';
export const TARGET_JOB_TASK_SUCCESS = 'TARGET_JOB_TASK_SUCCESS';
export const JOB_TASKS_SUCCESS = 'JOB_TASKS_SUCCESS';
export const FILTERED_JOB_TASK_SUCCESS = 'FILTERED_JOB_TASK_SUCCESS';
export const OVERDUE_JOB_TASK_SUCCESS = 'OVERDUE_JOB_TASK_SUCCESS';
export const JOB_TASK_FAIL = 'JOB_TASK_FAIL';

// CLIENT Action Types //
export const GET_CLIENT_START = 'GET_CLIENT_START';
export const GET_CLIENT_SUCCESS = 'GET_CLIENT_SUCCESS';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENT_FAIL = 'GET_CLIENT_FAIL';

// INSURANCE Action Types //
export const GET_INSURANCE_START = 'GET_INSURANCE_START';
export const GET_CLAIM_START = 'GET_CLAIM_START';
export const GET_INSURANCE_SUCCESS = 'GET_INSURANCE_SUCCESS';
export const GET_CLAIM_SUCCESS = 'GET_CLAIM_SUCCESS';
export const GET_INSURANCES_SUCCESS = 'GET_INSURANCES_SUCCESS';
export const GET_CLAIMS_SUCCESS = 'GET_CLAIMS_SUCCESS';
export const GET_INSURANCE_FAIL = 'GET_INSURANCE_FAIL';
export const GET_CLAIM_FAIL = 'GET_CLAIM_FAIL';

// COLLECTED DEVICES DETAILS Action Types //
export const GET_COLLECTED_DEVICE_START = 'GET_COLLECTED_DEVICE_START';
export const GET_COLLECTED_DEVICE_SUCCESS = 'GET_COLLECTED_DEVICE_SUCCESS';
export const GET_COLLECTED_DEVICES_SUCCESS = 'GET_COLLECTED_DEVICES_SUCCESS';
export const GET_COLLECTED_DEVICE_FAIL = 'GET_COLLECTED_DEVICE_FAIL';

// COLLECTED ITEMS Action Types //
export const GET_COLLECTED_ITEM_START = 'GET_COLLECTED_ITEM_START';
export const GET_COLLECTED_ITEM_SUCCESS = 'GET_COLLECTED_ITEM_SUCCESS';
export const GET_COLLECTED_ITEMS_SUCCESS = 'GET_COLLECTED_ITEMS_SUCCESS';
export const GET_COLLECTED_ITEM_FAIL = 'GET_COLLECTED_ITEM_FAIL';

// JOB COMMENT Action Types //
export const GET_JOB_COMMENT_START = 'GET_JOB_COMMENT_START';
export const GET_JOB_COMMENT_SUCCESS = 'GET_JOB_COMMENT_SUCCESS';
export const GET_JOB_COMMENTS_SUCCESS = 'GET_JOB_COMMENTS_SUCCESS';
export const GET_JOB_COMMENT_FAIL = 'GET_JOB_COMMENT_FAIL';

// JOB REPORTS Action Types //
export const GET_JOB_REPORT_START = 'GET_JOB_REPORT_START';
export const GET_JOB_TECH_REPORT_SUCCESS = 'GET_JOB_TECH_REPORT_SUCCESS';
export const GET_JOB_DAMAGE_REPORT_SUCCESS = 'GET_DAMAGE_JOB_REPORT_SUCCESS';
export const GET_JOB_TECH_REPORTS_SUCCESS = 'GET_JOB_TECH_REPORTS_SUCCESS';
export const GET_JOB_DAMAGE_REPORTS_SUCCESS = 'GET_DAMAGE_JOB_REPORTS_SUCCESS';
export const GET_JOB_REPORT_FAIL = 'GET_JOB_REPORT_FAIL';

// TEAM MEMBER Action Types //
export const GET_TEAM_MEMBER_START = 'GET_TEAM_MEMBER_START';
export const GET_TEAM_MEMBER_SUCCESS = 'GET_TEAM_MEMBER_SUCCESS';
export const GET_ALL_PERMISSIONS_SUCCESS = 'GET_ALL_PERMISSIONS_SUCCESS';
export const GET_TEAM_MEMBERS_SUCCESS = 'GET_TEAM_MEMBERS_SUCCESS';
export const GET_TEAM_MEMBER_FAIL = 'GET_TEAM_MEMBER_FAIL';

// TASK STATUS Action Types //
export const GET_TASK_STATUS_START = 'GET_TASK_STATUS_START';
export const GET_TASK_STATUS_SUCCESS = 'GET_TASK_STATUS_SUCCESS';
export const GET_TASK_STATUSES_SUCCESS = 'GET_TASK_STATUSES_SUCCESS';
export const GET_TASK_STATUS_FAIL = 'GET_TASK_STATUS_FAIL';

// Notification Action Types //
export const GET_NOTIFICATION_START = 'GET_NOTIFICATION_START';
export const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATION_FAIL = 'GET_NOTIFICATION_FAIL';

//DOCUMENT ACTION TYPES//

export const GET_REGISTERED_DOCUMENT_START = 'GET_REGISTERED_DOCUMENT_START';
export const GET_REGISTERED_DOCUMENT_SUCCESS =
  'GET_REGISTERED_DOCUMENT_SUCCESS';
export const GET_REGISTERED_DOCUMENTS_SUCCESS =
  'GET_REGISTERED_DOCUMENTS_SUCCESS';
export const GET_REGISTERED_DOCUMENT_FAILD = 'GET_REGISTERED_DOCUMENT_FAILD';

export const SET_PROGRESS_DATA = 'SET_PROGRESS_DATA';
export const SET_DATA_SIZE = 'SET_DATA_SIZE';

// Inventory Section //

// Order Action Types //
export const GET_ORDER_START = 'GET_ORDER_START';
export const GET_LAST_ORDER_SUCCESS = 'GET_LAST_ORDER_SUCCESS';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';

// Item Action Types //
export const GET_ITEM_START = 'GET_ITEM_START';
export const GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS';
export const GET_ITEM_TO_EDIT_SUCCESS = 'GET_ITEM_TO_EDIT_SUCCESS';
export const GET_ITEMS_SUCCESS = 'GET_ITEMS_SUCCESS';
export const GET_ITEM_FAIL = 'GET_ITEM_FAIL';

// Order Item Action Types //
export const GET_ORDER_ITEM_START = 'GET_ORDER_ITEM_START';
export const GET_ORDER_ITEM_SUCCESS = 'GET_ORDER_ITEM_SUCCESS';
export const GET_ORDER_ITEMS_SUCCESS = 'GET_ORDER_ITEMS_SUCCESS';
export const GET_PERIOD_ORDERS_SUCCESS = 'GET_PERIOD_ORDERS_SUCCESS';
export const GET_ORDER_ITEM_FAIL = 'GET_ORDER_ITEM_FAIL';

// Invoice Action Types //
export const GET_INVOICE_START = 'GET_INVOICE_START';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_LAST_INVOICE_SUCCESS = 'GET_LAST_INVOICE_SUCCESS';
export const GET_INVOICES_SUCCESS = 'GET_INVOICES_SUCCESS';
export const GET_INVOICE_FAIL = 'GET_INVOICE_FAIL';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const CLEAR_INVOICE_DATA = 'CLEAR_INVOICE_DATA';

// Quotaion Action Types //
export const GET_QUOTATION_START = 'GET_QUOTATION_START';
export const GET_QUOTATION_SUCCESS = 'GET_QUOTATION_SUCCESS';
export const GET_LAST_QUOTATION_SUCCESS = 'GET_LAST_QUOTATION_SUCCESS';
export const GET_QUOTATIONS_SUCCESS = 'GET_QUOTATIONS_SUCCESS';
export const GET_QUOTATION_FAIL = 'GET_QUOTATION_FAIL';
export const CLEAR_QUOTATION_DATA = 'CLEAR_QUOTATION_DATA';

// Company Action Types //
export const GET_COMPANY_START = 'GET_COMPANY_START';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_BRANCHES_DETAILS_SUCCESS = 'GET_BRANCHES_DETAILS_SUCCESS';
export const GET_COMPANY_BANKING_DETAILS_SUCCESS =
  'GET_COMPANY_BANKING_DETAILS_SUCCESS';

export const GET_COMPANY_FAIL = 'GET_COMPANY_FAIL';

// CLIENT Action Types //
export const GET_PRODUCT_START = 'GET_PRODUCT_START';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';

// Financial Account Action Types //
export const GET_FINANCIAL_ACCOUNT_START = 'GET_FINANCIAL_ACCOUNT_START';
export const GET_FINANCIAL_ACCOUNT_SUCCESS = 'GET_FINANCIAL_ACCOUNT_SUCCESS';
export const GET_FINANCIAL_ACCOUNTS_SUCCESS = 'GET_FINANCIAL_ACCOUNTS_SUCCESS';
export const GET_FINANCIAL_ACCOUNT_FAIL = 'GET_FINANCIAL_ACCOUNT_FAIL';

// Transaction Action Types //
export const GET_TRANSACTION_START = 'GET_TRANSACTION_START';
export const GET_TRANSACTION_SUCCESS = 'GET_TRANSACTION_SUCCESS';
export const GET_TRANSACTIONS_SUCCESS = 'GET_TRANSACTIONS_SUCCESS';
export const GET_UNALLOCATED_TRANSACTIONS_SUCCESS =
  'GET_UNALLOCATED_TRANSACTIONS_SUCCESS';
export const GET_TRANSACTION_FAIL = 'GET_TRANSACTION_FAIL';

// DAMAGE REPORT Types //
export const GET_DAMAGE_REPORT_START = 'GET_DAMAGE_REPORT_START';
export const GET_DAMAGE_REPORT_SUCCESS = 'GET_DAMAGE_REPORT_SUCCESS';
export const GET_DAMAGE_REPORTS_SUCCESS = 'GET_DAMAGE_REPORTS_SUCCESS';
export const GET_DAMAGE_REPORT_FAIL = 'GET_DAMAGE_REPORT_FAIL';

// DAMAGE REPORT ITEM Types //
export const GET_DAMAGE_REPORT_ITEM_START = 'GET_DAMAGE_REPORT_ITEM_START';
export const GET_DAMAGE_REPORT_ITEM_SUCCESS = 'GET_DAMAGE_REPORT_ITEM_SUCCESS';
export const GET_DAMAGE_REPORT_ITEMS_SUCCESS =
  'GET_DAMAGE_REPORT_ITEMS_SUCCESS';
export const GET_DAMAGE_REPORT_ITEM_FAIL = 'GET_DAMAGE_REPORT_ITEM_FAIL';

// Statement Action Types //
export const GET_STATEMENT_START = 'GET_STATEMENT_START';
export const GET_STATEMENT_SUCCESS = 'GET_STATEMENT_SUCCESS';
export const GET_STATEMENTS_SUCCESS = 'GET_STATEMENTS_SUCCESS';
export const GET_STATEMENT_FAIL = 'GET_STATEMENT_FAIL';

// Brand Action Types //
export const GET_BRAND_START = 'GET_BRAND_START';
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS';
export const GET_BRANDS_SUCCESS = 'GET_BRANDS_SUCCESS';
export const GET_BRAND_FAIL = 'GET_BRAND_FAIL';

// Category Action Types //
export const GET_CATEGORY_START = 'GET_CATEGORY_START';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORY_FAIL = 'GET_CATEGORY_FAIL';

// Supplier Action Types //
export const GET_SUPPLIER_START = 'GET_SUPPLIER_START';
export const GET_SUPPLIER_SUCCESS = 'GET_SUPPLIER_SUCCESS';
export const GET_SUPPLIERS_SUCCESS = 'GET_SUPPLIERS_SUCCESS';
export const GET_SUPPLIER_FAIL = 'GET_SUPPLIER_FAIL';

// INSIGHTS

// JOBS INSIGHTS  //
export const GET_JOBS_INSIGHTS_START = 'GET_JOBS_INSIGHTS_START';
export const GET_ALL_NUMERICS_SUCCESS = 'GET_ALL_NUMERICS_SUCCESS';
export const GET_TOTAL_JOBS_SUCCESS = 'GET_TOTAL_JOBS_SUCCESS';
export const GET_TOTAL_TASKS_SUCCESS = 'GET_TOTAL_TASKS_SUCCESS';
export const GET_PENDING_TASKS_SUCCESS = 'GET_PENDING_TASKS_SUCCESS';
export const GET_COMPLETE_TASKS_SUCCESS = 'GET_COMPLETE_TASKS_SUCCESS';
export const GET_CLOSED_TASKS_SUCCESS = 'GET_CLOSED_TASKS_SUCCESS';
export const GET_JOBS_INSIGHTS_FAILD = 'GET_JOBS_INSIGHTS_FAILD';
export const GET_JOBS_TASKS_SUCCESS = 'GET_JOBS_TASKS_SUCCESS';

// SUBSCRIPTION //

export const GET_SUBSCRIPTION_START = 'GET_SUBSCRIPTION_START';
export const GET_SUBSCRIPTION_SUCCESS = 'GET_SUBSCRIPTION_SUCCESS';
export const GET_SUBSCRIPTION_FAIL = 'GET_SUBSCRIPTION_FAIL';
