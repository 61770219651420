import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getItemStart = () => {
  return {
    type: actionTypes.GET_ITEM_START,
  };
};

export const getItemSuccess = (data) => {
  return {
    type: actionTypes.GET_ITEM_SUCCESS,
    payload: data,
  };
};

export const getItemToEditSuccess = (data) => {
  return {
    type: actionTypes.GET_ITEM_TO_EDIT_SUCCESS,
    payload: data,
  };
};

export const getOrderItemSuccess = (data) => {
  return {
    type: actionTypes.GET_ORDER_ITEM_SUCCESS,
    payload: data,
  };
};

export const getItemsSuccess = (data) => {
  return {
    type: actionTypes.GET_ITEMS_SUCCESS,
    payload: data,
  };
};

export const getOrderItemsSuccess = (data) => {
  return {
    type: actionTypes.GET_ORDER_ITEMS_SUCCESS,
    payload: data,
  };
};

export const getItemFaild = (error) => {
  return {
    type: actionTypes.GET_ITEM_FAIL,
    error: error,
  };
};

export const getItems = (token) => {
  return (dispatch) => {
    dispatch(getItemStart());
    console.log('getting all Items');
    axios
      .get(`${HOST_URL}/items/item/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getItemsSuccess(response.data)))
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const getItem = (token, id) => {
  return (dispatch) => {
    dispatch(getItemStart());

    axios
      .get(`${HOST_URL}/items/item/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getItemSuccess(response.data)))
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const getItemByCode = (token, item_code) => {
  return (dispatch) => {
    dispatch(getItemStart());

    axios
      .get(`${HOST_URL}/items/item/itemcode_${item_code}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getItemToEditSuccess(response.data)))
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const postItemData = (
  token,
  item_code,
  product_title,
  product_type,
  product_code,
  key_features,
  description,
  brand,
  category,
  supplier,
  order,
  sku,
  barcode,
  cost,
  markup,
  // mrp,
  // discount,
  price,
  quantity,
  sold,
  available,
  defective
) => {
  return (dispatch) => {
    dispatch(getItemStart());
    let fd = new FormData();
    fd.append('item_code', item_code);
    fd.append('product_title', product_title);
    fd.append('product_type', product_type);
    fd.append('product_code', product_code);
    fd.append('key_features', key_features);
    fd.append('description', description);
    fd.append('brand', brand);
    fd.append('category', category);
    fd.append('supplier', supplier);
    fd.append('order', order);
    fd.append('sku', sku);
    fd.append('barcode', barcode);
    // fd.append('mrp', mrp);
    // fd.append('discount', discount);
    fd.append('cost', cost);
    fd.append('markup', markup);
    fd.append('price', price);
    fd.append('quantity', quantity);
    fd.append('sold', sold);
    fd.append('available', available);
    fd.append('defective', defective);

    axios
      .post(`${HOST_URL}/items/item/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getItemSuccess(response.data));
      })
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const updateItemData = (
  token,
  item_id,
  product_id,
  item_code,
  product_title,
  product_type,
  product_code,
  key_features,
  description,
  brand,
  category,
  supplier,
  order,
  sku,
  barcode,
  cost,
  markup,
  price,
  quantity,
  sold,
  available,
  defective
) => {
  return (dispatch) => {
    dispatch(getItemStart());
    let fd = new FormData();
    fd.append('product_id', product_id);
    fd.append('item_code', item_code);
    fd.append('product_title', product_title);
    fd.append('product_type', product_type);
    fd.append('product_code', product_code);
    fd.append('key_features', key_features);
    fd.append('description', description);
    fd.append('brand', brand);
    fd.append('category', category);
    fd.append('supplier', supplier);
    fd.append('order', order);
    fd.append('sku', sku);
    fd.append('barcode', barcode);
    // fd.append('mrp', mrp);
    // fd.append('discount', discount);
    fd.append('cost', cost);
    fd.append('markup', markup);
    fd.append('price', price);
    fd.append('quantity', quantity);
    fd.append('sold', sold);
    fd.append('available', available);
    fd.append('defective', defective);

    axios
      .patch(`${HOST_URL}/items/item/${item_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getItemToEditSuccess(response.data));
      })
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

// Order Item's Actions

export const getOrderItems = (token) => {
  return (dispatch) => {
    dispatch(getItemStart());
    console.log('getting all Order Items');
    axios
      .get(`${HOST_URL}/items/order-item/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOrderItemsSuccess(response.data)))
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const getOrderItemsByOrder = (token, order_id) => {
  return (dispatch) => {
    dispatch(getItemStart());

    axios
      .get(`${HOST_URL}/items/order-item/order_${order_id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOrderItemsSuccess(response.data)))
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const getOrderItem = (token, id) => {
  return (dispatch) => {
    dispatch(getItemStart());

    axios
      .get(`${HOST_URL}/items/order-item/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOrderItemSuccess(response.data)))
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const deleteOrderItem = (token, id) => {
  return (dispatch) => {
    dispatch(getItemStart());

    axios
      .delete(`${HOST_URL}/items/order-item/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOrderItemSuccess(response.data)))
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const postOrderItemData = (
  token,
  description,
  content,
  item_code,
  order,
  sku,
  discount,
  price,
  quantity,
  amount
) => {
  return (dispatch) => {
    dispatch(getItemStart());
    let fd = new FormData();
    fd.append('description', description);
    fd.append('item_code', item_code);
    fd.append('order', order);
    fd.append('sku', sku);
    fd.append('discount', discount);
    fd.append('price', price);
    fd.append('quantity', quantity);
    fd.append('content', content);
    fd.append('amount', amount);

    axios
      .post(`${HOST_URL}/items/order-item/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getOrderItemSuccess(response.data));
        dispatch(getOrderItemsByOrder(token, order));
      })
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const partialUpdateOrderItemData = (
  token,
  order_item_id,
  description,
  content,
  price,
  quantity,
  amount,
  order
) => {
  return (dispatch) => {
    dispatch(getItemStart());
    let fd = new FormData();
    fd.append('description', description);
    fd.append('order', order);
    fd.append('price', price);
    fd.append('quantity', quantity);
    fd.append('amount', amount);
    fd.append('content', content);
    fd.append('sku', `SK${order_item_id}`);

    axios
      .put(`${HOST_URL}/items/order-item/${order_item_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getOrderItemSuccess(response.data));
      })
      .catch((error) => dispatch(getItemFaild(error)));
  };
};

export const updateOrderItemData = (
  token,
  order_item_id,
  description,
  content,
  item_code,
  order,
  sku,
  discount,
  price,
  quantity
) => {
  return (dispatch) => {
    dispatch(getItemStart());
    let fd = new FormData();
    fd.append('description', description);
    fd.append('item_code', item_code);
    fd.append('order', order);
    fd.append('sku', sku);
    fd.append('discount', discount);
    fd.append('price', price);
    fd.append('quantity', quantity);
    fd.append('content', content);

    axios
      .put(`${HOST_URL}/items/order-item/${order_item_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getOrderItemSuccess(response.data));
      })
      .catch((error) => dispatch(getItemFaild(error)));
  };
};
