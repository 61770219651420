import React, { Component } from 'react';
import { pink, blue } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';

export default class ReportImageCard extends Component {
  state = {
    checked: false,
  };

  componentDidMount() {
    // console.log(this.props.document.document_image);
    this.handlePreSelectedImages();
  }
  componentDidUpdate(prevProps) {
    if (this.props.preSelectedImagesList !== prevProps.preSelectedImagesList) {
      this.handlePreSelectedImages();
    }
  }

  handlePreSelectedImages = () => {
    if (this.props.preSelectedImagesList.includes(this.props.documentID)) {
      this.setState({ checked: true });
    }
  };

  handleDeleteImage = () => {
    this.props.deleteImage();
  };
  handlePreviewImage = () => {
    this.props.openPreview();
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
    if (event.target.checked) {
      this.props.handleSelectImage();
    } else {
      this.props.handleUnSelectImage();
    }
  };

  render() {
    return (
      <div className="IMAGE_CARD_CONTAINER" style={{ marginRight: '10px' }}>
        <div className="IMAGE_CARD_DELETE_ICON">
          <Tooltip title="Select">
            <Avatar sx={{ bgcolor: '#DDDDDD' }}>
              <Checkbox
                checked={this.state.checked}
                onChange={this.handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Avatar>
          </Tooltip>
          <Tooltip title="Preview">
            <Avatar
              sx={{ bgcolor: blue[500], ml: 2 }}
              onClick={this.handlePreviewImage}
            >
              <VisibilityIcon></VisibilityIcon>
            </Avatar>
          </Tooltip>
        </div>
        {this.props.file ? (
          <div className="IMAGE_CONTAINER" onClick={this.handlePreviewImage}>
            <img src={this.props.file} alt="repair cocument"></img>
          </div>
        ) : null}
      </div>
    );
  }
}
