import React, { Component } from 'react';
import './styles/employee-form.css';
import TextField from '@mui/material/TextField';
import { Button, IconButton, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default class EmployeeForm extends Component {
  state = {
    first_name: '',
    firstNameError: false,
    last_name: '',
    lastNameError: false,
    job_title: '',
    jobTitleError: false,
    email: '',
    emailError: false,
    branchName: '',
    branchNameError: false,
    companyName: '',
    members: [],
  };

  componentDidMount() {
    const savedData = JSON.parse(localStorage.getItem('members_form'));
    const companyData = JSON.parse(localStorage.getItem('company_form'));
    if (savedData) {
      this.setState({
        members: [...savedData],
        companyName: companyData?.companyName,
      });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ [`${e.target.name}Error`]: false });
  };

  validate = () => {
    const { first_name, last_name, job_title, email, branchName } = this.state;
    if (first_name === '') {
      this.setState({ firstNameError: true });
    } else if (last_name === '') {
      this.setState({ lastNameError: true });
    } else if (email === '') {
      this.setState({ emailError: true });
    } else if (job_title === '') {
      this.setState({ jobTitleError: true });
    } else if (branchName === '') {
      this.setState({ branchNameError: true });
    } else {
      return true;
    }
  };

  handleAddMember = () => {
    const valid = this.validate();
    if (valid) {
      const {
        first_name,
        last_name,
        branchName,
        companyName,
        email,
        job_title,
        members,
      } = this.state;

      const newMember = {
        branchName,
        companyName,
        first_name,
        last_name,
        email,
        job_title,
      };

      this.setState(
        {
          members: [...members, newMember],
        },
        () =>
          localStorage.setItem(
            'members_form',
            JSON.stringify(this.state.members)
          )
      );
    }
  };
  onSelectBranch = (e, option) => {
    console.log(option.props.value);

    if (option.props.value !== undefined) {
      this.setState({
        branchName: option ? option.props.value : '',
        branchNameError: false,
      });
    } else {
      this.setState({
        branchName: '',
      });
    }
  };

  deleteMember = (index) => {
    const newMembers = this.state.members;
    newMembers.splice(index, 1);
    this.setState({ members: newMembers });
    localStorage.setItem('members_form', JSON.stringify(newMembers));
  };
  render() {
    const branches = JSON.parse(localStorage.getItem('branch_form'));
    return (
      <div className="COMPAN_DETAILS_FORMS_CONTAINER">
        <div className="COMPANY_FORM_CONTAINER">
          <div className="COMPANY_EMPLOYEE_INPUT_FIELD_CONTAINER">
            <TextField
              id="first-name"
              label="First name"
              variant="outlined"
              name="first_name"
              type="text"
              required
              error={this.state.firstNameError}
              fullWidth
              value={this.state.first_name}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_EMPLOYEE_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_EMPLOYEE_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_EMPLOYEE_INPUT_FIELD_CONTAINER">
            <TextField
              id="last-name"
              label="Last name"
              variant="outlined"
              fullWidth
              name="last_name"
              required
              error={this.state.lastNameError}
              value={this.state.last_name}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_EMPLOYEE_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_EMPLOYEE_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_EMPLOYEE_INPUT_FIELD_CONTAINER">
            <TextField
              id="member-email"
              label="Employee Email"
              variant="outlined"
              name="email"
              type="email"
              required
              error={this.state.emailError}
              fullWidth
              value={this.state.email}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_EMPLOYEE_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_EMPLOYEE_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_EMPLOYEE_INPUT_FIELD_CONTAINER">
            <TextField
              id="job-title"
              label="Job title"
              variant="outlined"
              fullWidth
              name="job_title"
              required
              error={this.state.jobTitleError}
              value={this.state.job_title}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_EMPLOYEE_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_EMPLOYEE_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          {branches && branches.length > 0 ? (
            <div className="COMPANY_EMPLOYEE_INPUT_FIELD_CONTAINER">
              <TextField
                id="select-branch"
                select
                label="Select Branch"
                value={this.state.branchName}
                required
                error={this.state.branchNameError}
                fullWidth
                onChange={(e, option) => this.onSelectBranch(e, option)}
                InputProps={{
                  className: 'COMPANY_EMPLOYEE_INPUT_FIELD',
                }}
                InputLabelProps={{
                  className: 'COMPANY_EMPLOYEE_INPUT_FIELD_LABEL',
                }}
              >
                {branches.map((option) => (
                  <MenuItem key={option.name} value={option.name}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          ) : null}
        </div>
        <div className="COMPANY_FORM_CONTAINER">
          <Button
            variant="contained"
            sx={{ marginTop: 3 }}
            onClick={this.handleAddMember}
          >
            add
          </Button>
        </div>
        <div className="COMPANY_FORM_CONTAINER">
          {this.state.members.length > 0
            ? this.state.members.map((member, index) => (
                <div className="COMPANY_FORM_CONTAINER" key={member.email}>
                  <div className="BRANCH_ITEM">
                    <span>{member.email}</span>
                    <IconButton
                      aria-label="delete"
                      onClick={(index) => this.deleteMember(index)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}
