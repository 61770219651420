import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getJobCardStart = () => {
  return {
    type: actionTypes.JOB_CARD_START,
  };
};

export const getJobCardSuccess = (data) => {
  return {
    type: actionTypes.JOB_CARD_SUCCESS,
    payload: data,
  };
};

export const getTargetJobCardSuccess = (data) => {
  return {
    type: actionTypes.TARGET_JOB_CARD_SUCCESS,
    payload: data,
  };
};

export const getJobCardsSuccess = (data) => {
  return {
    type: actionTypes.JOB_CARDS_SUCCESS,
    payload: data,
  };
};

export const getJobFilteredCardsSuccess = (data) => {
  return {
    type: actionTypes.FILTERED_JOB_CARD_SUCCESS,
    payload: data,
  };
};

export const getJobCardFaild = (error) => {
  return {
    type: actionTypes.JOB_CARD_FAIL,
    error: error,
  };
};

export const getJobCards = (token) => {
  return (dispatch) => {
    dispatch(getJobCardStart());
    console.log('getting all cards');
    axios
      .get(`${HOST_URL}/booking/job-card/user_1/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobCardsSuccess(response.data)))
      .catch((error) => dispatch(getJobCardFaild(error)));
  };
};

export const getLastNJobCards = (token, N) => {
  return (dispatch) => {
    dispatch(getJobCardStart());
    axios
      .get(`${HOST_URL}/booking/job-card/user_${N}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobCardsSuccess(response.data)))
      .catch((error) => dispatch(getJobCardFaild(error)));
  };
};

export const getFilteredJobCards = (token, startDate, endDate) => {
  return (dispatch) => {
    dispatch(getJobCardStart());
    console.log('getting all cards');
    axios
      .get(`${HOST_URL}/booking/job-card/date_${startDate}_${endDate}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobFilteredCardsSuccess(response.data)))
      .catch((error) => dispatch(getJobCardFaild(error)));
  };
};

export const getFilteredJobCardsByKeywork = (token, keyword) => {
  return (dispatch) => {
    dispatch(getJobCardStart());
    console.log('getting all cards');
    axios
      .get(
        `${HOST_URL}/booking/job-card/keyword_${keyword.replace(/ /g, '')}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getJobFilteredCardsSuccess(response.data)))
      .catch((error) => dispatch(getJobCardFaild(error)));
  };
};

export const getJobCard = (token, id) => {
  return (dispatch) => {
    dispatch(getJobCardStart());

    axios
      .get(`${HOST_URL}/booking/job-card/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobCardSuccess(response.data)))
      .catch((error) => dispatch(getJobCardFaild(error)));
  };
};

// used when getting job card data for an invoice
export const getJobCardByNumber = (token, jobcardNumber) => {
  return (dispatch) => {
    dispatch(getJobCardStart());

    axios
      .get(`${HOST_URL}/booking/job-card/jc-number_${jobcardNumber}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTargetJobCardSuccess(response.data)))
      .catch((error) => dispatch(getJobCardFaild(error)));
  };
};

export const postJobCard = (
  token,
  date,
  jobCardNumber,
  bookingType,
  notes,
  clientName,
  clientEmail,
  clientNumber,
  clientAlternativeNumber,
  clientAddress,
  insuranceCompany,
  claimTech,
  insuranceContactNumber,
  insuranceClaimNumber,
  deviceType,
  itemDescription,
  deviceMake,
  deviceModel,
  deviceSN,
  deviceColour,
  deviceAccessories,
  devicePassCode,
  deviceCondetion,
  deviceFault,
  deviceFaultDescription
) => {
  return (dispatch) => {
    dispatch(getJobCardStart());
    let fd = new FormData();
    fd.append('job_card_number', jobCardNumber);
    fd.append('booking_type', bookingType);
    fd.append('notes', notes);
    fd.append('client_name', clientName);
    fd.append('client_email', clientEmail);
    fd.append('client_number', clientNumber);
    fd.append('client_alternative_number', clientAlternativeNumber);
    fd.append('client_address', clientAddress);
    fd.append('insurance_company_name', insuranceCompany);
    fd.append('claim_consultant', claimTech);
    fd.append('consultant_contact_number', insuranceContactNumber);
    fd.append('claim_number', insuranceClaimNumber);
    fd.append('device_type', deviceType);
    fd.append('device_description', itemDescription);
    fd.append('make', deviceMake);
    fd.append('model', deviceModel);
    fd.append('serial_number', deviceSN);
    fd.append('imei', deviceSN);
    fd.append('colour', deviceColour);
    fd.append('accessories', deviceAccessories);
    fd.append('passcode', devicePassCode);
    fd.append('device_condition', deviceCondetion);
    fd.append('device_fault', deviceFault);
    fd.append('fault_description', deviceFaultDescription);

    axios
      .post(`${HOST_URL}/booking/job-card/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobCardSuccess(response.data));
        dispatch(getLastNJobCards(token, 10));
      })
      .catch((error) => dispatch(getJobCardFaild(error)));
  };
};

export const updateJobCard = (
  token,
  job_card_id,
  client_id,
  insurance_id,
  device_id,
  date,
  jobCardNumber,
  bookingType,
  notes,
  clientName,
  clientEmail,
  clientNumber,
  clientAlternativeNumber,
  clientAddress,
  insuranceCompany,
  claimTech,
  insuranceContactNumber,
  insuranceClaimNumber,
  deviceType,
  itemDescription,
  deviceMake,
  deviceModel,
  deviceSN,
  deviceColour,
  deviceAccessories,
  devicePassCode,
  deviceCondetion,
  deviceFault,
  deviceFaultDescription
) => {
  return (dispatch) => {
    dispatch(getJobCardStart());
    let fd = new FormData();
    fd.append('job_card_number', jobCardNumber);
    fd.append('booking_type', bookingType);
    fd.append('notes', notes);
    fd.append('client_name', clientName);
    fd.append('client_email', clientEmail);
    fd.append('client_number', clientNumber);
    fd.append('client_alternative_number', clientAlternativeNumber);
    fd.append('client_address', clientAddress);
    fd.append('insurance_company_name', insuranceCompany);
    fd.append('claim_consultant', claimTech);
    fd.append('consultant_contact_number', insuranceContactNumber);
    fd.append('claim_number', insuranceClaimNumber);
    fd.append('device_type', deviceType);
    fd.append('device_description', itemDescription);
    fd.append('make', deviceMake);
    fd.append('model', deviceModel);
    fd.append('serial_number', deviceSN);
    fd.append('imei', deviceSN);
    fd.append('colour', deviceColour);
    fd.append('accessories', deviceAccessories);
    fd.append('passcode', devicePassCode);
    fd.append('device_condition', deviceCondetion);
    fd.append('device_fault', deviceFault);
    fd.append('fault_description', deviceFaultDescription);
    fd.append('client_id', client_id);
    fd.append('device_id', device_id);
    fd.append('insurance_id', insurance_id);

    axios
      .patch(`${HOST_URL}/booking/job-card/${job_card_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobCardSuccess(response.data));
        dispatch(getLastNJobCards(token, 10));
      })
      .catch((error) => dispatch(getJobCardFaild(error)));
  };
};
