import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  taskStatus: null,
  taskStatuses: [],
};

const getTaskStatusStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getTaskStatusSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    taskStatus: action.payload,
  });
};

const getTaskStatusesSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    taskStatuses: action.payload,
  });
};

const getTaskStatusFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const taskStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TASK_STATUS_START:
      return getTaskStatusStart(state, action);
    case actionTypes.GET_TASK_STATUS_SUCCESS:
      return getTaskStatusSuccess(state, action);
    case actionTypes.GET_TASK_STATUSES_SUCCESS:
      return getTaskStatusesSuccess(state, action);
    case actionTypes.GET_TASK_STATUS_FAIL:
      return getTaskStatusFaild(state, action);
    default:
      return state;
  }
};

export default taskStatusReducer;
