import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './styles/collect-device-form.css';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import BackspaceIcon from '@mui/icons-material/Backspace';
import Switch from '@mui/material/Switch';
import {
  getCollectedDevice,
  getCollectedDevices,
  postCollectedDevicesData,
  postCollectedDeviceData,
  postCollectedItemData,
} from '../../store/actions/collectDevices';
import { getClients } from '../../store/actions/client';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

class CollectDeviceForm extends Component {
  state = {
    client: '',
    address: '',
    contactNumber: '',
    email: '',
    noOfDevices: 0,
    specialInstruction: '',
    insuranceCompany: '',
    claimTech: '',
    collectionDate: moment().format('MMMM Do YYYY, h:mm:ss a'),
    item: '',
    comment: '',
    items: [],
    savingMultipleItems: false,
    open: false,
    loadingClients: false,
    existingClientActive: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.collectedDevice !== prevProps.collectedDevice &&
      this.state.savingMultipleItems
    ) {
      this.saveMultipleItems();
      this.setState({ savingMultipleItems: false });
    }
    if (this.props.clients !== prevProps.clients) {
      this.setState({ loadingClients: false });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSwitchChange = (e) => {
    this.setState({ existingClientActive: !this.state.existingClientActive });
  };
  onSelectClient = (e) => {
    if (e.target.innerText !== undefined) {
      this.setState({ client: e.target.innerText });
    } else {
      this.setState({ client: '' });
    }
  };

  getClients = () => {
    this.setState({ loadingClients: true });
    const token = this.props.token;
    this.props.getClients(token);
  };
  collectItems = () => {
    let items = this.state.items;
    let itemObj = {
      item: '',
      comment: '',
    };

    itemObj.item = this.state.item;
    itemObj.comment = this.state.comment;

    items.push(itemObj);
    this.setState({ items: items });
  };

  handleSaveCollectedDevices = () => {
    const token = this.props.token;
    const {
      client,
      address,
      contactNumber,
      email,
      noOfDevices,
      specialInstruction,
      insuranceCompany,
      claimTech,
      item,
      comment,
    } = this.state;
    let now = new Date();
    const collectionDate = moment(now).format();
    if (this.state.items.length === 1) {
      this.props.saveCollectedDeviceDetails(
        token,
        client,
        email,
        contactNumber,
        address,
        specialInstruction,
        noOfDevices,
        claimTech,
        collectionDate,
        insuranceCompany,
        item,
        comment
      );
    } else if (this.state.items.length > 1) {
      this.setState({ savingMultipleItems: true });
      this.props.saveCollectedDevicesDetails(
        token,
        client,
        email,
        contactNumber,
        address,
        specialInstruction,
        noOfDevices,
        claimTech,
        collectionDate,
        insuranceCompany
      );
    }
  };

  saveMultipleItems = () => {
    const token = this.props.token;
    const { items } = this.state;
    const collected_device_details = this.props.collectedDevice.id;
    items.map((item, index) => {
      setTimeout(
        () =>
          this.props.saveItem(
            token,
            collected_device_details,
            item.item,
            item.comment
          ),
        300
      );
    });
  };

  render() {
    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.client_name,
    });
    // const cilteredClientObject =
    return (
      <div className="COLLECT_DEVICE_FORM_CONTAINER">
        <div className="CLIENT_INSURANCE_DETAILS_CONTAINER">
          <div className="CLIENT_DETAILS_CONTAINER">
            <div className="EXISTED_CLIENT_SWITCH_CONTAINER">
              <Switch onChange={this.onSwitchChange} />
              <span>Existing Client</span>
            </div>
            {this.state.existingClientActive ? (
              <div className="COLLECT_DEVICE_FORM_SELECT_CLIENT_INPUT_FIELD_CONTAINER">
                <Autocomplete
                  id="filter-demo"
                  options={this.props.clients ? this.props.clients : []}
                  getOptionLabel={(option) => option.client_name}
                  filterOptions={filterOptions}
                  sx={{ width: 300 }}
                  onOpen={() => {
                    this.getClients();
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  sx={{ color: '#fff' }}
                  clearIcon={<BackspaceIcon color="error"></BackspaceIcon>}
                  openOnFocus={true}
                  onChange={this.onSelectClient}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client Name"
                      name="client"
                      // fullWidth
                      value={this.state.client}
                      onChange={this.onChange}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loadingClients ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        className: 'COLLECT_DEVICE_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        shrink: true,
                        className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              </div>
            ) : (
              <>
                <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Client Name"
                    variant="outlined"
                    name="client"
                    // fullWidth
                    value={this.state.client}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'COLLECT_DEVICE_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>

                <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Client Address"
                    variant="outlined"
                    name="address"
                    // fullWidth
                    value={this.state.address}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'COLLECT_DEVICE_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
                <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Contact Number"
                    variant="outlined"
                    name="contactNumber"
                    // fullWidth
                    value={this.state.contactNumber}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'COLLECT_DEVICE_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
                <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Client Email"
                    variant="outlined"
                    name="email"
                    // fullWidth
                    value={this.state.email}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'COLLECT_DEVICE_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
              </>
            )}
            <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Special Instruction"
                variant="outlined"
                name="specialInstruction"
                multiline
                rows={4}
                // fullWidth
                value={this.state.specialInstruction}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COLLECT_DEVICE_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="INSURANCE_DETAILS_CONTAINER">
            <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Insurance Company"
                variant="outlined"
                name="insuranceCompany"
                // fullWidth
                value={this.state.insuranceCompany}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COLLECT_DEVICE_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Claim Tech"
                variant="outlined"
                name="claimTech"
                // fullWidth
                value={this.state.claimTech}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COLLECT_DEVICE_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Collection Date"
                variant="outlined"
                name="collectionDate"
                // fullWidth
                value={this.state.collectionDate}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COLLECT_DEVICE_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="COLLECT_DEVICE_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="No Of Devices"
                variant="outlined"
                name="noOfDevices"
                // fullWidth
                value={this.state.noOfDevices}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COLLECT_DEVICE_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
        </div>
        <div className="ADD_ITEMS_MAIN_CONTAINER">
          <div className="ITEM_CONTAINER">
            <div className="COLLECT_DEVICE_FORM_ITEM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Item"
                variant="outlined"
                name="item"
                // fullWidth
                value={this.state.item}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COLLECT_DEVICE_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                }}
              ></TextField>
              <div className="ITEM_ADD_BUTTON" onClick={this.collectItems}>
                <span>Add</span>
              </div>
            </div>
            <div className="COLLECT_DEVICE_FORM_COMMENT_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                defaultValue=""
                variant="outlined"
                // color="secondary"
                type="text"
                fullWidth
                required
                name="comment"
                // fullWidth
                value={this.state.comment}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COLLECT_DEVICE_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'COLLECT_DEVICE_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
        </div>

        <div className="COLLECT_DEVICE_FORM_ACTION">
          <div
            className="COLLECT_DEVICE_FORM_ACTION_SAVE_BUTTON"
            onClick={this.handleSaveCollectedDevices}
          >
            <h5>Save</h5>
          </div>
          <div className="COLLECT_DEVICE_FORM_ACTION_CLEAR_BUTTON">
            <h5>Clear</h5>
          </div>
        </div>
        <div className="SAVED_ITEMS_MAIN_CONTAINER">
          {this.state.items
            ? this.state.items[0] !== undefined
              ? this.state.items.map((item, index) => (
                  <div className="SAVED_ITEM_CONTAINER" key={index}>
                    <div className="SAVED_ITEM">
                      <Chip
                        label={item.item}
                        variant="outlined"
                        color="primary"
                      />

                      <Chip
                        label={item.comment}
                        variant="outlined"
                        color="info"
                      />
                    </div>
                    <IconButton aria-label="delete">
                      <EditIcon color="primary"></EditIcon>
                    </IconButton>
                    <IconButton aria-label="delete">
                      <ClearIcon color="error"></ClearIcon>
                    </IconButton>
                  </div>
                ))
              : null
            : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    collectedDevice: state.collectedDevice.collectedDevice,
    collectedDevices: state.collectedDevice.collectedDevices,
    collectedItem: state.collectedDevice.collectedItem,
    collectedItems: state.collectedDevice.collectedItems,
    clients: state.client.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCollectedDevice: (token, id) => dispatch(getCollectedDevice(token, id)),
    getCollectedDevices: (token) => dispatch(getCollectedDevices(token)),
    saveCollectedDeviceDetails: (
      token,
      client_name,
      client_email,
      client_number,
      client_address,
      special_instruction,
      number_of_devices,
      claim_tech,
      collection_date,
      insurance_company,
      item,
      comment
    ) =>
      dispatch(
        postCollectedDeviceData(
          token,
          client_name,
          client_email,
          client_number,
          client_address,
          special_instruction,
          number_of_devices,
          claim_tech,
          collection_date,
          insurance_company,
          item,
          comment
        )
      ),
    saveCollectedDevicesDetails: (
      token,
      client_name,
      client_email,
      client_number,
      client_address,
      special_instruction,
      number_of_devices,
      claim_tech,
      collection_date,
      insurance_company
    ) =>
      dispatch(
        postCollectedDevicesData(
          token,
          client_name,
          client_email,
          client_number,
          client_address,
          special_instruction,
          number_of_devices,
          claim_tech,
          collection_date,
          insurance_company
        )
      ),
    saveItem: (token, collected_device_details, item, comment) =>
      dispatch(
        postCollectedItemData(token, collected_device_details, item, comment)
      ),
    getClients: (token) => dispatch(getClients(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectDeviceForm);
