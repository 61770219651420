import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles/capture-payment.css';
import moment from 'moment';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import OrdersTable from './orders-table/OrdersTable';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {
  getOrdersByDateRange,
  updateOrderStatus,
} from '../../store/actions/order';
import { getFinancialAccounts } from '../../store/actions/financialAccounts';
import {
  deleteTransaction,
  getUnallocatedTransactions,
  postTransactionData,
  updateTransactionOrder,
} from '../../store/actions/inventory/transaction';

class CapturePayment extends Component {
  state = {
    startDate: null,
    endDate: null,
    total: parseFloat(0).toFixed(2),
    clientAccount: '',
    loadingAccounts: false,
    showClientDetails: false,
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    openMessageModal: false,
    messageModalMessageTitle: '',
    messageModalMessageBody: '',
    invoiceNumber: '',
    paymentCode: '',
    paymentType: '',
    paymentMethod: '',
    paymentStatus: 'success',
    transactionNote: '',
    paymentAmount: parseFloat(0).toFixed(2),
    amountToAllocate: 'undefined',
  };

  componentDidMount() {
    const financial_account = localStorage.getItem('ac-number')
      ? localStorage.getItem('ac-number')
      : '';
    const start_date = localStorage.getItem('statment_start_date')
      ? localStorage.getItem('statment_start_date')
      : null;
    const end_date = localStorage.getItem('statment_end_date')
      ? localStorage.getItem('statment_end_date')
      : null;
    this.setState({
      clientAccount: financial_account,
      startDate: start_date,
      endDate: end_date,
    });
    this.props.getUnallocatedTransactions(this.props.token, financial_account);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.orders !== prevProps.orders &&
      this.props.orders.length > 0
    ) {
      this.calculateTotalAmount();
    }
    if (this.props.financialAccounts !== prevProps.financialAccounts) {
      this.setState({ loadingAccounts: false });
      this.props.getUnallocatedTransactions(
        this.props.token,
        this.state.clientAccount
      );
    }
    if (
      this.props.unallocatedTransactions !==
        prevProps.unallocatedTransactions &&
      this.props.orders.length > 0
    ) {
      this.handleSearch();
    }
    if (this.props.order !== prevProps.order) {
      this.handleSearch();
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSearch = () => {
    const token = this.props.token;
    if (
      this.state.startDate &&
      this.state.endDate &&
      this.state.clientAccount !== ''
    ) {
      const startDate = moment(new Date(this.state.startDate)).format();
      const endDate = moment(new Date(this.state.endDate)).format();
      const accountNumber = this.state.clientAccount;
      this.props.getFilteredOrders(token, startDate, endDate, accountNumber);
    } else {
      this.handleMessageOpen(
        'ERROR',
        'Both date range and account number should be selected.'
      );
    }
  };

  calculateTotalAmount = () => {
    let total = 0.0;
    if (this.props.orders.length > 0) {
      this.props.orders.map((order) => {
        if (order.status !== 'paid') {
          total = total + parseFloat(order.grand_total);
        } else {
          total = total - parseFloat(order.grand_total);
        }
      });
    }
    console.log(total);
    this.setState({ total: total });
  };

  getClientsAccounts = () => {
    this.setState({ loadingAccounts: true });
    const token = this.props.token;
    this.props.getFinancialAccounts(token);
  };

  onSelectAccount = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        clientAccount: option ? option.account_number : '',
        clientName: option ? option.client.client_name : '',
        clientEmail: option ? option.client.client_email : '',
        clientPhone: option ? option.client.client_number : '',
        showClientDetails: true,
      });
      localStorage.setItem('ac-number', option ? option.account_number : '');
      localStorage.setItem(
        'client-name',
        option ? option.client.client_name : ''
      );
      this.props.getUnallocatedTransactions(
        this.props.token,
        option.account_number
      );
    } else {
      this.setState({
        clientAccount: '',
        showClientDetails: false,
        clientName: '',
        clientEmail: '',
        clientPhone: '',
      });
    }
  };

  handleMessageOpen = (title, body) => {
    this.setState({
      messageModalMessageTitle: title,
      messageModalMessageBody: body,
      openMessageModal: true,
    });
  };

  handleMessageClose = () => {
    this.setState({ openMessageModal: false });
  };

  handleCapturePayment = () => {
    const token = this.props.token;
    const {
      clientAccount,
      paymentCode,
      paymentType,
      paymentMethod,
      paymentStatus,
      transactionNote,
      paymentAmount,
    } = this.state;
    const order = null; // because the transaction is not allocated yet
    this.props.createTransaction(
      token,
      clientAccount,
      order,
      paymentCode,
      paymentType,
      paymentMethod,
      paymentStatus,
      parseFloat(paymentAmount),
      transactionNote
    );
  };

  handleAllocatePayment = (transactionID) => {
    const token = this.props.token;
    const { invoiceNumber, clientAccount, amountToAllocate } = this.state;
    let validAmountToAllocate = amountToAllocate;
    if (amountToAllocate === 0 || amountToAllocate === 'undefined') {
      validAmountToAllocate = 'undefined';
    } else {
      validAmountToAllocate = amountToAllocate;
    }

    this.props.allocateTransaction(
      token,
      transactionID,
      invoiceNumber,
      clientAccount,
      validAmountToAllocate
    );
  };

  handleUpdateOrderStatus = (id, status) => {
    const token = this.props.token;
    console.log(id);
    this.props.updateOrderStatus(token, id, status);
  };

  handleDeleteTransaction = (id) => {
    const token = this.props.token;
    const financial_account = this.state.clientAccount;
    this.props.deleteTransaction(token, id, financial_account);
  };

  handleInvoiceOpen = (invoiceNumber) => {
    this.props.history.push(`/accounts-manager/invoice/${invoiceNumber}`);
    localStorage.setItem(
      'prevPath',
      `/accounts-manager/invoice/${invoiceNumber}`
    );
  };

  handleQuoteOpen = (quotationNumber) => {
    this.props.history.push(`/accounts-manager/quotation/${quotationNumber}`);
    localStorage.setItem(
      'prevPath',
      `/accounts-manager/quotation/${quotationNumber}`
    );
  };

  render() {
    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.account_number,
    });
    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    const paymentTypes = [
      { value: 'debit', lable: 'Debit' },
      { value: 'credit', lable: 'Credit' },
    ];
    const paymentMethods = [
      { value: 'cash', lable: 'Cash' },
      { value: 'card', lable: 'Card' },
      { value: 'eft', lable: 'EFT' },
    ];
    const mode = this.props.mode;
    return (
      <div className="CAPTURE_PAYMENT_MAIN_CONTAINER">
        <div className="ACCOUNT_STATMENT_HEADER_CONTAINER">
          <div className="ACCOUNT_STATMENT_SEARCH_CONTAINER">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={this.state.startDate}
                onChange={(newValue) => {
                  this.setState({ startDate: newValue });
                }}
                sx={{
                  svg:
                    mode === 'light'
                      ? { color: '#19376d' }
                      : { color: '#576cbc' },
                  input:
                    mode === 'light'
                      ? { color: '#17293e' }
                      : { color: '#ffffff' },
                  label:
                    mode === 'light'
                      ? {
                          color: '#182c43',
                          zIndex: 10,
                          background: '#ececec',
                        }
                      : {
                          color: '#b8b8b8',
                          zIndex: 10,
                          background: '#233044',
                        },
                  border: mode === 'light' ? '' : '1px solid #384f68',
                  width: '25%',
                  borderRadius: 2,
                }}
              />
            </LocalizationProvider>
            <span>To</span>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={this.state.endDate}
                onChange={(newValue) => {
                  this.setState({ endDate: newValue });
                }}
                sx={{
                  svg:
                    mode === 'light'
                      ? { color: '#19376d' }
                      : { color: '#576cbc' },
                  input:
                    mode === 'light'
                      ? { color: '#17293e' }
                      : { color: '#ffffff' },
                  label:
                    mode === 'light'
                      ? {
                          color: '#182c43',
                          zIndex: 10,
                          background: '#ececec',
                        }
                      : {
                          color: '#b8b8b8',
                          zIndex: 10,
                          background: '#233044',
                        },
                  border: mode === 'light' ? '' : '1px solid #384f68',
                  width: '25%',
                  borderRadius: 2,
                }}
              />
            </LocalizationProvider>
            <div>
              <Autocomplete
                id="filter-accounts"
                freeSolo
                options={
                  this.props.financialAccounts
                    ? this.props.financialAccounts
                    : []
                }
                getOptionLabel={(option) =>
                  option.account_number
                    ? option.account_number
                    : this.state.clientAccount
                }
                filterOptions={filterOptions}
                sx={{ width: 220, color: '#fff' }}
                onOpen={() => {
                  this.getClientsAccounts();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                onChange={(e, option) => this.onSelectAccount(e, option)}
                value={this.state.clientAccount}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Account Number"
                    name="clientAccount"
                    fullWidth
                    value={this.state.clientAccount}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingAccounts ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div className="ACCOUNT_STATEMENT_SEARCH_BUTTON">
              {this.props.loadingOrder ? (
                <CircularProgress sx={{ color: '#1597BB' }} size={30} />
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  sx={{ height: '45px', borderRadius: '5px' }}
                  onClick={this.handleSearch}
                >
                  Fetch Data
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className="CAPTURE_PAYMENT_PROCESS_MAIN_CONTAINER">
          <div className="CAPTURE_PAYMENT_PROCESS_CONTAINER">
            <div className="CAPTURE_PAYMENT_PROCESS_CAPTURE_HEADER">
              <span>Capture</span>
            </div>
            <div className="CAPTURE_PAYMENT_PROCESS_DATA">
              <div>
                <div>
                  <TextField
                    id="payment-code"
                    label="Code"
                    variant="outlined"
                    name="paymentCode"
                    value={this.state.paymentCode}
                    onChange={this.onChange}
                    fullWidth
                    InputProps={{
                      className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                    }}
                    InputLabelProps={{
                      className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                    }}
                  />
                </div>
                <div>
                  <Autocomplete
                    id="payment-type"
                    freeSolo
                    options={paymentTypes}
                    getOptionLabel={(option) =>
                      option.lable ? option.lable : this.state.paymentType
                    }
                    onClose={() => {
                      console.log('close');
                    }}
                    sx={{ marginTop: 0.7 }}
                    clearIcon={<CloseIcon color="error"></CloseIcon>}
                    openOnFocus={true}
                    onChange={(e, option) =>
                      this.setState({ paymentType: option.value })
                    }
                    value={this.state.paymentType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type"
                        name="paymentType"
                        fullWidth
                        value={this.state.paymentType}
                        onChange={this.onChange}
                        InputProps={{
                          ...params.InputProps,

                          className: 'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD',
                        }}
                        InputLabelProps={{
                          // shrink: true,
                          className:
                            'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD_LABEL',
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <Autocomplete
                    id="payment-method"
                    freeSolo
                    options={paymentMethods}
                    getOptionLabel={(option) =>
                      option.lable ? option.lable : this.state.paymentMethod
                    }
                    onClose={() => {
                      console.log('close');
                    }}
                    sx={{ marginTop: 0.7 }}
                    clearIcon={<CloseIcon color="error"></CloseIcon>}
                    openOnFocus={true}
                    value={this.state.paymentMethod}
                    onChange={(e, option) =>
                      this.setState({ paymentMethod: option.value })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Method"
                        name="paymentMethod"
                        fullWidth
                        value={this.state.paymentMethod}
                        onChange={this.onChange}
                        InputProps={{
                          ...params.InputProps,

                          className: 'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD',
                        }}
                        InputLabelProps={{
                          // shrink: true,
                          className:
                            'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD_LABEL',
                        }}
                      />
                    )}
                  />
                </div>
                <div>
                  <TextField
                    id="payment-amount"
                    label="Amount"
                    variant="outlined"
                    name="paymentAmount"
                    value={this.state.paymentAmount}
                    onChange={this.onChange}
                    fullWidth
                    InputProps={{
                      className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                    }}
                    InputLabelProps={{
                      className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                    }}
                  />
                </div>
              </div>
              <div>
                <div>
                  <TextField
                    id="payment-status"
                    label="Status"
                    variant="outlined"
                    name="paymentStatus"
                    value={this.state.paymentStatus}
                    onChange={this.onChange}
                    fullWidth
                    InputProps={{
                      className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                    }}
                    InputLabelProps={{
                      className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                    }}
                  />
                </div>
                <div>
                  <TextField
                    id="item-extra-details"
                    label="Note"
                    multiline
                    rows={4}
                    variant="outlined"
                    // color="secondary"
                    type="text"
                    name="transactionNote"
                    fullWidth
                    // error={this.state.questionError ? true : false}
                    value={this.state.transactionNote}
                    onChange={this.onChange}
                    InputProps={{
                      className: 'CAPTURE_PAYMENT_NOTE_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'CAPTURE_PAYMENT_NOTE_FIELD_LABEL',
                    }}
                  />
                </div>
                <div className="CAPTURE_PAYMENT_PROCESS_BUTTON">
                  {this.props.loadingTransaction ? (
                    <CircularProgress
                      sx={{ color: '#1597BB' }}
                      size={40}
                    ></CircularProgress>
                  ) : (
                    <Button
                      size="large"
                      variant="outlined"
                      sx={{ height: '45px', borderRadius: '5px' }}
                      onClick={this.handleCapturePayment}
                    >
                      Capture
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="CAPTURE_PAYMENT_PROCESS_ALLOCATE_CONTAINER">
            <div className="CAPTURE_PAYMENT_PROCESS_ALLOCATE_HEADER">
              <span>Allocate</span>
            </div>
            <div className="CAPTURE_PAYMENT_PROCESS_ALLOCATE_BODY">
              {this.props.unallocatedTransactions &&
              this.props.unallocatedTransactions.length > 0 ? (
                this.props.unallocatedTransactions.map((transaction, index) => (
                  <div className="CAPTURED_PAYMENT_CONTAINER" key={index}>
                    <div className="CAPTURED_PAYMENT_DATA_CONTAINER">
                      <div>
                        <span>code</span> <p>{transaction.code}</p>{' '}
                      </div>
                      <div>
                        <span>type</span> <p>{transaction.type}</p>{' '}
                      </div>
                      <div>
                        <span>method</span> <p>{transaction.method}</p>{' '}
                      </div>
                      <div>
                        <span>amount</span> <p>R&nbsp;{transaction.amount}</p>{' '}
                      </div>
                    </div>
                    <div className="CAPTURED_PAYMENT_ALOCATE_CONTAINER">
                      <div>
                        <TextField
                          id="payment-reference"
                          label="Reference"
                          variant="outlined"
                          placeholder="Invoice Number"
                          name="invoiceNumber"
                          value={this.state.invoiceNumber}
                          onChange={this.onChange}
                          sx={{ width: 150 }}
                          InputProps={{
                            className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                          }}
                          InputLabelProps={{
                            className:
                              'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                          }}
                        />
                      </div>
                      <div>
                        <TextField
                          id="amount-to-allocate"
                          label="Amount"
                          variant="outlined"
                          placeholder="Undefined"
                          name="amountToAllocate"
                          value={this.state.amountToAllocate}
                          onChange={this.onChange}
                          sx={{ marginLeft: '1em', width: 120 }}
                          InputProps={{
                            className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                          }}
                          InputLabelProps={{
                            className:
                              'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                          }}
                        />
                      </div>

                      <div className="CAPTURE_PAYMENT_PROCESS_BUTTON">
                        {this.props.loadingTransaction ? (
                          <CircularProgress
                            sx={{ color: '#1597BB', marginLeft: 3 }}
                            size={40}
                          ></CircularProgress>
                        ) : (
                          <Button
                            size="large"
                            variant="outlined"
                            sx={{
                              height: '45px',
                              borderRadius: '5px',
                              marginLeft: 3,
                            }}
                            onClick={() =>
                              this.handleAllocatePayment(transaction.id)
                            }
                          >
                            Allocate
                          </Button>
                        )}
                      </div>
                      <div style={{ marginLeft: '15px' }}>
                        <Tooltip title="Delete Payment">
                          <IconButton
                            onClick={() =>
                              this.handleDeleteTransaction(transaction.id)
                            }
                          >
                            <DeleteOutlineIcon
                              sx={{ color: '#F05454', fontSize: 40 }}
                            ></DeleteOutlineIcon>
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <AttachMoneyIcon
                  sx={{ fontSize: 200, color: '#0b0f19' }}
                ></AttachMoneyIcon>
              )}
            </div>
          </div>
        </div>
        <div className="ACCOUNT_STATMENT_TABLE_CONTAINER">
          <OrdersTable
            orders={this.props.orders}
            handleupdateOrderStatus={(id, status) =>
              this.handleUpdateOrderStatus(id, status)
            }
            loadingOrder={this.props.loadingOrder}
            openQuotation={(quotationNumber) =>
              this.handleQuoteOpen(quotationNumber)
            }
            openInvoice={(invoiceNumber) =>
              this.handleInvoiceOpen(invoiceNumber)
            }
            mode={this.props.mode}
          ></OrdersTable>
        </div>
        {this.state.showClientDetails ? (
          <div className="ACCOUNT_STATMENT_CLIENT_DETAILS_CONTAINER">
            <div>
              <TextField
                id="client-name"
                label="Name"
                variant="outlined"
                value={this.state.clientName}
                InputProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                }}
                InputLabelProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                }}
              />
            </div>
            <div>
              <TextField
                id="client-email"
                label="Email"
                variant="outlined"
                value={this.state.clientEmail}
                InputProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                }}
                InputLabelProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                }}
              />
            </div>
            <div>
              <TextField
                id="client-number"
                label="Phone Number"
                variant="outlined"
                value={this.state.clientPhone}
                InputProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                }}
                InputLabelProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                }}
              />
            </div>
          </div>
        ) : null}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openMessageModal}
          onClose={this.handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.messageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.messageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button variant="outlined" onClick={this.handleMessageClose}>
                  Okay
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    userDetails: state.users.userDetails,
    order: state.order.order,
    orders: state.order.orders,
    loadingOrder: state.order.loading,
    financialAccounts: state.financialAccount.accounts,
    transactions: state.transaction.transactions,
    loadingTransaction: state.transaction.loading,
    unallocatedTransactions: state.transaction.unallocated,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFilteredOrders: (token, startDate, endDate, accountNumber) =>
      dispatch(getOrdersByDateRange(token, startDate, endDate, accountNumber)),
    getFinancialAccounts: (token) => dispatch(getFinancialAccounts(token)),
    getUnallocatedTransactions: (token, accountNumber) =>
      dispatch(getUnallocatedTransactions(token, accountNumber)),
    createTransaction: (
      token,
      financialAccount,
      order,
      code,
      type,
      method,
      status,
      paymentAmount,
      content
    ) =>
      dispatch(
        postTransactionData(
          token,
          financialAccount,
          order,
          code,
          type,
          method,
          status,
          paymentAmount,
          content
        )
      ),
    allocateTransaction: (
      token,
      transactionID,
      invoiceNumber,
      financialAccount,
      amountToAllocate
    ) =>
      dispatch(
        updateTransactionOrder(
          token,
          transactionID,
          invoiceNumber,
          financialAccount,
          amountToAllocate
        )
      ),
    deleteTransaction: (token, id, financial_account) =>
      dispatch(deleteTransaction(token, id, financial_account)),
    updateOrderStatus: (token, id, status) =>
      dispatch(updateOrderStatus(token, id, status)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CapturePayment));
