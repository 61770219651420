import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  damageReport: null,
  damageReports: [],
};

const getDamageReportStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getDamageReportSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    damageReport: action.payload,
  });
};

const getDamageReportsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    damageReports: action.payload,
  });
};

const getDamageReportFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const damageReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DAMAGE_REPORT_START:
      return getDamageReportStart(state, action);
    case actionTypes.GET_DAMAGE_REPORT_SUCCESS:
      return getDamageReportSuccess(state, action);
    case actionTypes.GET_DAMAGE_REPORTS_SUCCESS:
      return getDamageReportsSuccess(state, action);
    case actionTypes.GET_DAMAGE_REPORT_FAIL:
      return getDamageReportFaild(state, action);
    default:
      return state;
  }
};

export default damageReportReducer;
