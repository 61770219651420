import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import * as userActions from './store/actions/auth';
import axios from 'axios';
import { HOST_URL } from './settings';
import Header from './layout/header/Header';
// import Home from './pages/home/Home';
import RegisterCompany from './pages/registerCompany/RegisterCompany';
import Dashboard from './pages/dashboard/Dashboard';
import Reception from './pages/reception/Reception';
import Technician from './pages/technician/Technician';
import Login from './pages/login/Login';
import UserProfile from './pages/user-profile/UserProfile';
import SecureRedirect from './pages/login/SecureRedirect';
import Signup from './pages/signup/Signup';
import ForgotPassword from './pages/forgot-password/ForgotPassword';
import PasswordResetConfirm from './pages/password-reset-confirm/PasswordResetConfirm';
import AccountsManager from './pages/accounts-manager/AccountsManager';
import RedirectUser from './pages/login/RedirectUser';
import InventoryManager from './pages/inventory/InventoryManager';
import NewMemberLogin from './pages/login/NewMemberLogin';
// import FuncTest from './components/fun-test/FuncTest';

class App extends Component {
  componentDidMount() {
    this.props.onTryAutoSignup();
    this.getAppVersion();
    this.props.getThemeDetails(this.props.token);
  }

  getAppVersion = () => {
    const token = this.props.token;
    if (token) {
      axios
        .get(`${HOST_URL}/version-control/version-details/`, {
          headers: { Authorization: `Token ${token}` },
        })
        .then((response) => {
          console.log(response.data);
          localStorage.setItem('version', response.data[0].version_number);
        })
        .catch((error) => console.log(error));
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      if (this.props.token !== null) {
        this.props.getUserDetails(this.props.token);
        this.getAppVersion();
        this.props.getThemeDetails(this.props.token);
      }
    }
  }
  render() {
    return (
      <Router>
        <div>
          <Header {...this.props}></Header>
          <Switch>
            {/* <Route exact path="/functest" component={FuncTest} /> */}
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/login/:key" component={NewMemberLogin} />
            <Route
              exact
              path="/my-profile"
              component={this.props.isAuthenticated ? UserProfile : Login}
            />
            <Route exact path="/redirect-user" component={RedirectUser} />
            <Route exact path="/signup/:key/" component={Signup} />
            <Route exact path="/signup" component={Signup} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path="/password-reset-confirm/:uid/:token/"
              component={PasswordResetConfirm}
            />
            <Route
              exact
              path="/register-company"
              component={this.props.isAuthenticated ? RegisterCompany : Login}
            />
            <Route
              exact
              path="/dashboard"
              component={
                this.props.isAuthenticated ? Dashboard : SecureRedirect
              }
            />
            <Route
              exact
              path="/dashboard/:form/"
              component={
                this.props.isAuthenticated ? Dashboard : SecureRedirect
              }
            />
            <Route
              exact
              path="/reception"
              component={this.props.isAuthenticated ? Reception : Login}
            />
            <Route
              exact
              path="/reception/:form/"
              component={this.props.isAuthenticated ? Reception : Login}
            />
            <Route
              exact
              path="/reception/:form/:data/"
              component={this.props.isAuthenticated ? Reception : Login}
            />
            <Route
              exact
              path="/technician"
              component={this.props.isAuthenticated ? Technician : Login}
            />
            <Route
              exact
              path="/accounts-manager/"
              component={this.props.isAuthenticated ? AccountsManager : Login}
            />
            <Route
              exact
              path="/accounts-manager/:form/"
              component={this.props.isAuthenticated ? AccountsManager : Login}
            />
            <Route
              exact
              path="/accounts-manager/:form/:data/"
              component={this.props.isAuthenticated ? AccountsManager : Login}
            />
            <Route
              exact
              path="/inventory-manager/"
              component={this.props.isAuthenticated ? InventoryManager : Login}
            />
            <Route
              exact
              path="/inventory-manager/:form/"
              component={this.props.isAuthenticated ? InventoryManager : Login}
            />
            <Route
              exact
              path="/inventory-manager/:form/:key/"
              component={this.props.isAuthenticated ? InventoryManager : Login}
            />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.users.token !== null,
    token: state.users.token,
    userDetails: state.users.userDetails,
    theme: state.users.theme,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onTryAutoSignup: () => dispatch(userActions.authCheckState()),
    getUserDetails: (token) => dispatch(userActions.getUserDetails(token)),
    getThemeDetails: (token) => dispatch(userActions.getTheme(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
