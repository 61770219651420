import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getOrderStart = () => {
  return {
    type: actionTypes.GET_ORDER_START,
  };
};

export const getOrderSuccess = (data) => {
  return {
    type: actionTypes.GET_ORDER_SUCCESS,
    payload: data,
  };
};

export const getLastOrderSuccess = (data) => {
  return {
    type: actionTypes.GET_LAST_ORDER_SUCCESS,
    payload: data,
  };
};

export const getOrdersSuccess = (data) => {
  return {
    type: actionTypes.GET_ORDERS_SUCCESS,
    payload: data,
  };
};

export const getPeriodOrdersSuccess = (data, period) => {
  return {
    type: actionTypes.GET_PERIOD_ORDERS_SUCCESS,
    payload: data,
    period: period,
  };
};

export const getOrderFaild = (error) => {
  return {
    type: actionTypes.GET_ORDER_FAIL,
    error: error,
  };
};

export const getOrders = (token) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    console.log('getting all Orders');
    axios
      .get(`${HOST_URL}/orders/order/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOrdersSuccess(response.data)))
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const getOrder = (token, id) => {
  return (dispatch) => {
    dispatch(getOrderStart());

    axios
      .get(`${HOST_URL}/orders/order/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOrderSuccess(response.data)))
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const getOrderByNumber = (token, order_number) => {
  return (dispatch) => {
    dispatch(getOrderStart());

    axios
      .get(`${HOST_URL}/orders/order/number_${order_number}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOrderSuccess(response.data)))
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const getLastOrder = (token) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    console.log('getting last order');
    axios
      .get(`${HOST_URL}/orders/order/last_order/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getLastOrderSuccess(response.data)))
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const getOrdersByAccount = (token, accountNumber) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    console.log('getting orders');
    axios
      .get(`${HOST_URL}/orders/order/account_${accountNumber}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getOrdersSuccess(response.data)))
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const getOrdersByDateRange = (
  token,
  startDate,
  endDate,
  accountNumber
) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    console.log('getting orders');
    axios
      .get(
        `${HOST_URL}/orders/order/date_${startDate}_${endDate}_${accountNumber}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getOrdersSuccess(response.data)))
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const getOrdersByPeriod = (
  token,
  startDate,
  endDate,
  accountNumber,
  period
) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    console.log('getting orders');
    axios
      .get(
        `${HOST_URL}/orders/order/date_${startDate}_${endDate}_${accountNumber}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) =>
        dispatch(getPeriodOrdersSuccess(response.data, period))
      )
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const postOrderData = (
  token,
  order_number,
  financial_account,
  job_card_number,
  order_type,
  status,
  sub_total,
  item_discount,
  tax,
  shipping,
  total,
  promo,
  grand_total,
  content
) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    let fd = new FormData();
    fd.append('order_number', order_number);
    fd.append('financial_account', financial_account);
    fd.append('job_card_number', job_card_number);
    fd.append('order_type', order_type);
    fd.append('status', status);
    fd.append('sub_total', sub_total);
    fd.append('item_discount', item_discount);
    fd.append('tax', tax);
    fd.append('shipping', shipping);
    fd.append('total', total);
    fd.append('promo', promo);
    fd.append('grand_total', grand_total);
    fd.append('content', content);

    axios
      .post(`${HOST_URL}/orders/order/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getOrderSuccess(response.data));
        dispatch(getOrders(token));
      })
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const updateOrderStatus = (token, order_id, status) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    let fd = new FormData();
    fd.append('status', status);
    axios
      .patch(`${HOST_URL}/orders/order/${order_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getOrderSuccess(response.data));
      })
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};

export const updateOrderData = (
  token,
  order_id,
  sub_total,
  tax,
  total,
  grand_total
) => {
  return (dispatch) => {
    dispatch(getOrderStart());
    let fd = new FormData();

    fd.append('sub_total', sub_total);
    fd.append('tax', tax);
    fd.append('total', total);
    fd.append('grand_total', grand_total);

    axios
      .patch(`${HOST_URL}/orders/order/${order_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getOrderSuccess(response.data));
      })
      .catch((error) => dispatch(getOrderFaild(error)));
  };
};
