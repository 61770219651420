import React, { Component } from 'react';
import './styles/branch-form.css';
import TextField from '@mui/material/TextField';
import { Button, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

export default class BranchForm extends Component {
  state = {
    name: '',
    nameError: false,
    country: '',
    countryError: false,
    region: '',
    regionError: false,
    address: '',
    addressError: false,
    branches: [],
  };

  componentDidMount() {
    const savedData = JSON.parse(localStorage.getItem('branch_form'));
    const companyData = JSON.parse(localStorage.getItem('company_form'));

    if (!savedData || savedData?.length === 0) {
      console.log(savedData);
      let mainBranch = {
        name: 'head office',
        country: companyData.country,
        region: '',
        address: companyData.address,
      };
      localStorage.setItem('branch_form', JSON.stringify([mainBranch]));
      this.setState({ branches: [mainBranch] });
    }
    if (savedData) {
      this.setState({ branches: [...savedData] });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ [`${e.target.name}Error`]: false });
  };

  validate = () => {
    const { name, country, region, address } = this.state;
    if (name === '') {
      this.setState({ nameError: true });
    } else if (country === '') {
      this.setState({ countryError: true });
    } else if (region === '') {
      this.setState({ regionError: true });
    } else if (address === '') {
      this.setState({ addressError: true });
    } else {
      return true;
    }
  };

  handleAddBranch = () => {
    const valid = this.validate();
    if (valid) {
      const { name, country, region, address, branches } = this.state;

      const newBranch = {
        name,
        country,
        region,
        address,
      };
      this.setState(
        {
          branches: [...branches, newBranch],
        },
        () =>
          localStorage.setItem(
            'branch_form',
            JSON.stringify(this.state.branches)
          )
      );
    }
  };

  deleteBranch = (index) => {
    const newBranchs = this.state.branches;
    newBranchs.splice(index, 1);
    this.setState({ members: newBranchs });
    localStorage.setItem('branch_form', JSON.stringify(newBranchs));
  };

  render() {
    return (
      <div className="COMPAN_DETAILS_FORMS_CONTAINER">
        <div className="COMPANY_FORM_CONTAINER">
          <div className="COMPANY_BRANCH_FORM_CONTAINER">
            <div className="COMPANY_BRANCH_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Branch Name"
                variant="outlined"
                name="name"
                fullWidth
                required
                error={this.state.nameError}
                value={this.state.name}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COMPANY_BRANCH_INPUT_FIELD',
                }}
                InputLabelProps={{
                  className: 'COMPANY_BRANCH_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="COMPANY_BRANCH_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Country"
                variant="outlined"
                fullWidth
                required
                error={this.state.countryError}
                name="country"
                value={this.state.country}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COMPANY_BRANCH_INPUT_FIELD',
                }}
                InputLabelProps={{
                  className: 'COMPANY_BRANCH_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="COMPANY_BRANCH_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Region"
                variant="outlined"
                fullWidth
                required
                error={this.state.regionError}
                name="region"
                value={this.state.region}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COMPANY_BRANCH_INPUT_FIELD',
                }}
                InputLabelProps={{
                  className: 'COMPANY_BRANCH_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="COMPANY_BRANCH_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Branch Address"
                variant="outlined"
                fullWidth
                required
                error={this.state.addressError}
                name="address"
                value={this.state.address}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'COMPANY_BRANCH_INPUT_FIELD',
                }}
                InputLabelProps={{
                  className: 'COMPANY_BRANCH_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
        </div>
        <div className="COMPANY_FORM_CONTAINER">
          <Button
            variant="contained"
            sx={{ marginTop: 3 }}
            onClick={this.handleAddBranch}
          >
            add
          </Button>
        </div>
        <div className="COMPANY_FORM_CONTAINER">
          {this.state.branches.length > 0
            ? this.state.branches.map((branch, index) => (
                <div className="COMPANY_FORM_CONTAINER" key={branch.name}>
                  <div className="BRANCH_ITEM">
                    <h3>{branch.name}</h3>
                    {branch.name !== 'head office' ? (
                      <IconButton
                        aria-label="delete"
                        onClick={() => this.deleteBranch(index)}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    ) : null}
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>
    );
  }
}
