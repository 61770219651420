import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  error: null,
  loading: false,
  brand: null,
  brands: [],
};

const getBrandStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getBrandSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    brand: action.payload,
  });
};

const getBrandsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    brands: action.payload,
  });
};

const getBrandFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BRAND_START:
      return getBrandStart(state, action);
    case actionTypes.GET_BRAND_SUCCESS:
      return getBrandSuccess(state, action);
    case actionTypes.GET_BRANDS_SUCCESS:
      return getBrandsSuccess(state, action);
    case actionTypes.GET_BRAND_FAIL:
      return getBrandFaild(state, action);
    default:
      return state;
  }
};

export default brandReducer;
