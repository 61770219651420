import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';
import { getJobCard } from './jobCard';

export const getRegisteredDocumentStart = () => {
  return {
    type: actionTypes.GET_REGISTERED_DOCUMENT_START,
  };
};

export const getRegisteredDocumentSuccess = (data) => {
  return {
    type: actionTypes.GET_REGISTERED_DOCUMENT_SUCCESS,
    payload: data,
  };
};

export const getRegisteredDocumentsSuccess = (data) => {
  return {
    type: actionTypes.GET_REGISTERED_DOCUMENTS_SUCCESS,
    payload: data,
  };
};

export const getRegisteredDocumentFaild = (error) => {
  return {
    type: actionTypes.GET_REGISTERED_DOCUMENT_FAILD,
    error: error,
  };
};

const setProgress = (data) => {
  return {
    type: actionTypes.SET_PROGRESS_DATA,
    payload: data,
  };
};
const setDataSize = (data) => {
  return {
    type: actionTypes.SET_DATA_SIZE,
    payload: data,
  };
};

export const getRegisteredDocuments = (token, job_card) => {
  return (dispatch) => {
    dispatch(getRegisteredDocumentStart());
    const token1 = token ? token : '';
    axios
      .get(`${HOST_URL}/docs/document/jc-${job_card}/`, {
        headers: token ? { Authorization: `Token ${token1}` } : {},
      })
      .then((res) => dispatch(getRegisteredDocumentsSuccess(res.data)))
      .catch((error) => dispatch(getRegisteredDocumentFaild(error)));
  };
};

export const getRegisteredDocument = (token, id) => {
  return (dispatch) => {
    dispatch(getRegisteredDocumentStart());
    const token1 = token ? token : '';
    axios
      .get(`${HOST_URL}/docs/document/${id}/`, {
        headers: token ? { Authorization: `Token ${token1}` } : {},
      })
      .then((res) => dispatch(getRegisteredDocumentSuccess(res.data)))
      .catch((error) => dispatch(getRegisteredDocumentFaild(error)));
  };
};

export const deleteRegisteredDocument = (token, id, job_card_id) => {
  return (dispatch) => {
    dispatch(getRegisteredDocumentStart());
    const token1 = token ? token : '';
    axios
      .delete(`${HOST_URL}/docs/document/${id}/`, {
        headers: token ? { Authorization: `Token ${token1}` } : {},
      })
      .then((res) => {
        dispatch(getRegisteredDocumentSuccess(res.data));
        dispatch(getJobCard(token, job_card_id));
      })
      .catch((error) => dispatch(getRegisteredDocumentFaild(error)));
  };
};

export const registerDocument = (
  token,
  job_card,
  document_name,
  document_type,
  document_size,
  document_image
) => {
  return (dispatch) => {
    dispatch(getRegisteredDocumentStart());
    let fd = new FormData();
    fd.append('job_card', job_card);
    fd.append('document_name', document_name);
    fd.append('document_type', document_type);
    fd.append('document_size', document_size);
    if (document_image) {
      fd.append('document_image', document_image, document_image.name);
    }
    console.log(document_image);
    axios
      .post(`${HOST_URL}/docs/document/`, fd, {
        headers: { Authorization: `Token ${token}` },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          dispatch(setProgress(Math.round((100 * data.loaded) / data.total)));
          dispatch(setDataSize(data.total));
        },
      })
      .then((res) => dispatch(getRegisteredDocuments(token, job_card)))
      .catch((error) => dispatch(getRegisteredDocumentFaild(error)));
  };
};

export const registerDocuments = (token, job_card, document_files) => {
  return (dispatch) => {
    dispatch(getRegisteredDocumentStart());
    let fd = new FormData();
    if (document_files) {
      document_files.map((document_file, index) => {
        fd.append(`document_${index}`, document_file);
        fd.append(`document_${index}_name`, document_file.name);
        fd.append(`document_${index}_type`, document_file.type);
        fd.append(`document_${index}_size`, document_file.size);
      });
    } else {
      fd.append('document_file', '');
    }

    fd.append('job_card', job_card);

    axios
      .post(`${HOST_URL}/docs/documents/`, fd, {
        headers: { Authorization: `Token ${token}` },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          dispatch(setProgress(Math.round((100 * data.loaded) / data.total)));
          dispatch(setDataSize(data.total));
        },
      })
      .then((res) => dispatch(getRegisteredDocuments(token, job_card)))
      .catch((error) => dispatch(getRegisteredDocumentFaild(error)));
  };
};
