import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getQuotationStart = () => {
  return {
    type: actionTypes.GET_QUOTATION_START,
  };
};

export const getQuotationSuccess = (data) => {
  return {
    type: actionTypes.GET_QUOTATION_SUCCESS,
    payload: data,
  };
};

export const getLastQuotationSuccess = (data) => {
  return {
    type: actionTypes.GET_LAST_QUOTATION_SUCCESS,
    payload: data,
  };
};

export const getQuotationsSuccess = (data) => {
  return {
    type: actionTypes.GET_QUOTATIONS_SUCCESS,
    payload: data,
  };
};

export const getQuotationFaild = (error) => {
  return {
    type: actionTypes.GET_QUOTATION_FAIL,
    error: error,
  };
};

export const clearQuotaionProps = () => {
  return {
    type: actionTypes.CLEAR_QUOTATION_DATA,
  };
};

export const getQuotations = (token) => {
  return (dispatch) => {
    dispatch(getQuotationStart());
    console.log('getting all Quotations');
    axios
      .get(`${HOST_URL}/quotations/quotation/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getQuotationsSuccess(response.data)))
      .catch((error) => dispatch(getQuotationFaild(error)));
  };
};

export const getQuotation = (token, id) => {
  return (dispatch) => {
    dispatch(getQuotationStart());

    axios
      .get(`${HOST_URL}/quotations/quotation/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getQuotationSuccess(response.data)))
      .catch((error) => dispatch(getQuotationFaild(error)));
  };
};

export const getQuotationByQuotationNumber = (token, quotationNumber) => {
  return (dispatch) => {
    dispatch(getQuotationStart());

    axios
      .get(`${HOST_URL}/quotations/quotation/quotation_${quotationNumber}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getQuotationSuccess(response.data)))
      .catch((error) => dispatch(getQuotationFaild(error)));
  };
};

export const getLastQuotation = (token) => {
  return (dispatch) => {
    dispatch(getQuotationStart());

    axios
      .get(`${HOST_URL}/quotations/quotation/last/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getLastQuotationSuccess(response.data)))
      .catch((error) => dispatch(getQuotationFaild(error)));
  };
};

export const postQuotationData = (
  token,
  quotation_number,
  jobCardNumber,
  account_number,
  client,
  claim,
  order,
  quoted_by,
  expiry_date,
  note,
  quotation_type
) => {
  return (dispatch) => {
    dispatch(getQuotationStart());
    let fd = new FormData();
    fd.append('quotation_number', quotation_number);
    fd.append('job_card_number', jobCardNumber);
    fd.append('financial_account', account_number);
    fd.append('client', client);
    fd.append('claim', claim);
    fd.append('order', order);
    fd.append('quoted_by', quoted_by);
    fd.append('expiry_date', expiry_date);
    fd.append('quotation_note', note);
    fd.append('quotation_type', quotation_type);

    axios
      .post(`${HOST_URL}/quotations/quotation/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => {
        dispatch(getQuotationSuccess(response.data));
        // dispatch(getQuotations(token));
      })
      .catch((error) => dispatch(getQuotationFaild(error)));
  };
};

export const partialUpdateQuotationData = (
  token,
  quotation_id,
  expiry_date,
  note,
  quotation_type
) => {
  return (dispatch) => {
    dispatch(getQuotationStart());
    let fd = new FormData();
    fd.append('expiry_date', expiry_date);
    fd.append('quotation_note', note);
    fd.append('quotation_type', quotation_type);

    axios
      .patch(`${HOST_URL}/quotations/quotation/${quotation_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getQuotationSuccess(response.data));
      })
      .catch((error) => dispatch(getQuotationFaild(error)));
  };
};

export const updateQuotationData = (
  token,
  quotation_id,
  quotation_number,
  account_number,
  claim,
  order,
  quoted_by,
  expiry_date,
  note,
  quotation_type
) => {
  return (dispatch) => {
    dispatch(getQuotationStart());
    let fd = new FormData();
    fd.append('quotation_number', quotation_number);
    fd.append('financial_account', account_number);
    fd.append('claim', claim);
    fd.append('order', order);
    fd.append('quoted_by', quoted_by);
    fd.append('expiry_date', expiry_date);
    fd.append('quotation_note', note);
    fd.append('quotation_type', quotation_type);

    axios
      .put(`${HOST_URL}/quotations/quotation/${quotation_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getQuotationSuccess(response.data));
      })
      .catch((error) => dispatch(getQuotationFaild(error)));
  };
};
