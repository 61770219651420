import React from 'react';
import './style.css';
import { Button } from '@mui/material';
const Header = (props) => {
  return (
    <div className="DASHBOARD_SPACE_HEADER_CONTAINER">
      <div className="DASHBOARD_SPACE_HEADER">
        <h1>{props.title}</h1>
        <span>{props.description}</span>
      </div>
      <div style={{ marginRight: '1em' }}>
        {props.isEditing ? (
          <Button
            variant="contained"
            disabled={props.loading}
            onClick={props.handleSaveChanges}
          >
            SAVE
          </Button>
        ) : null}
      </div>
    </div>
  );
};

export default Header;
