import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  loading: null,
  error: null,
  documents: [],
  document: null,
  progress: 0,
  dataSize: 0,
};

const getRegisteredDocumentStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const getRegisteredDocumentsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    documents: action.payload,
    progress: 0,
  });
};

const getRegisteredDocumentSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    document: action.payload,
    progress: 0,
  });
};

const getRegisteredDocumentFaild = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
  });
};

const setProgressData = (state, action) => {
  return updateObject(state, {
    progress: action.payload,
  });
};

const setDataSize = (state, action) => {
  return updateObject(state, {
    dataSize: action.payload,
  });
};

const documentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_REGISTERED_DOCUMENT_START:
      return getRegisteredDocumentStart(state, action);
    case actionTypes.GET_REGISTERED_DOCUMENTS_SUCCESS:
      return getRegisteredDocumentsSuccess(state, action);
    case actionTypes.GET_REGISTERED_DOCUMENT_SUCCESS:
      return getRegisteredDocumentSuccess(state, action);
    case actionTypes.GET_REGISTERED_DOCUMENT_FAILD:
      return getRegisteredDocumentFaild(state, action);
    case actionTypes.SET_PROGRESS_DATA:
      return setProgressData(state, action);
    case actionTypes.SET_DATA_SIZE:
      return setDataSize(state, action);
    default:
      return state;
  }
};

export default documentReducer;
