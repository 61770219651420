import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  account: null,
  accounts: [],
};

const getFinancialAccountStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getFinancialAccountSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    account: action.payload,
  });
};

const getFinancialAccountsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    accounts: action.payload,
  });
};

const getFinancialAccountFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const financialAccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_FINANCIAL_ACCOUNT_START:
      return getFinancialAccountStart(state, action);
    case actionTypes.GET_FINANCIAL_ACCOUNT_SUCCESS:
      return getFinancialAccountSuccess(state, action);
    case actionTypes.GET_FINANCIAL_ACCOUNTS_SUCCESS:
      return getFinancialAccountsSuccess(state, action);
    case actionTypes.GET_FINANCIAL_ACCOUNT_FAIL:
      return getFinancialAccountFaild(state, action);
    default:
      return state;
  }
};

export default financialAccountReducer;
