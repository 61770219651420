import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getDamageReportStart = () => {
  return {
    type: actionTypes.GET_DAMAGE_REPORT_START,
  };
};

export const getDamageReportSuccess = (data) => {
  return {
    type: actionTypes.GET_DAMAGE_REPORT_SUCCESS,
    payload: data,
  };
};

export const getDamageReportsSuccess = (data) => {
  return {
    type: actionTypes.GET_DAMAGE_REPORTS_SUCCESS,
    payload: data,
  };
};

export const getDamageReportFaild = (error) => {
  return {
    type: actionTypes.GET_DAMAGE_REPORT_FAIL,
    error: error,
  };
};

export const getDamageReports = (token) => {
  return (dispatch) => {
    dispatch(getDamageReportStart());
    console.log('getting all Damage Reports');
    axios
      .get(`${HOST_URL}/damage-reports/damage-report/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getDamageReportsSuccess(response.data)))
      .catch((error) => dispatch(getDamageReportFaild(error)));
  };
};

export const getDamageReport = (token, id) => {
  return (dispatch) => {
    dispatch(getDamageReportStart());

    axios
      .get(`${HOST_URL}/damage-reports/damage-report/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getDamageReportSuccess(response.data)))
      .catch((error) => dispatch(getDamageReportFaild(error)));
  };
};

export const getDamageReportByClaim = (token, claim) => {
  return (dispatch) => {
    dispatch(getDamageReportStart());

    axios
      .get(`${HOST_URL}/damage-reports/damage-report/claim_${claim}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getDamageReportSuccess(response.data)))
      .catch((error) => dispatch(getDamageReportFaild(error)));
  };
};

export const postDamageReportData = (
  token,
  damage_report_number,
  claim,
  client,
  createdBy,
  date
) => {
  return (dispatch) => {
    dispatch(getDamageReportStart());
    let fd = new FormData();
    fd.append('damage_report_number', damage_report_number);
    fd.append('claim', claim);
    fd.append('client', client);
    fd.append('created_by', createdBy);
    fd.append('report_date', date);

    axios
      .post(`${HOST_URL}/damage-reports/damage-report/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getDamageReportSuccess(response.data));
        dispatch(getDamageReports(token));
      })
      .catch((error) => dispatch(getDamageReportFaild(error)));
  };
};

export const updateDamageReportData = (
  token,
  DamageReportID,
  damage_report_number,
  claim,
  client,
  created_by,
  date
) => {
  return (dispatch) => {
    dispatch(getDamageReportStart());
    let fd = new FormData();
    fd.append('damage_report_number', damage_report_number);
    fd.append('claim', claim);
    fd.append('client', client);
    fd.append('created_by', created_by);
    fd.append('report_date', date);

    axios
      .patch(
        `${HOST_URL}/damage-reports/damage-report/${DamageReportID}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getDamageReportSuccess(response.data));
        dispatch(getDamageReports(token));
      })
      .catch((error) => dispatch(getDamageReportFaild(error)));
  };
};
