import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';
import { getJobTask } from './jobTask';
import { getJobCard } from './jobCard';

export const getJobReportStart = () => {
  return {
    type: actionTypes.GET_JOB_REPORT_START,
  };
};

export const getJobTechReportSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_TECH_REPORT_SUCCESS,
    payload: data,
  };
};

export const getJobDamageReportSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_DAMAGE_REPORT_SUCCESS,
    payload: data,
  };
};

export const getJobTechReportsSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_TECH_REPORTS_SUCCESS,
    payload: data,
  };
};

export const getJobDamageReportsSuccess = (data) => {
  return {
    type: actionTypes.GET_JOB_DAMAGE_REPORTS_SUCCESS,
    payload: data,
  };
};

export const getJobReportFaild = (error) => {
  return {
    type: actionTypes.GET_JOB_REPORT_FAIL,
    error: error,
  };
};

export const getJobTechReports = (token, task) => {
  return (dispatch) => {
    dispatch(getJobReportStart());
    console.log('getting all tasks');
    axios
      .get(`${HOST_URL}/job-reports/job-tech-report/task-${task}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobTechReportsSuccess(response.data)))
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};

export const getJobTechReport = (token, id) => {
  return (dispatch) => {
    dispatch(getJobReportStart());

    axios
      .get(`${HOST_URL}/job-reports/job-tech-report/${id}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobTechReportSuccess(response.data)))
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};

export const postJobTechReport = (token, task, author, report) => {
  return (dispatch) => {
    dispatch(getJobReportStart());
    let fd = new FormData();
    fd.append('task', task);
    fd.append('author', author);
    fd.append('report', report);

    axios
      .post(`${HOST_URL}/job-reports/job-tech-report/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobTechReportSuccess(response.data));
        dispatch(getJobTechReports(token, task));
      })
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};

export const updateJobTechReport = (
  token,
  job_report_id,
  task,
  author,
  report
) => {
  return (dispatch) => {
    dispatch(getJobReportStart());
    let fd = new FormData();
    fd.append('job_report_id', job_report_id);
    fd.append('task', task);
    fd.append('author', author);
    fd.append('report', report);

    axios
      .patch(`${HOST_URL}/job-reports/job-tech-report/${job_report_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobTechReportSuccess(response.data));
        dispatch(getJobTechReports(token, task));
        dispatch(getJobTask(token, task));
      })
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};

// JOB DAMAGE REPORTS

export const getJobDamageReports = (token, job_card) => {
  return (dispatch) => {
    dispatch(getJobReportStart());
    console.log('getting all tasks');
    axios
      .get(`${HOST_URL}/job-reports/job-damage-report/jobcard-${job_card}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobDamageReportsSuccess(response.data)))
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};

export const getJobDamageReportByJobCard = (token, job_card) => {
  return (dispatch) => {
    dispatch(getJobReportStart());
    console.log('getting all tasks');
    axios
      .get(`${HOST_URL}/job-reports/job-damage-report/jobcard-${job_card}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobDamageReportSuccess(response.data)))
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};

export const getJobDamageReport = (token, id) => {
  return (dispatch) => {
    dispatch(getJobReportStart());

    axios
      .get(`${HOST_URL}/job-reports/job-damage-report/${id}`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobDamageReportSuccess(response.data)))
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};

export const postJobDamageReport = (token, job_card, author, report) => {
  return (dispatch) => {
    dispatch(getJobReportStart());
    let fd = new FormData();
    fd.append('job_card', job_card);
    fd.append('author', author);
    fd.append('report', report);

    axios
      .post(`${HOST_URL}/job-reports/job-damage-report/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getJobDamageReportSuccess(response.data));
        dispatch(getJobDamageReports(token, job_card));
      })
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};

export const updateJobDamageReport = (
  token,
  job_damage_report_id,
  job_card,
  author,
  report
) => {
  return (dispatch) => {
    dispatch(getJobReportStart());
    let fd = new FormData();
    fd.append('job_damage_report_id', job_damage_report_id);
    fd.append('job_card', job_card);
    fd.append('author', author);
    fd.append('report', report);

    axios
      .patch(
        `${HOST_URL}/job-reports/job-damage-report/${job_damage_report_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getJobDamageReportSuccess(response.data));
        dispatch(getJobCard(token, job_card));
      })
      .catch((error) => dispatch(getJobReportFaild(error)));
  };
};
