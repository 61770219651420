import React from 'react';
import './styles/pie-chart.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import {
  Chart,
  registerShape,
  Axis,
  Interval,
  Interaction,
  Coordinate,
  Tooltip,
} from 'bizcharts';

const sliceNumber = 0.01;

registerShape('interval', 'sliceShape', {
  draw(cfg, container) {
    const points = cfg.points;
    let path = [];
    path.push(['M', points[0].x, points[0].y]);
    path.push(['L', points[1].x, points[1].y - sliceNumber]);
    path.push(['L', points[2].x, points[2].y - sliceNumber]);
    path.push(['L', points[3].x, points[3].y]);
    path.push('Z');
    path = this.parsePath(path);
    return container.addShape('path', {
      attrs: {
        fill: cfg.color,
        path: path,
      },
    });
  },
});

export default class PieChart extends React.Component {
  state = {
    openInfo: false,
    anchorEl: null,
  };

  handleOpenInfo = (event) => {
    this.setState({
      openInfo: !this.state.openInfo,
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const data = this.props.data;
    const describtion = this.props.describtion;

    return (
      <div className="PIE_CHART_MAIN_CONTAINER">
        <div className="PIE_CHART_INFO_CONTAINER">
          <IconButton onClick={this.handleOpenInfo}>
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </div>

        <Popper
          open={this.state.openInfo}
          anchorEl={this.state.anchorEl}
          placement="bottom-start"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                sx={{
                  opacity: 0.5,
                  backgroundColor: '#082032',
                  maxWidth: '250px',
                }}
              >
                <Typography sx={{ p: 2, color: '#DDDDDD' }}>
                  {describtion}
                </Typography>
              </Paper>
            </Fade>
          )}
        </Popper>

        <Chart data={data} height={220} width={300} autoFit>
          <Coordinate type="theta" radius={0.8} innerRadius={0.75} />

          <Axis visible={false} />

          <Interval
            adjust="stack"
            position="value"
            shape="sliceShape"
            label="name"
            color="title"
            // color={[
            //   'type',
            //   (xVal) => {
            //     if (xVal === 'Error') {
            //       return 'red';
            //     }
            //     return 'yellow';
            //   },
            // ]}
          />
          <Tooltip>
            {(title, items) => {
              return (
                <div>
                  {/* <span>{title}</span> */}
                  <p>{items[0].data.title}</p>
                  <p>{items[0].data.value}</p>
                </div>
              );
            }}
          </Tooltip>
          <Interaction type="element-single-selected"></Interaction>
          {/* <Annotation.Text
            position={[-1.2, -5]}
            content="Total"
            style={{
              fontSize: 20,
              fontWeight: 'normal',
              fill: 'white',
            }}
            // rotate={Math.PI * 0.25}
          /> */}
          {/* <Annotation.Text
            position={[-2.1, -20]}
            content={50}
            style={{
              fontSize: 40,
              fontWeight: 'normal',
              fill: 'white',
            }}
            // rotate={Math.PI * 0.25}
          /> */}
        </Chart>
      </div>
    );
  }
}
