import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getStatementStart = () => {
  return {
    type: actionTypes.GET_STATEMENT_START,
  };
};

export const getStatementSuccess = (data) => {
  return {
    type: actionTypes.GET_STATEMENT_SUCCESS,
    payload: data,
  };
};

export const getStatementsSuccess = (data) => {
  return {
    type: actionTypes.GET_STATEMENTS_SUCCESS,
    payload: data,
  };
};

export const getStatementFaild = (error) => {
  return {
    type: actionTypes.GET_STATEMENT_FAIL,
    error: error,
  };
};

export const getStatements = (token) => {
  return (dispatch) => {
    dispatch(getStatementStart());
    console.log('getting all Statements');
    axios
      .get(`${HOST_URL}/account-statement/account-statement/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getStatementsSuccess(response.data)))
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const getStatement = (token, id) => {
  return (dispatch) => {
    dispatch(getStatementStart());

    axios
      .get(`${HOST_URL}/account-statement/account-statement/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getStatementSuccess(response.data)))
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const getStatementByFinancialAccount = (token, financial_account) => {
  return (dispatch) => {
    dispatch(getStatementStart());

    axios
      .get(
        `${HOST_URL}/account-statement/account-statement/financial-account_${financial_account}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getStatementSuccess(response.data)))
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const postStatementData = (
  token,
  financial_account,
  current_balance,
  thirty_days_balance,
  sixty_days_balance,
  ninety_days_balance,
  hundred_twinty_plus_days_balance,
  total
) => {
  return (dispatch) => {
    dispatch(getStatementStart());
    let fd = new FormData();
    fd.append('financial_account', financial_account);
    fd.append('current_balance', current_balance);
    fd.append('thirty_days_balance', thirty_days_balance);
    fd.append('sixty_days_balance', sixty_days_balance);
    fd.append('ninety_days_balance', ninety_days_balance);
    fd.append(
      'hundred_twinty_plus_days_balance',
      hundred_twinty_plus_days_balance
    );
    fd.append('total', total);
    axios
      .post(`${HOST_URL}/account-statement/account-statement/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getStatementSuccess(response.data));
      })
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const updateCurrentBalanceStatement = (
  token,
  statement_id,
  financial_account,
  current_balance
) => {
  return (dispatch) => {
    dispatch(getStatementStart());
    let fd = new FormData();
    fd.append('financial_account', financial_account);
    fd.append('current_balance', current_balance);

    axios
      .patch(
        `${HOST_URL}/account-statement/account-statement/${statement_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getStatementSuccess(response.data));
      })
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const update30DaysBalanceStatement = (
  token,
  statement_id,
  financial_account,
  thirty_days_balance
) => {
  return (dispatch) => {
    dispatch(getStatementStart());
    let fd = new FormData();
    fd.append('financial_account', financial_account);
    fd.append('thirty_days_balance', thirty_days_balance);

    axios
      .patch(
        `${HOST_URL}/account-statement/account-statement/${statement_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getStatementSuccess(response.data));
      })
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const update60DaysBalanceStatement = (
  token,
  statement_id,
  financial_account,
  sixty_days_balance
) => {
  return (dispatch) => {
    dispatch(getStatementStart());
    let fd = new FormData();
    fd.append('financial_account', financial_account);
    fd.append('sixty_days_balance', sixty_days_balance);

    axios
      .patch(
        `${HOST_URL}/account-statement/account-statement/${statement_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getStatementSuccess(response.data));
      })
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const update90DaysBalanceStatement = (
  token,
  statement_id,
  financial_account,
  ninety_days_balance
) => {
  return (dispatch) => {
    dispatch(getStatementStart());
    let fd = new FormData();
    fd.append('financial_account', financial_account);
    fd.append('ninety_days_balance', ninety_days_balance);

    axios
      .patch(
        `${HOST_URL}/account-statement/account-statement/${statement_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getStatementSuccess(response.data));
      })
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const update120PlusDaysBalanceStatement = (
  token,
  statement_id,
  financial_account,
  hundred_twinty_plus_days_balance
) => {
  return (dispatch) => {
    dispatch(getStatementStart());
    let fd = new FormData();
    fd.append('financial_account', financial_account);
    fd.append(
      'hundred_twinty_plus_days_balance',
      hundred_twinty_plus_days_balance
    );

    axios
      .patch(
        `${HOST_URL}/account-statement/account-statement/${statement_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getStatementSuccess(response.data));
      })
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};

export const updateStatementData = (
  token,
  statement_id,
  current_balance,
  thirty_days_balance,
  sixty_days_balance,
  ninety_days_balance,
  hundred_twinty_plus_days_balance,
  total
) => {
  return (dispatch) => {
    dispatch(getStatementStart());
    let fd = new FormData();
    fd.append('current_balance', current_balance);
    fd.append('thirty_days_balance', thirty_days_balance);
    fd.append('sixty_days_balance', sixty_days_balance);
    fd.append('ninety_days_balance', ninety_days_balance);
    fd.append(
      'hundred_twinty_plus_days_balance',
      hundred_twinty_plus_days_balance
    );
    fd.append('total', total);

    axios
      .patch(
        `${HOST_URL}/account-statement/account-statement/${statement_id}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getStatementSuccess(response.data));
      })
      .catch((error) => dispatch(getStatementFaild(error)));
  };
};
