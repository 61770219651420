import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

function TablePaginationActions(props) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function StatementTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [orderID, setOrderID] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setOrderID(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.orders.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCreditOrder = () => {
    props.handleupdateOrderStatus(orderID, 'credited');
    handleClose();
  };
  const hnadlePaidOrder = () => {
    props.handleupdateOrderStatus(orderID, 'paid');
    handleClose();
  };
  const handleUnPaidOrder = () => {
    props.handleupdateOrderStatus(orderID, 'unpaid');
    handleClose();
  };

  return (
    <TableContainer
      component={'div'}
      sx={{
        borderRadius: '10px',
      }}
    >
      <Table
        sx={{
          minWidth: 500,
          bgcolor: '#1E272E',
          borderRadius: 10,
        }}
        aria-label="custom pagination table"
      >
        <TableHead sx={{ bgcolor: '#111827' }}>
          <TableRow>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Order No
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Date
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Quotaion
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Invoice
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Amount
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Status
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={
            props.mode === 'dark'
              ? { bgcolor: '#183151' }
              : { bgcolor: '#c7c7c7' }
          }
        >
          {(rowsPerPage > 0
            ? props.orders.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : props.orders
          ).map((order, index) => (
            <TableRow key={index}>
              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 160, color: '#dddddd' }
                    : { width: 160, color: '#1c2d43' }
                }
                align="left"
              >
                {order.id}
              </TableCell>
              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 160, color: '#dddddd' }
                    : { width: 160, color: '#1c2d43' }
                }
                align="left"
              >
                {moment(order.created_at).calendar()}
              </TableCell>

              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 160, color: '#dddddd' }
                    : { width: 160, color: '#1c2d43' }
                }
                align="center"
              >
                {order.quotation ? (
                  <Button
                    variant="text"
                    onClick={() => props.openQuotation(order.quotation)}
                  >
                    {order.quotation}
                  </Button>
                ) : (
                  '-'
                )}
              </TableCell>
              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 160, color: '#dddddd' }
                    : { width: 160, color: '#1c2d43' }
                }
                align="center"
              >
                {order.invoice ? (
                  <Button
                    variant="text"
                    onClick={() => props.openInvoice(order.invoice)}
                  >
                    {order.invoice}
                  </Button>
                ) : (
                  '-'
                )}
              </TableCell>

              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 160, color: '#dddddd' }
                    : { width: 160, color: '#1c2d43' }
                }
                align="center"
              >
                R&nbsp;
                {order.grand_total}
              </TableCell>
              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 160, color: '#dddddd' }
                    : { width: 160, color: '#1c2d43' }
                }
                align="center"
              >
                {order.status}
              </TableCell>
              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 160, color: '#dddddd' }
                    : { width: 160, color: '#1c2d43' }
                }
                align="center"
              >
                {props.loadingOrder && order.id === orderID ? (
                  <Box>
                    <CircularProgress fontSize="small"></CircularProgress>
                  </Box>
                ) : (
                  <Button
                    id="order-actions-button"
                    aria-controls={open ? 'order-actions-button' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={(e) => handleClick(e, order.id)}
                  >
                    Actions
                  </Button>
                )}
                <Menu
                  id="order-actions-menu"
                  aria-labelledby="order-actions-button"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <MenuItem onClick={handleCreditOrder}>Credit</MenuItem>
                  <MenuItem onClick={hnadlePaidOrder}>Mark Paid</MenuItem>
                  <MenuItem onClick={handleUnPaidOrder}>Mark Unpaid</MenuItem>
                </Menu>
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter sx={{ bgcolor: '#bbbbbb' }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={7}
              count={props.orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
