import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/job-tech-report.css';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import {
  postJobTechReport,
  updateJobTechReport,
} from '../../store/actions/jobReport';

class JobTechReport extends Component {
  state = {
    report: '',
    reportEditing: false,
    showUpdatedReport: false,
    reportPosting: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.jobReport !== prevProps.jobReport &&
      this.state.reportEditing
    ) {
      this.setState({
        reportEditing: false,
        showUpdatedReport: true,
        report: '',
      });
    }

    if (
      this.props.jobReport !== prevProps.jobReport &&
      this.state.reportPosting
    ) {
      this.setState({
        reportPosting: false,
        showUpdatedReport: true,
        report: '',
      });
    }
    if (this.props.activeJobTask !== prevProps.activeJobTask) {
      this.setState({ showUpdatedReport: false, report: '' });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDeleteReport = () => {
    console.log('delete report');
  };

  handleEditReport = () => {
    this.setState({
      reportEditing: true,
      report:
        this.props.task.job_tech_report[0] !== undefined
          ? this.props.task.job_tech_report[0].report
          : this.props.jobReport.report,
    });
  };

  handleCloseEditing = () => {
    this.setState({
      reportEditing: false,
    });
  };

  handleUpdateReport = () => {
    const { id, author, task } =
      this.props.task.job_tech_report[0] !== undefined
        ? this.props.task.job_tech_report[0]
        : this.props.jobReport;
    const report = this.state.report;
    const token = this.props.token;
    this.props.updateJobTechReport(token, id, task, author, report);
  };

  handlePostReport = () => {
    this.setState({ reportPosting: true });
    const author = this.props.userDetails.username;
    const task = this.props.task.id;
    const report = this.state.report;
    const token = this.props.token;
    this.props.postJobTechReport(token, task, author, report);
  };

  render() {
    const mode = this.props.mode;
    return (
      <div className="WORKSPACE_JOB_REPORT_CONTAINER" id={mode}>
        {/* {this.props.activeTaskLoading ? (
          <div className="WORKSPACE_JOB_REPORT_LOADING">
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <> */}
        {(this.props.task &&
          this.props.task.job_tech_report[0] !== undefined) ||
        (this.props.jobReport &&
          this.props.jobReport.task === this.props.task.id) ? (
          <div className="TECHNICIAN_REPORT">
            <div className="TECHNICIAN_REPORT_HEADER">
              <span>Report</span>
              <div className="JOB_REPORT_ACTION_CONTAINER">
                {this.state.reportEditing ? (
                  <div
                    className="JOB_REPORT_ACTION_SAVE"
                    onClick={this.handleUpdateReport}
                  >
                    <Tooltip title=" Save report">
                      <SaveIcon color="inherit" />
                    </Tooltip>
                  </div>
                ) : null}
                {this.state.reportEditing ? (
                  <div className="JOB_REPORT_ACTION_CANCEL_EDIT">
                    <Tooltip title="Cancel Edit">
                      <CancelIcon
                        color="inherit"
                        onClick={this.handleCloseEditing}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div className="JOB_REPORT_ACTION_EDIT">
                    <Tooltip title="Edit Report">
                      <ModeEditOutlineIcon
                        color="inherit"
                        onClick={this.handleEditReport}
                      />
                    </Tooltip>
                  </div>
                )}
                <div
                  className="JOB_REPORT_ACTION_DELETE"
                  onClick={this.handleDeleteReport}
                >
                  <Tooltip title=" Delete Report">
                    <DeleteForeverIcon color="inherit" />
                  </Tooltip>
                </div>
              </div>
            </div>

            {this.state.reportEditing ? (
              <div className="TECHNICIAN_EDIT_REPORT_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-multiline-static"
                  label="Edit report"
                  multiline
                  rows={4}
                  // defaultValue=""
                  variant="outlined"
                  name="report"
                  value={this.state.report}
                  onChange={this.onChange}
                  // color="secondary"
                  type="text"
                  fullWidth
                  required
                  InputProps={{
                    className: 'JOB_TASK_REPORT_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_TASK_REPORT_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            ) : (
              <div className="JOB_REPORT_BODY">
                {this.state.showUpdatedReport ? (
                  <p>
                    {this.props.jobReport ? this.props.jobReport.report : null}
                  </p>
                ) : (
                  <p>
                    {this.props.task &&
                    this.props.task.job_tech_report[0] !== undefined
                      ? this.props.task.job_tech_report[0].report
                      : null}
                  </p>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className="TECHNICIAN_ADD_REPORT_CONTAINER">
            <div className="TECHNICIAN_ADD_REPORT_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-multiline-static"
                label="Report"
                multiline
                rows={5}
                // defaultValue=""
                variant="outlined"
                name="report"
                value={this.state.report}
                onChange={this.onChange}
                // color="secondary"
                type="text"
                fullWidth
                required
                InputProps={{
                  className: 'JOB_TASK_REPORT_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_TASK_REPORT_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div
              className="TECHNICIAN_ADD_REPORT_POST_BUTTON"
              // onClick={this.handlePostReport}
            >
              {this.state.reportPosting ? (
                <CircularProgress></CircularProgress>
              ) : (
                <IconButton color="inherit" onClick={this.handlePostReport}>
                  <SaveIcon color="inherit" />
                </IconButton>
              )}
            </div>
          </div>
        )}
        <div className="TECHNICIAN_REPORT_FOOTER_CONTAINER">
          <span>
            {this.props.task.job_tech_report[0] !== undefined
              ? moment(this.props.task.job_tech_report[0].created).calendar()
              : null}
          </span>
        </div>
        {/* </> */}
        {/* )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    jobReport: state.jobReport.jobTechReport,
    jobReports: state.jobReport.jobTechReports,
    activeJobTask: state.jobTask.jobTask,
    // activeTaskLoading: state.jobTask.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateJobTechReport: (token, job_report_id, task, author, report) =>
      dispatch(updateJobTechReport(token, job_report_id, task, author, report)),
    postJobTechReport: (token, task, author, report) =>
      dispatch(postJobTechReport(token, task, author, report)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTechReport);
