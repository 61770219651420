import * as actionTypes from '../actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../../settings';

export const getJobsInsightsStart = () => {
  return {
    type: actionTypes.GET_JOBS_INSIGHTS_START,
  };
};

export const getJobsTasksSuccess = (data) => {
  return {
    type: actionTypes.GET_JOBS_TASKS_SUCCESS,
    payload: data,
  };
};

export const getJobsInsightsFaild = (error) => {
  return {
    type: actionTypes.GET_JOBS_INSIGHTS_FAILD,
    error: error,
  };
};

export const getDailyJobsTasks = (token) => {
  return (dispatch) => {
    dispatch(getJobsInsightsStart());

    axios
      .get(`${HOST_URL}/insights/daily-jobs-tasks/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getJobsTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobsInsightsFaild(error)));
  };
};
