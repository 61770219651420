import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  error: null,
  loading: false,
  category: null,
  categories: [],
};

const getCategoryStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getCategorySuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    category: action.payload,
  });
};

const getCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    categories: action.payload,
  });
};

const getCategoryFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CATEGORY_START:
      return getCategoryStart(state, action);
    case actionTypes.GET_CATEGORY_SUCCESS:
      return getCategorySuccess(state, action);
    case actionTypes.GET_CATEGORIES_SUCCESS:
      return getCategoriesSuccess(state, action);
    case actionTypes.GET_CATEGORY_FAIL:
      return getCategoryFaild(state, action);
    default:
      return state;
  }
};

export default categoryReducer;
