import * as actionTypes from '../actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../../settings';

export const getSupplierStart = () => {
  return {
    type: actionTypes.GET_SUPPLIER_START,
  };
};

export const getSupplierSuccess = (data) => {
  return {
    type: actionTypes.GET_SUPPLIER_SUCCESS,
    payload: data,
  };
};

export const getSuppliersSuccess = (data) => {
  return {
    type: actionTypes.GET_SUPPLIERS_SUCCESS,
    payload: data,
  };
};

export const getSupplierFaild = (error) => {
  return {
    type: actionTypes.GET_SUPPLIER_FAIL,
    error: error,
  };
};

export const getSuppliers = (token) => {
  return (dispatch) => {
    dispatch(getSupplierStart());
    console.log('getting all Suppliers');
    axios
      .get(`${HOST_URL}/suppliers/supplier/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getSuppliersSuccess(response.data)))
      .catch((error) => dispatch(getSupplierFaild(error)));
  };
};

export const getSupplier = (token, id) => {
  return (dispatch) => {
    dispatch(getSupplierStart());

    axios
      .get(`${HOST_URL}/suppliers/supplier/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getSupplierSuccess(response.data)))
      .catch((error) => dispatch(getSupplierFaild(error)));
  };
};

export const updateSupplier = (
  token,
  supplierID,
  name,
  address,
  email,
  alternativeEmail,
  phoneNumber,
  alternativePhoneNumber,
  vatNumber,
  salesPerson
) => {
  return (dispatch) => {
    dispatch(getSupplierStart());
    let fd = new FormData();
    fd.append('name', name);
    fd.append('address', address);
    fd.append('email', email);
    fd.append('alternative_email', alternativeEmail);
    fd.append('phone_number', phoneNumber);
    fd.append('alternative_phone_number', alternativePhoneNumber);
    fd.append('vat_number', vatNumber);
    fd.append('sales_name', salesPerson);

    axios
      .patch(`${HOST_URL}/suppliers/supplier/${supplierID}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getSupplierSuccess(response.data));
        dispatch(getSuppliers(token));
      })
      .catch((error) => dispatch(getSupplierFaild(error)));
  };
};

export const saveSupplier = (
  token,
  name,
  address,
  email,
  alternativeEmail,
  phoneNumber,
  alternativePhoneNumber,
  vatNumber,
  salesPerson
) => {
  return (dispatch) => {
    dispatch(getSupplierStart());
    let fd = new FormData();
    fd.append('name', name);
    fd.append('address', address);
    fd.append('email', email);
    fd.append('alternative_email', alternativeEmail);
    fd.append('phone_number', phoneNumber);
    fd.append('alternative_phone_number', alternativePhoneNumber);
    fd.append('vat_number', vatNumber);
    fd.append('sales_name', salesPerson);

    axios
      .post(`${HOST_URL}/suppliers/supplier/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getSupplierSuccess(response.data));
        dispatch(getSuppliers(token));
      })
      .catch((error) => dispatch(getSupplierFaild(error)));
  };
};

export const deleteSupplier = (token, supplierID) => {
  return (dispatch) => {
    dispatch(getSupplierStart());

    axios
      .delete(`${HOST_URL}/suppliers/supplier/${supplierID}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getSupplierSuccess(response.data));
        dispatch(getSuppliers(token));
      })
      .catch((error) => dispatch(getSupplierFaild(error)));
  };
};
