import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import './styles/reception.css';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { pink } from '@mui/material/colors';
import JobCardForm from '../../components/job-card-form/JobCardForm';
import CollectDeviceForm from '../../components/collect-device-form/CollectDeviceForm';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import DownloadingIcon from '@mui/icons-material/Downloading';
import ClearIcon from '@mui/icons-material/Clear';
import JobCardTicket from '../../components/job-card-ticket/JobCardTicket';
import {
  getJobCard,
  getJobCards,
  getLastNJobCards,
} from '../../store/actions/jobCard';
import {
  getJobTasks,
  getJobTask,
  getJobTasksByEmployee,
  postJobTask,
  getLastNJobTasks,
  updateJobTask,
  getOverdueJobTasks,
} from '../../store/actions/jobTask';
import EditJobCardForm from '../../components/edit-job-card-form/EditJobCardForm';
import JobTaskTicket from '../../components/job-task-ticket/JobTaskTicket';
import JobCardSearch from '../../components/job-card-search/JobCardSearch';
import JobTaskSearch from '../../components/job-task-search/JobTaskSearch';
import ClientForm from '../../components/client-form/ClientForm';
import JobTaskForm from '../../components/job-task-form/JobTaskForm';
import InvoiceForm from '../../components/invoice-form/InvoiceForm';
import { getTeamMembers } from '../../store/actions/teamMember';
import { getNotificationsByTarget } from '../../store/actions/notification';
import DamageReport from '../../components/damage-report/DamageReport';
import QuotationForm from '../../components/quotation-form/QuotationForm';
import { checkPermissions } from '../../tools/checkPermissions';

class Reception extends Component {
  state = {
    approveAccess: false,
    approveAccessHandled: false,
    addClientOpen: false,
    assignJobOpen: false,
    handleAssignJobClose: false,
    taskPriority: '',
    taskPriorityError: false,
    taskTechnician: '',
    taskTechnicianError: false,
    dueDate: null,
    dueDateError: false,
    activeJobCard: null,
    gettingMoreJobCards: false,
    jobCardsNumberToGet: 5,
    gettingMoreJobTasks: false,
    jobTasksNumberToGet: 5,
    jobCardSearch: '',
    jobTaskSearch: '',
    intervalId: 0,
    jobCardIDToAssign: null,
    jobCardNoToAssign: null,
    jobCardTask: null,
    showUrgentTasksOnTop: false,
  };

  componentDidMount() {
    if (this.props.match.params.form === undefined) {
      this.handleBookDeviceOpen();
    }
    const token = this.props.token;
    const username = this.props.userDetails
      ? this.props.userDetails.username
      : null;
    if (token) {
      this.props.getLastNJobCards(token, this.state.jobCardsNumberToGet);
      this.props.getLastNJobTasks(token, this.state.jobTasksNumberToGet);
      this.props.getTeamMembers(token, 'jobtitle', 'technician'); // should be optimized and made dynamic

      this.props.getNotifications(token, username);
      this.props.getOverdueJobTasks(token);
      console.log(this.props.match.params.form);

      const newIntervalId = setInterval(() => {
        this.props.getNotifications(token, username);
        console.log('getting new notifications');
      }, 180000);

      this.setState((prevState) => {
        return {
          ...prevState,
          intervalId: newIntervalId,
        };
      });
    }
    if (
      this.props.memberPermissions.length > 0 &&
      !this.state.approveAccessHandled
    ) {
      const permissions = checkPermissions(this.props.memberPermissions);
      console.log(!permissions.primaryPermissions.includes('reception'));
      if (!permissions.primaryPermissions.includes('reception')) {
        this.handleLoginOpen();
      } else {
        this.setState({ approveAccess: true, approveAccessHandled: true });
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.memberPermissions.length > 0
    ) {
      const permissions = checkPermissions(this.props.memberPermissions);
      console.log(!permissions.primaryPermissions.includes('reception'));
      if (!permissions.primaryPermissions.includes('reception')) {
        this.handleLoginOpen();
      } else {
        this.setState({ approveAccess: true });
      }
    }

    if (
      this.props.jobCards !== prevProps.jobCards &&
      this.state.gettingMoreJobCards
    ) {
      this.setState({ gettingMoreJobCards: false });
    }
    if (
      this.props.jobTasks !== prevProps.jobTasks &&
      this.state.gettingMoreJobTasks
    ) {
      this.setState({ gettingMoreJobTasks: false });
    }
    if (
      this.props.jobTasks !== prevProps.jobTasks &&
      !this.state.gettingMoreJobTasks
    ) {
      this.props.getLastNJobCards(this.props.token, 5);
    }
    if (
      this.props.jobTask !== prevProps.jobTask &&
      this.state.getJobCardStart
    ) {
      this.props.getJobCard(this.props.token, this.props.jobTask.job_card);
      this.setState({ getJobCardStart: false });
    }
    if (this.props.jobTask !== prevProps.jobTask && this.state.assignJobOpen) {
      this.handleAssignJobClose();
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    // remove the following lines to their own onchange event handler
    this.setState({
      taskTechnicianError: false,
      taskPriorityError: false,
    });
  };

  handleLoginOpen = () => {
    this.props.history.push('/login');
    localStorage.setItem('prevPath', '/login');
  };

  handleAddClientClose = () => {
    this.setState({ addClientOpen: false });
  };

  handleBookDeviceOpen = () => {
    this.props.history.push('/reception/book-a-device');
    localStorage.setItem('prevPath', '/reception/book-a-device');
  };

  handleCollectDeviceOpen = () => {
    this.props.history.push('/reception/collect-device');
    localStorage.setItem('prevPath', '/reception/collect-device');
  };

  handleEditJobCardOpen = () => {
    this.props.history.push('/reception/job-card-edit');
    localStorage.setItem('prevPath', '/reception/job-card-edit');
  };

  handleJobTaskSearchOpen = () => {
    this.props.history.push('/reception/job-tasks');
    localStorage.setItem('prevPath', '/reception/job-tasks');
  };

  handleJobCardSearchOpen = () => {
    this.props.history.push('/reception/job-cards');
    localStorage.setItem('prevPath', '/reception/job-cards');
  };

  handleClientOpen = () => {
    this.props.history.push('/reception/our-clients');
    localStorage.setItem('prevPath', '/reception/our-clients');
  };

  handleJobTaskFormOpen = (jobTask) => {
    this.getTaskRelatedInfo(jobTask);
    localStorage.setItem('activeTask', jobTask.id);
    this.props.history.push('/reception/job-task-form');
    localStorage.setItem('prevPath', '/reception/job-task-form');
  };

  handleCreateInvoiceOpen = () => {
    this.props.history.push('/reception/create-invoice');
    localStorage.setItem('prevPath', '/reception/create-invoice');
  };

  handleCreateQuoteOpen = () => {
    this.props.history.push('/reception/create-quotation');
    localStorage.setItem('prevPath', '/reception/create-quotation');
  };

  handleCreateDamageReportOpen = () => {
    this.props.history.push('/reception/create-damage-report');
    localStorage.setItem('prevPath', '/reception/create-damage-report');
  };

  handleAccountManagerOpen = () => {
    this.props.history.push('/accounts-manager/statments');
    localStorage.setItem('prevPath', '/accounts-manager/statments');
  };

  handleInventoryOpen = () => {
    this.props.history.push('/inventory-manager/add-item');
    localStorage.setItem('prevPath', '/inventory-manager/add-item');
  };

  handleOpenInvoiceForm = (jobCard) => {
    this.props.history.push(`/reception/create-invoice/${jobCard}`);
    localStorage.setItem('prevPath', '/reception/create-invoice');
  };

  handleOpenQuotationForm = (jobCard) => {
    this.props.history.push(`/reception/create-quotation/${jobCard}`);
    localStorage.setItem('prevPath', '/reception/create-quotation');
  };

  getTaskRelatedInfo = (jobTask) => {
    const token = this.props.token;
    this.props.getActiveTaskDetails(token, jobTask.id);
    this.setState({ getJobCardStart: true });
  };

  handleAssignJobClose = () => {
    this.setState({
      assignJobOpen: false,
      taskTechnicianError: false,
      taskPriorityError: false,
      dueDateError: false,
    });
  };

  handleAssignJobOpen = (job_card_id, job_card_number, task) => {
    this.setState({
      assignJobOpen: true,
      jobCardIDToAssign: job_card_id,
      jobCardNoToAssign: job_card_number,
      jobCardTask: task,
    });
  };

  validateCreateTaskParameters = () => {
    const task_number = this.state.jobCardNoToAssign;
    const job_card_id = this.state.jobCardIDToAssign;
    const due_date = this.state.dueDate;
    const assigned_to = this.state.taskTechnician;
    const priority = this.state.taskPriority;

    if (due_date === null) {
      this.setState({ dueDateError: true });
    }
    if (assigned_to === '') {
      this.setState({ taskTechnicianError: true });
    }
    if (priority === '') {
      this.setState({ taskPriorityError: true });
    }

    if (
      task_number === null ||
      job_card_id === null ||
      due_date === null ||
      assigned_to === '' ||
      priority === ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  handleCreateTask = () => {
    console.log('create task');
    const token = this.props.token;
    const task_number = this.state.jobCardNoToAssign;
    const job_card_id = this.state.jobCardIDToAssign;
    const due_date = moment(this.state.dueDate).format();
    const assigned_to = this.state.taskTechnician;
    const assigned_by = this.props.userDetails.username;
    const task_status = 'pending';
    const priority = this.state.taskPriority;
    const jobCardTask = this.state.jobCardTask;

    const validate = this.validateCreateTaskParameters();
    if (validate && jobCardTask === null) {
      this.props.createTask(
        token,
        task_number,
        assigned_by,
        assigned_to,
        due_date,
        job_card_id,
        task_status,
        priority
      );
    } else if (validate && jobCardTask !== null) {
      this.props.updateJobTask(
        token,
        jobCardTask.id,
        jobCardTask.task_number,
        assigned_by,
        assigned_to,
        due_date,
        jobCardTask.job_card,
        jobCardTask.status.id,
        priority
      );
    }
  };

  handleEditJobCard = (jobCard) => {
    const token = this.props.token;
    this.handleEditJobCardOpen();
    this.setState({ activeJobCard: jobCard });
    this.props.getJobCard(token, jobCard.id);
  };

  handleAssignTask = (job_card_id, job_card_number, assigned_to_name, task) => {
    const token = this.props.token;
    const task_number = job_card_number; // Use the company name
    const assigned_by = this.props.userDetails.username;
    const assigned_to = assigned_to_name;
    let now = new Date();
    let added3days = now.setDate(now.getDate() + 3); // adding 3 days
    var due_date = moment(added3days).format();
    console.log(due_date);
    const task_status = 'pending';
    const priority = 'low';
    if (task === null) {
      this.props.createTask(
        token,
        task_number,
        assigned_by,
        assigned_to,
        due_date,
        job_card_id,
        task_status,
        priority
      );
    } else if (task) {
      this.props.updateJobTask(
        token,
        task.id,
        task.task_number,
        assigned_by,
        assigned_to,
        task.due_date,
        task.job_card,
        task.status.id,
        task.priority
      );
    }
  };

  getLastXJobCards = (number_of_jobcards) => {
    this.setState({ gettingMoreJobCards: true });
    this.props.getLastNJobCards(this.props.token, number_of_jobcards);
  };

  getLastXJobTasks = (number_of_jobtasks) => {
    this.setState({ gettingMoreJobTasks: true });
    this.props.getLastNJobTasks(this.props.token, number_of_jobtasks);
  };

  onJobCardsScroll = (event) => {
    var element = event.target;
    const { jobCardsNumberToGet } = this.state;

    // console.log(element.scrollHeight, element.scrollTop, element.clientHeight);
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
        element.clientHeight + 1 &&
      !this.props.jobCardLoading
    ) {
      this.getLastXJobCards(jobCardsNumberToGet + 10);
      this.setState({ jobCardsNumberToGet: jobCardsNumberToGet + 10 });
    }
  };

  getMoreJobCards = () => {
    if (!this.props.jobCardLoading) {
      const { jobCardsNumberToGet } = this.state;
      this.getLastXJobCards(jobCardsNumberToGet + 10);
      this.setState({ jobCardsNumberToGet: jobCardsNumberToGet + 10 });
    }
  };

  getMoreJobTasks = () => {
    if (!this.props.jobTaskLoading) {
      const { jobTasksNumberToGet } = this.state;
      this.getLastXJobTasks(jobTasksNumberToGet + 10);
      this.setState({ jobTasksNumberToGet: jobTasksNumberToGet + 10 });
    }
  };

  onJobTasksScroll = (event) => {
    var element = event.target;
    const { jobTasksNumberToGet } = this.state;
    // console.log(
    //   element.scrollHeight,
    //   Math.round(element.scrollTop),
    //   element.clientHeight
    // );
    if (
      element.scrollHeight - Math.round(element.scrollTop) ===
        element.clientHeight + 1 &&
      !this.props.jobTaskLoading
    ) {
      this.getLastXJobTasks(jobTasksNumberToGet + 10);
      this.setState({ jobTasksNumberToGet: jobTasksNumberToGet + 10 });
    }
  };

  handleShowUrgentTasksOnTop = () => {
    this.setState((state) => ({
      showUrgentTasksOnTop: !state.showUrgentTasksOnTop,
    }));
  };

  render() {
    const priorities = [
      {
        value: 'low',
        label: 'Low',
      },
      {
        value: 'medium',
        label: 'Medium',
      },
      {
        value: 'heigh',
        label: 'Heigh',
      },
    ];

    let jobCardFiltered = this.props.jobCards.filter((job_card) => {
      return (
        job_card.job_card_number
          .toLowerCase()
          .indexOf(this.state.jobCardSearch.toLowerCase()) !== -1 ||
        job_card.client_details.client_name
          .toLowerCase()
          .indexOf(this.state.jobCardSearch.toLowerCase()) !== -1 ||
        job_card.client_details.client_number
          .toLowerCase()
          .indexOf(this.state.jobCardSearch.toLowerCase()) !== -1 ||
        job_card.device_details.serial_number
          .toLowerCase()
          .indexOf(this.state.jobCardSearch.toLowerCase()) !== -1
      );
    });

    let jobTaskFiltered = this.props.jobTasks.filter((job_task) => {
      return (
        job_task.task_number
          .toLowerCase()
          .indexOf(this.state.jobTaskSearch.toLowerCase()) !== -1 ||
        job_task.assigned_to
          .toLowerCase()
          .indexOf(this.state.jobTaskSearch.toLowerCase()) !== -1 ||
        job_task.status.status
          .toLowerCase()
          .indexOf(this.state.jobTaskSearch.toLowerCase()) !== -1
      );
    });
    const permissions = checkPermissions(this.props.memberPermissions);
    const mode = this.props.mode;
    return (
      <div className="RECEPTION_MAIN_CONTAINER" id={mode}>
        {this.state.approveAccess ? (
          <>
            <div className="RECEPTION_LEFT_SIDE_BAR_CONTAINER">
              <div className="RECEPTION_ACTION_CONTAINER">
                <div
                  className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                  style={
                    this.props.match.params.form === 'book-a-device'
                      ? mode === 'dark'
                        ? { background: '#04293A' }
                        : { background: '#c3cfd1' }
                      : {}
                  }
                  onClick={this.handleBookDeviceOpen}
                >
                  <span>Book a</span>
                  <span>Device</span>
                </div>
                <div
                  className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                  style={
                    this.props.match.params.form === 'job-cards'
                      ? mode === 'dark'
                        ? { background: '#04293A' }
                        : { background: '#c3cfd1' }
                      : {}
                  }
                  onClick={this.handleJobCardSearchOpen}
                >
                  <span>Job</span>
                  <span>Cards</span>
                </div>
                <div
                  className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                  style={
                    this.props.match.params.form === 'job-tasks'
                      ? mode === 'dark'
                        ? { background: '#04293A' }
                        : { background: '#c3cfd1' }
                      : {}
                  }
                  onClick={this.handleJobTaskSearchOpen}
                >
                  <span>Job</span>
                  <span>Tasks</span>
                </div>
                <div
                  className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                  style={
                    this.props.match.params.form === 'create-damage-report'
                      ? mode === 'dark'
                        ? { background: '#04293A' }
                        : { background: '#c3cfd1' }
                      : {}
                  }
                  onClick={this.handleCreateDamageReportOpen}
                >
                  <span>Damage</span>
                  <span>Report</span>
                </div>
                <div
                  className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                  style={
                    this.props.match.params.form === 'our-clients'
                      ? mode === 'dark'
                        ? { background: '#04293A' }
                        : { background: '#c3cfd1' }
                      : {}
                  }
                  onClick={this.handleClientOpen}
                >
                  <span>Our</span>
                  <span>Clients</span>
                </div>
                {/* <div
                  className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                  style={
                    this.props.match.params.form === 'collect-device'
                      ? { background: '#04293A' }
                      : {}
                  }
                  onClick={this.handleCollectDeviceOpen}
                >
                  <span>Collect</span>
                  <span>Devices</span>
                </div> */}
                {permissions.permissions.includes('invoice') ? (
                  <div
                    className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                    style={
                      this.props.match.params.form === 'create-invoice'
                        ? mode === 'dark'
                          ? { background: '#04293A' }
                          : { background: '#c3cfd1' }
                        : {}
                    }
                    onClick={this.handleCreateInvoiceOpen}
                  >
                    <span>Invoice</span>
                  </div>
                ) : null}
                {permissions.permissions.includes('quotation') ? (
                  <div
                    className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                    style={
                      this.props.match.params.form === 'create-quotation'
                        ? mode === 'dark'
                          ? { background: '#04293A' }
                          : { background: '#c3cfd1' }
                        : {}
                    }
                    onClick={this.handleCreateQuoteOpen}
                  >
                    <span>Quotation</span>
                  </div>
                ) : null}
                {permissions.primaryPermissions.includes('accounting') ? (
                  <div
                    className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                    onClick={this.handleAccountManagerOpen}
                  >
                    <span>Accounts</span>
                    <span>Manager</span>
                  </div>
                ) : null}
                {permissions.primaryPermissions.includes('inventory') ? (
                  <div
                    className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
                    onClick={this.handleInventoryOpen}
                  >
                    <span>Inventory</span>
                    <span>Manager</span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="RECEPTION_WORKSPACE_CONTAINER">
              <div className="RECEPTION_FORMS_CONTAINER">
                {this.props.match.params.form === 'book-a-device' ? (
                  <JobCardForm></JobCardForm>
                ) : null}
                {this.props.match.params.form === 'job-task-form' ? (
                  <JobTaskForm></JobTaskForm>
                ) : null}
                {this.props.match.params.form === 'job-card-edit' ? (
                  <EditJobCardForm
                    jobCardToEdit={this.state.activeJobCard}
                  ></EditJobCardForm>
                ) : null}
                {this.props.match.params.form === 'job-cards' ? (
                  <JobCardSearch></JobCardSearch>
                ) : null}
                {this.props.match.params.form === 'job-tasks' ? (
                  <JobTaskSearch></JobTaskSearch>
                ) : null}

                {this.props.match.params.form === 'our-clients' ? (
                  <ClientForm></ClientForm>
                ) : null}
                {this.props.match.params.form === 'collect-device' ? (
                  <CollectDeviceForm></CollectDeviceForm>
                ) : null}
                {this.props.match.params.form === 'create-invoice' ? (
                  <InvoiceForm></InvoiceForm>
                ) : null}
                {this.props.match.params.form === 'create-quotation' ? (
                  <QuotationForm></QuotationForm>
                ) : null}
                {this.props.match.params.form === 'create-damage-report' ? (
                  <DamageReport></DamageReport>
                ) : null}
              </div>
            </div>
            <div className="RECEPTION_RIGHT_SIDE_BAR_CONTAINER">
              <div className="RECEPTION_JOB_CARD_SEARCH_SIDE_BAR_CONTAINER">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Job Cards"
                  inputProps={{
                    'aria-label': 'search jobs',
                    className: 'JOB_SEARCH_INPUT_FIELD',
                  }}
                  value={this.state.jobCardSearch}
                  name="jobCardSearch"
                  onChange={this.onChange}
                />
                {/* <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton> */}
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  color="error"
                  sx={{ p: '10px' }}
                  aria-label="directions"
                  onClick={() => this.setState({ jobCardSearch: '' })}
                >
                  <ClearIcon />
                </IconButton>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        <Typography color="inherit">
                          Load More Job Cards
                        </Typography>
                        <p style={{ fontSize: '0.7rem', maxWidth: '150px' }}>
                          Use this button to load more job cards, it loads 10
                          more every time you use it
                        </p>
                      </div>
                    </React.Fragment>
                  }
                >
                  <IconButton
                    sx={{ p: '2px', color: '#4E9F3D' }}
                    onClick={this.getMoreJobCards}
                  >
                    {!this.state.gettingMoreJobCards ? (
                      <DownloadingIcon color="primary"></DownloadingIcon>
                    ) : null}
                  </IconButton>
                </Tooltip>
              </div>
              {this.state.gettingMoreJobCards ? (
                <Box sx={{ width: '80%', color: 'grey.500' }}>
                  <LinearProgress color="inherit" />
                </Box>
              ) : null}
              <div
                className="JOB_CARDS_SIDE_CONTAINER"
                onScroll={this.onJobCardsScroll}
              >
                {this.props.jobCards ? (
                  this.props.jobCards.length >= 1 ? (
                    jobCardFiltered.map((jobcard, index) => (
                      <JobCardTicket
                        mode={this.props.mode}
                        key={index}
                        memberKey={this.props.userDetails.team_member.key}
                        advanceAssign={() =>
                          this.handleAssignJobOpen(
                            jobcard.id,
                            jobcard.job_card_number,
                            jobcard.task
                          )
                        }
                        jobCard={jobcard}
                        technicians={
                          this.props.teamMembers ? this.props.teamMembers : []
                        }
                        editJobCard={() => this.handleEditJobCard(jobcard)}
                        assignTask={(assigned_to) =>
                          this.handleAssignTask(
                            jobcard.id,
                            jobcard.job_card_number,
                            assigned_to,
                            jobcard.task
                          )
                        }
                        allowInvoiceQuotaion={
                          permissions.permissions.includes('quotation') &&
                          permissions.permissions.includes('invoice')
                        }
                        openInvoiceForm={() =>
                          this.handleOpenInvoiceForm(jobcard.job_card_number)
                        }
                        openQuotationForm={() =>
                          this.handleOpenQuotationForm(jobcard.job_card_number)
                        }
                      ></JobCardTicket>
                    ))
                  ) : this.props.jobCardLoading ? (
                    <CircularProgress></CircularProgress>
                  ) : null
                ) : this.props.jobCardLoading ? (
                  <CircularProgress></CircularProgress>
                ) : null}
                {this.state.gettingMoreJobCards ? (
                  <CircularProgress></CircularProgress>
                ) : null}
              </div>
              <div className="RECEPTION_JOB_TASKS_SEARCH_BAR_CONTAINER">
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Job Tasks"
                  inputProps={{
                    'aria-label': 'search jobs',
                    className: 'JOB_SEARCH_INPUT_FIELD',
                  }}
                  value={this.state.jobTaskSearch}
                  name="jobTaskSearch"
                  onChange={this.onChange}
                />
                {/* <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton> */}
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  color="error"
                  sx={{ p: '10px' }}
                  aria-label="directions"
                  onClick={() => this.setState({ jobTaskSearch: '' })}
                >
                  <ClearIcon />
                </IconButton>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        <Typography color="inherit">Load More Tasks</Typography>
                        <p style={{ fontSize: '0.7rem', maxWidth: '150px' }}>
                          Use this button to load more tasks, it loads 10 more
                          every time you use it
                        </p>
                      </div>
                    </React.Fragment>
                  }
                >
                  <IconButton
                    sx={{ p: '2px', color: '#4E9F3D' }}
                    onClick={this.getMoreJobTasks}
                  >
                    {!this.state.gettingMoreJobTasks ? (
                      <DownloadingIcon color="primary"></DownloadingIcon>
                    ) : null}
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        <Typography color="inherit">
                          Show Urgent Tasks
                        </Typography>
                        <p style={{ fontSize: '0.7rem', maxWidth: '150px' }}>
                          Enable this CheckBox to show all urgent Tasks on top
                          of the list
                        </p>
                      </div>
                    </React.Fragment>
                  }
                >
                  <Checkbox
                    defaultChecked={false}
                    onClick={this.handleShowUrgentTasksOnTop}
                    sx={{
                      color: pink[800],
                      '&.Mui-checked': {
                        color: pink[600],
                      },
                    }}
                  />
                </Tooltip>
              </div>
              {this.state.gettingMoreJobTasks ? (
                <Box sx={{ width: '80%', color: 'grey.500' }}>
                  <LinearProgress color="inherit" />
                </Box>
              ) : null}
              <div
                className="JOB_TASKS_SIDE_CONTAINER"
                onScroll={this.onJobTasksScroll}
              >
                {this.props.overDueJobTasks
                  ? this.props.overDueJobTasks.length >= 1 &&
                    this.state.jobTaskSearch === '' &&
                    this.state.showUrgentTasksOnTop
                    ? this.props.overDueJobTasks.map((jobtask, index) => (
                        <JobTaskTicket
                          mode={this.props.mode}
                          key={index}
                          reception={true}
                          task={jobtask}
                          viewJobTask={() =>
                            this.handleJobTaskFormOpen(jobtask)
                          }
                          overDue={true}
                          technicians={
                            this.props.teamMembers ? this.props.teamMembers : []
                          }
                          assignTask={(assigned_to) =>
                            this.handleAssignTask(
                              null,
                              null,
                              assigned_to,
                              jobtask
                            )
                          }
                          advanceAssign={() =>
                            this.handleAssignJobOpen('', '', jobtask)
                          }
                        ></JobTaskTicket>
                      ))
                    : null
                  : null}
                {this.props.jobTasks ? (
                  this.props.jobTasks.length >= 1 ? (
                    jobTaskFiltered.map((jobtask, index) => (
                      <JobTaskTicket
                        mode={this.props.mode}
                        key={index}
                        reception={true}
                        task={jobtask}
                        viewJobTask={() => this.handleJobTaskFormOpen(jobtask)}
                        overDue={
                          jobtask.due_date <= moment(new Date()).format() &&
                          jobtask.status.status === 'pending'
                            ? true
                            : false
                        }
                        technicians={
                          this.props.teamMembers ? this.props.teamMembers : []
                        }
                        assignTask={(assigned_to) =>
                          this.handleAssignTask(
                            null,
                            null,
                            assigned_to,
                            jobtask
                          )
                        }
                        advanceAssign={() =>
                          this.handleAssignJobOpen('', '', jobtask)
                        }
                      ></JobTaskTicket>
                    ))
                  ) : this.props.jobTaskLoading ? (
                    <CircularProgress></CircularProgress>
                  ) : null
                ) : this.props.jobTaskLoading ? (
                  <CircularProgress></CircularProgress>
                ) : null}
                {this.state.gettingMoreJobTasks ? (
                  <CircularProgress></CircularProgress>
                ) : null}
              </div>
            </div>

            <Modal
              open={this.state.assignJobOpen}
              onClose={this.handleAssignJobClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <div className="ASSIGN_TASK_MODAL" id="dark">
                <h2>Assign Task</h2>
                <div style={{ width: '300px' }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <MobileDatePicker
                      label="Date"
                      value={this.state.dueDate}
                      onChange={(newValue) => {
                        this.setState({
                          dueDate: newValue,
                          dueDateError: false,
                        });
                      }}
                      InputProps={{
                        className: 'JOB_CARD_INPUT_FIELD',
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          required
                          error={this.state.dueDateError}
                          InputLabelProps={{
                            // shrink: true,
                            className: 'JOB_CARD_INPUT_FIELD_LABEL',
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div style={{ width: '300px' }}>
                  <TextField
                    id="outlined-secondary-3"
                    label="Technician"
                    variant="outlined"
                    select
                    name="taskTechnician"
                    required
                    error={this.state.taskTechnicianError}
                    fullWidth
                    value={this.state.taskTechnician}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'JOB_CARD_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: 'JOB_CARD_INPUT_FIELD_LABEL',
                    }}
                  >
                    {this.props.teamMembers
                      ? this.props.teamMembers.map((member, index) => (
                          <MenuItem key={index} value={member.first_name}>
                            {member.first_name}
                          </MenuItem>
                        ))
                      : null}
                  </TextField>
                </div>
                <div style={{ width: '300px' }}>
                  <TextField
                    id="outlined-secondary-3"
                    label="Priority"
                    variant="outlined"
                    select
                    name="taskPriority"
                    fullWidth
                    required
                    error={this.state.taskPriorityError}
                    value={this.state.taskPriority}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'JOB_CARD_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      shrink: true,
                      className: 'JOB_CARD_INPUT_FIELD_LABEL',
                    }}
                  >
                    {priorities.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
                <div
                  className="SUBMIT_TASK_BUTTON"
                  onClick={this.handleCreateTask}
                >
                  <span>Submit</span>
                </div>
              </div>
            </Modal>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    mode: state.users.theme?.mode,
    jobCardLoading: state.jobCard.loading,
    jobTaskLoading: state.jobTask.loading,
    jobCard: state.jobCard.jobCard,
    jobCards: state.jobCard.jobCards,
    jobTask: state.jobTask.jobTask,
    jobTasks: state.jobTask.jobTasks,
    overDueJobTasks: state.jobTask.overdueJobTasks,
    documents: state.document.documents,
    progress: state.document.progress,
    teamMember: state.teamMember.teamMember,
    teamMembers: state.teamMember.teamMembers,
    memberPermissions: state.users.userDetails
      ? state.users.userDetails.team_member.permissions
      : [],
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobCards: (token) => dispatch(getJobCards(token)),
    getLastNJobCards: (token, N) => dispatch(getLastNJobCards(token, N)),
    getLastNJobTasks: (token, N) => dispatch(getLastNJobTasks(token, N)),
    getJobCard: (token, id) => dispatch(getJobCard(token, id)),
    getActiveTaskDetails: (token, task_id) =>
      dispatch(getJobTask(token, task_id)),
    getJobTasks: (token) => dispatch(getJobTasks(token)),
    getOverdueJobTasks: (token) => dispatch(getOverdueJobTasks(token)),
    getTasksByReception: (token, employee, name) =>
      dispatch(getJobTasksByEmployee(token, employee, name)),
    createTask: (
      token,
      task_number,
      assigned_by,
      assigned_to,
      due_date,
      job_card,
      task_status,
      priority
    ) =>
      dispatch(
        postJobTask(
          token,
          task_number,
          assigned_by,
          assigned_to,
          due_date,
          job_card,
          task_status,
          priority
        )
      ),
    updateJobTask: (
      token,
      job_task_id,
      task_number,
      assigned_by,
      assigned_to,
      due_date,
      job_card,
      task_status,
      priority
    ) =>
      dispatch(
        updateJobTask(
          token,
          job_task_id,
          task_number,
          assigned_by,
          assigned_to,
          due_date,
          job_card,
          task_status,
          priority
        )
      ),
    getTeamMembers: (token, keyword, value) =>
      dispatch(getTeamMembers(token, keyword, value)),
    getNotifications: (token, name) =>
      dispatch(getNotificationsByTarget(token, name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reception);
