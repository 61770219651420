import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  order: null,
  lastOrder: null,
  orders: [],
  periodOrders: [],
  periodOrders30: [],
  periodOrders60: [],
  periodOrders90: [],
  periodOrders120plus: [],
};

const getOrderStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getOrderSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    order: action.payload,
  });
};

const getLastOrderSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    lastOrder: action.payload,
  });
};

const getOrdersSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    orders: action.payload,
  });
};

const getPeriodOrdersSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    periodOrders:
      action.period === 'CURRENT' ? action.payload : state.periodOrders,
    periodOrders30:
      action.period === '30 DAYS' ? action.payload : state.periodOrders30,
    periodOrders60:
      action.period === '60 DAYS' ? action.payload : state.periodOrders60,
    periodOrders90:
      action.period === '90 DAYS' ? action.payload : state.periodOrders90,
    periodOrders120plus:
      action.period === '120+ DAYS'
        ? action.payload
        : state.periodOrders120plus,
  });
};

const getOrderFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ORDER_START:
      return getOrderStart(state, action);

    case actionTypes.GET_LAST_ORDER_SUCCESS:
      return getLastOrderSuccess(state, action);
    case actionTypes.GET_ORDER_SUCCESS:
      return getOrderSuccess(state, action);
    case actionTypes.GET_ORDERS_SUCCESS:
      return getOrdersSuccess(state, action);
    case actionTypes.GET_PERIOD_ORDERS_SUCCESS:
      return getPeriodOrdersSuccess(state, action);
    case actionTypes.GET_ORDER_FAIL:
      return getOrderFaild(state, action);
    default:
      return state;
  }
};

export default orderReducer;
