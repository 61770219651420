import React, { Component } from 'react';
import './styles/job-task-item.css';

export default class JobTaskItem extends Component {
  state = {
    value: '1',
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };
  render() {
    const jobTask = this.props.jobTask;
    return (
      <div className="JOB_TASK_ITEM_CONTAINER">
        <div className="JOB_TASK_ITEM_HEADER">
          <h4>Task Details For :</h4>
          <span>{jobTask.task_number}</span>
        </div>
        <div className="JOB_TASK_ITEM_FEATURES_CONTAINER">
          <div className="JOB_TASK_ITEM_NUMBER">
            <span>Task No</span>
            <p>{jobTask.task_number}</p>
          </div>
          <div className="JOB_TASK_ITEM_ASSIGNED_BY">
            <span>Assigned By</span>
            <p>{jobTask.assigned_by}</p>
          </div>
          <div className="JOB_TASK_ITEM_ASSIGNED_TO">
            <span>Assigned To</span>
            <p>{jobTask.assigned_to}</p>
          </div>
          <div
            className="JOB_TASK_ITEM_STATUS"
            style={{ backgroundColor: jobTask.status.colour }}
          >
            <span>Status</span>
            <p>{jobTask.status.status}</p>
          </div>
          <div className="JOB_TASK_ITEM_PRIORITY">
            <span>Priority</span>
            <p>{jobTask.priority}</p>
          </div>
        </div>
        <div className="JOB_TASK_ITEM_REPORT">
          <span>Report:</span>
          <p>
            {jobTask.job_tech_report[0]
              ? jobTask.job_tech_report[0].report
              : 'No report added'}
          </p>
        </div>
      </div>
    );
  }
}
