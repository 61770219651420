import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles/inventory-manager.css';
import PieChart from './charts/pie-chart/PieChart';
import LineChart from './charts/line-chart/LineChart';
import HistogramChart from './charts/histogram-chart/HistogramChart';
import SuppliersForm from '../../components/inventory/suppliers-form/SuppliersForm';
import AddItemForm from '../../components/inventory/add-item-form/AddItemForm';
import StockForm from '../../components/inventory/stock-form/StockForm';
import BrandsForm from '../../components/inventory/brands-form/BrandsForm';
import CategoryForm from '../../components/inventory/category-form/CategoryForm';
import { checkPermissions } from '../../tools/checkPermissions';

class AccountsManager extends Component {
  state = {
    approveAccess: false,
  };

  componentDidMount() {
    if (this.props.match.params.form == undefined) {
      this.handleAddItemOpen();
    }
    if (this.props.memberPermissions.length > 0) {
      const permissions = checkPermissions(this.props.memberPermissions);
      console.log(!permissions.primaryPermissions.includes('inventory'));
      if (!permissions.primaryPermissions.includes('inventory')) {
        this.handleLoginOpen();
      } else {
        this.setState({ approveAccess: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.memberPermissions.length > 0
    ) {
      const permissions = checkPermissions(this.props.memberPermissions);
      console.log(!permissions.primaryPermissions.includes('inventory'));
      if (!permissions.primaryPermissions.includes('inventory')) {
        this.handleLoginOpen();
      } else {
        this.setState({ approveAccess: true });
      }
    }
  }

  handleLoginOpen = () => {
    this.props.history.push('/login');
    localStorage.setItem('prevPath', '/login');
  };

  handleSuppliersOpen = () => {
    this.props.history.push('/inventory-manager/suppliers');
    localStorage.setItem('prevPath', '/inventory-manager/suppliers');
  };

  handleAddItemOpen = () => {
    this.props.history.push('/inventory-manager/add-item');
    localStorage.setItem('prevPath', '/inventory-manager/add-item');
  };

  handleStockOpen = () => {
    this.props.history.push('/inventory-manager/stock');
    localStorage.setItem('prevPath', '/inventory-manager/stock');
  };

  handleBrandsOpen = () => {
    this.props.history.push('/inventory-manager/brands');
    localStorage.setItem('prevPath', '/inventory-manager/brands');
  };

  handleCategoriesOpen = () => {
    this.props.history.push('/inventory-manager/categories');
    localStorage.setItem('prevPath', '/inventory-manager/categories');
  };

  handleReceptionOpen = () => {
    this.props.history.push('/reception/book-a-device');
    localStorage.setItem('prevPath', 'reception/book-a-device');
  };

  handleAccountManagerOpen = () => {
    this.props.history.push('/accounts-manager/statments');
    localStorage.setItem('prevPath', '/accounts-manager/statments');
  };

  render() {
    const permissions = checkPermissions(this.props.memberPermissions);
    const mode = this.props.mode;
    return (
      <div className="INVENTORY_MANAGER_MAIN_CONTAINER" id={mode}>
        {this.state.approveAccess ? (
          <div className="INVENTORY_MANAGER_CONTAINER">
            <div className="INVENTORY_MANAGER_ACTIONS_CONTAINER">
              <div
                className="INVENTORY_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'add-item'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#ececec' }
                    : {}
                }
                onClick={this.handleAddItemOpen}
              >
                <span>Add</span>
                <span>Item</span>
              </div>
              <div
                className="INVENTORY_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'suppliers'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#ececec' }
                    : {}
                }
                onClick={this.handleSuppliersOpen}
              >
                <span>Suppliers</span>
              </div>
              <div
                className="INVENTORY_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'brands'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#ececec' }
                    : {}
                }
                onClick={this.handleBrandsOpen}
              >
                <span>Brands</span>
              </div>
              <div
                className="INVENTORY_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'categories'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#ececec' }
                    : {}
                }
                onClick={this.handleCategoriesOpen}
              >
                <span>Categories</span>
              </div>
              <div
                className="INVENTORY_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'stock'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#ececec' }
                    : {}
                }
                onClick={this.handleStockOpen}
              >
                <span>Stock</span>
              </div>
              {permissions.primaryPermissions.includes('reception') ? (
                <div
                  className="INVENTORY_MANAGER_ACTIONS_BUTTON_CONTAINER"
                  onClick={this.handleReceptionOpen}
                >
                  <span>Reception</span>
                </div>
              ) : null}
              {permissions.primaryPermissions.includes('accounting') ? (
                <div
                  className="INVENTORY_MANAGER_ACTIONS_BUTTON_CONTAINER"
                  onClick={this.handleAccountManagerOpen}
                >
                  <span>Accounts</span>
                  <span>Manager</span>
                </div>
              ) : null}
            </div>
            <div className="INVENTORY_MANAGER_FORMS_MAIN_CONTAINER">
              <div className="INVENTORY_MANAGER_FORMS_CONTAINER">
                {this.props.match.params.form === 'add-item' ? (
                  <AddItemForm></AddItemForm>
                ) : null}
                {this.props.match.params.form === 'suppliers' ? (
                  <SuppliersForm></SuppliersForm>
                ) : null}
                {this.props.match.params.form === 'brands' ? (
                  <BrandsForm></BrandsForm>
                ) : null}
                {this.props.match.params.form === 'categories' ? (
                  <CategoryForm></CategoryForm>
                ) : null}
                {this.props.match.params.form === 'stock' ? (
                  <StockForm></StockForm>
                ) : null}
              </div>
            </div>
            <div className="INVENTORY_MANAGER_GRAPHS_CONTAINER">
              {/* <div>
                <PieChart
                  data={[
                    {
                      title: 'In Stock',
                      value: 500,
                    },
                    {
                      title: 'Sold',
                      value: 100,
                    },
                    {
                      title: 'Defective',
                      value: 10,
                    },
                  ]}
                  describtion="........."
                ></PieChart>
              </div>

              <div>
                <HistogramChart
                  describtion="............."
                  data={[
                    {
                      day: 'Sat',
                      quantity: 20,
                    },
                    {
                      day: 'Sun',
                      quantity: 20,
                    },
                    {
                      day: 'Mon',
                      quantity: 30,
                    },
                    {
                      day: 'Tus',
                      quantity: 20,
                    },
                    {
                      day: 'Wed',
                      quantity: 20,
                    },
                    {
                      day: 'Thu',
                      quantity: 15,
                    },
                    {
                      day: 'Fri',
                      quantity: 20,
                    },
                  ]}
                ></HistogramChart>
              </div> */}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.users.userDetails,
    mode: state.users.theme?.mode,
    memberPermissions: state.users.userDetails
      ? state.users.userDetails.team_member.permissions
      : [],
  };
};

export default connect(mapStateToProps, null)(withRouter(AccountsManager));
