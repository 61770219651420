import React, { useEffect, useState } from 'react';
import './style.css';
import { Chip, CircularProgress, TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CloseIcon from '@mui/icons-material/Close';

const MemberCard = (props) => {
  const [permission, setPermission] = useState('');
  const [active, setActive] = useState(false);

  useEffect(() => {
    const adjustLoading = () => {
      if (props.loading === false) {
        setActive(false);
      }
    };
    adjustLoading();
  }, [props.loading]);

  const onSelectPermission = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined && option) {
      setPermission(option ? option.permission_title : '');
      props.handleAddPermission(option.id, props.member.id);
      setActive(true);
    } else {
      setPermission('');
    }
  };

  const filterPermissionOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.permission_title,
  });
  return (
    <div className="MEMBER_CARD_CONTAINER">
      <div className="MEMBER_INFO_CONTAINER">
        <div>
          <TextField
            id="card_first_name"
            label="First Name"
            variant="standard"
            value={props.member.first_name}
            InputProps={{
              className: 'DETAIL_FIELD',
            }}
            InputLabelProps={{
              // shrink: true,
              className: 'DETAIL_FIELD_LABEL',
            }}
          ></TextField>
        </div>
        <div>
          <TextField
            id="card_last_name"
            label="Last Name"
            variant="standard"
            value={props.member.last_name}
            InputProps={{
              className: 'DETAIL_FIELD',
            }}
            InputLabelProps={{
              // shrink: true,
              className: 'DETAIL_FIELD_LABEL',
            }}
          ></TextField>
        </div>
        <div>
          <TextField
            id="card_job_title"
            label="Job Title"
            variant="standard"
            value={props.member.job_title}
            InputProps={{
              className: 'DETAIL_FIELD',
            }}
            InputLabelProps={{
              // shrink: true,
              className: 'DETAIL_FIELD_LABEL',
            }}
          ></TextField>
        </div>
        <div>
          <TextField
            id="card_job_email"
            label="Email"
            variant="standard"
            value={props.member.email}
            InputProps={{
              className: 'DETAIL_FIELD',
            }}
            InputLabelProps={{
              // shrink: true,
              className: 'DETAIL_FIELD_LABEL',
            }}
          ></TextField>
        </div>
      </div>
      <div className="MEMBER_PERMISSIONS_CONTAINER">
        <div className="MEMBER_PERMISSIONS_HEADER">
          <h4>Permissions:</h4>
          <h4> Add</h4>
          <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
            <Autocomplete
              id="filter-claims"
              options={props.permissions ? props.permissions : []}
              getOptionLabel={(option) =>
                option.permission_title ? option.permission_title : permission
              }
              filterOptions={filterPermissionOptions}
              sx={{ color: '#fff' }}
              onOpen={() => {
                props.getPermissions();
              }}
              onClose={() => {
                console.log('close');
              }}
              clearIcon={<CloseIcon color="error"></CloseIcon>}
              openOnFocus={true}
              value={permission}
              onChange={(e, option) => onSelectPermission(e, option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Permission"
                  name="permission"
                  fullWidth
                  variant="standard"
                  value={permission}
                  onChange={(e) => setPermission(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/* {props.loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null} */}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    className: 'PERMISSION_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'PERMISSION_INPUT_FIELD_LABEL',
                  }}
                />
              )}
            />
          </div>
        </div>

        <div className="MEMBER_PERMISSIONS">
          {props.member.permissions?.map((permission) => (
            <Chip
              key={`${permission.created_at}_${permission.permission_title}`}
              sx={{ marginInline: '1em', bgcolor: '#bbbbbb' }}
              label={permission.permission_title}
              variant="standard"
              onDelete={() => {
                setActive(true);
                props.handleDeletePermission(permission.id, props.member.id);
              }}
            />
          ))}

          {props.loading && active ? (
            <CircularProgress
              color="inherit"
              size={20}
              sx={{ marginTop: '0.5em' }}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default MemberCard;
