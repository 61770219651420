// This function returns a list of member permissions
export const checkPermissions = (permissions) => {
  let permissions_list = [];
  let primary_permissions_list = [];

  permissions.map((permission, index) => {
    if (!permissions_list.includes(permission.permission_title)) {
      permissions_list.push(permission.permission_title);
    }
    if (
      permission.primary_permission &&
      !primary_permissions_list.includes(
        permission.primary_permission.permission_title
      )
    ) {
      primary_permissions_list.push(
        permission.primary_permission.permission_title
      );
    }
  });

  return {
    primaryPermissions: primary_permissions_list,
    permissions: permissions_list,
  };
};
