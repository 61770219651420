import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getInvoiceStart = () => {
  return {
    type: actionTypes.GET_INVOICE_START,
  };
};

export const getInvoiceSuccess = (data) => {
  return {
    type: actionTypes.GET_INVOICE_SUCCESS,
    payload: data,
  };
};

export const getLastInvoiceSuccess = (data) => {
  return {
    type: actionTypes.GET_LAST_INVOICE_SUCCESS,
    payload: data,
  };
};

export const getInvoicesSuccess = (data) => {
  return {
    type: actionTypes.GET_INVOICES_SUCCESS,
    payload: data,
  };
};

export const getInvoiceFaild = (error) => {
  return {
    type: actionTypes.GET_INVOICE_FAIL,
    error: error,
  };
};

export const getDeleteInvoiceSuccess = () => {
  return {
    type: actionTypes.DELETE_INVOICE_SUCCESS,
  };
};

export const clearInvoiceProps = () => {
  return {
    type: actionTypes.CLEAR_INVOICE_DATA,
  };
};

export const getInvoices = (token) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());
    console.log('getting all Invoices');
    axios
      .get(`${HOST_URL}/invoices/invoice/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getInvoicesSuccess(response.data)))
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};

export const getInvoice = (token, id) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());

    axios
      .get(`${HOST_URL}/invoices/invoice/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getInvoiceSuccess(response.data)))
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};

export const deleteInvoice = (token, id) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());

    axios
      .delete(`${HOST_URL}/invoices/invoice/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getDeleteInvoiceSuccess(response.data)))
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};

export const getInvoiceByInvoiceNumber = (token, invoiceNumber) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());

    axios
      .get(`${HOST_URL}/invoices/invoice/invoice_${invoiceNumber}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getInvoiceSuccess(response.data)))
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};

export const getInvoiceByJobcard = (token, jobCardNumber) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());

    axios
      .get(`${HOST_URL}/invoices/invoice/jobcard_${jobCardNumber}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getInvoiceSuccess(response.data)))
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};

export const getLastInvoice = (token) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());

    axios
      .get(`${HOST_URL}/invoices/invoice/last/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getLastInvoiceSuccess(response.data)))
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};

export const postInvoiceData = (
  token,
  invoice_number,
  jobCardNumber,
  financial_account,
  client,
  claim,
  terms,
  order,
  invoiced_by,
  tax_date,
  due_date,
  pop_email,
  terms_and_conditions,
  note,
  invoice_type
) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());
    let fd = new FormData();
    fd.append('invoice_number', invoice_number);
    fd.append('job_card_number', jobCardNumber);
    fd.append('financial_account', financial_account);
    fd.append('client', client);
    fd.append('claim', claim);
    fd.append('terms', terms);
    fd.append('order', order);
    fd.append('invoiced_by', invoiced_by);
    fd.append('tax_date', tax_date);
    fd.append('due_date', due_date);
    fd.append('pop_email', pop_email);
    fd.append('terms_and_conditions', terms_and_conditions);
    fd.append('invoice_note', note);
    fd.append('invoice_type', invoice_type);

    axios
      .post(`${HOST_URL}/invoices/invoice/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getInvoiceSuccess(response.data));
        // dispatch(getInvoices(token));
      })
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};

export const partialUpdateInvoiceData = (
  token,
  invoice_id,
  invoiceNumber,
  due_date,
  tax_date,
  note,
  terms
) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());
    let fd = new FormData();
    fd.append('invoice_number', invoiceNumber);
    fd.append('due_date', due_date);
    fd.append('tax_date', tax_date);
    fd.append('note', note);
    fd.append('terms', terms);
    axios
      .patch(`${HOST_URL}/invoices/invoice/${invoice_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getInvoiceSuccess(response.data));
      })
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};

export const updateInvoiceData = (
  token,
  invoice_id,
  invoice_number,
  financial_account,
  claim,
  terms,
  order,
  invoiced_by,
  tax_date,
  due_date,
  pop_email,
  terms_and_conditions,
  note,
  invoice_type
) => {
  return (dispatch) => {
    dispatch(getInvoiceStart());
    let fd = new FormData();
    fd.append('invoice_number', invoice_number);
    fd.append('financial_account', financial_account);
    fd.append('claim', claim);
    fd.append('terms', terms);
    fd.append('order', order);
    fd.append('invoiced_by', invoiced_by);
    fd.append('tax_date', tax_date);
    fd.append('due_date', due_date);
    fd.append('pop_email', pop_email);
    fd.append('terms_and_conditions', terms_and_conditions);
    fd.append('invoice_note', note);
    fd.append('invoice_type', invoice_type);

    axios
      .put(`${HOST_URL}/invoices/invoice/${invoice_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getInvoiceSuccess(response.data));
      })
      .catch((error) => dispatch(getInvoiceFaild(error)));
  };
};
