import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  error: null,
  loading: false,
  product: null,
  products: [],
};

const getProductStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getProductSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    product: action.payload,
  });
};

const getProductsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    products: action.payload,
  });
};

const getProductFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PRODUCT_START:
      return getProductStart(state, action);
    case actionTypes.GET_PRODUCT_SUCCESS:
      return getProductSuccess(state, action);
    case actionTypes.GET_PRODUCTS_SUCCESS:
      return getProductsSuccess(state, action);
    case actionTypes.GET_PRODUCT_FAIL:
      return getProductFaild(state, action);
    default:
      return state;
  }
};

export default productReducer;
