import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import {
  postJobDamageReport,
  updateJobDamageReport,
} from '../../../store/actions/jobReport';

class JobDamageReport extends Component {
  state = {
    report: '',
    reportEditing: false,
    showUpdatedReport: false,
    reportPosting: false,
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.jobDamageReport !== prevProps.jobDamageReport &&
      this.state.reportEditing
    ) {
      this.setState({
        reportEditing: false,
        showUpdatedReport: true,
        report: '',
      });
    }
    if (
      this.props.jobDamageReport !== prevProps.jobDamageReport &&
      this.state.reportPosting
    ) {
      this.setState({
        reportPosting: false,
        showUpdatedReport: true,
        report: '',
      });
    }
    if (this.props.activeJobCard !== prevProps.activeJobCard) {
      this.setState({ showUpdatedReport: false, report: '' });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDeleteReport = () => {
    console.log('delete report');
  };

  handleEditReport = () => {
    this.setState({
      reportEditing: true,
      report: this.props.jobDamageReport
        ? this.props.jobDamageReport.report
        : '',
    });
  };

  handleCloseEditing = () => {
    this.setState({
      reportEditing: false,
    });
  };

  handleUpdateReport = () => {
    const { id, author, job_card } =
      this.props.jobCard.job_damage_report[0] !== undefined
        ? this.props.jobCard.job_damage_report[0]
        : this.props.jobDamageReport;
    const report = this.state.report;
    const token = this.props.token;
    this.props.updateJobDamageReport(token, id, job_card, author, report);
  };

  handlePostReport = () => {
    this.setState({ reportPosting: true });
    const author = this.props.userDetails.username;
    const job_card = this.props.jobCard.id;
    const report = this.state.report;
    const token = this.props.token;
    this.props.postJobDamageReport(token, job_card, author, report);
  };

  render() {
    return (
      <div className="WORKSPACE_JOB_REPORT_CONTAINER">
        {this.props.damageReportLoading ? (
          <div className="WORKSPACE_JOB_REPORT_LOADING">
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <>
            {this.props.jobCard.job_damage_report[0] !== undefined ||
            (this.props.jobDamageReport &&
              this.props.jobDamageReport.job_card === this.props.jobCard.id) ? (
              <div className="TECHNICIAN_REPORT">
                <div className="TECHNICIAN_REPORT_HEADER">
                  <span>Report</span>
                  <div className="JOB_REPORT_ACTION_CONTAINER">
                    {this.state.reportEditing ? (
                      <div
                        className="JOB_REPORT_ACTION_SAVE"
                        onClick={this.handleUpdateReport}
                      >
                        <Tooltip title=" Save report">
                          <SaveIcon color="inherit" />
                        </Tooltip>
                      </div>
                    ) : null}
                    {this.state.reportEditing ? (
                      <div className="JOB_REPORT_ACTION_CANCEL_EDIT">
                        <Tooltip title="Cancel Edit">
                          <CancelIcon
                            color="inherit"
                            onClick={this.handleCloseEditing}
                          />
                        </Tooltip>
                      </div>
                    ) : (
                      <div className="JOB_REPORT_ACTION_EDIT">
                        <Tooltip title="Edit Report">
                          <ModeEditOutlineIcon
                            color="inherit"
                            onClick={this.handleEditReport}
                          />
                        </Tooltip>
                      </div>
                    )}
                    <div
                      className="JOB_REPORT_ACTION_DELETE"
                      onClick={this.handleDeleteReport}
                    >
                      <Tooltip title=" Delete Report">
                        <DeleteForeverIcon color="inherit" />
                      </Tooltip>
                    </div>
                  </div>
                </div>

                {this.state.reportEditing ? (
                  <div className="TECHNICIAN_EDIT_REPORT_INPUT_FIELD_CONTAINER">
                    <TextField
                      id="outlined-multiline-static"
                      label="Edit report"
                      multiline
                      rows={5}
                      fullWidth
                      // defaultValue=""
                      variant="outlined"
                      name="report"
                      value={this.state.report}
                      onChange={this.onChange}
                      // color="secondary"
                      type="text"
                      required
                      InputProps={{
                        className: 'JOB_TASK_REPORT_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'JOB_TASK_REPORT_INPUT_FIELD_LABEL',
                      }}
                    ></TextField>
                  </div>
                ) : (
                  <div className="JOB_REPORT_BODY">
                    {this.state.showUpdatedReport ? (
                      <p>
                        {this.props.jobDamageReport
                          ? this.props.jobDamageReport.report
                          : null}
                      </p>
                    ) : (
                      <p>
                        {this.props.jobDamageReport
                          ? this.props.jobDamageReport.report
                          : null}
                      </p>
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className="TECHNICIAN_ADD_REPORT_CONTAINER">
                <div className="TECHNICIAN_ADD_REPORT_INPUT_FIELD_CONTAINER">
                  <TextField
                    id="outlined-multiline-static"
                    label="Damage Report"
                    multiline
                    rows={5}
                    // defaultValue=""
                    variant="outlined"
                    // color="secondary"
                    name="report"
                    value={this.state.report}
                    onChange={this.onChange}
                    type="text"
                    fullWidth
                    required
                    InputProps={{
                      className: 'JOB_TASK_REPORT_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'JOB_TASK_REPORT_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
                <div
                  className="TECHNICIAN_ADD_REPORT_POST_BUTTON"
                  // onClick={this.handlePostReport}
                >
                  {this.state.reportPosting ? (
                    <CircularProgress></CircularProgress>
                  ) : (
                    <IconButton color="inherit" onClick={this.handlePostReport}>
                      <SaveIcon color="inherit" />
                    </IconButton>
                  )}
                </div>
              </div>
            )}
            <div className="TECHNICIAN_DAMAGE_REPORT_FOOTER_CONTAINER">
              <span>
                {this.props.jobCard.job_damage_report[0] !== undefined
                  ? moment(
                      this.props.jobCard.job_damage_report.created
                    ).calendar()
                  : null}
              </span>
            </div>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    jobDamageReport: state.jobReport.jobDamageReport,
    activeJobCard: state.jobCard.jobCard,
    damageReportLoading: state.damageReport.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateJobDamageReport: (token, job_report_id, job_card, author, report) =>
      dispatch(
        updateJobDamageReport(token, job_report_id, job_card, author, report)
      ),
    postJobDamageReport: (token, job_card, author, report) =>
      dispatch(postJobDamageReport(token, job_card, author, report)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobDamageReport);
