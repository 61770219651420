import React, { Component } from 'react';
import './styles/company-form.css';
import TextField from '@mui/material/TextField';

export default class AccountingForm extends Component {
  state = {
    bank: '',
    account_name: '',
    account_number: '',
    branch_code: '',
  };

  componentDidMount() {
    const savedData = JSON.parse(localStorage.getItem('accounting_form'));
    if (savedData) {
      this.setState({ ...savedData });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () =>
      localStorage.setItem('accounting_form', JSON.stringify(this.state))
    );
  };
  render() {
    return (
      <div className="COMPAN_DETAILS_FORMS_CONTAINER">
        <div className="COMPANY_FORM_CONTAINER">
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="bank"
              label="Bank name"
              variant="outlined"
              fullWidth
              name="bank"
              value={this.state.bank}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="account-name"
              label="Account name"
              variant="outlined"
              fullWidth
              name="account_name"
              value={this.state.account_name}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="account-number"
              label="Account number"
              variant="outlined"
              fullWidth
              name="account_number"
              value={this.state.account_number}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="COMPANY_INPUT_FIELD_CONTAINER">
            <TextField
              id="branch-code"
              label="Branch code"
              variant="outlined"
              fullWidth
              name="branch_code"
              value={this.state.branch_code}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'COMPANY_INPUT_FIELD',
              }}
              InputLabelProps={{
                className: 'COMPANY_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
        </div>
      </div>
    );
  }
}
