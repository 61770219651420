import axios from 'axios';
import * as actionTypes from './actionTypes';
import { HOST_URL } from '../../settings';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (token) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    token: token,
  };
};

export const userDetails = (data) => {
  return {
    type: actionTypes.AUTH_DETAILS_SUCCESS,
    payload: data,
  };
};

export const profileImageSuccess = (data) => {
  return {
    type: actionTypes.AUTH_PROFILE_PIC_SUCCESS,
    payload: data,
  };
};

export const setModeSuccess = (data) => {
  return {
    type: actionTypes.UI_MODE_SUCCESS,
    payload: data,
  };
};

export const resetPasswordResponse = (data) => {
  return {
    type: actionTypes.RESET_PASSWORD_RESPONSE,
    payload: data,
  };
};

export const confirmPasswordChangeResponse = (data) => {
  return {
    type: actionTypes.CHANGE_PASSWORD_RESPONSE,
    payload: data,
  };
};

export const profilePasswordChangeResponse = (data) => {
  return {
    type: actionTypes.PROFILE_CHANGE_PASSWORD_RESPONSE,
    payload: data,
  };
};

export const getVerficationInfoSuccess = (data) => {
  return {
    type: actionTypes.AUTH_VERIFICATION_INFO_SUCCESS,
    payload: data,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  userLogout();
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const checkAuthTimeout = (expirationTime) => {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000);
  };
};

export const userLogout = () => {
  return (dispatch) => {
    axios
      .get(`${HOST_URL}/dj-rest-auth/logout/`)
      .then((res) => {
        console.log('');
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const getUserDetails = (token) => {
  return (dispatch) => {
    console.log('getting user details');
    axios
      .get(`${HOST_URL}/dj-rest-auth/user/`, {
        headers: { Authorization: 'Token ' + token },
      })
      .then((res) => {
        dispatch(userDetails(res.data));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const defineOwner = (token) => {
  return (dispatch) => {
    console.log('getting user details');
    axios
      .patch(
        `${HOST_URL}/dj-rest-auth/user/`,
        { is_owner: true },
        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then((res) => {
        dispatch(userDetails(res.data));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const authLogin = (username, password) => {
  return (dispatch) => {
    dispatch(authStart());
    const fd = new FormData();
    fd.append('username', username);
    fd.append('password', password);
    axios
      .post(`${HOST_URL}/dj-rest-auth/login/`, fd)
      .then((res) => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 43200 * 1000);
        localStorage.setItem('token', token);
        localStorage.setItem('expirationDate', expirationDate);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(43200));
        dispatch(getUserDetails(token));
      })
      .catch((res) => {
        dispatch(authFail(res));
      });
  };
};

export const authSignup = (username, email, password1, password2, key) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(`${HOST_URL}/dj-rest-auth/registration/`, {
        username,
        email,
        password1,
        password2,
        key,
      })
      .then((res) => {
        const token = res.data.key;
        const expirationDate = new Date(new Date().getTime() + 43200 * 1000);
        localStorage.setItem('token', token);
        localStorage.setItem('expirationDate', expirationDate);
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout(43200));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const authCheckState = () => {
  return (dispatch) => {
    const token = localStorage.getItem('token');
    if (token === undefined) {
      dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(logout);
      } else {
        dispatch(authSuccess(token));
        dispatch(
          checkAuthTimeout(
            (expirationDate.getTime() - new Date().getTime()) / 1000
          )
        );
      }
    }
  };
};

export const putUserDetails = (
  token,
  userName,
  firstName,
  lastName,
  phoneNumber
) => {
  return (dispatch) => {
    axios
      .put(
        `${HOST_URL}/dj-rest-auth/user/`,
        {
          username: userName,
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
        },
        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then((res) => {
        dispatch(userDetails(res.data));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const addUserKey = (token, key) => {
  return (dispatch) => {
    axios
      .patch(
        `${HOST_URL}/dj-rest-auth/user/`,
        {
          key,
        },
        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then((res) => {
        dispatch(userDetails(res.data));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const putUserLocDetails = (
  token,
  userName,
  country,
  city,
  address1,
  address2,
  zipCode
) => {
  return (dispatch) => {
    axios
      .put(
        `${HOST_URL}/dj-rest-auth/user/`,
        {
          username: userName,
          country: country,
          city: city,
          address1: address1,
          address2: address2,
          zip_code: zipCode,
        },
        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then((res) => {
        dispatch(userDetails(res.data));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const putUserMeDetails = (token, userName, whoIam) => {
  return (dispatch) => {
    axios
      .put(
        `${HOST_URL}/dj-rest-auth/user/`,
        {
          username: userName,
          about_me: whoIam,
        },
        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then((res) => dispatch(userDetails(res.data)))
      .catch((err) => dispatch(authFail(err)));
  };
};

export const getUserProfileImage = (token, user_id) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(`${HOST_URL}/users/profile-image/user-${user_id}/`, {
        headers: { Authorization: 'Token ' + token },
      })
      .then((res) => dispatch(profileImageSuccess(res.data)))
      .catch((err) => dispatch(authFail(err)));
  };
};

export const postUserProfileImage = (token, picture) => {
  return (dispatch) => {
    dispatch(authStart());
    const fd = new FormData();
    fd.append('picture', picture, picture.name);

    axios
      .post(`${HOST_URL}/users/member-picture/`, fd, {
        headers: { Authorization: 'Token ' + token },
      })
      .then((res) => dispatch(getUserDetails(token)))
      .catch((err) => dispatch(authFail(err)));
  };
};

export const updateUserProfileImage = (token, image_id, picture) => {
  return (dispatch) => {
    dispatch(authStart());
    const fd = new FormData();
    fd.append('picture', picture, picture.name);

    axios
      .patch(`${HOST_URL}/users/member-picture/${image_id}/`, fd, {
        headers: { Authorization: 'Token ' + token },
      })
      .then((res) => dispatch(getUserDetails(token)))
      .catch((err) => dispatch(authFail(err)));
  };
};

export const deleteUserProfileImage = (token, imageID) => {
  return (dispatch) => {
    dispatch(authStart());

    axios
      .delete(`${HOST_URL}/users/member-picture/${imageID}/`, {
        headers: { Authorization: 'Token ' + token },
      })
      .then((res) => dispatch(getUserDetails(token)))
      .catch((err) => dispatch(authFail(err)));
  };
};

// Theme

export const getTheme = (token) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(`${HOST_URL}/users/theme/`, {
        headers: { Authorization: 'Token ' + token },
      })
      .then((res) => {
        dispatch(setModeSuccess(res.data));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const setMode = (token, id, mode) => {
  return (dispatch) => {
    dispatch(authStart());
    let data = new FormData();
    data.append('mode', mode);
    axios
      .patch(`${HOST_URL}/users/theme/${id}/`, data, {
        headers: { Authorization: 'Token ' + token },
      })
      .then((res) => {
        dispatch(setModeSuccess(res.data));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

// Email Verification Code

export const postVerficationInfo = (
  token,
  user_id,
  verification_code,
  verified
) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        `${HOST_URL}/users/user-verify-info/`,
        {
          user_id,
          verification_code,
          verified,
        },
        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then(() => {
        dispatch(getVerficationInfo(token, user_id));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const getVerficationInfo = (token, user_id) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .get(
        `${HOST_URL}/users/user-verify-info/user-${user_id}/`,

        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then((res) => {
        dispatch(getVerficationInfoSuccess(res.data));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

export const putVerficationInfo = (
  verificationInfoID,
  token,
  user_id,
  verification_code,
  verified
) => {
  return (dispatch) => {
    dispatch(authStart());
    const fd = new FormData();
    fd.append('user_id', user_id);
    fd.append('verification_code', verification_code);
    fd.append('verified', verified);

    axios
      .put(
        `${HOST_URL}/users/user-verify-info/${verificationInfoID}/`,

        fd,
        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then(() => {
        dispatch(getVerficationInfo(token, user_id));
      })
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

// Send Email function

export const sendVerifyEmail = (
  token,
  subject,
  verification_code,
  recipient
) => {
  return (dispatch) => {
    dispatch(authStart());
    axios
      .post(
        `${HOST_URL}/handle-email/verify-email`,
        {
          subject: subject,
          verification_code: verification_code,
          recipient: recipient,
        },
        {
          headers: { Authorization: 'Token ' + token },
        }
      )
      .then(console.log('email sent'))
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};

// Mark-1 Change Password

export function changePassword(token, newPassword1, newPassword2, oldPassword) {
  let fd = new FormData();
  fd.append('new_password1', newPassword1);
  fd.append('new_password2', newPassword2);
  fd.append('old_password', oldPassword);

  return (dispatch) => {
    axios
      .post(`${HOST_URL}/dj-rest-auth/password/change/`, fd, {
        headers: {
          authorization: 'Token ' + token,
        },
      })
      .then((response) => {
        dispatch(profilePasswordChangeResponse(response));
      })
      .catch((error) => {
        dispatch(profilePasswordChangeResponse(error));
      });
  };
}

// Mark-2 Reset Password

export function resetPassword(email) {
  let fd = new FormData();
  fd.append('email', email);

  return (dispatch) => {
    axios
      .post(`${HOST_URL}/dj-rest-auth/password/reset/`, fd, {
        headers: {
          withCredentials: true,
          cookie: document.cookie,
        },
      })
      .then((response) => {
        dispatch(resetPasswordResponse(response));
      })
      .catch((error) => {
        dispatch(resetPasswordResponse(error));
      });
  };
}

export function confirmPasswordChange(new_password1, new_password2, props) {
  const { uid, token } = props.match.params;
  let fd = new FormData();
  fd.append('uid', uid);
  fd.append('token', token);
  fd.append('new_password1', new_password1);
  fd.append('new_password2', new_password2);

  return (dispatch) => {
    axios
      .post(`${HOST_URL}/dj-rest-auth/password/reset/confirm/`, fd)
      .then((response) => {
        dispatch(confirmPasswordChangeResponse(response));
      })
      .catch((error) => {
        dispatch(confirmPasswordChangeResponse(error));
      });
  };
}

// Mark-3 Activate User Account

export function activateUserAccount(props) {
  const { key } = props.match.params;
  let fd = new FormData();
  fd.append('key', key);
  return (dispatch) => {
    axios
      .post(`${HOST_URL}/user/dj-rest-auth-registration/verify-email/`, fd)
      .then((response) => {
        console.log(response);

        props.history.push('/account/account-activation/done');
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

/*
export const createSendInvoice = (
  token,
  invoice_number,
  username,
  user_email,
  amount_due,
  date,
  products,
  total_amount,
  taxes,
  balance_due
) => {
  return (dispatch) => {
    axios
      .post(
        `${HOST_URL}/handle-email/invoice`,
        {
          invoice_number,
          username,
          user_email,
          amount_due,
          date,
          products,
          total_amount,
          taxes,
          balance_due,
        },
        { headers: { Authorization: 'Token ' + token } }
      )
      .then((res) => dispatch(invoice(res.data)))
      .catch((err) => {
        dispatch(authFail(err));
      });
  };
};
*/
