import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './styles/dashboard-l-navigation-bar.css';

class DashboardLNavigationBar extends Component {
  handleCompanyDetailsOpen = () => {
    this.props.history.push('/dashboard/company-details');
    localStorage.setItem('prevPath', '/dashboard/company-details');
  };
  handleTeamsOpen = () => {
    this.props.history.push('/dashboard/teams');
    localStorage.setItem('prevPath', '/dashboard/teams');
  };
  handleAnalysisOpen = () => {
    this.props.history.push('/dashboard/analysis');
    localStorage.setItem('prevPath', '/dashboard/analysis');
  };
  handleClinetsOpen = () => {
    this.props.history.push('/dashboard/clients');
    localStorage.setItem('prevPath', '/dashboard/clients');
  };
  handleAccountManagerOpen = () => {
    this.props.history.push('/accounts-manager');
    localStorage.setItem('prevPath', '/accounts-manager');
  };
  handleInventoryOpen = () => {
    this.props.history.push('/inventory-manager');
    localStorage.setItem('prevPath', '/inventory-manager');
  };
  handleReceptionOpen = () => {
    this.props.history.push('/reception/book-a-device');
    localStorage.setItem('prevPath', 'reception/book-a-device');
  };
  render() {
    const mode = this.props.mode;
    return (
      <div className="RECEPTION_LEFT_SIDE_BAR_CONTAINER">
        <div
          className="RECEPTION_ACTION_CONTAINER"
          style={{ marginBottom: '1em' }}
        >
          <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
            onClick={this.handleCompanyDetailsOpen}
            style={
              this.props.match.params.form === 'company-details'
                ? mode === 'dark'
                  ? { background: '#233044' }
                  : { background: '#ececec' }
                : {}
            }
          >
            <span>Company</span>
          </div>
          <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
            onClick={this.handleTeamsOpen}
            style={
              this.props.match.params.form === 'teams'
                ? mode === 'dark'
                  ? { background: '#233044' }
                  : { background: '#ececec' }
                : {}
            }
          >
            <span>Teams</span>
          </div>
          <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
            onClick={this.handleAnalysisOpen}
            style={
              this.props.match.params.form === 'analysis'
                ? mode === 'dark'
                  ? { background: '#233044' }
                  : { background: '#ececec' }
                : {}
            }
          >
            <span>Insights</span>
          </div>
          {/* <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"

            // onClick={this.handleCreateDamageReportOpen}
          >
            <span>Finance</span>
          </div> */}
          <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
            onClick={this.handleClinetsOpen}
            style={
              this.props.match.params.form === 'clients'
                ? mode === 'dark'
                  ? { background: '#233044' }
                  : { background: '#ececec' }
                : {}
            }
          >
            <span>Clients</span>
          </div>
          {/* <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
            // onClick={this.handleClinetsOpen}
          >
            <span>Peformance</span>
          </div> */}

          <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
            onClick={this.handleAccountManagerOpen}
          >
            <span>Accounts</span>
          </div>

          <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
            onClick={this.handleInventoryOpen}
          >
            <span>Inventory</span>
          </div>
          <div
            className="RECEPTION_NAVIGATION_BUTTON_CONTAINER"
            onClick={this.handleReceptionOpen}
          >
            <span>Reception</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(DashboardLNavigationBar);
