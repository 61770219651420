import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  teamMember: null,
  teamMembers: [],
  permissions: null,
};

const getTeamMemberStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getTeamMemberSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    teamMember: action.payload,
  });
};

const getAllPermissionsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    permissions: action.payload,
  });
};

const getTeamMembersSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    teamMembers: action.payload,
  });
};

const getTeamMemberFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const teamMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TEAM_MEMBER_START:
      return getTeamMemberStart(state, action);
    case actionTypes.GET_TEAM_MEMBER_SUCCESS:
      return getTeamMemberSuccess(state, action);
    case actionTypes.GET_ALL_PERMISSIONS_SUCCESS:
      return getAllPermissionsSuccess(state, action);
    case actionTypes.GET_TEAM_MEMBERS_SUCCESS:
      return getTeamMembersSuccess(state, action);
    case actionTypes.GET_TEAM_MEMBER_FAIL:
      return getTeamMemberFaild(state, action);
    default:
      return state;
  }
};

export default teamMemberReducer;
