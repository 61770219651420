import React from 'react';
import './styles/job-card-ticket.css';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
// import PageviewIcon from '@mui/icons-material/Pageview';
import Link from '@mui/material/Link';
import PrintIcon from '@mui/icons-material/Print';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import RequestQuoteOutlinedIcon from '@mui/icons-material/RequestQuoteOutlined';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { HOST_URL } from '../../settings';

const JobCardTicket = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const open = Boolean(anchorEl);
  const openE2 = Boolean(anchorE2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickE2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseE2 = () => {
    setAnchorE2(null);
  };

  const openAdvancedAssign = () => {
    props.advanceAssign();
  };

  return (
    <div className="JOB_CARD_TICKET_CONTAINER">
      <div className="JOB_CARD_TICKET_HEADER">
        <div>
          <span>Job Card No : </span>
        </div>
        <div>
          <span>{props.jobCard ? props.jobCard.job_card_number : ''}</span>
        </div>
      </div>
      <div className="JOB_CARD_TICKET_BODY">
        {props.allowInvoiceQuotaion ? (
          <Avatar sx={{ bgcolor: '#152A38' }} onClick={handleClickE2}>
            <MoreHorizIcon />
          </Avatar>
        ) : null}
        <Avatar sx={{ bgcolor: '#EEEEEE' }}>
          <Link
            underline="none"
            href={`${HOST_URL}/booking/job-card-to-pdf/${props.jobCard.id}/${props.memberKey}/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <PrintIcon sx={{ color: '#010101' }} />
          </Link>
        </Avatar>
        <Avatar sx={{ bgcolor: '#00ADB5' }} onClick={props.editJobCard}>
          <EditIcon />
        </Avatar>
        <Avatar sx={{ bgcolor: '#152A38' }} onClick={handleClick}>
          <AssignmentIcon />
        </Avatar>
        {props.jobCard.task ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              color: '#519872',
            }}
          >
            <Tooltip title={props.jobCard.task.assigned_to}>
              <AssignmentTurnedInIcon></AssignmentTurnedInIcon>
            </Tooltip>
          </div>
        ) : null}
      </div>
      <div className="JOB_CARD_TICKET_FOOTER">
        <div>
          <span>{moment(props.jobCard.created).calendar()}</span>
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {props.technicians.map((tech, index) => (
          <MenuItem
            key={index}
            onClick={() => props.assignTask(tech.first_name)}
          >
            <Avatar /> {tech.first_name}
          </MenuItem>
        ))}
        <Divider />

        <MenuItem onClick={openAdvancedAssign}>
          <ListItemIcon>
            <AddCircleIcon fontSize="medium" />
          </ListItemIcon>
          Add More Parameters
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorE2}
        open={openE2}
        onClose={handleCloseE2}
        onClick={handleCloseE2}
        PaperProps={{
          elevation: 0,

          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            borderRadius: 20,
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
          sx={{ minWidth: 150 }}
          onClick={() => props.openInvoiceForm()}
        >
          <RequestQuoteIcon sx={{ marginRight: 3 }}></RequestQuoteIcon> Invoice
        </MenuItem>

        <Divider />

        <MenuItem onClick={() => props.openQuotationForm()}>
          <RequestQuoteOutlinedIcon
            sx={{ marginRight: 3 }}
          ></RequestQuoteOutlinedIcon>{' '}
          Quotation
        </MenuItem>
      </Menu>
    </div>
  );
};

export default JobCardTicket;
