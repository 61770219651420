import React, { useState } from 'react';
import {
  Autocomplete,
  Button,
  CircularProgress,
  TextField,
  createFilterOptions,
} from '@mui/material';
import Close from '@mui/icons-material/Close';

const MemberForm = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [email, setEmail] = useState('');
  const [branch, setBranch] = useState('');
  const [branchObj, setBranchObj] = useState(null);

  const filterBranchOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.name,
  });

  const onSelectBranch = (e, option) => {
    if (e.target.innerText !== undefined && option) {
      setBranchObj(option);
      setBranch(option ? option.name : '');
    } else {
      setBranch('');
      setBranchObj(null);
    }
  };

  const validateMemberInfo = () => {
    if (
      firstName !== '' &&
      lastName !== '' &&
      jobTitle !== '' &&
      email !== '' &&
      branch !== ''
    ) {
      return true;
    } else {
      return false;
    }
  };

  const saveMember = () => {
    const valid = validateMemberInfo();
    if (valid) {
      props.handleAddNewMember(firstName, lastName, jobTitle, email, branchObj);
    }
  };

  return (
    <div className="MEMBER_CARD_CONTAINER">
      <div className="MEMBER_INFO_CONTAINER">
        <div>
          <TextField
            id="first_name"
            label="First Name"
            variant="standard"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            InputProps={{
              className: 'DETAIL_FIELD',
            }}
            InputLabelProps={{
              // shrink: true,
              className: 'DETAIL_FIELD_LABEL',
            }}
          ></TextField>
        </div>
        <div>
          <TextField
            id="last_name"
            label="Last Name"
            variant="standard"
            value={lastName}
            onChange={(e) => setlastName(e.target.value)}
            InputProps={{
              className: 'DETAIL_FIELD',
            }}
            InputLabelProps={{
              // shrink: true,
              className: 'DETAIL_FIELD_LABEL',
            }}
          ></TextField>
        </div>
        <div>
          <TextField
            id="job_title"
            label="Job Title"
            variant="standard"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            InputProps={{
              className: 'DETAIL_FIELD',
            }}
            InputLabelProps={{
              // shrink: true,
              className: 'DETAIL_FIELD_LABEL',
            }}
          ></TextField>
        </div>
        <div>
          <TextField
            id="card_job_email"
            label="Email"
            variant="standard"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              className: 'DETAIL_FIELD',
            }}
            InputLabelProps={{
              // shrink: true,
              className: 'DETAIL_FIELD_LABEL',
            }}
          ></TextField>
        </div>
      </div>
      <div className="MEMBER_PERMISSIONS_CONTAINER">
        <div className="MEMBER_INFO_CONTAINER">
          <div>
            <Autocomplete
              id="filter-claims"
              options={props.branches ? props.branches : []}
              getOptionLabel={(option) => (option.name ? option.name : branch)}
              filterOptions={filterBranchOptions}
              sx={{ color: '#fff', mt: 3 }}
              onOpen={() => {
                props.getBranches();
              }}
              onClose={() => {
                console.log('close');
              }}
              clearIcon={<Close color="error"></Close>}
              openOnFocus={true}
              value={branch}
              onChange={(e, option) => onSelectBranch(e, option)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Branch"
                  name="branch"
                  fullWidth
                  variant="standard"
                  value={branch}
                  onChange={(e) => setBranch(e.target.value)}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {/* {props.loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null} */}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className="MEMBER_PERMISSIONS_CONTAINER">
        <div className="MEMBER_INFO_CONTAINER">
          {props.loading ? (
            <CircularProgress></CircularProgress>
          ) : (
            <Button
              variant="contained"
              size="small"
              sx={{ ml: 2, mt: 3 }}
              onClick={saveMember}
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberForm;
