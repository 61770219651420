import * as React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function StatementTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.orders.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer
      component={'div'}
      sx={{
        borderRadius: '10px',
      }}
    >
      <Table
        sx={{
          minWidth: 500,
          bgcolor: '#0b0f19',
          borderRadius: 10,
        }}
        aria-label="custom pagination table"
      >
        <TableHead sx={{ bgcolor: '#111827' }}>
          <TableRow>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Order No
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Date
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Invoice No
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Amount
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Credit
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Allocated
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Balance
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody
          sx={
            props.mode === 'dark'
              ? { bgcolor: '#183151' }
              : { bgcolor: '#c7c7c7' }
          }
        >
          {(rowsPerPage > 0
            ? props.orders.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
            : props.orders
          ).map((order, index) => (
            <TableRow key={index}>
              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 80, color: '#dddddd' }
                    : { width: 80, color: '#1c2d43' }
                }
                align="left"
              >
                #{order.order_number}
              </TableCell>
              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 100, color: '#dddddd' }
                    : { width: 100, color: '#1c2d43' }
                }
                align="left"
              >
                {moment(order.created_at).calendar()}
              </TableCell>

              <TableCell
                style={
                  props.mode === 'dark'
                    ? { width: 100, color: '#dddddd' }
                    : { width: 100, color: '#1c2d43' }
                }
                align="center"
              >
                {order.invoice ? order.invoice : 'None'}
              </TableCell>
              {/* <TableCell
                style={
                  order.status === 'paid'
                    ? { width: 160, color: 'red' }
                    : { width: 160, color: '#dddddd' }
                }
                align="center"
              >
                {order.status === 'paid' ? (
                  <>
                    -&nbsp;
                    {order.grand_total}
                  </>
                ) : (
                  order.grand_total
                )}
              </TableCell> */}
              <TableCell
                align="center"
                style={
                  props.mode === 'dark'
                    ? { color: '#dddddd' }
                    : { color: '#1c2d43' }
                }
              >
                {order.grand_total}
              </TableCell>
              <TableCell
                align="center"
                style={
                  props.mode === 'dark'
                    ? { color: '#dddddd' }
                    : { color: '#1c2d43' }
                }
              >
                {order.status === 'credited' ? order.grand_total : 0.0}
              </TableCell>
              <TableCell
                align="center"
                style={
                  props.mode === 'dark'
                    ? { color: '#dddddd' }
                    : { color: '#1c2d43' }
                }
              >
                {order.allocated_amount}
              </TableCell>

              <TableCell
                align="center"
                style={
                  props.mode === 'dark'
                    ? { color: '#dddddd' }
                    : { color: '#1c2d43' }
                }
              >
                {order.status === 'credited' ? (
                  <span style={{ color: 'red' }}>
                    -&nbsp; {order.grand_total}
                  </span>
                ) : (
                  parseFloat(order.grand_total).toFixed(2)
                )}
              </TableCell>
              {/* <TableCell style={{ width: 160, color: '#dddddd' }} align="right">
                <IconButton
                  aria-label="edit"
                  sx={{ color: '#00A8CC' }}
                  onClick={() => props.editClient(order)}
                >
                  <EditOutlinedIcon color="inherit" />
                </IconButton>
                <IconButton aria-label="delete" sx={{ color: '#F05454' }}>
                  <DeleteIcon color="inherit" />
                </IconButton>
              </TableCell> */}
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter sx={{ bgcolor: '#bbbbbb' }}>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={7}
              count={props.orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
