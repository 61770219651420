import React, { Component } from 'react';
import ClientForm from '../../client-form/ClientForm';
import Header from '../header/Header';

export default class Clinets extends Component {
  render() {
    return (
      <div className="DASHBOARD_WORKSAPCE_SUB_CONTAINER">
        <Header
          title="Company Clients"
          description="View and control client's info."
        />
        <ClientForm></ClientForm>
      </div>
    );
  }
}
