import * as actionTypes from '../actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../../settings';

export const getBrandStart = () => {
  return {
    type: actionTypes.GET_BRAND_START,
  };
};

export const getBrandSuccess = (data) => {
  return {
    type: actionTypes.GET_BRAND_SUCCESS,
    payload: data,
  };
};

export const getBrandsSuccess = (data) => {
  return {
    type: actionTypes.GET_BRANDS_SUCCESS,
    payload: data,
  };
};

export const getBrandFaild = (error) => {
  return {
    type: actionTypes.GET_BRAND_FAIL,
    error: error,
  };
};

export const getBrands = (token) => {
  return (dispatch) => {
    dispatch(getBrandStart());
    console.log('getting all Brands');
    axios
      .get(`${HOST_URL}/brands/brand/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getBrandsSuccess(response.data)))
      .catch((error) => dispatch(getBrandFaild(error)));
  };
};

export const getBrand = (token, id) => {
  return (dispatch) => {
    dispatch(getBrandStart());

    axios
      .get(`${HOST_URL}/brands/brand/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getBrandSuccess(response.data)))
      .catch((error) => dispatch(getBrandFaild(error)));
  };
};

export const updateBrand = (token, brandID, name, summary, content) => {
  return (dispatch) => {
    dispatch(getBrandStart());
    let fd = new FormData();
    fd.append('name', name);
    fd.append('summary', summary);
    fd.append('content', content);

    axios
      .patch(`${HOST_URL}/brands/brand/${brandID}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getBrandSuccess(response.data));
        dispatch(getBrands(token));
      })
      .catch((error) => dispatch(getBrandFaild(error)));
  };
};

export const deleteBrand = (token, brandID) => {
  return (dispatch) => {
    dispatch(getBrandStart());
    axios
      .delete(`${HOST_URL}/brands/brand/${brandID}/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getBrandSuccess(response.data));
        dispatch(getBrands(token));
      })
      .catch((error) => dispatch(getBrandFaild(error)));
  };
};

export const saveBrand = (token, name, summary, content) => {
  return (dispatch) => {
    dispatch(getBrandStart());
    let fd = new FormData();
    fd.append('name', name);
    fd.append('summary', summary);
    fd.append('content', content);

    axios
      .post(`${HOST_URL}/brands/brand/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getBrandSuccess(response.data));
        dispatch(getBrands(token));
      })
      .catch((error) => dispatch(getBrandFaild(error)));
  };
};
