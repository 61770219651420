import React, { Component } from 'react';
import './styles/damage-report.css';
import { connect } from 'react-redux';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Backdrop from '@mui/material/Backdrop';
import CloseIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import CircularProgress from '@mui/material/CircularProgress';
import JobCardTable from './job-card-table/JobCardTable';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getClients } from '../../store/actions/client';
import {
  getClaimByNumber,
  getClaims,
  getInsurances,
} from '../../store/actions/insurance';
import {
  getDamageReportByClaim,
  postDamageReportData,
  updateDamageReportData,
} from '../../store/actions/damageReport';
import { HOST_URL } from '../../settings';

class DamageReport extends Component {
  state = {
    reportNumber: '',
    date: new Date(),
    customer: '',
    insuredCustomer: '',
    insuranceClaimNumber: '',
    insuredItem: '',
    accountNumber: '',
    orderNumber: '',
    generatedBy: '',
    clientName: '',
    claim: null,
    jobCards: [],
    selectedClient: null,
    loadingClients: false,
    loadingInsurances: false,
    insuranceCompany: '',
    selectedInsurance: null,
    generateReportStart: false,
    openMessageModal: false,
    messageModalMessageTitle: '',
    messageModalMessageBody: '',
  };

  componentDidUpdate(prevProps) {
    if (this.props.clients !== prevProps.clients) {
      this.setState({ loadingClients: false });
    }
    if (this.props.insuranceCompanies !== prevProps.insuranceCompanies) {
      this.setState({ loadingInsurances: false });
    }
    if (this.props.claims !== prevProps.claims) {
      this.setState({ loadingClaims: false });
    }
    if (this.props.damageReport !== prevProps.damageReport) {
      this.setState({ clientName: this.props.damageReport.client.client_name });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleMessageClose = () => {
    this.setState({ openMessageModal: false });
  };

  handleMessageOpen = (title, body) => {
    this.setState({
      messageModalMessageTitle: title,
      messageModalMessageBody: body,
      openMessageModal: true,
    });
  };

  getClients = () => {
    this.setState({ loadingClients: true });
    const token = this.props.token;
    this.props.getClients(token);
  };

  onSelectClient = (e, option) => {
    console.log(option);

    if (e.target.innerText !== undefined) {
      this.setState({
        clientName: option ? option.client_name : '',
        selectedClient: option ? option : null,
      });
    } else {
      this.setState({
        clientName: '',
      });
    }
  };

  getInsuranceCompanies = () => {
    this.setState({ loadingInsurances: true });
    const token = this.props.token;
    this.props.getInsurances(token);
  };

  onSelectInsurance = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        insuranceCompany: option ? option.insurance_company_name : '',
        selectedInsurance: option ? option : null,
      });
    } else {
      this.setState({
        insuranceCompany: '',
      });
    }
  };

  getInsuranceClaims = () => {
    this.setState({ loadingClaims: true });
    const token = this.props.token;
    this.props.getClaims(token);
  };

  onSelectClaim = (e, option) => {
    console.log(option);

    if (e.target.innerText !== undefined) {
      this.setState({
        insuranceClaimNumber: option ? option.claim_number : '',
        claimTech: option ? option.claim_consultant : '',
        insuranceContactNumber: option ? option.consultant_contact_number : '',
        insuranceCompany: option
          ? option.insurance_company.insurance_company_name
          : '',
        jobCards: option ? option.job_card : [],
        reportNumber: option.damage_report[0] ? option.damage_report[0] : '',
      });
      if (option) {
        this.props.getDamageReportByClaim(
          this.props.token,
          option.claim_number
        );
        this.props.getSelectedClaim(this.props.token, option.claim_number);
      }
    } else {
      this.setState({
        insuranceClaimNumber: '',
        claimTech: '',
        insuranceContactNumber: '',
        insuranceCompany: '',
        reportNumber: '',
      });
    }
  };

  validateRegisterReportFields = () => {
    const { reportNumber, insuranceCompany, insuranceClaimNumber, clientName } =
      this.state;

    if (
      reportNumber === '' ||
      insuranceCompany === '' ||
      insuranceClaimNumber === '' ||
      clientName === ''
    ) {
      return false;
    } else {
      return true;
    }
  };

  handleRegisterDamageReport = () => {
    const valid = this.validateRegisterReportFields();
    if (
      valid &&
      this.props.selectedClaim &&
      this.props.selectedClaim[0] &&
      this.props.selectedClaim[0].damage_report[0] === undefined
    ) {
      const token = this.props.token;
      const damage_report_number = this.state.reportNumber;
      const claim = this.state.insuranceClaimNumber;
      const client = this.state.selectedClient.id;
      const createdBy = this.props.userDetails.team_member.id;
      const date = this.state.date;

      this.props.saveDamageReport(
        token,
        damage_report_number,
        claim,
        client,
        createdBy,
        date
      );
    } else if (!valid) {
      this.handleMessageOpen(
        'Error',
        'Please fill the needed fields to be able to register the damage report.'
      );
    } else {
      this.handleMessageOpen(
        'Error',
        'This claim already has a damage report assigned to it. You can only modify the  report items.'
      );
    }
  };

  handleUpdateDamageReport = () => {
    const valid = this.validateRegisterReportFields();
    if (
      valid &&
      this.props.selectedClaim &&
      this.props.selectedClaim[0] &&
      this.props.selectedClaim[0].damage_report[0] !== undefined
    ) {
      const token = this.props.token;
      const damageReportID = this.props.damageReport.id;
      const damage_report_number = this.state.reportNumber;
      const claim = this.state.insuranceClaimNumber;
      const client = this.state.selectedClient.id;
      const createdBy = this.props.userDetails.team_member.id;
      const date = this.state.date;

      this.props.updateDamageReport(
        token,
        damageReportID,
        damage_report_number,
        claim,
        client,
        createdBy,
        date
      );
    } else if (!valid) {
      this.handleMessageOpen(
        'Error',
        'Please fill the needed fields to be able to register the damage report.'
      );
    } else {
      this.handleMessageOpen('Error', "Couldn't update damage report.");
    }
  };

  handleGenerateDamageReport = () => {
    const damageReportItems = this.props.damageReport.damage_report_item;
    if (damageReportItems.lenght !== 0) {
      console.log('Report has items');
    } else {
      console.log('Report has no items');
    }
  };

  render() {
    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.insurance_company_name,
    });

    const filterClientOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.client_name,
    });
    const filterClaimOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.claim_number,
    });

    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };

    const mode = this.props.mode;
    return (
      <div className="DAMAGE_REPORT_FORM_MAIN_CONTAINER">
        <div className="INVOICE_FORM_HEADER">
          <h2>GENERATE DAMAGE REPORT</h2>
          <div style={{ marginLeft: 'auto' }}></div>
        </div>
        <div className="DAMAGE_REPORT_FORM_INFO_CONTAINER">
          <div className="DAMAGE_REPORT_INPUT_GROUP_CONTAINER">
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-claims"
                freeSolo
                options={this.props.claims ? this.props.claims : []}
                getOptionLabel={(option) =>
                  option.claim_number
                    ? option.claim_number
                    : this.state.insuranceClaimNumber
                }
                filterOptions={filterClaimOptions}
                sx={{ color: '#ffffff' }}
                onOpen={() => {
                  this.getInsuranceClaims();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                value={this.state.insuranceClaimNumber}
                onChange={(e, option) => this.onSelectClaim(e, option)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Claim Number"
                    name="insuranceClaimNumber"
                    fullWidth
                    value={this.state.insuranceClaimNumber}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingClaims ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'INVOICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Generated By"
                variant="outlined"
                name="generatedBy"
                fullWidth
                value={
                  this.props.userDetails
                    ? this.props.userDetails.username
                    : this.state.generatedBy
                }
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="DAMAGE_REPORT_INPUT_GROUP_CONTAINER">
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-insurance"
                freeSolo
                options={
                  this.props.insuranceCompanies
                    ? this.props.insuranceCompanies
                    : []
                }
                getOptionLabel={(option) =>
                  option.insurance_company_name
                    ? option.insurance_company_name
                    : this.state.insuranceCompany
                }
                filterOptions={filterOptions}
                sx={{ color: '#ffffff' }}
                onOpen={() => {
                  this.getInsuranceCompanies();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                onChange={(e, option) => this.onSelectInsurance(e, option)}
                value={this.state.insuranceCompany}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Insurance Company"
                    name="insuranceCompany"
                    fullWidth
                    value={this.state.insuranceCompany}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingInsurances ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'INVOICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>

            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-client"
                freeSolo
                options={this.props.clients ? this.props.clients : []}
                getOptionLabel={(option) =>
                  option.client_name
                    ? option.client_name
                    : this.state.clientName
                }
                filterOptions={filterClientOptions}
                sx={{ color: '#ffffff' }}
                onOpen={() => {
                  this.getClients();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                onChange={(e, option) => this.onSelectClient(e, option)}
                value={this.state.clientName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    name="clientName"
                    fullWidth
                    value={this.state.clientName}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingClients ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'INVOICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div className="DAMAGE_REPORT_INPUT_GROUP_CONTAINER">
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Report Number"
                variant="outlined"
                name="reportNumber"
                fullWidth
                value={this.state.reportNumber}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>

            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Date"
                  value={this.state.date}
                  onChange={(newValue) => {
                    this.setState({ date: newValue });
                  }}
                  fullWidth
                  sx={{
                    svg:
                      mode === 'light'
                        ? { color: '#19376d' }
                        : { color: '#576cbc' },
                    input:
                      mode === 'light'
                        ? { color: '#17293e' }
                        : { color: '#ffffff' },
                    label:
                      mode === 'light'
                        ? {
                            color: '#182c43',
                            zIndex: 10,
                            background: '#c3cfd1',
                          }
                        : {
                            color: '#b8b8b8',
                            zIndex: 10,
                            background: '#111827',
                          },
                    border: mode === 'light' ? '' : '1px solid #384f68',
                    width: '100%',
                    borderRadius: 2,
                  }}
                />
              </LocalizationProvider>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 10,
            marginTop: 20,
          }}
        >
          {this.props.damageReportLoading ? (
            <CircularProgress></CircularProgress>
          ) : this.props.damageReport &&
            this.state.reportNumber ===
              this.props.damageReport.damage_report_number ? (
            <Button variant="outlined" onClick={this.handleUpdateDamageReport}>
              Update Report
            </Button>
          ) : (
            <Button
              variant="outlined"
              onClick={this.handleRegisterDamageReport}
            >
              Register Report
            </Button>
          )}
          {this.props.damageReport &&
          this.state.reportNumber ===
            this.props.damageReport.damage_report_number ? (
            <Avatar sx={{ bgcolor: '#EEEEEE', marginLeft: 5 }} disabled>
              <Link
                underline="none"
                href={`${HOST_URL}/damage-reports/generate-damage-report-pdf/${this.props.damageReport.id}/${this.props.userDetails.team_member.key}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrintIcon sx={{ color: '#010101' }} />
              </Link>
            </Avatar>
          ) : null}
        </div>
        <div>
          <JobCardTable jobCards={this.state.jobCards}></JobCardTable>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openMessageModal}
          onClose={this.handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.messageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.messageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button variant="outlined" onClick={this.handleMessageClose}>
                  Okay
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    mode: state.users.theme?.mode,
    clients: state.client.clients,
    insuranceCompanies: state.insurance.insurances,
    claims: state.insurance.claims,
    selectedClaim: state.insurance.claim,
    damageReport: state.damageReport.damageReport,
    damageReportLoading: state.damageReport.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClients: (token) => dispatch(getClients(token)),
    getInsurances: (token) => dispatch(getInsurances(token)),
    getClaims: (token) => dispatch(getClaims(token)),
    getDamageReportByClaim: (token, claim) =>
      dispatch(getDamageReportByClaim(token, claim)),
    saveDamageReport: (
      token,
      damage_report_number,
      claim,
      client,
      createdBy,
      date
    ) =>
      dispatch(
        postDamageReportData(
          token,
          damage_report_number,
          claim,
          client,
          createdBy,
          date
        )
      ),
    updateDamageReport: (
      token,
      damage_report_id,
      damage_report_number,
      claim,
      client,
      createdBy,
      date
    ) =>
      dispatch(
        updateDamageReportData(
          token,
          damage_report_id,
          damage_report_number,
          claim,
          client,
          createdBy,
          date
        )
      ),
    getSelectedClaim: (token, claim_number) =>
      dispatch(getClaimByNumber(token, claim_number)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DamageReport);
