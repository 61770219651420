import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  client: null,
  clients: [],
};

const getClientStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getClientSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    client: action.payload,
  });
};

const getClientsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    clients: action.payload,
  });
};

const getClientFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clientReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CLIENT_START:
      return getClientStart(state, action);
    case actionTypes.GET_CLIENT_SUCCESS:
      return getClientSuccess(state, action);
    case actionTypes.GET_CLIENTS_SUCCESS:
      return getClientsSuccess(state, action);
    case actionTypes.GET_CLIENT_FAIL:
      return getClientFaild(state, action);
    default:
      return state;
  }
};

export default clientReducer;
