import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/redirect-user.css';
import * as userActions from '../../store/actions/auth';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

class RedirectUser extends Component {
  constructor(props) {
    super(props);
    // Don't call this.setState() here!
    this.state = {
      processing: true,
      prevPath: localStorage.getItem('prevPath')
        ? localStorage.getItem('prevPath')
        : '',
    };
    this.handleRedirecting();
  }

  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.goToLogin();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      if (this.props.token !== null) {
        this.props.getUserDetails(this.props.token);
      } else {
        this.goToLogin();
      }
    }
    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.userDetails !== null
    ) {
      this.setState({ processing: false });
      this.handleRedirecting();
    }
  }

  handleRedirecting = () => {
    console.log('redirecting');
    if (
      this.props.userDetails &&
      this.props.userDetails.team_member.job_title === 'technician'
    ) {
      this.props.history.push('/technician');
    } else if (
      this.props.userDetails &&
      this.props.userDetails.team_member.job_title === 'reception'
    ) {
      this.props.history.push(
        this.state.prevPath.includes('/reception')
          ? this.state.prevPath
          : '/reception'
      );
    } else if (
      this.props.userDetails &&
      this.props.userDetails.team_member.job_title === 'admin'
    ) {
      this.props.history.push(
        this.state.prevPath === '/' || this.state.prevPath === '/login'
          ? '/reception/book-a-device'
          : this.state.prevPath
      );
    } else if (
      this.props.userDetails &&
      this.props.userDetails.team_member.job_title === 'owner'
    ) {
      this.props.history.push(
        this.state.prevPath === '/' || this.state.prevPath === '/login'
          ? '/dashboard'
          : this.state.prevPath
      );
    }
  };

  goToLogin = () => {
    localStorage.setItem('prevPath', this.props.history.location.pathname);
    this.props.history.push('/login');
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="REDIRECT_USER_MAIN_CONTAINER">
        <Backdrop
          sx={{ color: '#fff' }}
          open={this.state.processing}
          onClick={this.handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.users.token !== null,
    loading: state.users.loading,
    token: state.users.token,
    error: state.users.error,
    userDetails: state.users.userDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) =>
      dispatch(userActions.authLogin(username, password)),
    getUserDetails: (token) => dispatch(userActions.getUserDetails(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RedirectUser);
