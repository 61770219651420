import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/job-card-edit-form.css';
import TextField from '@mui/material/TextField';
import * as documentAction from '../../store/actions/documents';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import { updateJobCard, getJobCard } from '../../store/actions/jobCard';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ImageCard from '../image-card/ImageCard';
import CircularProgress from '@mui/material/CircularProgress';
import ProgressCircle from '../progress-circle/ProgressCircle';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

class EditJobCardForm extends Component {
  state = {
    date: null,
    jobCardNumber: '',
    bookingType: '',
    notes: '',
    clientName: '',
    clientEmail: '',
    clientNumber: '',
    clientAlternativeNumber: '',
    clientAddress: '',
    insuranceCompany: '',
    claimTech: '',
    insuranceContactNumber: '',
    insuranceClaimNumber: '',
    deviceType: '',
    itemDescreption: '',
    deviceMake: '',
    deviceModel: '',
    deviceSN: '',
    deviceColour: '',
    deviceAccessories: '',
    devicePassCode: '',
    deviceCondetion: '',
    deviceFault: '',
    deviceFaultDescription: '',
    allowedFileTypes: 'png,jpeg',
    documents: [],
    documentsDetails: [],
    showProgress: false,
    preLoadingImages: true,
    openImagePreview: false,
    previewFile: null,
    virtical: 'bottom',
    horizontal: 'left',
    alertOpen: false,
    alertMessage: '',
    alertBgColor: '#1B262C',
    openMessageModal: false,
    messageModalMessageTitle: '',
    messageModalMessageBody: '',
  };

  componentDidMount() {
    this.updateState();
    this.setState({ preLoadingImages: true });
    setTimeout(() => this.setState({ preLoadingImages: false }), 1000);
  }
  componentDidUpdate(prevProps) {
    if (this.props.jobCard !== prevProps.jobCard) {
      if (
        this.state.documentsDetails &&
        this.state.documentsDetails.length === 1
      ) {
        this.saveDocument();
      } else if (
        this.state.documentsDetails &&
        this.state.documentsDetails.length > 1
      ) {
        this.saveAllDocuments();
      } else if (this.state.showProgress) {
        this.setState({ showProgress: false });
        this.handleAlertFeddback('Job Card Has Been Updated', '#17B794');
      } else {
        this.setState({ showProgress: false });
      }
    }
    if (
      this.props.documents !== prevProps.documents &&
      this.state.showProgress
    ) {
      this.props.getJobCard(this.props.token, this.props.jobCard.id);
      this.setState({
        showProgress: false,
        documents: [],
        documentsDetails: [],
        preLoadingImages: false,
      });
      this.handleAlertFeddback('Job Card Has Been Updated', '#17B794');
    }
    if (this.props.jobCardToEdit !== prevProps.jobCardToEdit) {
      this.updateState();
    }
  }

  updateState = () => {
    const jobCard = this.props.jobCardToEdit;
    if (jobCard) {
      this.setState({
        jobCardNumber: jobCard.job_card_number,
        bookingType: jobCard.booking_type,
        notes: jobCard.notes,
        clientName: jobCard.client_details.client_name,
        clientEmail: jobCard.client_details.client_email,
        clientNumber: jobCard.client_details.client_number,
        clientAlternativeNumber:
          jobCard.client_details.client_alternative_number,
        clientAddress: jobCard.client_details.client_address,
        insuranceCompany: jobCard.insurance_details
          ? jobCard.insurance_details.insurance_company_name
          : '',
        claimTech: jobCard.claim_consultant,
        insuranceContactNumber: jobCard.consultant_contact_number,
        insuranceClaimNumber: jobCard.claim_number ? jobCard.claim_number : '',
        deviceType: jobCard.device_details.device_type,
        itemDescreption: jobCard.device_details.device_description,
        deviceMake: jobCard.device_details.make,
        deviceModel: jobCard.device_details.model,
        deviceSN: jobCard.device_details.serial_number,
        deviceColour: jobCard.device_details.colour,
        deviceAccessories: jobCard.device_details.accessories,
        devicePassCode: jobCard.device_details.passcode,
        deviceCondetion: jobCard.device_details.device_condition,
        deviceFault: jobCard.device_details.device_fault,
        deviceFaultDescription: jobCard.device_details.fault_description,
        documents: [],
        documentsDetails: [],
        showProgress: false,
      });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleClose = () => {
    this.setState({ addDocumentModalOpen: false });
  };

  handleImagePreviewClose = () => {
    this.setState({ openImagePreview: false });
  };
  handleImagePreviewOpen = (file) => {
    console.log('opening preview');
    this.setState({ openImagePreview: true, previewFile: file });
  };

  validateJobCardFields = () => {
    const {
      jobCardNumber,
      clientName,
      deviceType,
      itemDescreption,
      deviceSN,
      deviceFault,
    } = this.state;

    if (
      jobCardNumber === '' ||
      clientName === '' ||
      deviceType === '' ||
      itemDescreption === '' ||
      deviceSN === '' ||
      deviceFault === ''
    ) {
      this.handleMessageOpen(
        'Error',
        "[ Client Name, Item Type, Item Descreption, SN/IMEI, Device Fault ] are required to update a Job Card. If any of the mentioned field are not available, use 'none' instead."
      );
      return false;
    } else {
      return true;
    }
  };

  handleMessageClose = () => {
    this.setState({ openMessageModal: false });
  };

  handleMessageOpen = (title, body) => {
    this.setState({
      messageModalMessageTitle: title,
      messageModalMessageBody: body,
      openMessageModal: true,
    });
  };

  handleJobCardUpdate = () => {
    const valid = this.validateJobCardFields();
    if (valid) {
      this.setState({ showProgress: true });
      const token = this.props.token; //this.props.token;
      const job_card_id = this.props.jobCardToEdit.id;
      const client_id = this.props.jobCardToEdit.client_details.id;
      const insurance_id = this.props.jobCardToEdit.insurance_details
        ? this.props.jobCardToEdit.insurance_details.id
        : null;
      const device_id = this.props.jobCardToEdit.device_details.id;
      const {
        date,
        jobCardNumber,
        bookingType,
        notes,
        clientName,
        clientEmail,
        clientNumber,
        clientAlternativeNumber,
        clientAddress,
        insuranceCompany,
        claimTech,
        insuranceContactNumber,
        insuranceClaimNumber,
        deviceType,
        itemDescreption,
        deviceMake,
        deviceModel,
        deviceSN,
        deviceColour,
        deviceAccessories,
        devicePassCode,
        deviceCondetion,
        deviceFault,
        deviceFaultDescription,
      } = this.state;
      this.props.updateJobCard(
        token,
        job_card_id,
        client_id,
        insurance_id,
        device_id,
        date,
        jobCardNumber,
        bookingType,
        notes,
        clientName,
        clientEmail,
        clientNumber,
        clientAlternativeNumber,
        clientAddress,
        insuranceCompany,
        claimTech,
        insuranceContactNumber,
        insuranceClaimNumber,
        deviceType,
        itemDescreption,
        deviceMake,
        deviceModel,
        deviceSN,
        deviceColour,
        deviceAccessories,
        devicePassCode,
        deviceCondetion,
        deviceFault,
        deviceFaultDescription
      );
    } else {
      console.log('Please fill the necessary fields');
    }
  };
  onImgChange = (e) => {
    const image = e.target.files;

    const addedImageCount = this.state.documents.length;
    const imageCountCanBeAdded = 30 - addedImageCount;
    var imageNumberLimit = 0;
    if (image.length > imageCountCanBeAdded) {
      imageNumberLimit = imageCountCanBeAdded;
    } else if (image.length <= imageCountCanBeAdded) {
      imageNumberLimit = image.length;
    }

    for (var index = 0; index < imageNumberLimit; index++) {
      let reader = new FileReader();
      const img = image[index];
      console.log(img);
      if (
        // this.state.allowedFileTypes.includes(
        //   img.type.split('/')[1] || img.name.split('.')[1] // use this to restrict file type
        // )
        img.type.split('/')[0] === 'image'
      ) {
        reader.readAsDataURL(img);
        reader.onloadend = () => {
          this.setState((state) => ({
            documents: [...state.documents, reader.result],
          }));
        };

        this.setState((state) => ({
          documentsDetails: [...state.documentsDetails, img],
        }));
      }
    }
  };

  saveDocument = () => {
    // this.setState({ showProgress: true });
    const { documentsDetails } = this.state;
    const document_name = documentsDetails[0].name;
    const document_type = documentsDetails[0].type;
    const document_size = documentsDetails[0].size;
    const job_card_id = this.props.jobCard.id;

    if (document_type.includes('image')) {
      this.props.saveDocument(
        this.props.token,
        job_card_id,
        document_name,
        document_type,
        document_size,
        documentsDetails[0]
      );
    }
  };

  saveAllDocuments = () => {
    // this.setState({ showProgress: true });
    const documentList = this.state.documentsDetails;
    const job_card_id = this.props.jobCard.id;
    this.props.saveDocuments(this.props.token, job_card_id, documentList);
  };

  deleteImageFromState = (id) => {
    let documents = this.state.documents;
    let documentsDetails = this.state.documentsDetails;
    documents.splice(id, 1);
    documentsDetails.splice(id, 1);
    this.setState({ documents: documents });
    this.setState({ documentsDetails: documentsDetails });
  };

  deleteSavedImage = (id) => {
    const token = this.props.token;
    const job_card_id = this.props.jobCard.id;

    this.props.deleteDocument(token, id, job_card_id);
  };

  handleAlertFeddback = (message, backgroundColor) => {
    this.setState({
      alertOpen: true,
      alertMessage: message,
      alertBgColor: backgroundColor,
    });
    setTimeout(
      () =>
        this.setState({
          alertOpen: false,
          alterMessage: '',
          alertBgColor: '#1B262C',
        }),
      4000
    );
  };

  handleAlertClose = () => {
    this.setState({ alertOpen: false });
  };

  TransitionLeft = (props) => {
    return <Slide {...props} direction="right" />;
  };

  render() {
    const { virtical, horizontal } = this.state;
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: 1000,
      height: '80%',
      bgcolor: 'none',
      border: '2px solid #2c3e50',
      borderRadius: 4,
      boxShadow: 24,
      p: 2,
    };

    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    return (
      <div className="JOB_CARD_EDIT_FORM_CONTAINER">
        <Snackbar
          // anchorOrigin={{ virtical, horizontal }}
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          message={this.state.alertMessage}
          key={virtical + horizontal}
          TransitionComponent={this.TransitionLeft}
          ContentProps={{
            sx: { backgroundColor: this.state.alertBgColor },
          }}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={this.handleAlertClose}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        />
        <div className="JOB_CARD_FIELDS_FORM_CONTAINER">
          <div>
            <div className="JOB_CARD_FORM_S">
              {/* <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <MobileDatePicker
                    label="Date"
                    value={this.state.date}
                    onChange={(newValue) => {
                      this.setState({ date: newValue });
                    }}
                    InputProps={{
                      className: 'JOB_CARD_INPUT_FIELD',
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputLabelProps={{
                          // shrink: true,
                          className: 'JOB_CARD_INPUT_FIELD_LABEL',
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div> */}

              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Job Number"
                  variant="outlined"
                  name="jobCardNumber"
                  fullWidth
                  value={this.state.jobCardNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Booking Type"
                  variant="outlined"
                  name="bookingType"
                  fullWidth
                  value={this.state.bookingType}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="JOB_CARD_FORM_CLIENT">
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Client Name"
                  variant="outlined"
                  name="clientName"
                  fullWidth
                  value={this.state.clientName}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Client Email"
                  variant="outlined"
                  name="clientEmail"
                  fullWidth
                  value={this.state.clientEmail}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Client Number"
                  variant="outlined"
                  name="clientNumber"
                  fullWidth
                  value={this.state.clientNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Alternative Number"
                  variant="outlined"
                  name="clientAlternativeNumber"
                  fullWidth
                  value={this.state.clientAlternativeNumber}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label="Address"
                  variant="outlined"
                  name="clientAddress"
                  fullWidth
                  value={this.state.clientAddress}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'JOB_CARD_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'JOB_CARD_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
          </div>
          <div className="JOB_CARD_FORM_INSURANCE">
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Insurance Company"
                variant="outlined"
                name="insuranceCompany"
                fullWidth
                value={this.state.insuranceCompany}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Claim Consultant"
                variant="outlined"
                name="claimTech"
                fullWidth
                value={this.state.claimTech ? this.state.claimTech : ''}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Consultant Number"
                variant="outlined"
                name="insuranceContactNumber"
                fullWidth
                value={
                  this.state.insuranceContactNumber
                    ? this.state.insuranceContactNumber
                    : ''
                }
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Claim Number"
                variant="outlined"
                name="insuranceClaimNumber"
                fullWidth
                value={this.state.insuranceClaimNumber}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-multiline-static"
                label="Notes"
                multiline
                rows={10}
                variant="outlined"
                // color="secondary"
                type="text"
                name="notes"
                fullWidth
                required
                // error={this.state.questionError ? true : false}
                value={this.state.notes}
                onChange={this.onChange}
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              />
            </div>
          </div>
          <div className="JOB_CARD_FORM_DEVICE">
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Item Type"
                variant="outlined"
                name="deviceType"
                fullWidth
                value={this.state.deviceType}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Item Description"
                variant="outlined"
                name="itemDescreption"
                fullWidth
                value={this.state.itemDescreption}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Make"
                variant="outlined"
                name="deviceMake"
                fullWidth
                value={this.state.deviceMake}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Model"
                variant="outlined"
                name="deviceModel"
                fullWidth
                value={this.state.deviceModel}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="SN / IMEI"
                variant="outlined"
                name="deviceSN"
                fullWidth
                value={this.state.deviceSN}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Colour"
                variant="outlined"
                name="deviceColour"
                fullWidth
                value={this.state.deviceColour}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Accessories"
                variant="outlined"
                name="deviceAccessories"
                fullWidth
                value={this.state.deviceAccessories}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Unlock Code"
                variant="outlined"
                name="devicePassCode"
                fullWidth
                value={this.state.devicePassCode}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="JOB_CARD_FORM_DESCRIPTION_CONTAINER">
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-multiline-static"
                label="Device Condition"
                multiline
                rows={8}
                defaultValue=""
                variant="outlined"
                // color="secondary"
                type="text"
                name="deviceCondetion"
                fullWidth
                required
                // error={this.state.questionError ? true : false}
                value={this.state.deviceCondetion}
                onChange={this.onChange}
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              />
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Fault"
                variant="outlined"
                name="deviceFault"
                fullWidth
                value={this.state.deviceFault}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="JOB_CARD_FORM_S_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-multiline-static"
                label="Fault Description"
                multiline
                rows={8}
                variant="outlined"
                // color="secondary"
                type="text"
                name="deviceFaultDescription"
                fullWidth
                required
                // error={this.state.questionError ? true : false}
                value={this.state.deviceFaultDescription}
                onChange={this.onChange}
                InputProps={{
                  className: 'JOB_CARD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'JOB_CARD_INPUT_FIELD_LABEL',
                }}
              />
            </div>
          </div>
        </div>
        <div className="BOOK_DEVICE_IMAGE">
          {this.state.showProgress ? (
            <div className="PROGRESS_CIRCLE_CONTAINER">
              <ProgressCircle progress={this.props.progress}></ProgressCircle>
            </div>
          ) : (
            <div
              className="ADD_IMAGE_BUTTON"
              // data-toggle="modal"
              // data-target="#imgModal"
              // onClick={this.generateAllowedFileTypesList}
            >
              <input
                type="file"
                multiple={true}
                accept={this.state.allowedFileTypes}
                onChange={this.onImgChange}
              />
              <div className="UPLOAD_FILE_ICON">
                <AddPhotoAlternateIcon
                  fontSize="inherit"
                  color="inherit"
                ></AddPhotoAlternateIcon>
              </div>
            </div>
          )}

          {this.state.preLoadingImages ? (
            <div className="JOB_CARD_EDIT_LOADING_IMAGES_CONTAINER">
              <CircularProgress></CircularProgress>
            </div>
          ) : (
            <div className="JOB_CARD_EDIT_UPLOADED_IMAGES_CONTAINER">
              {this.state.documents
                ? this.state.documents.map((document, index) => (
                    <div
                      className="JOB_CARD_UPLOADED_IMAGE_CONTAINER"
                      key={index}
                    >
                      <ImageCard
                        file={document}
                        deleteImage={() => this.deleteImageFromState(index)}
                        openPreview={() =>
                          this.handleImagePreviewOpen(document)
                        }
                      ></ImageCard>
                    </div>
                  ))
                : null}
              {this.props.jobCard && this.props.jobCard.documet
                ? this.props.jobCard.documet.map((document, index) => (
                    <div
                      className="JOB_CARD_EDIT_UPLOADED_IMAGE_CONTAINER"
                      key={index}
                    >
                      <ImageCard
                        file={document.document_image}
                        deleteImage={() => this.deleteSavedImage(document.id)}
                        openPreview={() =>
                          this.handleImagePreviewOpen(document.document_image)
                        }
                      ></ImageCard>
                    </div>
                  ))
                : null}
            </div>
          )}
        </div>
        <div className="BOOK_DEVICE_FORM_ACTION">
          {this.state.showProgress ? (
            <div className="PROGRESS_CIRCLE_CONTAINER">
              <CircularProgress></CircularProgress>
            </div>
          ) : (
            <div
              className="BOOK_DEVICE_FORM_ACTION_SAVE_BUTTON"
              onClick={this.handleJobCardUpdate}
            >
              <h5>Update</h5>
            </div>
          )}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openImagePreview}
          onClose={this.handleImagePreviewClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openImagePreview}>
            <Box sx={style}>
              <div className="PREVIEW_MODAL_IMAGE_HEADER">
                <Avatar
                  sx={{ bgcolor: '#393E46' }}
                  onClick={this.handleImagePreviewClose}
                >
                  <CloseIcon></CloseIcon>
                </Avatar>
              </div>
              <div className="PREVIEW_MODAL_IMAGE_CONTAINER">
                {this.state.previewFile ? (
                  <img src={this.state.previewFile} alt="repair cocument"></img>
                ) : null}
              </div>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openMessageModal}
          onClose={this.handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.messageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.messageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button variant="outlined" onClick={this.handleMessageClose}>
                  Okay
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    jobCard: state.jobCard.jobCard,
    jobCards: state.jobCard.jobCards,
    documents: state.document.documents,
    progress: state.document.progress,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getJobCard: (token, id) => dispatch(getJobCard(token, id)),
    updateJobCard: (
      token,
      job_card_id,
      client_id,
      insurance_id,
      device_id,
      date,
      jobCardNumber,
      bookingType,
      notes,
      clientName,
      clientEmail,
      clientNumber,
      clientAlternativeNumber,
      clientAddress,
      insuranceCompany,
      claimTech,
      insuranceContactNumber,
      insuranceClaimNumber,
      deviceType,
      itemDescreption,
      deviceMake,
      deviceModel,
      deviceSN,
      deviceColour,
      deviceAccessories,
      devicePassCode,
      deviceCondetion,
      deviceFault,
      deviceFaultDescription
    ) =>
      dispatch(
        updateJobCard(
          token,
          job_card_id,
          client_id,
          insurance_id,
          device_id,
          date,
          jobCardNumber,
          bookingType,
          notes,
          clientName,
          clientEmail,
          clientNumber,
          clientAlternativeNumber,
          clientAddress,
          insuranceCompany,
          claimTech,
          insuranceContactNumber,
          insuranceClaimNumber,
          deviceType,
          itemDescreption,
          deviceMake,
          deviceModel,
          deviceSN,
          deviceColour,
          deviceAccessories,
          devicePassCode,
          deviceCondetion,
          deviceFault,
          deviceFaultDescription
        )
      ),
    getDocument: (token, id) =>
      dispatch(documentAction.getRegisteredDocument(token, id)),
    getDocuments: (token, workGroupID) =>
      dispatch(documentAction.getRegisteredDocuments(token, workGroupID)),
    saveDocument: (
      token,
      job_card,
      document_name,
      document_type,
      document_size,
      document_image
    ) =>
      dispatch(
        documentAction.registerDocument(
          token,
          job_card,
          document_name,
          document_type,
          document_size,
          document_image
        )
      ),
    saveDocuments: (token, job_card, documentList) =>
      dispatch(documentAction.registerDocuments(token, job_card, documentList)),
    deleteDocument: (token, id, job_card_id) =>
      dispatch(documentAction.deleteRegisteredDocument(token, id, job_card_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditJobCardForm);
