import React, { Component } from 'react';
import './styles/register-company.css';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CompanyForm from '../../components/company/CompanyForm';
import BranchForm from '../../components/company/BranchForm';
import EmployeeForm from '../../components/company/EmployeeForm';
import AccountingForm from '../../components/company/AccountingForm';
import { connect } from 'react-redux';
import { createCompanyProfile } from '../../store/actions/company';
import { CircularProgress } from '@mui/material';

const steps = [
  'Company Details',
  'Accounting Info',
  'Branches',
  'Team Members',
];

class RegisterCompany extends Component {
  state = {
    activeStep: 0,
    skipped: new Set(),
    allowNextStep: true,
    erroeMessage: '',
    loading: false,
  };

  componentDidMount() {
    localStorage.setItem('prevPath', '/register-company');
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  isStepOptional = (step) => {
    return step === 1 || step === 2 || step === 3;
  };

  isStepSkipped = (step) => {
    const skipped = this.state.skipped;
    return skipped.has(step);
  };

  handleNext = () => {
    const { allowNextStep } = this.state;
    if (allowNextStep) {
      let newSkipped = this.state.skipped;
      if (this.isStepSkipped(this.state.activeStep)) {
        newSkipped.delete(this.state.activeStep);
      }

      this.setState({ activeStep: this.state.activeStep + 1 });
      this.setState({ skipped: newSkipped });
      this.setState({ erroeMessage: '' });
    } else {
      this.setState({ erroeMessage: 'Please fill required fields.' });
    }
  };

  handleSkip = () => {
    const prevSkipped = this.state.skipped;
    const newSkipped = new Set(prevSkipped.values());
    newSkipped.add(this.state.activeStep);

    if (!this.isStepOptional(this.state.activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState({ activeStep: this.state.activeStep + 1 });
    this.setState({ skipped: newSkipped });
  };

  handleBack = () => {
    this.setState({ activeStep: this.state.activeStep - 1 });
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  handleUpdateAllowNext = (status) => {
    if (status) {
      this.setState({ allowNextStep: true, erroeMessage: '' });
    } else {
      this.setState({
        allowNextStep: false,
        erroeMessage: 'Please fill required fields.',
      });
    }
  };

  handleFinishForms = () => {
    let newSkipped = this.state.skipped;
    this.setState({ activeStep: this.state.activeStep + 1 });
    this.setState({ skipped: newSkipped });
  };

  handleSubmitForms = async () => {
    this.setState({ loading: true });
    const token = this.props.token;
    const companyDetails = JSON.parse(localStorage.getItem('company_form'));
    const branchDetails = JSON.parse(localStorage.getItem('branch_form'));
    const bankingDetails = JSON.parse(localStorage.getItem('accounting_form'));
    const membersDetails = JSON.parse(localStorage.getItem('members_form'));

    const data = {
      company_details: companyDetails,
      branch_details: branchDetails,
      banking_details: bankingDetails,
      members_details: membersDetails,
    };
    console.log(data);

    const response = await createCompanyProfile(token, data);
    if (response.status === 200) {
      this.setState({ loading: false });
      this.props.history.push('/dashboard');
    } else {
      setTimeout(() => this.setState({ loading: false }), 1000);
    }
  };

  render() {
    return (
      <div className="COMPANY_REGISTR_MAIN_CONTAINER">
        <Box sx={{ width: '80%', minWidth: '1000px', maxWidth: '1200px' }}>
          <Stepper activeStep={this.state.activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (this.isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (this.isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
          {this.state.activeStep === steps.length ? (
            <React.Fragment>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  mt: 4,
                  mb: 2,
                }}
              >
                <h4 style={{ fontFamily: 'Roboto', color: '#1c2d43' }}>
                  All Steps Complete
                </h4>
                {this.state.loading ? (
                  <CircularProgress></CircularProgress>
                ) : (
                  <Button variant="contained" onClick={this.handleSubmitForms}>
                    Create Profile
                  </Button>
                )}
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={this.handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="REGISTER_COMPANY_FORMS_CONTAINER">
                {this.state.activeStep === 0 ? (
                  <div className="REGISTER_COMPANY_DETAILS_CONTAINER">
                    <span>{this.state.erroeMessage}</span>
                    <CompanyForm
                      updateAllowNext={this.handleUpdateAllowNext}
                    ></CompanyForm>
                  </div>
                ) : this.state.activeStep === 1 ? (
                  <div className="REGISTER_BRANCH_DETAILS_CONTAINER">
                    <AccountingForm></AccountingForm>
                  </div>
                ) : this.state.activeStep === 2 ? (
                  <div className="REGISTER_BRANCH_DETAILS_CONTAINER">
                    <BranchForm></BranchForm>
                  </div>
                ) : this.state.activeStep === 3 ? (
                  <div className="REGISTER_COMPANY_EMPLOYEE_CONTAINER">
                    <EmployeeForm></EmployeeForm>
                  </div>
                ) : null}
              </div>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color="inherit"
                  disabled={this.state.activeStep === 0}
                  onClick={this.handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {this.isStepOptional(this.state.activeStep) && (
                  <Button
                    color="inherit"
                    onClick={this.handleSkip}
                    sx={{ mr: 1 }}
                  >
                    Skip
                  </Button>
                )}
                {this.state.activeStep === steps.length - 1 ? (
                  <Button onClick={this.handleFinishForms}>Finish</Button>
                ) : (
                  <Button onClick={this.handleNext}>Next</Button>
                )}
              </Box>
            </React.Fragment>
          )}
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterCompany);
