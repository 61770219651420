import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import './styles/style.css';
import * as actions from '../../store/actions/auth';
import classnames from 'classnames';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import LockIcon from '@mui/icons-material/Lock';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';

class PasswordReset extends Component {
  state = {
    email: '',
    emailError: false,
    errors: {},
    requestError: false,
    loading: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const response = this.props.response;
      console.log(response);
      if (response.status == 200) {
        this.setState({ loading: false });
      } else {
        this.setState({
          errors: { email: this.props.response.message },
          requestError: true,
          loading: false,
        });
      }
    }
  }

  onSubmit = (e) => {
    e.preventDefault();

    const { email } = this.state;

    if (email === '') {
      this.setState({
        errors: { email: 'Email field is required' },
        emailError: true,
        requestError: true,
      });
      return;
    }
    this.setState({ errors: {}, loading: true });

    this.sendResetEmail();
  };

  sendResetEmail() {
    this.props.sendResetEmail(this.state.email);
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      emailError: false,
      requestError: false,
    });
  };

  render() {
    return (
      <div className="container">
        <div className="FORGOT_PASSWORD_PAGE_CONTAINER">
          <form className="FORGOT_PASSWORD_FORM" onSubmit={this.onSubmit}>
            <div className="FORGOT_PASSWORD_LOADING_CONTAINER">
              {this.state.loading ? (
                <CircularProgress color="success"></CircularProgress>
              ) : (
                <LockIcon fontSize="inherit" color="inherit"></LockIcon>
              )}
            </div>
            <div className="INPUT_FIELD_FRAME">
              <TextField
                id="outlined-secondary-2"
                label="Your E-mail"
                variant="outlined"
                // color="secondary"
                type="email"
                name="email"
                fullWidth
                required
                error={this.state.emailError ? true : false}
                value={this.state.email}
                onChange={(e) => this.onChange(e)}
                InputProps={{
                  className: 'FORGOT_PASSWORD_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'FLOATING_LABLE_STYLE',
                }}
              />
            </div>
            <div
              className="FORGOT_PASSWORD_ERROR_CONTAINER"
              style={
                this.state.requestError
                  ? { display: 'flex', marginTop: '15px' }
                  : { display: 'none' }
              }
            >
              <p>{this.state.errors.email}</p>
            </div>
            <div className="FORGOT_PASSWORD_FORM_BUTTON">
              <h6 onClick={this.onSubmit}>Send</h6>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error,
    token: state.users.token,
    response: state.users.resetPWResponse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendResetEmail: (email) => dispatch(actions.resetPassword(email)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
