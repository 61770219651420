import React, { Component } from 'react';
import './style.css';
import HistoChart from '../../histogram-chart/HistogramChart';
import { connect } from 'react-redux';
import { getDailyJobsTasks } from '../../../../store/actions/insights/dailyJobs';

class DailyJobs extends Component {
  componentDidMount() {
    const token = this.props.token;
    this.props.getJobsTasks(token);
  }

  render() {
    return (
      <div className="DAILY_JOBS_CONTAINER">
        <h2 className="NUMERICS_HEADR">Booked Jobs</h2>
        <HistoChart data={this.props.data} target="jobs" />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    data: state.jobsInsights.jobsTasks,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getJobsTasks: (token) => dispatch(getDailyJobsTasks(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DailyJobs);
