import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getTaskStatusStart = () => {
  return {
    type: actionTypes.GET_TASK_STATUS_START,
  };
};

export const getTaskStatusSuccess = (data) => {
  return {
    type: actionTypes.GET_TASK_STATUS_SUCCESS,
    payload: data,
  };
};

export const getTaskStatusesSuccess = (data) => {
  return {
    type: actionTypes.GET_TASK_STATUSES_SUCCESS,
    payload: data,
  };
};

export const getTaskStatusFaild = (error) => {
  return {
    type: actionTypes.GET_TASK_STATUS_FAIL,
    error: error,
  };
};

export const getTaskStatuses = (token) => {
  return (dispatch) => {
    dispatch(getTaskStatusStart());
    console.log('getting all task status');
    axios
      .get(`${HOST_URL}/task-status/task-status/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTaskStatusesSuccess(response.data)))
      .catch((error) => dispatch(getTaskStatusFaild(error)));
  };
};

export const getTaskStatus = (token, id) => {
  return (dispatch) => {
    dispatch(getTaskStatusStart());

    axios
      .get(`${HOST_URL}/task-status/task-status/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTaskStatusSuccess(response.data)))
      .catch((error) => dispatch(getTaskStatusFaild(error)));
  };
};
