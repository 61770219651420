import { TextField } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';
import Header from '../header/Header';
import {
  getCompanyDetails,
  updateCompanyDetails,
} from '../../../store/actions/company';

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyDetails: {
        name: '',
        size: '',
        number_branches: Number(1),
        fax_number: '',
        registraion_number: '',
        vat_registraion_number: '',
        country: '',
        address: '',
        website: '',
        email: '',
        banking_details: '',
        terms_and_conditions: '',
        invoice_terms_and_conditions: '',
        quotation_terms_and_conditions: '',
      },
      initialData: {},
      isEditing: false,
    };
  }

  componentDidMount() {
    const token = this.props.token;
    this.props.getDetails(token);
  }

  componentDidUpdate(preProps) {
    if (this.props.companyDetails !== preProps.companyDetails) {
      this.setState({
        companyDetails: { ...this.props.companyDetails },
        initialData: { ...this.props.companyDetails }, // Store the initial object
        isEditing: false,
      });
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState(
      (prevState) => ({
        companyDetails: {
          ...prevState.companyDetails,
          [name]: value,
        },
      }),
      () => this.isDataChanged(this.state)
    );
  };

  isDataChanged = (state) => {
    // Compare the current data with the initial data to check if any changes have been made
    if (
      JSON.stringify(state.initialData) !== JSON.stringify(state.companyDetails)
    ) {
      this.setState({ isEditing: true });
    } else {
      this.setState({
        isEditing: false,
      });
    }
  };

  handleSaveChanges = () => {
    const token = this.props.token;
    const id = this.props.companyDetails.id;
    const data = this.state.companyDetails;
    this.props.updateDetails(token, id, data);
  };

  render() {
    const { isEditing } = this.state;

    return (
      <div className="DASHBOARD_WORKSAPCE_SUB_CONTAINER">
        <Header
          title={this.state.companyDetails.name}
          description="View company details"
          isEditing={isEditing}
          loading={this.props.loading}
          handleSaveChanges={this.handleSaveChanges}
        />
        <div className="DETAILS_WRAPPER">
          <div>
            <div className="DETAILS_CONTAINER">
              <div className="">
                <TextField
                  id="company-name"
                  label="Name"
                  variant="standard"
                  name="name"
                  fullWidth
                  value={this.state.companyDetails.name}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="">
                <TextField
                  id="company-email"
                  label="Size"
                  variant="standard"
                  name="size"
                  fullWidth
                  value={this.state.companyDetails.size}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="">
                <TextField
                  id="registration-number"
                  label="Registration Number"
                  variant="standard"
                  name="registraion_number"
                  fullWidth
                  value={this.state.companyDetails.registraion_number}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="">
                <TextField
                  id="vat-number"
                  label="VAT Number"
                  variant="standard"
                  name="vat_registraion_number"
                  fullWidth
                  value={this.state.companyDetails.vat_registraion_number}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="">
                <TextField
                  id="fax-number"
                  label="FAX Number"
                  variant="standard"
                  name="fax_number"
                  fullWidth
                  value={this.state.companyDetails.fax_number}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="">
                <TextField
                  id="number_branches-q"
                  label="number_branches"
                  variant="standard"
                  name="number_branches"
                  contentEditable={false}
                  fullWidth
                  value={this.state.companyDetails.number_branches}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="DETAILS_CONTAINER">
              <div className="">
                <TextField
                  id="company-country"
                  label="Country"
                  variant="standard"
                  name="country"
                  fullWidth
                  value={this.state.companyDetails.country}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="">
                <TextField
                  id="company-address"
                  label="Address"
                  variant="standard"
                  name="address"
                  fullWidth
                  value={this.state.companyDetails.address}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="">
                <TextField
                  id="company-website"
                  label="Website"
                  variant="standard"
                  name="website"
                  fullWidth
                  value={this.state.companyDetails.website}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="">
                <TextField
                  id="company-email"
                  label="Email"
                  variant="standard"
                  name="email"
                  fullWidth
                  value={this.state.companyDetails.email}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="DETAILS_CONTAINER">
              <div className="">
                <TextField
                  id="company-banking-details"
                  label="Banking Details"
                  variant="standard"
                  name="banking_details"
                  fullWidth
                  multiline
                  rows={4}
                  value={this.state.companyDetails.banking_details}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
          </div>
          <div>
            <div className="ONE_FIELD_DETAILS_CONTAINER">
              <TextField
                id="Terms-Conditions"
                label="Terms & Conditions"
                variant="standard"
                name="terms_and_conditions"
                fullWidth
                multiline
                rows={4}
                value={this.state.companyDetails.terms_and_conditions}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'DETAIL_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'DETAIL_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="ONE_FIELD_DETAILS_CONTAINER">
              <div className="">
                <TextField
                  id="invoice-terms-conditions"
                  label="Invoice Terms"
                  variant="standard"
                  name="invoice_terms_and_conditions"
                  fullWidth
                  multiline
                  rows={4}
                  value={this.state.companyDetails.invoice_terms_and_conditions}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="ONE_FIELD_DETAILS_CONTAINER">
              <div className="">
                <TextField
                  id="quotation-terms-conditions"
                  label="Quotation Terms"
                  variant="standard"
                  name="quotation_terms_and_conditions"
                  fullWidth
                  multiline
                  rows={4}
                  value={
                    this.state.companyDetails.quotation_terms_and_conditions
                  }
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'DETAIL_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'DETAIL_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    loading: state.company.loading,
    companyDetails: state.company.companyDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDetails: (token) => dispatch(getCompanyDetails(token)),
    updateDetails: (token, id, data) =>
      dispatch(updateCompanyDetails(token, id, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Company);
