import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function ClientTable(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.clients.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={'div'}>
      <Table aria-label="custom pagination table">
        <TableHead sx={{ bgcolor: '#233044' }}>
          <TableRow>
            <TableCell sx={{ color: '#bbbbbb' }}>Name</TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              E-Mail
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Phone Number
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Address
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              Alternative Number
            </TableCell>
            <TableCell align="left" sx={{ color: '#bbbbbb' }}>
              VAT Reg NO
            </TableCell>
            <TableCell align="center" sx={{ color: '#bbbbbb' }}>
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        {props.activateClient ? (
          <>
            <TableBody
              sx={
                props.mode === 'dark'
                  ? { bgcolor: '#183151' }
                  : { bgcolor: '#c7c7c7' }
              }
            >
              {(rowsPerPage > 0
                ? props.clients.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : props.clients
              ).map((client) => (
                <TableRow key={client.client_name}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={
                      props.mode === 'dark'
                        ? { color: '#dddddd' }
                        : { color: '#1c2d43' }
                    }
                  >
                    {client.client_name}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="left"
                  >
                    {client.client_email}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="left"
                  >
                    {client.client_number}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="right"
                  >
                    {client.client_address}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="left"
                  >
                    {client.client_alternative_number}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="right"
                  >
                    {client.client_vat_reg_number}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="right"
                  >
                    <IconButton
                      aria-label="edit"
                      sx={{ color: '#00A8CC' }}
                      onClick={() => props.editClient(client)}
                    >
                      <EditOutlinedIcon color="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      sx={{ color: '#F05454' }}
                      onClick={() => props.deleteClient(client)}
                    >
                      <DeleteIcon color="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter sx={{ bgcolor: '#bbbbbb' }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={7}
                  count={props.clients.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </>
        ) : (
          <>
            <TableBody
              sx={
                props.mode === 'dark'
                  ? { bgcolor: '#0b0f19' }
                  : { bgcolor: '#c7c7c7' }
              }
            >
              {(rowsPerPage > 0
                ? props.insurances.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : props.insurances
              ).map((insurance) => (
                <TableRow key={insurance.insurance_company_name}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={
                      props.mode === 'dark'
                        ? { color: '#dddddd' }
                        : { color: '#1c2d43' }
                    }
                  >
                    {insurance.insurance_company_name}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="left"
                  >
                    {insurance.insurance_email}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="left"
                  >
                    {insurance.insurance_number}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="right"
                  >
                    {insurance.insurance_address}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="left"
                  >
                    {insurance.insurance_alternative_number}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="right"
                  >
                    {insurance.insurance_vat_reg_number}
                  </TableCell>
                  <TableCell
                    style={
                      props.mode === 'dark'
                        ? { width: 120, color: '#dddddd' }
                        : { width: 120, color: '#1c2d43' }
                    }
                    align="right"
                  >
                    <IconButton
                      aria-label="edit"
                      sx={{ color: '#00A8CC' }}
                      onClick={() => props.editInsurance(insurance)}
                    >
                      <EditOutlinedIcon color="inherit" />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      sx={{ color: '#F05454' }}
                      onClick={() => props.deleteClient(insurance)}
                    >
                      <DeleteIcon color="inherit" />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={7} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter sx={{ bgcolor: '#bbbbbb' }}>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  colSpan={7}
                  count={props.insurances.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </>
        )}
      </Table>
    </TableContainer>
  );
}
