import React from 'react';
import './styles/job-task-ticket.css';
import moment from 'moment';
import Avatar from '@mui/material/Avatar';
import PageviewIcon from '@mui/icons-material/Pageview';
import AssignmentIcon from '@mui/icons-material/Assignment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
// import EditIcon from '@mui/icons-material/Edit';

const JobTaskTicket = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAdvancedAssign = () => {
    props.advanceAssign();
  };

  const handleViewJobTask = () => {
    if (props.reception === true) {
      props.viewJobTask();
    } else if (props.reception === false) {
      props.getTaskRelatedInfo();
    }
  };

  return (
    <div className="JOB_TASK_TICKET_MAIN_CONTAINER" id={props.mode}>
      <div className="JOB_TASK_TICKET_CONTAINER">
        <div className="JOB_TASK_TICKET_HEADER">
          <div>
            <span>Task No : </span>
          </div>
          <div>
            <span>{props.task ? props.task.task_number : ''}</span>
          </div>
        </div>
        <div className="JOB_TASK_TICKET_BODY">
          <Avatar sx={{ bgcolor: '#2F8886' }} onClick={handleViewJobTask}>
            <PageviewIcon />
          </Avatar>
          {props.reception === true ? (
            <Avatar sx={{ bgcolor: '#152A38' }} onClick={handleClick}>
              <AssignmentIcon />
            </Avatar>
          ) : null}
          {props.task ? (
            <Tooltip title={props.task.status.status}>
              <Avatar sx={{ bgcolor: props.task.status.colour }}>
                <></>
              </Avatar>
            </Tooltip>
          ) : null}
          {props.task ? (
            <Tooltip title={props.task.assigned_to}>
              <Avatar sx={{ bgcolor: '#ED6663' }} onClick={handleViewJobTask}>
                {props.task.assigned_to[0].toUpperCase()}
              </Avatar>
            </Tooltip>
          ) : null}
        </div>
        <div className="JOB_TASK_TICKET_FOOTER">
          <div>
            <span>{moment(props.task.created).calendar()}</span>
          </div>
        </div>
        {props.reception === true ? (
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {props.technicians.map((tech, index) => (
              <MenuItem
                key={index}
                onClick={() => props.assignTask(tech.first_name)}
              >
                <Avatar /> {tech.first_name}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem onClick={openAdvancedAssign}>
              <ListItemIcon>
                <AddCircleIcon fontSize="medium" />
              </ListItemIcon>
              Add More Parameters
            </MenuItem>
          </Menu>
        ) : null}
      </div>
      <div className="JOB_TASK_TICKET_PRIORITY">
        <div className="JOB_TASK_PRIORITY">
          <span>Priority</span>
          <p>{props.task ? props.task.priority : ''}</p>
        </div>
      </div>
      {props.overDue ? (
        <>
          <div className="JOB_TASK_TICKET_OVERDUE_INDICATOR_LYER">
            <div className="JOB_TASK_TICKET_OVERDUE_INDICATOR_CONTENT">
              <span>ATTENTION</span>
              <p>Please Address This Task ASAP</p>
              <h6>Due Date: {moment(props.task.due_date).calendar()}</h6>
            </div>
          </div>

          <div className="JOB_TASK_TICKET_OVERDUE_INDICATOR"></div>
        </>
      ) : null}
    </div>
  );
};

export default JobTaskTicket;
