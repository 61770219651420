import React, { Component } from 'react';
import TextField from '@mui/material/TextField';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/auth';
import './styles/signup.css';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { timeout } from 'q';

class Signup extends Component {
  state = {
    username: '',
    email: '',
    password1: '',
    password2: '',
    errors: {},
    termsOfService: '',
    usernameFieldsCheck: false,
    emailFieldsCheck: false,
    password1FieldsCheck: false,
    password2FieldsCheck: false,
    P1P2Match: false,
    passwordMatchAlert: false,
    goToProfile: false,
    disableBtn: false,
  };

  componentWillMount() {}
  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      const { key } = this.props.match.params;
      const token = this.props.token;
      this.props.addUserKey(token, key);
      ReactDOM.render(
        <div></div>,
        document.getElementById('successful'),
        () => {
          console.log('rendered');
        }
      );
      timeout(this.props.history.push('/reception'), 100);
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { key } = this.props.match.params;
    const { username, email, password1, password2, P1P2Match } = this.state;

    if (username === '') {
      this.setState({ errors: { username: 'Username field is required' } });
      this.setState({ usernameFieldsCheck: true });
      return;
    }
    if (email === '') {
      this.setState({ errors: { email: 'Email field is required' } });
      this.setState({ emailFieldsCheck: true });
      return;
    }
    if (password1 === '') {
      this.setState({ errors: { password1: 'Password field is required' } });
      this.setState({ password1FieldsCheck: true });
      return;
    }
    if (password2 === '') {
      this.setState({
        errors: { password2: 'You need to verify your password' },
      });
      this.setState({ password2FieldsCheck: true });

      return;
    }
    if (P1P2Match === true) {
      this.setState({ passwordMatchAlert: true });
      return;
    }
    this.setState({ errors: {} });
    // this.setState({ fieldsCheck: false });
    this.setState({ disableBtn: true });
    ReactDOM.render(
      <img
        src="https://mega-tronic-files.s3.eu-west-2.amazonaws.com/media/loadingImage.gif"
        alt=""
        style={{ width: '100px' }}
      />,
      document.getElementById('successful'),
      () => {
        console.log('rendered');
      }
    );

    console.log('all fields are vaild');
    this.props.onAuth(
      username.toLowerCase(),
      email.toLowerCase(),
      password1,
      password2
    );
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ [e.target.name + 'FieldsCheck']: false });

    this.setState({ passwordMatchAlert: false });
    this.setState({ errors: {} });
  };

  onPassword1Change = (e) => {
    this.setState({ passwordMatchAlert: false });
    if (e.target.value !== this.state.password2) {
      this.setState({ P1P2Match: true });
    } else {
      this.setState({ P1P2Match: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  onPassword2Change = (e) => {
    this.setState({ passwordMatchAlert: false });
    if (e.target.value !== this.state.password1) {
      this.setState({ P1P2Match: true });
    } else {
      this.setState({ P1P2Match: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div className="SIGNUP_MAIN_CONTAINER">
        <div className=" my-3 SIGNUP_FORM_CONTAINER">
          <div className="form-group col-sm-12 col-md-6 col-lg-6 mx-auto">
            <form onSubmit={this.onSubmit} className="form-signup">
              <div className="SIGNUP_FORM_ICON">
                <GroupAddIcon color="inherit" fontSize="inherit"></GroupAddIcon>
              </div>

              <div className="text-center my-3" id="successful" />
              <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label={
                    this.state.errors.username
                      ? this.state.errors.username
                      : 'Username'
                  }
                  variant="outlined"
                  error={this.state.usernameFieldsCheck}
                  name="username"
                  fullWidth
                  value={this.state.username}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'SIGNUP_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SIGNUP_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              {this.props.error ? (
                <alert className="small" id="passwordMatchAlert">
                  {this.props.error.response
                    ? this.props.error.response.data['username']
                      ? this.props.error.response.data['username'][0]
                      : null
                    : null}
                </alert>
              ) : null}

              <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  label={
                    this.state.errors.username
                      ? this.state.errors.username
                      : 'Email'
                  }
                  variant="outlined"
                  error={this.state.emailFieldsCheck}
                  name="email"
                  fullWidth
                  value={this.state.email}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'SIGNUP_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SIGNUP_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              {this.props.error ? (
                <alert className="small" id="passwordMatchAlert">
                  {this.props.error.response
                    ? this.props.error.response.data['email']
                      ? this.props.error.response.data['email'][0]
                      : null
                    : null}
                </alert>
              ) : null}

              <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  type="password"
                  label={
                    this.state.errors.password1
                      ? this.state.errors.password1
                      : 'Password (8 Characters or more)'
                  }
                  variant="outlined"
                  error={this.state.password1FieldsCheck}
                  name="password1"
                  fullWidth
                  value={this.state.password1}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'SIGNUP_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SIGNUP_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              {this.props.error ? (
                <alert className="small" id="passwordMatchAlert">
                  {this.props.error.response
                    ? this.props.error.response.data['password1']
                      ? this.props.error.response.data['password1'][0]
                      : null
                    : null}
                </alert>
              ) : null}
              <div className="SIGNUP_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-basic"
                  type="password"
                  label={
                    this.state.errors.password2
                      ? this.state.errors.password2
                      : 'Verify Password'
                  }
                  variant="outlined"
                  error={this.state.password2FieldsCheck}
                  name="password2"
                  fullWidth
                  value={this.state.password2}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'SIGNUP_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'SIGNUP_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              {this.state.passwordMatchAlert ? (
                <alert className="small" id="passwordMatchAlert">
                  Password doesn't match
                </alert>
              ) : null}
              <div className="SIGNUP_TERMS_CONTAINER">
                <h6 className="mt-2 ml-2">
                  By signing up, you agree to our..
                  <span
                    className="TERMS_LINK"
                    data-toggle="modal"
                    data-target="#termsOfServiceModal"
                  >
                    terms of service
                  </span>
                </h6>
              </div>
              <div class="SIGNUP_BUTTON_CONTAINER" onClick={this.onSubmit}>
                <h6>Sign Up</h6>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.users.loading,
    error: state.users.error,
    userDetails: state.users.userDetails,
    token: state.users.token,
    verificationInfo: state.users.verificationInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, email, password1, password2, key) =>
      dispatch(actions.authSignup(username, email, password1, password2, key)),
    addUserKey: (token, key) => dispatch(actions.addUserKey(token, key)),
    userDetails: (token) => dispatch(actions.getUserDetails(token)),
    postVerficationCode: (token, userID, verificationCode) =>
      dispatch(actions.postVerficationInfo(token, userID, verificationCode)),
    sendVerificationEmail: (token, subject, verification_code, recipient) =>
      dispatch(
        actions.sendVerifyEmail(token, subject, verification_code, recipient)
      ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
