import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  collectedDevice: null,
  collectedDevices: [],
  collectedItem: null,
  collectedItems: [],
};

const getCollectedDeviceStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getCollectedItemStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getCollectedDeviceSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    collectedDevice: action.payload,
  });
};

const getCollectedDevicesSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    collectedDevices: action.payload,
  });
};

const getCollectedItemSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    collectedItem: action.payload,
  });
};

const getCollectedItemsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    collectedItems: action.payload,
  });
};

const getCollectedDeviceFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getCollectedItemFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const collectedDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COLLECTED_DEVICE_START:
      return getCollectedDeviceStart(state, action);
    case actionTypes.GET_COLLECTED_DEVICE_SUCCESS:
      return getCollectedDeviceSuccess(state, action);
    case actionTypes.GET_COLLECTED_DEVICES_SUCCESS:
      return getCollectedDevicesSuccess(state, action);
    case actionTypes.GET_COLLECTED_DEVICE_FAIL:
      return getCollectedDeviceFaild(state, action);
    case actionTypes.GET_COLLECTED_ITEM_START:
      return getCollectedItemStart(state, action);
    case actionTypes.GET_COLLECTED_ITEM_SUCCESS:
      return getCollectedItemSuccess(state, action);
    case actionTypes.GET_COLLECTED_ITEMS_SUCCESS:
      return getCollectedItemsSuccess(state, action);
    case actionTypes.GET_COLLECTED_ITEM_FAIL:
      return getCollectedItemFaild(state, action);
    default:
      return state;
  }
};

export default collectedDeviceReducer;
