import * as actionTypes from '../actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../../settings';

export const getJobsInsightsStart = () => {
  return {
    type: actionTypes.GET_JOBS_INSIGHTS_START,
  };
};

export const getAllNumericsSuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_NUMERICS_SUCCESS,
    payload: data,
  };
};

export const getTotalJobsSuccess = (data) => {
  return {
    type: actionTypes.GET_TOTAL_JOBS_SUCCESS,
    payload: data,
  };
};

export const getTotalTasksSuccess = (data) => {
  return {
    type: actionTypes.GET_TOTAL_TASKS_SUCCESS,
    payload: data,
  };
};

export const getCompleteTasksSuccess = (data) => {
  return {
    type: actionTypes.GET_COMPLETE_TASKS_SUCCESS,
    payload: data,
  };
};

export const getClosedTasksSuccess = (data) => {
  return {
    type: actionTypes.GET_CLOSED_TASKS_SUCCESS,
    payload: data,
  };
};

export const getPendingTasksSuccess = (data) => {
  return {
    type: actionTypes.GET_PENDING_TASKS_SUCCESS,
    payload: data,
  };
};

export const getJobsInsightsFaild = (error) => {
  return {
    type: actionTypes.GET_JOBS_INSIGHTS_FAILD,
    error: error,
  };
};

export const getAllNumerics = (token) => {
  return (dispatch) => {
    dispatch(getJobsInsightsStart());
    axios
      .get(`${HOST_URL}/insights/jobs-numeric-data/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getAllNumericsSuccess(response.data)))
      .catch((error) => dispatch(getJobsInsightsFaild(error)));
  };
};

export const getTotalJobs = (token) => {
  return (dispatch) => {
    dispatch(getJobsInsightsStart());

    axios
      .get(`${HOST_URL}/insights/jobs-count/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTotalJobsSuccess(response.data)))
      .catch((error) => dispatch(getJobsInsightsFaild(error)));
  };
};

export const getTotalTasks = (token) => {
  return (dispatch) => {
    dispatch(getJobsInsightsStart());

    axios
      .get(`${HOST_URL}/insights/tasks-count/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTotalTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobsInsightsFaild(error)));
  };
};

export const getTotalCompleteTasks = (token) => {
  return (dispatch) => {
    dispatch(getJobsInsightsStart());

    axios
      .get(`${HOST_URL}/insights/complete-tasks-count/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCompleteTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobsInsightsFaild(error)));
  };
};

export const getTotalPendingTasks = (token) => {
  return (dispatch) => {
    dispatch(getJobsInsightsStart());

    axios
      .get(`${HOST_URL}/insights/pending-tasks-count/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getPendingTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobsInsightsFaild(error)));
  };
};

export const getTotalClosedTasks = (token) => {
  return (dispatch) => {
    dispatch(getJobsInsightsStart());

    axios
      .get(`${HOST_URL}/insights/closed-tasks-count/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getClosedTasksSuccess(response.data)))
      .catch((error) => dispatch(getJobsInsightsFaild(error)));
  };
};
