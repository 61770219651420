import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getDamageReportItemStart = () => {
  return {
    type: actionTypes.GET_DAMAGE_REPORT_ITEM_START,
  };
};

export const getDamageReportItemSuccess = (data) => {
  return {
    type: actionTypes.GET_DAMAGE_REPORT_ITEM_SUCCESS,
    payload: data,
  };
};

export const getDamageReportItemsSuccess = (data) => {
  return {
    type: actionTypes.GET_DAMAGE_REPORT_ITEMS_SUCCESS,
    payload: data,
  };
};

export const getDamageReportItemFaild = (error) => {
  return {
    type: actionTypes.GET_DAMAGE_REPORT_ITEM_FAIL,
    error: error,
  };
};

export const getDamageReportItems = (token) => {
  return (dispatch) => {
    dispatch(getDamageReportItemStart());
    console.log('getting all Damage Reports');
    axios
      .get(`${HOST_URL}/damage-reports/damage-report-item/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getDamageReportItemsSuccess(response.data)))
      .catch((error) => dispatch(getDamageReportItemFaild(error)));
  };
};

export const getDamageReportItem = (token, id) => {
  return (dispatch) => {
    dispatch(getDamageReportItemStart());

    axios
      .get(`${HOST_URL}/damage-reports/damage-report-item/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getDamageReportItemSuccess(response.data)))
      .catch((error) => dispatch(getDamageReportItemFaild(error)));
  };
};

export const deleteDamageReportItem = (token, id) => {
  return (dispatch) => {
    dispatch(getDamageReportItemStart());

    axios
      .delete(`${HOST_URL}/damage-reports/damage-report-item/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getDamageReportItemSuccess(response.data)))
      .catch((error) => dispatch(getDamageReportItemFaild(error)));
  };
};

export const getDamageReportItemByJobCard = (token, jobCardNumber) => {
  return (dispatch) => {
    dispatch(getDamageReportItemStart());

    axios
      .get(
        `${HOST_URL}/damage-reports/damage-report-item/jobcard_${jobCardNumber}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getDamageReportItemSuccess(response.data)))
      .catch((error) => dispatch(getDamageReportItemFaild(error)));
  };
};

export const getDamageReportItemByReport = (token, report) => {
  return (dispatch) => {
    dispatch(getDamageReportItemStart());

    axios
      .get(`${HOST_URL}/damage-reports/damage-report-item/report_${report}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getDamageReportItemSuccess(response.data)))
      .catch((error) => dispatch(getDamageReportItemFaild(error)));
  };
};

export const postDamageReportItemData = (
  token,
  damage_report,
  job_card,
  images,
  report,
  status,
  cause_of_damage
) => {
  return (dispatch) => {
    dispatch(getDamageReportItemStart());
    let fd = new FormData();
    fd.append('damage_report', damage_report);
    fd.append('job_card', job_card);
    fd.append('images', images);
    fd.append('report', report);
    fd.append('status', status);
    fd.append('cause_of_damage', cause_of_damage);

    axios
      .post(`${HOST_URL}/damage-reports/damage-report-item/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getDamageReportItemSuccess(response.data));
        dispatch(getDamageReportItems(token));
      })
      .catch((error) => dispatch(getDamageReportItemFaild(error)));
  };
};

export const updateDamageReportItemData = (
  token,
  DamageReportItemID,
  damage_report,
  job_card,
  images,
  report,
  status,
  cause_of_damage
) => {
  return (dispatch) => {
    dispatch(getDamageReportItemStart());
    let fd = new FormData();
    fd.append('damage_report', damage_report);
    fd.append('job_card', job_card);
    fd.append('images', images);
    fd.append('report', report);
    fd.append('status', status);
    fd.append('cause_of_damage', cause_of_damage);

    axios
      .patch(
        `${HOST_URL}/damage-reports/damage-report-item/${DamageReportItemID}/`,
        fd,
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((response) => {
        dispatch(getDamageReportItemSuccess(response.data));
      })
      .catch((error) => dispatch(getDamageReportItemFaild(error)));
  };
};
