import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  error: null,
  loading: false,
  jobsTasks: [],
};

const getJobsInsightsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getJobsTasksSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobsTasks: action.payload,
  });
};

const getJobsInsightsFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const dailyJobsTasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_JOBS_INSIGHTS_START:
      return getJobsInsightsStart(state, action);
    case actionTypes.GET_JOBS_TASKS_SUCCESS:
      return getJobsTasksSuccess(state, action);
    case actionTypes.GET_CLIENT_FAIL:
      return getJobsInsightsFaild(state, action);
    default:
      return state;
  }
};

export default dailyJobsTasksReducer;
