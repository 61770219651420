import React, { Component } from 'react';
import './style.css';
import Header from '../header/Header';
// import NumericBlock from './numeric-data/NumericBlock';
import Numerics from './numeric-data/Numerics';
import DailyJobs from './daily-jobs/DailyJobs';
import DailyTasks from './daily-tasks/DailyTasks';

export default class Analysis extends Component {
  render() {
    return (
      <div className="DASHBOARD_WORKSAPCE_SUB_CONTAINER">
        <Header
          title="Business Analysis"
          description="View your business data insights"
        />
        <Numerics />
        <DailyJobs />
        <DailyTasks />
      </div>
    );
  }
}
