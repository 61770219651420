import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/job-comment.css';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import TextField from '@mui/material/TextField';
import { updateJobComment } from '../../store/actions/jobComment';

class JobComment extends Component {
  state = {
    comment: '',
    commentEditing: false,
  };

  componentDidUpdate(prevProps) {
    if (this.props.comment !== prevProps.comment && this.state.commentEditing) {
      this.setState({ commentEditing: false });
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDeleteComment = () => {
    this.props.deleteComment();
  };

  handleEditComment = () => {
    this.setState({
      commentEditing: true,
      comment: this.props.comment.comment,
    });
  };

  handleCloseEditing = () => {
    this.setState({
      commentEditing: false,
    });
  };

  handleUpdateComment = () => {
    const { id, author, task } = this.props.comment;
    const comment = this.state.comment;
    const token = this.props.token;
    this.props.updateJobComment(token, id, task, author, comment);
  };

  render() {
    return (
      <div>
        {this.props.comment ? (
          <div className="JOB_COMMENT_CONTAINER">
            <div className="JOB_COMMENT_HEADER">
              <span>
                {this.props.comment.author.charAt(0).toUpperCase()}
                {this.props.comment.author.slice(1)}
              </span>
              <div className="JOB_COMMENT_ACTION_CONTAINER">
                {this.state.commentEditing ? (
                  <div
                    className="JOB_COMMENT_ACTION_SAVE"
                    onClick={this.handleUpdateComment}
                  >
                    <Tooltip title=" Save comment">
                      <SaveIcon color="inherit" />
                    </Tooltip>
                  </div>
                ) : null}
                {this.state.commentEditing ? (
                  <div className="JOB_COMMENT_ACTION_CANCEL_EDIT">
                    <Tooltip title="Cancel Edit">
                      <CancelIcon
                        color="inherit"
                        onClick={this.handleCloseEditing}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  <div className="JOB_COMMENT_ACTION_EDIT">
                    <Tooltip title="Edit Comment">
                      <ModeEditOutlineIcon
                        color="inherit"
                        onClick={this.handleEditComment}
                      />
                    </Tooltip>
                  </div>
                )}
                <div
                  className="JOB_COMMENT_ACTION_DELETE"
                  onClick={this.handleDeleteComment}
                >
                  <Tooltip title=" Delete comment">
                    <DeleteForeverIcon color="inherit" />
                  </Tooltip>
                </div>
              </div>
            </div>

            {this.state.commentEditing ? (
              <div className="TECHNICIAN_EDIT_COMMENT_INPUT_FIELD_CONTAINER">
                <TextField
                  id="outlined-multiline-static"
                  label="Edit Comment"
                  multiline
                  rows={3}
                  // defaultValue=""
                  variant="outlined"
                  name="comment"
                  value={this.state.comment}
                  onChange={this.onChange}
                  // color="secondary"
                  type="text"
                  fullWidth
                  required
                ></TextField>
              </div>
            ) : this.props.comment ? (
              <div className="JOB_COMMENT_BODY">
                <p>{this.props.comment.comment}</p>
              </div>
            ) : null}
            <div className="JOB_COMMENT_FOOTER">
              {this.props.comment ? (
                <span>{moment(this.props.comment.created).calendar()}</span>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    jobComment: state.jobComment.comment,
    jobComments: state.jobComment.comments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateJobComment: (token, job_comment_id, task, author, comment) =>
      dispatch(updateJobComment(token, job_comment_id, task, author, comment)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobComment);
