export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const addObject = (state, newProperties) => {
  return this.setState((state) => ({
    comments: [newProperties, ...state.comments],
  }));
};
