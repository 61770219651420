import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  insurance: null,
  insurances: [],
  claim: null,
  claims: [],
};

const getInsuranceStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getClaimStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getInsuranceSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    insurance: action.payload,
  });
};

const getClaimSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    claim: action.payload,
  });
};

const getInsurancesSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    insurances: action.payload,
  });
};

const getClaimsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    claims: action.payload,
  });
};

const getInsuranceFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getClaimFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const insuranceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INSURANCE_START:
      return getInsuranceStart(state, action);
    case actionTypes.GET_INSURANCE_SUCCESS:
      return getInsuranceSuccess(state, action);
    case actionTypes.GET_INSURANCES_SUCCESS:
      return getInsurancesSuccess(state, action);
    case actionTypes.GET_INSURANCE_FAIL:
      return getInsuranceFaild(state, action);
    case actionTypes.GET_CLAIM_START:
      return getClaimStart(state, action);
    case actionTypes.GET_CLAIM_SUCCESS:
      return getClaimSuccess(state, action);
    case actionTypes.GET_CLAIMS_SUCCESS:
      return getClaimsSuccess(state, action);
    case actionTypes.GET_CLAIM_FAIL:
      return getClaimFaild(state, action);
    default:
      return state;
  }
};

export default insuranceReducer;
