import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getNotificationStart = () => {
  return {
    type: actionTypes.GET_NOTIFICATION_START,
  };
};

export const getNotificationSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATION_SUCCESS,
    payload: data,
  };
};

export const getNotificationsSuccess = (data) => {
  return {
    type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload: data,
  };
};

export const getNotificationFaild = (error) => {
  return {
    type: actionTypes.GET_NOTIFICATION_FAIL,
    error: error,
  };
};

export const getNotifications = (token) => {
  return (dispatch) => {
    dispatch(getNotificationStart());
    console.log('getting all Notifications');
    axios
      .get(`${HOST_URL}/notifications/notification/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getNotificationsSuccess(response.data)))
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const getNotification = (token, id) => {
  return (dispatch) => {
    dispatch(getNotificationStart());

    axios
      .delete(`${HOST_URL}/notifications/notification/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getNotificationSuccess(response.data)))
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const getNotificationsByTarget = (token, name) => {
  return (dispatch) => {
    dispatch(getNotificationStart());

    axios
      .get(`${HOST_URL}/notifications/notification/target_${name}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getNotificationsSuccess(response.data)))
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const postNotificationData = (
  token,
  title,
  description,
  read,
  generated_by,
  generated_to,
  generated_to_user,
  task,
  job_card
) => {
  return (dispatch) => {
    dispatch(getNotificationStart());
    let fd = new FormData();
    fd.append('title', title);
    fd.append('description', description);
    fd.append('read', read);
    fd.append('generated_by', generated_by);
    fd.append('generated_to', generated_to);
    fd.append('generated_to_user', generated_to_user);
    fd.append('task', task);
    fd.append('job_card', job_card);

    axios
      .post(`${HOST_URL}/notifications/notification/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getNotificationSuccess(response.data));
        dispatch(getNotifications(token));
      })
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const updateNotificationToRead = (
  token,
  notification_id,
  read,
  generated_to
) => {
  return (dispatch) => {
    dispatch(getNotificationStart());
    let fd = new FormData();
    fd.append('read', read);

    axios
      .patch(`${HOST_URL}/notifications/notification/${notification_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getNotificationSuccess(response.data));
        dispatch(getNotificationsByTarget(token, generated_to));
      })
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};

export const updateNotificationData = (
  token,
  notification_id,
  title,
  description,
  read,
  generated_by,
  generated_to,
  task,
  job_card
) => {
  return (dispatch) => {
    dispatch(getNotificationStart());
    let fd = new FormData();
    fd.append('title', title);
    fd.append('description', description);
    fd.append('read', read);
    fd.append('generated_by', generated_by);
    fd.append('generated_to', generated_to);
    fd.append('task', task);
    fd.append('job_card', job_card);

    axios
      .put(`${HOST_URL}/notifications/notification/${notification_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getNotificationSuccess(response.data));
        dispatch(getNotificationsByTarget(token, generated_to));
      })
      .catch((error) => dispatch(getNotificationFaild(error)));
  };
};
