import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  error: null,
  loading: false,
  supplier: null,
  suppliers: [],
};

const getSupplierStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getSupplierSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    supplier: action.payload,
  });
};

const getSuppliersSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    suppliers: action.payload,
  });
};

const getSupplierFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const supplierReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUPPLIER_START:
      return getSupplierStart(state, action);
    case actionTypes.GET_SUPPLIER_SUCCESS:
      return getSupplierSuccess(state, action);
    case actionTypes.GET_SUPPLIERS_SUCCESS:
      return getSuppliersSuccess(state, action);
    case actionTypes.GET_SUPPLIER_FAIL:
      return getSupplierFaild(state, action);
    default:
      return state;
  }
};

export default supplierReducer;
