import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';
import NumericBlock from './NumericBlock';
import { getAllNumerics } from '../../../../store/actions/insights/numeric';

class Numerics extends Component {
  componentDidMount() {
    const token = this.props.token;
    this.props.getAllNumericsData(token);
  }
  render() {
    return (
      <div style={{ marginTop: '3em' }}>
        <h2 className="NUMERICS_HEADR">Jobs Data</h2>
        <div className="NUMERIC_DATA_CONTAINER">
          {this.props.data.length > 0 &&
            this.props.data.map((item) => (
              <NumericBlock
                amount={item.amount}
                label={item.label}
                key={item.label}
              />
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    data: state.insights.allNumerics,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllNumericsData: (token) => dispatch(getAllNumerics(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Numerics);
