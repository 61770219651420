import React, { Component } from 'react';
import {
  CircularProgress,
  IconButton,
  TextField,
  Tooltip,
} from '@mui/material';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import Edit from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import Save from '@mui/icons-material/Save';
import { connect } from 'react-redux';
import {
  deleteOrderItem,
  partialUpdateOrderItemData,
} from '../../store/actions/item';

class OrderItem extends Component {
  state = {
    enableEdit: false,
    description: this.props.item.description,
    extraDetails: this.props.item.content,
    rate: this.props.item.price,
    quantity: this.props.item.quantity,
    amount: this.props.item.price * this.props.item.quantity,
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDeleteOrderItem = (itemID) => {
    const token = this.props.token;
    this.props.deleteOrderItem(token, itemID);
  };

  handleUpdateOrderItem = () => {
    const token = this.props.token;
    const itemID = this.props.item.id;
    const order = this.props.item.order.id;
    const { description, extraDetails, rate, quantity } = this.state;
    const amount = rate * quantity;
    this.props.updateOrderItem(
      token,
      itemID,
      description,
      extraDetails,
      rate,
      quantity,
      amount,
      order
    );
  };
  render() {
    return this.state.enableEdit ? (
      <div className="INVOICE_FORM_ITEMS_CONTAINER">
        <div className="INVOICE_FORM_ITEM_ADD">
          {this.props.orderItemsLoading ? (
            <CircularProgress size={30}></CircularProgress>
          ) : (
            <div className="INVOICE_FORM_SAVED_ITEM_DELETE">
              <Tooltip title="Save item">
                <IconButton
                  aria-label="save-item"
                  onClick={this.handleUpdateOrderItem}
                >
                  <Save sx={{ fontSize: 40, color: '#6E85B2' }} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Close">
                <IconButton
                  aria-label="add"
                  onClick={() => this.setState({ enableEdit: false })}
                >
                  <CloseIcon sx={{ fontSize: 40, color: '#f36767' }} />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="INVOICE_FORM_ITEM_DESCRIPTION">
          <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
            <TextField
              id="item-description"
              label="Item Description"
              variant="outlined"
              name="description"
              fullWidth
              type="text"
              value={this.state.description}
              onChange={this.onChange}
              required
              // helperText="Please select your currency"
              InputProps={{
                className: 'INVOICE_FORM_INPUT_FIELD',
              }}
              InputLabelProps={{
                // shrink: true,
                className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
          <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
            <TextField
              id="item-extra-details"
              label="Extra Details"
              multiline
              rows={6}
              variant="outlined"
              // color="secondary"
              type="text"
              name="extraDetails"
              fullWidth
              required
              // error={this.state.questionError ? true : false}
              value={this.state.extraDetails}
              onChange={this.onChange}
              InputProps={{
                className: 'INVOICE_FORM_INPUT_FIELD',
              }}
              InputLabelProps={{
                // shrink: true,
                className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
              }}
            />
          </div>
        </div>
        <div className="INVOICE_FORM_ITEM_RATE">
          <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
            <TextField
              id="item-rate"
              label="Rate"
              variant="outlined"
              name="rate"
              fullWidth
              value={this.state.rate}
              onChange={this.onChange}
              required
              // helperText="Please select your currency"
              InputProps={{
                className: 'INVOICE_FORM_INPUT_FIELD',
              }}
              InputLabelProps={{
                // shrink: true,
                className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
        </div>
        <div className="INVOICE_FORM_ITEM_QTY">
          <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
            <TextField
              id="outlined-basic"
              label="Quantity"
              variant="outlined"
              name="quantity"
              fullWidth
              value={this.state.quantity}
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'INVOICE_FORM_INPUT_FIELD',
              }}
              InputLabelProps={{
                // shrink: true,
                className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
        </div>
        <div className="INVOICE_FORM_ITEM_AMOUNT">
          <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
            <TextField
              id="outlined-basic"
              label="Amount"
              variant="outlined"
              name="amount"
              fullWidth
              value={
                this.state.rate !== 0
                  ? this.state.rate * this.state.quantity
                  : 0
              }
              onChange={this.onChange}
              // helperText="Please select your currency"
              InputProps={{
                className: 'INVOICE_FORM_INPUT_FIELD',
              }}
              InputLabelProps={{
                // shrink: true,
                className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
              }}
            ></TextField>
          </div>
        </div>
      </div>
    ) : (
      <div className="INVOICE_FORM_SAVED_ITEMS_CONTAINER" key={this.props.key}>
        <div className="INVOICE_FORM_SAVED_ITEM_DELETE">
          {this.props.orderItemsLoading || this.props.loadingInvoice ? (
            <CircularProgress sx={{ fontSize: 30 }}></CircularProgress>
          ) : (
            <div className="INVOICE_FORM_SAVED_ITEM_DELETE">
              <Tooltip title="Remove item">
                <IconButton
                  aria-label="remove-item"
                  onClick={() => this.handleDeleteOrderItem(this.props.item.id)}
                >
                  <RemoveCircleOutlineOutlinedIcon
                    sx={{ fontSize: 30, color: '#f36767' }}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit item">
                <IconButton
                  aria-label="edite-item"
                  onClick={() =>
                    this.setState({
                      enableEdit: true,
                    })
                  }
                >
                  <Edit sx={{ fontSize: 30, color: '#6E85B2' }} />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </div>
        <div className="INVOICE_FORM_SAVED_ITEM_DESCRIPTION">
          <span>{this.props.item.description}</span>
          <p>{this.props.item.content}</p>
        </div>
        <div className="INVOICE_FORM_SAVED_ITEM_RATE">
          {this.props.item.price}
        </div>
        <div className="INVOICE_FORM_SAVED_ITEM_QTY">
          {this.props.item.quantity}
        </div>
        <div className="INVOICE_FORM_SAVED_ITEM_AMOUNT">
          {this.props.item.price * this.props.item.quantity}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    orderItem: state.item.orderItem,
    orderItems: state.item.orderItems,
    orderItemsLoading: state.item.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteOrderItem: (token, orderItemID) =>
      dispatch(deleteOrderItem(token, orderItemID)),
    updateOrderItem: (
      token,
      orderItemID,
      description,
      content,
      price,
      quantity,
      amount,
      order
    ) =>
      dispatch(
        partialUpdateOrderItemData(
          token,
          orderItemID,
          description,
          content,
          price,
          quantity,
          amount,
          order
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
