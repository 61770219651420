import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  notification: null,
  notifications: [],
};

const getNotificationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getNotificationSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    notification: action.payload,
  });
};

const getNotificationsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    notifications: action.payload,
  });
};

const getNotificationFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_START:
      return getNotificationStart(state, action);
    case actionTypes.GET_NOTIFICATION_SUCCESS:
      return getNotificationSuccess(state, action);
    case actionTypes.GET_NOTIFICATIONS_SUCCESS:
      return getNotificationsSuccess(state, action);
    case actionTypes.GET_NOTIFICATION_FAIL:
      return getNotificationFaild(state, action);
    default:
      return state;
  }
};

export default NotificationReducer;
