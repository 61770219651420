import React, { Component } from 'react';
import { connect } from 'react-redux';

class RedirectLogin extends Component {
  componentDidMount() {
    localStorage.setItem('prevPath', this.props.history.location.pathname);
    this.props.history.push('/redirect-user');
  }

  render() {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.users.token !== null,
    token: state.users.token,
  };
};

export default connect(mapStateToProps, null)(RedirectLogin);
