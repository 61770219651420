import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/category-form.css';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CategoryTable from './category-table/CategoryTable';
import {
  getCategory,
  getCategories,
  updateCategory,
  saveCategory,
  deleteCategory,
} from '../../../store/actions/inventory/category';

class CategoryForm extends Component {
  state = {
    categoryID: null,
    category: '',
    title: '',
    content: '',
    categoryError: false,
    titleError: false,
    contentError: false,
  };

  componentDidMount() {
    const token = this.props.token;
    this.props.getCategories(token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.categories !== prevProps.categories) {
      this.setState({
        categoryID: null,
        category: '',
        title: '',
        content: '',
        categoryError: false,
        titleError: false,
        contentError: false,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + `Error`]: false,
    });
  };

  validateCategoryData = () => {
    const { title, content } = this.state;
    if (title === '' || content === '') {
      this.setState({
        titleError: true,
        contentError: true,
      });
      return false;
    } else {
      return true;
    }
  };

  handleSaveCategory = () => {
    const valid = this.validateCategoryData();
    if (valid) {
      const token = this.props.token;
      console.log('save');
      const { category, title, content } = this.state;
      this.props.saveCategory(token, category, title, content);
    }
  };

  handleEditCategoryDetails = (category) => {
    this.setState({
      categoryID: category.id,
      title: category.title,
      content: category.content,
    });
  };

  updateCategoryDetails = () => {
    const token = this.props.token;
    console.log('update');
    const { categoryID, title, content } = this.state;
    this.props.updateCategory(token, categoryID, title, content);
  };

  clearCategoryDetails = () => {
    this.setState({
      categoryID: null,
      category: '',
      title: '',
      content: '',
      categoryError: false,
      titleError: false,
      contentError: false,
    });
  };

  getCategories = () => {
    const token = this.props.token;
    this.props.getCategories(token);
  };

  onSelectCategory = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        category: option ? option.title : '',
      });
    } else {
      this.setState({
        category: '',
      });
    }
  };

  handleDeleteCategory = (categoryID) => {
    const token = this.props.token;
    this.props.deleteCategory(token, categoryID);
  };

  render() {
    const filterCategories = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.title,
    });
    return (
      <div className="CATEGORY_FORM_MAIN_CONTAINER">
        <div className="CATEGORY_FORM_HEADER_CONTAINER">
          <h1>CATEGORYS</h1>
        </div>
        <div className="CATEGORY_FORM_FIELDS_MAIN_CONTAINER">
          <div className="CATEGORY_FORM_INPUT_GROUP_MAIN_CONTAINER">
            <div className="CATEGORY_FORM_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-categories"
                freeSolo
                options={this.props.categories ? this.props.categories : []}
                getOptionLabel={(option) =>
                  option.title ? option.title : this.state.category
                }
                filterOptions={filterCategories}
                sx={{ color: '#fff' }}
                onOpen={() => {
                  this.getCategories();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                onChange={(e, option) => this.onSelectCategory(e, option)}
                value={this.state.category}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    name="category"
                    fullWidth
                    value={this.state.category}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.props.loadingCategories ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : (
                            <Tooltip
                              TransitionComponent={Zoom}
                              TransitionProps={{ timeout: 600 }}
                              title="Identify the parent category if needed."
                              placement="left"
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: '#dddddd',
                                    color: '#0b0f19',
                                    lineHeight: '20px',
                                    fontSize: '0.85rem',
                                  },
                                },
                              }}
                            >
                              <IconButton aria-label="info">
                                <InfoOutlinedIcon
                                  sx={{ color: '#719FB0' }}
                                  size={20}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'ADD_ITEM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div className="CATEGORY_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="category-title"
                label="Category Title"
                variant="outlined"
                name="title"
                fullWidth
                value={this.state.title}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <Tooltip
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 600 }}
                        title="The category title."
                        placement="left"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#dddddd',
                              color: '#0b0f19',
                              lineHeight: '20px',
                              fontSize: '0.85rem',
                            },
                          },
                        }}
                      >
                        <IconButton aria-label="info">
                          <InfoOutlinedIcon
                            sx={{ color: '#719FB0' }}
                            size={20}
                          />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  ),
                  className: 'CATEGORY_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'CATEGORY_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="CATEGORY_FORM_INPUT_GROUP_MAIN_CONTAINER">
            <div className="CATEGORY_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="category-content"
                label="Category Content"
                variant="outlined"
                name="content"
                fullWidth
                multiline
                rows={7}
                value={this.state.content}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <Tooltip
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 600 }}
                        title="The field used to store the category details."
                        placement="left"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#dddddd',
                              color: '#0b0f19',
                              lineHeight: '20px',
                              fontSize: '0.85rem',
                            },
                          },
                        }}
                      >
                        <IconButton
                          aria-label="info"
                          sx={{ marginBottom: 'auto' }}
                        >
                          <InfoOutlinedIcon
                            sx={{ color: '#719FB0' }}
                            size={20}
                          />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  ),
                  className: 'ADD_ITEM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'ADD_ITEM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            {this.props.loadingCategories ? (
              <div className="CATEGORY_FORM_ACTIONS_CONTAINER">
                <CircularProgress></CircularProgress>
              </div>
            ) : (
              <div className="CATEGORY_FORM_ACTIONS_CONTAINER">
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ margin: 2, backgroundColor: '#162447', color: 'white' }}
                  onClick={this.handleSaveCategory}
                >
                  Save
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ margin: 2, backgroundColor: '#162447', color: 'white' }}
                  onClick={this.updateCategoryDetails}
                >
                  Update
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="error"
                  sx={{ margin: 2 }}
                  onClick={this.clearCategoryDetails}
                >
                  Reset
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="CATEGORY_TABLE_MAIN_CONTAINER">
          <div className="CATEGORY_TABLE_CONTAINER">
            <CategoryTable
              categories={
                this.props.categories && this.props.categories[0] !== undefined
                  ? this.props.categories
                  : []
              }
              editCategory={(category) =>
                this.handleEditCategoryDetails(category)
              }
              deleteCategory={this.handleDeleteCategory}
              mode={this.props.mode}
            ></CategoryTable>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    categories: state.category.categories,
    loadingCategories: state.category.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (token) => dispatch(getCategories(token)),
    updateCategory: (token, categoryID, title, content) =>
      dispatch(updateCategory(token, categoryID, title, content)),
    saveCategory: (token, category, title, content) =>
      dispatch(saveCategory(token, category, title, content)),
    deleteCategory: (token, categoryID) =>
      dispatch(deleteCategory(token, categoryID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryForm);
