import * as React from 'react';
import './style.css';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';

export default function DataDrawer(props) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpen(open);
  };

  const list = (anchor) => (
    <Box
      sx={{ width: 350 }}
      role="presentation"
      // onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      id={props.mode}
      className="DATA_DRAWER_JOB_INFO_CONTAINER"
    >
      <IconButton
        color="error"
        aria-label="close data drawer"
        onClick={toggleDrawer(false)}
        sx={{ marginRight: 'auto' }}
      >
        <Close />
      </IconButton>
      <div className="DATA_DRAWER_JOB_INFO" id={props.mode}>
        <div className="DATA_DRAWER_JOB_INFO_ITEM">
          <span>Client:</span>
          <p>{props.jobCard?.client_details?.client_name}</p>
        </div>
        <div className="DATA_DRAWER_JOB_INFO_ITEM">
          <span>Device Desc:</span>
          <p>{props.jobCard?.device_details?.device_description}</p>
        </div>
        <div className="DATA_DRAWER_JOB_INFO_ITEM">
          <span>Device SN:</span>
          <p>{props.jobCard?.device_details?.serial_number}</p>
        </div>
        <div className="DATA_DRAWER_JOB_INFO_ITEM">
          <span>Notes:</span>
          <p>{props.jobCard?.notes}</p>
        </div>
      </div>
      <div className="DATA_DRAWER_JOB_INFO">
        <div className="DATA_DRAWER_JOB_INFO_ITEM">
          <span>Tech Report:</span>
          <p>
            {props.jobTask?.job_tech_report?.length > 0
              ? props.jobTask?.job_tech_report[0]
              : 'No tech report available.'}
          </p>
        </div>
        <div className="DATA_DRAWER_JOB_INFO_ITEM">
          <span>Damage Report:</span>
          <p>
            {props.jobCard?.job_damage_report?.length > 0
              ? props.jobCard?.job_damage_report[0]
              : 'No damage report available.'}
          </p>
        </div>
      </div>
    </Box>
  );

  return (
    <div id={props.mode}>
      <React.Fragment>
        <Button variant="outlined" size="small" onClick={toggleDrawer(true)}>
          Job Info
        </Button>
        <Drawer
          anchor="right"
          open={open}
          // hideBackdrop={true}
          onClose={toggleDrawer(false)}
          SlideProps={{
            className: 'DATA_DRAWER_SLIDE',
          }}
        >
          {list('right')}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
