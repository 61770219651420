import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
  error: null,
  loading: false,
  allNumerics: [],
  totalJobs: 10,
  totalTasks: 10,
  completeTasks: 10,
  pendingTasks: 10,
  closedTasks: 10,
};

const getJobsInsightsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getAllNumericsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    allNumerics: action.payload,
  });
};

const getTotalJobsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    totalJobs: action.payload,
  });
};

const getTotalTasksSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    totalTasks: action.payload,
  });
};

const getCompleteTasksSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    completeTasks: action.payload,
  });
};

const getClosedTasksSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    closedTasks: action.payload,
  });
};

const getPendingTasksSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    pendingTasks: action.payload,
  });
};

const getJobsInsightsFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const numericReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_JOBS_INSIGHTS_START:
      return getJobsInsightsStart(state, action);
    case actionTypes.GET_ALL_NUMERICS_SUCCESS:
      return getAllNumericsSuccess(state, action);
    case actionTypes.GET_TOTAL_JOBS_SUCCESS:
      return getTotalJobsSuccess(state, action);
    case actionTypes.GET_TOTAL_TASKS_SUCCESS:
      return getTotalTasksSuccess(state, action);
    case actionTypes.GET_COMPLETE_TASKS_SUCCESS:
      return getCompleteTasksSuccess(state, action);
    case actionTypes.GET_CLOSED_TASKS_SUCCESS:
      return getClosedTasksSuccess(state, action);
    case actionTypes.GET_PENDING_TASKS_SUCCESS:
      return getPendingTasksSuccess(state, action);
    case actionTypes.GET_CLIENT_FAIL:
      return getJobsInsightsFaild(state, action);
    default:
      return state;
  }
};

export default numericReducer;
