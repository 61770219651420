import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  subscription: null,
};

const getSubscriptionStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getSubscriptionSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    subscription: action.payload,
  });
};

const getSubscriptionFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const subscriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SUBSCRIPTION_START:
      return getSubscriptionStart(state, action);
    case actionTypes.GET_SUBSCRIPTION_SUCCESS:
      return getSubscriptionSuccess(state, action);
    case actionTypes.GET_SUBSCRIPTION_FAIL:
      return getSubscriptionFaild(state, action);
    default:
      return state;
  }
};

export default subscriptionReducer;
