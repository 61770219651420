import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getClientStart = () => {
  return {
    type: actionTypes.GET_CLIENT_START,
  };
};

export const getClientSuccess = (data) => {
  return {
    type: actionTypes.GET_CLIENT_SUCCESS,
    payload: data,
  };
};

export const getClientsSuccess = (data) => {
  return {
    type: actionTypes.GET_CLIENTS_SUCCESS,
    payload: data,
  };
};

export const getClientFaild = (error) => {
  return {
    type: actionTypes.GET_CLIENT_FAIL,
    error: error,
  };
};

export const getClients = (token) => {
  return (dispatch) => {
    dispatch(getClientStart());
    console.log('getting all clients');
    axios
      .get(`${HOST_URL}/clients/client/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getClientsSuccess(response.data)))
      .catch((error) => dispatch(getClientFaild(error)));
  };
};

export const getClient = (token, id) => {
  return (dispatch) => {
    dispatch(getClientStart());

    axios
      .get(`${HOST_URL}/clients/client/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getClientSuccess(response.data)))
      .catch((error) => dispatch(getClientFaild(error)));
  };
};

export const deleteClient = (token, id) => {
  return (dispatch) => {
    dispatch(getClientStart());

    axios
      .delete(`${HOST_URL}/clients/client/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getClients(token)))
      .catch((error) => dispatch(getClientFaild(error)));
  };
};

export const getClientByName = (token, name) => {
  return (dispatch) => {
    dispatch(getClientStart());

    axios
      .get(`${HOST_URL}/clients/client/name-${name}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getClientSuccess(response.data)))
      .catch((error) => dispatch(getClientFaild(error)));
  };
};

export const postClientData = (
  token,
  client_name,
  client_email,
  client_number,
  client_alternative_number,
  client_address,
  client_vat_reg_number
) => {
  return (dispatch) => {
    dispatch(getClientStart());
    let fd = new FormData();
    fd.append('client_name', client_name);
    fd.append('client_email', client_email);
    fd.append('client_number', client_number);
    fd.append('client_alternative_number', client_alternative_number);
    fd.append('client_address', client_address);
    fd.append('client_vat_reg_number', client_vat_reg_number);

    axios
      .post(`${HOST_URL}/clients/client/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getClientSuccess(response.data));
        dispatch(getClients(token));
      })
      .catch((error) => dispatch(getClientFaild(error)));
  };
};

export const updateClientData = (
  token,
  client_id,
  client_name,
  client_email,
  client_number,
  client_alternative_number,
  client_address,
  client_vat_reg_number
) => {
  return (dispatch) => {
    dispatch(getClientStart());
    let fd = new FormData();
    fd.append('client_name', client_name);
    fd.append('client_email', client_email);
    fd.append('client_number', client_number);
    fd.append('client_alternative_number', client_alternative_number);
    fd.append('client_address', client_address);
    fd.append('client_vat_reg_number', client_vat_reg_number);

    axios
      .patch(`${HOST_URL}/clients/client/${client_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getClientSuccess(response.data));
        dispatch(getClients(token));
      })
      .catch((error) => dispatch(getClientFaild(error)));
  };
};
