import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/brands-form.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BrandTable from './brand-table/BrandTable';
import {
  deleteBrand,
  getBrand,
  getBrands,
  saveBrand,
  updateBrand,
} from '../../../store/actions/inventory/brand';

class BrandsForm extends Component {
  state = {
    brandID: null,
    name: '',
    summary: '',
    content: '',
    nameError: false,
    summaryError: false,
    contentError: false,
  };

  componentDidMount() {
    const token = this.props.token;
    this.props.getBrands(token);
  }

  componentDidUpdate(prevProps) {
    if (this.props.brands !== prevProps.brands) {
      this.setState({
        brandID: null,
        name: '',
        summary: '',
        content: '',
        nameError: false,
        summaryError: false,
        contentError: false,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + `Error`]: false,
    });
  };

  validateBrandData = () => {
    const { name, summary, content } = this.state;
    if (name === '' || summary === '' || content === '') {
      this.setState({
        nameError: true,
        summaryError: true,
        contentError: true,
      });
      return false;
    } else {
      return true;
    }
  };

  handleSaveBrand = () => {
    const valid = this.validateBrandData();
    if (valid) {
      const token = this.props.token;
      console.log('save');
      const { name, summary, content } = this.state;
      this.props.saveBrand(token, name, summary, content);
    }
  };

  handleEditBrandDetails = (brand) => {
    this.setState({
      brandID: brand.id,
      name: brand.name,
      summary: brand.summary,
      content: brand.content,
    });
  };

  updateBrandDetails = () => {
    const token = this.props.token;
    console.log('update');
    const { brandID, name, summary, content } = this.state;
    this.props.updateBrand(token, brandID, name, summary, content);
  };

  clearBrandDetails = () => {
    this.setState({
      brandID: null,
      name: '',
      summary: '',
      content: '',
      nameError: false,
      summaryError: false,
      contentError: false,
    });
  };

  handleDeleteBrand = (brandID) => {
    const token = this.props.token;
    this.props.deleteBrand(token, brandID);
  };

  render() {
    return (
      <div className="BRAND_FORM_MAIN_CONTAINER">
        <div className="BRAND_FORM_HEADER_CONTAINER">
          <h1>BRANDS</h1>
        </div>
        <div className="BRAND_FORM_FIELDS_MAIN_CONTAINER">
          <div className="BRAND_FORM_INPUT_GROUP_MAIN_CONTAINER">
            <div className="BRAND_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="brand-name"
                label="Brand Name"
                variant="outlined"
                name="name"
                fullWidth
                value={this.state.name}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <Tooltip
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 600 }}
                        title="The brand name to be displayed on the Inventory."
                        placement="left"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#dddddd',
                              color: '#0b0f19',
                              lineHeight: '20px',
                              fontSize: '0.85rem',
                            },
                          },
                        }}
                      >
                        <IconButton aria-label="info">
                          <InfoOutlinedIcon
                            sx={{ color: '#719FB0' }}
                            size={20}
                          />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  ),
                  className: 'BRAND_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'BRAND_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="BRAND_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="brand-summary"
                label="Brand Summary"
                variant="outlined"
                name="summary"
                fullWidth
                multiline
                rows={7}
                value={this.state.summary}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <Tooltip
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 600 }}
                        title="The summary mentions the key highlights."
                        placement="left"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#dddddd',
                              color: '#0b0f19',
                              lineHeight: '20px',
                              fontSize: '0.85rem',
                            },
                          },
                        }}
                      >
                        <IconButton
                          aria-label="info"
                          sx={{ marginBottom: 'auto' }}
                        >
                          <InfoOutlinedIcon
                            sx={{ color: '#719FB0' }}
                            size={20}
                          />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  ),
                  className: 'BRAND_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'BRAND_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="BRAND_FORM_INPUT_GROUP_MAIN_CONTAINER">
            <div className="BRAND_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="brand-content"
                label="Brand Content"
                variant="outlined"
                name="content"
                fullWidth
                multiline
                rows={7}
                value={this.state.content}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <Tooltip
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 600 }}
                        title="Used to store the additional details of the brand."
                        placement="left"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#dddddd',
                              color: '#0b0f19',
                              lineHeight: '20px',
                              fontSize: '0.85rem',
                            },
                          },
                        }}
                      >
                        <IconButton
                          aria-label="info"
                          sx={{ marginBottom: 'auto' }}
                        >
                          <InfoOutlinedIcon
                            sx={{ color: '#719FB0' }}
                            size={20}
                          />
                        </IconButton>
                      </Tooltip>
                    </React.Fragment>
                  ),
                  className: 'BRAND_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'BRAND_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            {this.props.loadingBrands ? (
              <div className="BRAND_FORM_ACTIONS_CONTAINER">
                <CircularProgress></CircularProgress>
              </div>
            ) : (
              <div className="BRAND_FORM_ACTIONS_CONTAINER">
                <Button
                  variant="outlined"
                  size="large"
                  sx={{ margin: 2, backgroundColor: '#162447', color: 'white' }}
                  onClick={this.handleSaveBrand}
                >
                  Save
                </Button>

                <Button
                  variant="outlined"
                  size="large"
                  sx={{ margin: 2, backgroundColor: '#162447', color: 'white' }}
                  onClick={this.updateBrandDetails}
                >
                  Update
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  color="error"
                  sx={{ margin: 2 }}
                  onClick={this.clearBrandDetails}
                >
                  Reset
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="BRAND_TABLE_MAIN_CONTAINER">
          <div className="BRAND_TABLE_CONTAINER">
            <BrandTable
              brands={
                this.props.brands && this.props.brands[0] !== undefined
                  ? this.props.brands
                  : []
              }
              editBrand={(brand) => this.handleEditBrandDetails(brand)}
              deleteBrand={this.handleDeleteBrand}
              mode={this.props.mode}
            ></BrandTable>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    brands: state.brand.brands,
    loadingBrands: state.brand.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBrands: (token) => dispatch(getBrands(token)),
    updateBrand: (token, brandID, name, summary, content) =>
      dispatch(updateBrand(token, brandID, name, summary, content)),
    saveBrand: (token, name, summary, content) =>
      dispatch(saveBrand(token, name, summary, content)),
    deleteBrand: (token, brandID) => dispatch(deleteBrand(token, brandID)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsForm);
