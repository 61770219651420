import React from 'react';
import moment from 'moment';
import './styles/notification-menu-item.css';

export default function NotificationMenuItem(props) {
  return (
    <React.Fragment>
      <div
        className="NOTIFICATION_ITEM_CONTAINER"
        style={
          props.notification.read
            ? { backgroundColor: 'none' }
            : { backgroundColor: '#3c415c' }
        }
      >
        <div className="NOTIFICATION_ITEM_HEADER">
          <span>{props.notification.title}</span>
        </div>
        <div className="NOTIFICATION_ITEM_BODY">
          <p>{props.notification.description}</p>
        </div>
        <div className="NOTIFICATION_ITEM_FOOTER">
          <span>{moment(props.notification.created).calendar()}</span>
        </div>
      </div>
    </React.Fragment>
  );
}
