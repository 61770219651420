import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/login.css';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import * as userActions from '../../store/actions/auth';
import { linkTeamMember } from '../../store/actions/teamMember';
import CircularProgress from '@mui/material/CircularProgress';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';

class Login extends Component {
  state = {
    username: '',
    password: '',
    prevPath: localStorage.getItem('prevPath')
      ? localStorage.getItem('prevPath')
      : '',
    open: true,
    messageOpen: false,
  };

  redirect = (path) => {
    window.location.href = path;
  };

  handleMessageOpen = () => this.setState({ messageOpen: true });
  handleMessageClose = () => this.setState({ messageOpen: false });

  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {
    setTimeout(() => this.setState({ open: false }), 1000);
    localStorage.setItem('prevPath', this.props.history.location.pathname);

    if (this.props.token !== null) {
      this.handleRedirecting();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.token !== prevProps.token) {
      if (this.props.token !== null) {
        this.props.getUserDetails(this.props.token);
      }
    }

    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.userDetails
    ) {
      if (!this.props.userDetails.teamMember && this.props.match.params.key) {
        const key = this.props.match.params.key;
        this.props.linkMember(this.props.token, key);
      } else {
        this.setState({ open: false });
        this.handleRedirecting();
      }
    }
    if (this.props.teamMember !== prevProps.teamMember) {
      this.props.getUserDetails(this.props.token);
      this.handleRedirecting();
    }
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  handleRedirecting = () => {
    console.log('redirecting');
    localStorage.setItem('prevPath', this.props.history.location.pathname);
    this.props.history.push('/redirect-user');
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    this.props.onAuth(username.toLowerCase(), password);
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    return (
      <div className="LOGIN_MAIN_CONTAINER">
        <div className="LOGIN_CONTAINER">
          <Backdrop
            sx={{ color: '#fff' }}
            open={this.state.open}
            onClick={this.handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          {!this.state.open ? (
            <form className="form-signin mb-3" onSubmit={this.onSubmit}>
              <div className="text-center mb-4">
                <span className="text-primary">
                  {this.props.loading ? (
                    <div className="LOGIN_FORM_ICON">
                      <CircularProgress fontSize="inherit"></CircularProgress>
                    </div>
                  ) : (
                    <div className="LOGIN_FORM_ICON">
                      <LockOpenIcon
                        color="inherit"
                        fontSize="inherit"
                      ></LockOpenIcon>
                    </div>
                  )}
                </span>
              </div>

              {this.props.error ? (
                <span className="small" id="wrongCredentials">
                  {this.props.error.response
                    ? this.props.error.response.data['non_field_errors']
                      ? this.props.error.response.data['non_field_errors'][0]
                      : null
                    : null}
                </span>
              ) : null}

              <div className="form-group SIGNUP_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="login-username"
                  label="Username"
                  variant="outlined"
                  name="username"
                  fullWidth
                  value={this.state.username}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'LOGIN_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'LOGIN_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>

              <div className="form-group SIGNUP_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="login-password"
                  type="password"
                  label="Password"
                  variant="outlined"
                  name="password"
                  fullWidth
                  value={this.state.password}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'LOGIN_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'LOGIN_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div style={{ marginTop: '3em' }}>
                <Button
                  variant="contained"
                  className="LOGIN_BUTTON_CONTAINER"
                  type="submit"
                  onClick={this.onSubmit}
                >
                  <h6>Sign in</h6>
                </Button>
              </div>
              {/* <div className="FORGOT_PASSWORD_CONTAINER">
              <h6 onClick={() => this.props.history.push('/forgot-password')}>
                Forgot Password
              </h6>
            </div> */}
            </form>
          ) : null}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.messageOpen}
          onClose={this.handleMessageCloseClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={this.state.messageOpen}>
            <Box sx={style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
              >
                Membership not found!
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                If you haven't activated your membership, kindly acquire a
                subscription to utilize TimeFlow for organizing your company's
                workflow. Alternatively, if you're a team member in a
                TimeFlow-utilizing company, kindly reach out to the owner to
                have yourself added to the system.
              </Typography>
              <Box
                sx={{
                  width: 400,
                  marginTop: 2,
                  display: 'flex',

                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}
              >
                <Button
                  variant="outlined"
                  color="inherit"
                  onClick={() => this.navigate('https://timeflow.co.za/')}
                >
                  Back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => this.navigate('https://timeflow.co.za/plans')}
                >
                  Subscribe
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.users.loading,
    token: state.users.token,
    error: state.users.error,
    userDetails: state.users.userDetails,
    teamMember: state.teamMember.teamMember,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (username, password) =>
      dispatch(userActions.authLogin(username, password)),
    getUserDetails: (token) => dispatch(userActions.getUserDetails(token)),
    linkMember: (token, key) => dispatch(linkTeamMember(token, key)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
