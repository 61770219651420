import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles/stock-form.css';
import StockTable from './stock-table/StockTable';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { getItems } from '../../../store/actions/item';

class StockForm extends Component {
  state = {
    itemToDelete: null,
    openMessageModal: false,
    messageModalMessageTitle: '',
    messageModalMessageBody: '',
  };

  componentDidMount() {
    this.props.getItems(this.props.token);
  }

  handleEditItemOpen = (item_code) => {
    this.props.history.push(`/inventory-manager/add-item/${item_code}`);
    localStorage.setItem('prevPath', '/inventory-manager/add-item');
  };

  handleMessageClose = () => {
    this.setState({ openMessageModal: false });
  };

  handleMessageOpen = (title, body) => {
    this.setState({
      messageModalMessageTitle: title,
      messageModalMessageBody: body,
      openMessageModal: true,
    });
  };

  deleteItemWarning = (itemID) => {
    this.setState({ itemToDelete: itemID });
    this.handleMessageOpen(
      'Warning',
      'Are you sure you want to delete this item from your inventory?'
    );
  };

  handleDeleteItem = () => {
    this.props.deleteItem(this.props.token, this.state.itemToDelete);
  };

  render() {
    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      // border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    return (
      <div className="STOCK_FORM_MAIN_CONTAINER">
        <div className="STOCK_FORM_HEADER_CONTAINER">
          <h1>STOCK</h1>
        </div>
        <div className="STOCK_TABLE_MAIN_CONTAINER">
          {this.props.loadingItems ? (
            <CircularProgress></CircularProgress>
          ) : (
            <div className="STOCK_TABLE_CONTAINER">
              <StockTable
                items={
                  this.props.items && this.props.items[0] !== undefined
                    ? this.props.items
                    : []
                }
                editItem={(item_code) => this.handleEditItemOpen(item_code)}
                deleteItem={this.deleteItemWarning}
                mode={this.props.mode}
              ></StockTable>
            </div>
          )}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openMessageModal}
          onClose={this.handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.messageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.messageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button
                  variant="outlined"
                  sx={{ marginRight: 2 }}
                  onClick={this.handleMessageClose}
                >
                  Close
                </Button>
                <Button variant="outlined" onClick={this.handleDeleteItem}>
                  Yes
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    items: state.item.items,
    loadingItems: state.item.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getItems: (token) => dispatch(getItems(token)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StockForm));
