import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  lastInvoice: null,
  invoice: null,
  invoices: [],
};

const getInvoiceStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getInvoiceSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    invoice: action.payload,
  });
};

const getLastInvoiceSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    lastInvoice: action.payload,
  });
};

const getInvoicesSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    invoices: action.payload,
  });
};

const deleteInvoicesSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    invoices: null,
  });
};

const getInvoiceFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    invoice: null,
  });
};

const clearInvoiceProps = (state, action) => {
  return updateObject(state, {
    invoice: null,
  });
};

const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INVOICE_START:
      return getInvoiceStart(state, action);
    case actionTypes.GET_INVOICE_SUCCESS:
      return getInvoiceSuccess(state, action);
    case actionTypes.GET_LAST_INVOICE_SUCCESS:
      return getLastInvoiceSuccess(state, action);
    case actionTypes.GET_INVOICES_SUCCESS:
      return getInvoicesSuccess(state, action);
    case actionTypes.GET_INVOICE_FAIL:
      return getInvoiceFaild(state, action);
    case actionTypes.DELETE_INVOICE_SUCCESS:
      return deleteInvoicesSuccess(state, action);
    case actionTypes.CLEAR_INVOICE_DATA:
      return clearInvoiceProps(state, action);
    default:
      return state;
  }
};

export default invoiceReducer;
