import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getOrdersByPeriod } from '../../../store/actions/order';
import './statement-period.css';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment';
import { getFinancialAccountByAccountNumber } from '../../../store/actions/financialAccounts';

class StatementPeriod extends Component {
  state = {
    periodBalance: parseFloat(0).toFixed(2),
    paidAmount: parseFloat(0).toFixed(2),
    creditAmount: parseFloat(0).toFixed(2),
  };

  componentDidMount() {
    const token = this.props.token;
    const accountNumber = this.props.clientAccount;

    this.props.getActiveFinancialAccount(token, accountNumber);
  }

  componentDidUpdate(prevProps) {
    if (this.props.periodOrders !== prevProps.periodOrders) {
      this.calculateBalanceAmount();
    }
    if (this.props.clientAccount !== prevProps.clientAccount) {
      const token = this.props.token;
      const accountNumber = this.props.clientAccount;
      this.props.getActiveFinancialAccount(token, accountNumber);
    }
    if (
      this.props.financialAccount !== prevProps.financialAccount &&
      this.props.financialAccount
    ) {
      this.handlePeriodCalculations();
    }
  }

  calculate120Days = () => {
    const date = new Date();
    const firstDate = new Date(date.getFullYear(), date.getMonth() - 4, 1);
    const secondDate = new Date(date.getFullYear(), date.getMonth() - 3, 1);
    console.log(firstDate);
    console.log(secondDate);
    return {
      firstDate: moment(firstDate).format(),
      secondDate: moment(secondDate).format(),
    };
  };

  calculate120PlusDays = () => {
    const date = new Date();
    const firstDate = this.props.financialAccount.created_at; // the date the account was created
    const secondDate = new Date(date.getFullYear(), date.getMonth() - 3, 1);
    console.log(firstDate);
    console.log(secondDate);
    return {
      firstDate: moment(firstDate).format(),
      secondDate: moment(secondDate).format(),
    };
  };

  // calculate150Days = () => {
  //   const date = new Date();
  //   const firstDate = new Date(date.getFullYear(), date.getMonth() - 5, 1);
  //   const secondDate = new Date(date.getFullYear(), date.getMonth() - 4, 1);
  //   console.log(firstDate);
  //   console.log(secondDate);
  //   return firstDate, secondDate;
  // };

  // calculate180Days = () => {
  //   const date = new Date();
  //   const firstDate = new Date(date.getFullYear(), date.getMonth() - 6, 1);
  //   const secondDate = new Date(date.getFullYear(), date.getMonth() - 5, 1);
  //   console.log(firstDate);
  //   console.log(secondDate);
  //   return firstDate, secondDate;
  // };

  calculateBalanceAmount = () => {
    let balance = parseFloat(0);
    let paid = parseFloat(0);
    let credit = parseFloat(0);
    if (this.props.periodOrders.length > 0) {
      this.props.periodOrders.map((order) => {
        if (order.status === 'unpaid') {
          balance =
            parseFloat(balance) +
            parseFloat(order.grand_total - order.allocated_amount);
          paid = parseFloat(paid) + parseFloat(order.allocated_amount);
        } else if (order.status === 'credited') {
          balance = parseFloat(balance) - parseFloat(order.grand_total);
          credit = parseFloat(credit) + parseFloat(order.grand_total);
        } else {
          paid = parseFloat(paid) + parseFloat(order.grand_total);
        }
      });
    }
    console.log(parseFloat(balance).toFixed(2));

    this.setState({
      periodBalance: parseFloat(balance).toFixed(2),
      paidAmount: paid,
      creditAmount: credit,
    });
    this.props.periodBalance(parseFloat(balance).toFixed(2));
  };

  handlePeriodCalculations = () => {
    const token = this.props.token;
    const period = this.props.period;
    const clientAccount = this.props.clientAccount;
    const { firstDate, secondDate } = this.calculate120PlusDays();
    this.props.getPeriodOrders(
      token,
      firstDate,
      secondDate,
      clientAccount,
      period
    );
  };

  render() {
    return (
      <div className="STATEMENT_PERIOD_CONTAINER">
        {this.props.loadingOrder ? (
          <CircularProgress
            sx={{ color: '#f39422' }}
            size={50}
          ></CircularProgress>
        ) : (
          <>
            <span>{this.props.period}</span>
            <h2>{this.state.periodBalance}</h2>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    periodOrders: state.order.periodOrders120plus,
    loadingOrder: state.order.loading,
    financialAccount: state.financialAccount.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPeriodOrders: (token, startDate, endDate, accountNumber, period) =>
      dispatch(
        getOrdersByPeriod(token, startDate, endDate, accountNumber, period)
      ),
    getActiveFinancialAccount: (token, accountNumber) =>
      dispatch(getFinancialAccountByAccountNumber(token, accountNumber)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatementPeriod);
