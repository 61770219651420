import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getTeamMemberStart = () => {
  return {
    type: actionTypes.GET_TEAM_MEMBER_START,
  };
};

export const getTeamMemberSuccess = (data) => {
  return {
    type: actionTypes.GET_TEAM_MEMBER_SUCCESS,
    payload: data,
  };
};

export const getAllPermissionsSuccess = (data) => {
  return {
    type: actionTypes.GET_ALL_PERMISSIONS_SUCCESS,
    payload: data,
  };
};

export const getTeamMembersSuccess = (data) => {
  return {
    type: actionTypes.GET_TEAM_MEMBERS_SUCCESS,
    payload: data,
  };
};

export const getTeamMemberFaild = (error) => {
  return {
    type: actionTypes.GET_TEAM_MEMBER_FAIL,
    error: error,
  };
};

export const getAllTeamMembers = (token) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    console.log('getting members');
    axios
      .get(`${HOST_URL}/users/team-member/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTeamMembersSuccess(response.data)))
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const getAllPermissions = (token) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    console.log('getting members');
    axios
      .get(`${HOST_URL}/users/member-permissions/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getAllPermissionsSuccess(response.data)))
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const getTeamMembers = (token, keyword, value) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    console.log('getting members');
    axios
      .get(`${HOST_URL}/users/team-member/${keyword}-${value}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTeamMembersSuccess(response.data)))
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const getTeamMember = (token, id) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());

    axios
      .get(`${HOST_URL}/users/team-member/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTeamMemberSuccess(response.data)))
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const postTeamMember = (
  token,
  company_name,
  branch_name,
  first_name,
  last_name,
  job_title,
  email,
  keyword,
  value
) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    let fd = new FormData();
    fd.append('company_name', company_name);
    fd.append('branch_name', branch_name);
    fd.append('first_name', first_name);
    fd.append('last_name', last_name);
    fd.append('job_title', job_title);
    fd.append('email', email);

    axios
      .post(`${HOST_URL}/users/team-member/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTeamMemberSuccess(response.data));
        dispatch(getTeamMembers(token, keyword, value));
      })
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const updateTeamMember = (
  token,
  team_member_id,
  company_name,
  branch_name,
  first_name,
  last_name,
  job_title,
  email,
  keyword,
  value
) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    let fd = new FormData();
    fd.append('company_name', company_name);
    fd.append('branch_name', branch_name);
    fd.append('first_name', first_name);
    fd.append('last_name', last_name);
    fd.append('job_title', job_title);
    fd.append('email', email);

    axios
      .patch(`${HOST_URL}/users/team-member/${team_member_id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTeamMemberSuccess(response.data));
        dispatch(getTeamMembers(token, keyword, value));
      })
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const deleteTeamMemberPermission = (token, memberID, permissionID) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    let fd = new FormData();
    fd.append('target', 'permission');
    fd.append('action', 'delete');
    fd.append('permission_id', permissionID);

    axios
      .patch(`${HOST_URL}/users/team-member/${memberID}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTeamMemberSuccess(response.data));
        dispatch(getAllTeamMembers(token));
      })
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const addTeamMemberPermission = (token, memberID, permissionID) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    let fd = new FormData();
    fd.append('target', 'permission');
    fd.append('action', 'add');
    fd.append('permission_id', permissionID);

    axios
      .patch(`${HOST_URL}/users/team-member/${memberID}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTeamMemberSuccess(response.data));
        dispatch(getAllTeamMembers(token));
      })
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const inviteTeamMember = (token, data) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    let fd = new FormData();
    fd.append('company', data.companyId);
    fd.append('branch', data.branchId);
    fd.append('first_name', data.firstName);
    fd.append('last_name', data.lastName);
    fd.append('job_title', data.jobTitle);
    fd.append('email', data.email);

    axios
      .post(`${HOST_URL}/users/team-member/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getTeamMemberSuccess(response.data));
        dispatch(getAllTeamMembers(token));
      })
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};

export const linkTeamMember = (token, key) => {
  return (dispatch) => {
    dispatch(getTeamMemberStart());
    let fd = new FormData();
    fd.append('key', key);

    axios
      .post(`${HOST_URL}/users/link-member/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getTeamMemberSuccess(response.data)))
      .catch((error) => dispatch(getTeamMemberFaild(error)));
  };
};
