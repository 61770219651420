import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getFinancialAccountStart = () => {
  return {
    type: actionTypes.GET_FINANCIAL_ACCOUNT_START,
  };
};

export const getFinancialAccountSuccess = (data) => {
  return {
    type: actionTypes.GET_FINANCIAL_ACCOUNT_SUCCESS,
    payload: data,
  };
};

export const getFinancialAccountsSuccess = (data) => {
  return {
    type: actionTypes.GET_FINANCIAL_ACCOUNTS_SUCCESS,
    payload: data,
  };
};

export const getFinancialAccountFaild = (error) => {
  return {
    type: actionTypes.GET_FINANCIAL_ACCOUNT_FAIL,
    error: error,
  };
};

export const getFinancialAccounts = (token) => {
  return (dispatch) => {
    dispatch(getFinancialAccountStart());
    console.log('getting all clients');
    axios
      .get(`${HOST_URL}/financial-accounts/financial-account/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getFinancialAccountsSuccess(response.data)))
      .catch((error) => dispatch(getFinancialAccountFaild(error)));
  };
};

export const getFinancialAccount = (token, id) => {
  return (dispatch) => {
    dispatch(getFinancialAccountStart());

    axios
      .get(`${HOST_URL}/financial-accounts/financial-account/${id}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getFinancialAccountSuccess(response.data)))
      .catch((error) => dispatch(getFinancialAccountFaild(error)));
  };
};

export const getFinancialAccountByAccountNumber = (token, accountNumber) => {
  return (dispatch) => {
    dispatch(getFinancialAccountStart());

    axios
      .get(
        `${HOST_URL}/financial-accounts/financial-account/accountnumber_${accountNumber}/`,
        {
          headers: { Authorization: `Token ${token}` },
        }
      )
      .then((response) => dispatch(getFinancialAccountSuccess(response.data)))
      .catch((error) => dispatch(getFinancialAccountFaild(error)));
  };
};

export const postFinancialAccountData = (
  token,
  client,
  insurance,
  account_number
) => {
  return (dispatch) => {
    dispatch(getFinancialAccountStart());
    let fd = new FormData();
    fd.append('client', client);
    fd.append('insurance', insurance);
    fd.append('account_number', account_number);

    axios
      .post(`${HOST_URL}/financial-accounts/financial-account/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getFinancialAccountSuccess(response.data));
        dispatch(getFinancialAccounts(token));
      })
      .catch((error) => dispatch(getFinancialAccountFaild(error)));
  };
};

export const updateFinancialAccountData = (token, id, account_number) => {
  return (dispatch) => {
    dispatch(getFinancialAccountStart());
    let fd = new FormData();
    fd.append('account_number', account_number);

    axios
      .patch(`${HOST_URL}/financial-accounts/financial-account/${id}/`, fd, {
        headers: {
          Authorization: `Token ${token}`,
        },
      })
      .then((response) => {
        dispatch(getFinancialAccountSuccess(response.data));
        dispatch(getFinancialAccounts(token));
      })
      .catch((error) => dispatch(getFinancialAccountFaild(error)));
  };
};
