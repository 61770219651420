import * as actionTypes from './actionTypes';
import axios from 'axios';
import { HOST_URL } from '../../settings';

export const getCompanyStart = () => {
  return {
    type: actionTypes.GET_COMPANY_START,
  };
};

export const getCompanyDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_COMPANY_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getBrancesDetailsSuccess = (data) => {
  return {
    type: actionTypes.GET_BRANCHES_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getCompanyBankingDetails = (data) => {
  return {
    type: actionTypes.GET_COMPANY_BANKING_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getCompanyFaild = (error) => {
  return {
    type: actionTypes.GET_COMPANY_FAIL,
    error: error,
  };
};

export const getCompanyDetails = (token) => {
  return (dispatch) => {
    dispatch(getCompanyStart());
    axios
      .get(`${HOST_URL}/company/company-details/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCompanyDetailsSuccess(response.data)))
      .catch((error) => dispatch(getCompanyFaild(error)));
  };
};

export const getBrnchesDetails = (token, companyId) => {
  return (dispatch) => {
    dispatch(getCompanyStart());
    axios
      .get(`${HOST_URL}/company/branch-details/company_${companyId}/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getBrancesDetailsSuccess(response.data)))
      .catch((error) => dispatch(getCompanyFaild(error)));
  };
};

export const getBankingDetails = (token) => {
  return (dispatch) => {
    dispatch(getCompanyStart());

    axios
      .get(`${HOST_URL}/company/company-banking-details/`, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCompanyBankingDetails(response.data)))
      .catch((error) => dispatch(getCompanyFaild(error)));
  };
};

// export const saveBranchDetails = (token, data) => {
//   return (dispatch) => {
//     dispatch(getCompanyStart());
//     let fd = new FormData();
//     fd.append('name', data.name);
//     fd.append('country', data.country);
//     fd.append('region', data.region);
//     fd.append('address', data.address);

//     axios
//       .post(`${HOST_URL}/company/branch-details/`, fd, {
//         headers: { Authorization: `Token ${token}` },
//       })
//       .then((response) => dispatch(getCompanyBankingDetails(response.data)))
//       .catch((error) => dispatch(getCompanyFaild(error)));
//   };
// };

// export const saveBankingDetails = (token, data) => {
//   return (dispatch) => {
//     dispatch(getCompanyStart());
//     let fd = new FormData();
//     fd.append('bank', data.bank);
//     fd.append('account_name', data.accountName);
//     fd.append('account_number', data.accountNumber);
//     fd.append('branch_code', data.branchCode);

//     axios
//       .post(`${HOST_URL}/company/company-banking-details/`, fd, {
//         headers: { Authorization: `Token ${token}` },
//       })
//       .then((response) => dispatch(getCompanyBankingDetails(response.data)))
//       .catch((error) => dispatch(getCompanyFaild(error)));
//   };
// };

export const updateBankingDetails = (
  token,
  id,
  bank,
  account_name,
  account_number,
  branch_code
) => {
  return (dispatch) => {
    dispatch(getCompanyStart());
    let fd = new FormData();
    fd.append('bank', bank);
    fd.append('account_name', account_name);
    fd.append('account_number', account_number);
    fd.append('branch_code', branch_code);

    axios
      .patch(`${HOST_URL}/company/company-banking-details/${id}/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCompanyBankingDetails(response.data)))
      .catch((error) => dispatch(getCompanyFaild(error)));
  };
};

// export const saveCompanyDetails = (token, data) => {
//   return (dispatch) => {
//     dispatch(getCompanyStart());
//     let fd = new FormData();
//     fd.append('name', data.companyName);
//     fd.append('size', data.companySize);
//     fd.append('fax_number', data.faxNumber);
//     fd.append('registraion_number', data.regNumber);
//     fd.append('vat_registraion_number', data.vatRegistraionNumber);
//     fd.append('country', data.country);
//     fd.append('address', data.address);
//     fd.append('website', data.website);
//     fd.append('email', data.email);
//     fd.append('telephone_number', data.companyPhone);

//     axios
//       .post(`${HOST_URL}/company/company-details/`, fd, {
//         headers: { Authorization: `Token ${token}` },
//       })
//       .then((response) => dispatch(getCompanyDetailsSuccess(response.data)))
//       .catch((error) => dispatch(getCompanyFaild(error)));
//   };
// };

export const updateCompanyDetails = (token, id, data) => {
  return (dispatch) => {
    dispatch(getCompanyStart());
    let fd = new FormData();
    fd.append('name', data.name);
    fd.append('size', data.size);
    fd.append('fax_number', data.fax_number);
    fd.append('registraion_number', data.registraion_number);
    fd.append('vat_registraion_number', data.vat_registraion_number);
    fd.append('country', data.country);
    fd.append('address', data.address);
    fd.append('website', data.website);
    fd.append('email', data.email);
    fd.append('banking_details', data.banking_details);
    fd.append('terms_and_conditions', data.terms_and_conditions);
    fd.append(
      'invoice_terms_and_conditions',
      data.invoice_terms_and_conditions
    );
    fd.append(
      'quotation_terms_and_conditions',
      data.quotation_terms_and_conditions
    );

    axios
      .patch(`${HOST_URL}/company/company-details/${id}/`, fd, {
        headers: { Authorization: `Token ${token}` },
      })
      .then((response) => dispatch(getCompanyDetailsSuccess(response.data)))
      .catch((error) => dispatch(getCompanyFaild(error)));
  };
};

export const createCompanyProfile = async (token, data) => {
  try {
    const response = await axios.post(
      `${HOST_URL}/company/company-profile/`,
      data,
      {
        headers: { Authorization: `Token ${token}` },
      }
    );

    return response;
  } catch (error) {
    return error;
  }
};
