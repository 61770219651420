import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import './header.css';
import * as userActions from '../../store/actions/auth';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import UserActionMenu from './header-components/user-actions/user-action-menu/UserActionMenu';
import UserNotificationMenu from './header-components/user-actions/notification-menu/UserNotificationMenu';
import { Button, IconButton } from '@mui/material';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';

class Header extends Component {
  state = { open: false, anchorEl: null, mobileMoreAnchorEl: null };
  onClick = (e) => {
    e.preventDefault();
    this.props.onAuth();
  };
  toggleDrawer = (open) => {
    this.setState({ open: open });
  };
  navigate = (path) => {
    this.props.history.push(path);
  };

  handleLogout = () => {
    this.props.logout();
    this.navigate('/login');
  };

  handleGoToProfile = () => {
    this.navigate('/my-profile');
  };

  switchMode = () => {
    const token = this.props.token;
    const id = this.props.theme?.id;
    if (this.props.theme?.mode === 'light') {
      this.props.setMode(token, id, 'dark');
      localStorage.setItem('mode', 'dark');
    } else {
      this.props.setMode(token, id, 'light');
      localStorage.setItem('mode', 'light');
    }
  };

  render() {
    const mode = this.props.theme?.mode;
    const ownerCheck = () => {
      if (
        this.props.userDetails &&
        this.props.userDetails.team_member &&
        this.props.userDetails.team_member.job_title === 'owner'
      ) {
        return true;
      } else {
        return false;
      }
    };
    const isOwner = ownerCheck();
    return (
      <div className="NAVBAR_CONTAINER">
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="fixed" sx={{ bgcolor: '#082032' }}>
            <Toolbar>
              <div className="container NAV_TOOLBAR_CONTAINER">
                <div className="NAVBAR_LEFT_CONTAINER">
                  <div
                    className="NAVBAR_BRAND_NAME"
                    onClick={() => this.navigate('/')}
                  >
                    <img
                      src="https://platinum-webapp.s3.af-south-1.amazonaws.com/static/frontend/static/media/timeflow-logo-new.png"
                      alt="TimeFlow logo"
                    ></img>
                    <h6>TimeFlow</h6>
                    <span>
                      {localStorage.getItem('version') !== undefined
                        ? localStorage.getItem('version')
                        : ''}
                    </span>
                  </div>
                </div>
                <div className="NAVBAR_RIGHT_CONTAINER">
                  {!this.props.isAuthenticated ? (
                    <div
                      className="NAVBAR_LOGIN_BUTTON"
                      onClick={() => this.navigate('/login')}
                    >
                      <h6>Login</h6>
                    </div>
                  ) : null}
                  {this.props.isAuthenticated ? (
                    <div className="NAVBAR_USER_NAME">
                      {this.props.userDetails ? (
                        <div className="HEADER_USER_ACTIONS_CONTAINER">
                          {isOwner ? (
                            <Button
                              size="small"
                              sx={{ mr: '1em' }}
                              onClick={() =>
                                this.navigate('/dashboard/company-details')
                              }
                            >
                              Dashboard
                            </Button>
                          ) : null}
                          <IconButton
                            size="large"
                            edge="start"
                            sx={{ ml: 'auto' }}
                            onClick={this.switchMode}
                          >
                            {mode === 'light' ? (
                              <LightModeIcon
                                sx={{ color: '#F05454', fontSize: 30 }}
                              />
                            ) : (
                              <DarkModeIcon
                                sx={{ color: '#6B778D', fontSize: 30 }}
                              />
                            )}
                          </IconButton>
                          <UserNotificationMenu></UserNotificationMenu>
                          <UserActionMenu
                            userData={this.props.userDetails}
                            isAuthenticated={this.props.isAuthenticated}
                            userDataLoading={this.props.userDataLoading}
                            logout={this.handleLogout}
                            profile={this.handleGoToProfile}
                          ></UserActionMenu>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </div>
            </Toolbar>
          </AppBar>
        </Box>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.users.userDetails,
    theme: state.users.theme,
    userDataLoading: state.users.loading,
    isAuthenticated: state.users.token !== null,
    notification: state.notification.notification,
    notifications: state.notification.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(userActions.logout()),
    setMode: (token, id, mode) =>
      dispatch(userActions.setMode(token, id, mode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
