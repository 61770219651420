import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/account-statements.css';
import { HOST_URL } from '../../settings';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PrintIcon from '@mui/icons-material/Print';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import StatementTable from './statment-table/StatmentTable';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { getOrdersByAccount } from '../../store/actions/order';
import { getFinancialAccounts } from '../../store/actions/financialAccounts';
import {
  updateStatementData,
  getStatementByFinancialAccount,
} from '../../store/actions/accountStatement';
import { getClients } from '../../store/actions/client';
import StatementPeriod from './statment-period/StatementPeriod';
import StatementPeriod30 from './statment-period/StatementPeriod30';
import StatementPeriod60 from './statment-period/StatementPeriod60';
import StatementPeriod90 from './statment-period/StatementPeriod90';
import StatementPeriod120Plus from './statment-period/StatementPeriod120Plus';

class AccountStatement extends Component {
  state = {
    startDate: null,
    endDate: null,
    balance: parseFloat(0),
    paidAmount: parseFloat(0),
    creditAmount: parseFloat(0),
    clientAccount: '',
    loadingAccounts: false,
    showClientDetails: false,
    clientName: '',
    clientEmail: '',
    clientPhone: '',
    openMessageModal: false,
    messageModalMessageTitle: '',
    messageModalMessageBody: '',
    readyToPrint: false,
    currentPeriodBalance: parseFloat(0).toFixed(2),
    thirtyPeriodBalance: parseFloat(0).toFixed(2),
    sixtyPeriodBalance: parseFloat(0).toFixed(2),
    ninetyPeriodBalance: parseFloat(0).toFixed(2),
    hundredTwintyPlusPeriodBalance: parseFloat(0).toFixed(2),
  };

  componentDidMount() {
    this.calculateBalanceAmount();
    const financial_account = localStorage.getItem('ac-number')
      ? localStorage.getItem('ac-number')
      : '';
    const client_name = localStorage.getItem('client-name')
      ? localStorage.getItem('client-name')
      : '';
    const start_date = localStorage.getItem('statment_start_date')
      ? localStorage.getItem('statment_start_date')
      : null;
    const end_date = localStorage.getItem('statment_end_date')
      ? localStorage.getItem('statment_end_date')
      : null;

    this.setState({
      clientAccount: financial_account,
      startDate: start_date,
      endDate: end_date,
      clientName: client_name,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.orders !== prevProps.orders &&
      this.props.orders.length > 0
    ) {
      this.calculateBalanceAmount();
    }
    if (this.props.financialAccounts !== prevProps.financialAccounts) {
      this.setState({ loadingAccounts: false });
    }
    if (
      this.props.accountStatement !== prevProps.accountStatement &&
      this.props.accountStatement
    ) {
      this.setState({ readyToPrint: true });
    }
    if (
      this.props.loadingOrders !== prevProps.loadingOrders &&
      this.props.loadingOrders === false
    ) {
      this.handleUpdateStatementBalances();
    }
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSearch = () => {
    const token = this.props.token;
    this.setState({ readyToPrint: false });
    if (this.state.clientAccount !== '') {
      const accountNumber = this.state.clientAccount;
      this.props.getOrdersByAccount(token, accountNumber);
      this.props.getStatement(token, accountNumber);
    } else {
      this.handleMessageOpen(
        'ERROR',
        'Both date range and account number should be selected.'
      );
    }
  };

  calculateBalanceAmount = () => {
    let balance = parseFloat(0);
    let paid = parseFloat(0);
    let credit = parseFloat(0);
    if (this.props.orders.length > 0) {
      this.props.orders.map((order) => {
        if (order.status === 'unpaid') {
          balance =
            parseFloat(balance) +
            parseFloat(order.grand_total - order.allocated_amount);
          paid = parseFloat(paid) + parseFloat(order.allocated_amount);
        } else if (order.status === 'credited') {
          balance = parseFloat(balance) - parseFloat(order.grand_total);
          credit = parseFloat(credit) + parseFloat(order.grand_total);
        } else {
          paid = parseFloat(paid) + parseFloat(order.allocated_amount);
        }
      });
    }

    this.setState({
      balance: balance,
      paidAmount: paid,
      creditAmount: credit,
    });
    this.handleUpdateStatementBalances();
  };

  getClientsAccounts = () => {
    this.setState({ loadingAccounts: true });
    const token = this.props.token;
    this.props.getFinancialAccounts(token);
  };

  onSelectAccount = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        clientAccount: option ? option.account_number : '',
        clientName: option ? option.client.client_name : '',
        clientEmail: option ? option.client.client_email : '',
        clientPhone: option ? option.client.client_number : '',
        showClientDetails: true,
      });
      localStorage.setItem('ac-number', option ? option.account_number : '');
      localStorage.setItem(
        'client-name',
        option ? option.client.client_name : ''
      );
    } else {
      this.setState({
        clientAccount: '',
        showClientDetails: false,
        clientName: '',
        clientEmail: '',
        clientPhone: '',
      });
    }
  };

  getClients = () => {
    const token = this.props.token;
    this.props.getClients(token);
  };

  onSelectClient = (e, option) => {
    console.log(option);

    if (e.target.innerText !== undefined) {
      this.setState({
        clientName: option ? option.client_name : '',
        selectedClient: option ? option : null,
        clientAccount:
          option && option.financial_account ? option.financial_account : '',
      });
      localStorage.setItem(
        'ac-number',
        option && option.financial_account ? option.financial_account : ''
      );
      localStorage.setItem('client-name', option ? option.client_name : '');
    } else {
      this.setState({
        clientName: '',
        clientAccount: '',
      });
    }
  };

  handleMessageOpen = (title, body) => {
    this.setState({
      messageModalMessageTitle: title,
      messageModalMessageBody: body,
      openMessageModal: true,
    });
  };

  handleMessageClose = () => {
    this.setState({ openMessageModal: false });
  };

  handleUpdateStatementBalances = () => {
    const token = this.props.token;
    // const financial_account = this.state.clientAccount;
    const {
      currentPeriodBalance,
      thirtyPeriodBalance,
      sixtyPeriodBalance,
      ninetyPeriodBalance,
      hundredTwintyPlusPeriodBalance,
      balance,
    } = this.state;
    const statementID = this.props.accountStatement
      ? this.props.accountStatement.id
      : null;
    if (statementID) {
      this.props.updateStatementBalances(
        token,
        statementID,
        currentPeriodBalance,
        thirtyPeriodBalance,
        sixtyPeriodBalance,
        ninetyPeriodBalance,
        hundredTwintyPlusPeriodBalance,
        balance
      );
    }
  };

  render() {
    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.account_number,
    });
    const filterClientOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.client_name,
    });
    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    // const mode = this.props.mode;
    return (
      <div className="ACCOUNT_STATMENT_MAIN_CONTAINER">
        <div className="ACCOUNT_STATMENT_HEADER_CONTAINER">
          <div className="ACCOUNT_STATMENT_SEARCH_CONTAINER">
            <div className="">
              <Autocomplete
                id="filter-accounts"
                freeSolo
                options={
                  this.props.financialAccounts
                    ? this.props.financialAccounts
                    : []
                }
                getOptionLabel={(option) =>
                  option.account_number
                    ? option.account_number
                    : this.state.clientAccount
                }
                filterOptions={filterOptions}
                sx={{ width: 220, color: '#fff' }}
                onOpen={() => {
                  this.getClientsAccounts();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                onChange={(e, option) => this.onSelectAccount(e, option)}
                value={this.state.clientAccount}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Account Number"
                    name="clientAccount"
                    fullWidth
                    value={this.state.clientAccount}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingAccounts ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      className: 'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div>
              <Autocomplete
                id="filter-client"
                freeSolo
                options={this.props.clients ? this.props.clients : []}
                getOptionLabel={(option) =>
                  option.client_name
                    ? option.client_name
                    : this.state.clientName
                }
                filterOptions={filterClientOptions}
                sx={{ width: '100%', color: '#fff' }}
                onOpen={() => {
                  this.getClients();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={<CloseIcon color="error"></CloseIcon>}
                openOnFocus={true}
                onChange={(e, option) => this.onSelectClient(e, option)}
                value={this.state.clientName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    name="clientName"
                    fullWidth
                    value={this.state.clientName}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.props.loadingClients ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'ACCOUNT_STATEMENT_SEARCH_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div className="ACCOUNT_STATEMENT_SEARCH_BUTTON">
              {this.props.loadingOrders ? (
                <CircularProgress sx={{ color: '#1597BB' }} size={30} />
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  sx={{ height: '45px', borderRadius: '5px' }}
                  onClick={this.handleSearch}
                >
                  Fetch Data
                </Button>
              )}
            </div>
            {/* <div className="ACCOUNT_STATEMENT_SEARCH_BUTTON">
              {this.props.loadingStatement ? (
                <CircularProgress sx={{ color: '#3282B8' }} size={30} />
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  sx={{ height: '45px', borderRadius: '5px' }}
                  onClick={this.handleUpdateStatementBalances}
                >
                  generate
                </Button>
              )}
            </div> */}
            {this.state.readyToPrint && !this.props.loadingOrders ? (
              <Link
                underline="none"
                href={
                  this.props.accountStatement
                    ? `${HOST_URL}/account-statement/generate-statement/${this.state.clientAccount}/${this.props.userDetails.team_member.key}/`
                    : '#!'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrintIcon
                  sx={{ color: '#3282B8', fontSize: 35, marginTop: 1 }}
                />
              </Link>
            ) : null}
          </div>
        </div>

        <div className="ACCOUNT_STATMENT_TABLE_CONTAINER">
          <StatementTable
            orders={this.props.orders}
            mode={this.props.mode}
          ></StatementTable>
        </div>
        <div className="ACCOUNT_STATMENT_RESULTS_CONTAINER">
          <div className="ACCOUNT_STATMENT_TOTAL_CONTAINER">
            <h1 style={{ color: '#21E6C1' }}>Paid</h1>
            <span style={{ color: '#21E6C1' }}>
              R&nbsp;{this.state.paidAmount}
            </span>
          </div>
          <div className="ACCOUNT_STATMENT_TOTAL_CONTAINER">
            <h1 style={{ color: '#F05454' }}>Credit</h1>
            <span style={{ color: '#F05454' }}>
              R&nbsp;{this.state.creditAmount}
            </span>
          </div>
          <div className="ACCOUNT_STATMENT_TOTAL_CONTAINER">
            <h1>Balance</h1>
            <span>R&nbsp;{this.state.balance}</span>
          </div>
        </div>
        {this.state.showClientDetails ? (
          <div className="ACCOUNT_STATMENT_CLIENT_DETAILS_CONTAINER">
            <div>
              <TextField
                id="client-name"
                label="Name"
                variant="outlined"
                value={this.state.clientName}
                InputProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                }}
                InputLabelProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                }}
              />
            </div>
            <div>
              <TextField
                id="client-email"
                label="Email"
                variant="outlined"
                value={this.state.clientEmail}
                InputProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                }}
                InputLabelProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                }}
              />
            </div>
            <div>
              <TextField
                id="client-number"
                label="Phone Number"
                variant="outlined"
                value={this.state.clientPhone}
                InputProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD',
                }}
                InputLabelProps={{
                  className: 'ACCOUNT_STATEMENT_CLIENT_DETAILS_FIELD_LABEL',
                }}
              />
            </div>
          </div>
        ) : null}

        <div className="ACCOUNT_STATMENT_PERIODS_MAIN_CONTAINER">
          <div className="ACCOUNT_STATMENT_PERIODS_CONTAINER">
            {this.state.clientAccount !== '' ? (
              <>
                <StatementPeriod
                  period="CURRENT"
                  clientAccount={this.state.clientAccount}
                  periodBalance={(balance) =>
                    this.setState({ currentPeriodBalance: balance })
                  }
                ></StatementPeriod>
                <StatementPeriod30
                  period="30 DAYS"
                  clientAccount={this.state.clientAccount}
                  periodBalance={(balance) =>
                    this.setState({ thirtyPeriodBalance: balance })
                  }
                ></StatementPeriod30>
                <StatementPeriod60
                  period="60 DAYS"
                  clientAccount={this.state.clientAccount}
                  periodBalance={(balance) =>
                    this.setState({ sixtyPeriodBalance: balance })
                  }
                ></StatementPeriod60>
                <StatementPeriod90
                  period="90 DAYS"
                  clientAccount={this.state.clientAccount}
                  periodBalance={(balance) =>
                    this.setState({ ninetyPeriodBalance: balance })
                  }
                ></StatementPeriod90>
                <StatementPeriod120Plus
                  period="120+ DAYS"
                  clientAccount={this.state.clientAccount}
                  periodBalance={(balance) =>
                    this.setState({ hundredTwintyPlusPeriodBalance: balance })
                  }
                ></StatementPeriod120Plus>
              </>
            ) : null}
          </div>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openMessageModal}
          onClose={this.handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.messageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.messageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button variant="outlined" onClick={this.handleMessageClose}>
                  Okay
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    userDetails: state.users.userDetails,
    orders: state.order.orders,
    loadingOrders: state.order.loading,
    financialAccounts: state.financialAccount.accounts,
    clients: state.client.clients,
    loadingClients: state.client.loading,
    accountStatement: state.accountStatement.statement,
    loadingStatement: state.accountStatement.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrdersByAccount: (token, accountNumber) =>
      dispatch(getOrdersByAccount(token, accountNumber)),
    getFinancialAccounts: (token) => dispatch(getFinancialAccounts(token)),
    getClients: (token) => dispatch(getClients(token)),
    getStatement: (token, financial_account) =>
      dispatch(getStatementByFinancialAccount(token, financial_account)),
    updateStatementBalances: (
      token,
      statement_id,
      current_balance,
      thirty_days_balance,
      sixty_days_balance,
      ninety_days_balance,
      hundred_twinty_plus_days_balance,
      total
    ) =>
      dispatch(
        updateStatementData(
          token,
          statement_id,
          current_balance,
          thirty_days_balance,
          sixty_days_balance,
          ninety_days_balance,
          hundred_twinty_plus_days_balance,
          total
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountStatement);
