import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  item: null,
  itemToEdit: null,
  items: null,
  orderItem: null,
  orderItems: null,
};

const getItemStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getOrderItemStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getItemSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    item: action.payload,
  });
};

const getItemToEditSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    itemToEdit: action.payload,
  });
};

const getOrderItemSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    orderItem: action.payload,
  });
};

const getItemsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    items: action.payload,
  });
};

const getOrderItemsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    orderItems: action.payload,
  });
};

const getItemFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const getOrderItemFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const itemReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ITEM_START:
      return getItemStart(state, action);
    case actionTypes.GET_ORDER_ITEM_START:
      return getOrderItemStart(state, action);
    case actionTypes.GET_ITEM_SUCCESS:
      return getItemSuccess(state, action);
    case actionTypes.GET_ITEM_TO_EDIT_SUCCESS:
      return getItemToEditSuccess(state, action);
    case actionTypes.GET_ORDER_ITEM_SUCCESS:
      return getOrderItemSuccess(state, action);
    case actionTypes.GET_ITEMS_SUCCESS:
      return getItemsSuccess(state, action);
    case actionTypes.GET_ORDER_ITEMS_SUCCESS:
      return getOrderItemsSuccess(state, action);
    case actionTypes.GET_ITEM_FAIL:
      return getItemFaild(state, action);
    case actionTypes.GET_ORDER_ITEM_FAIL:
      return getOrderItemFaild(state, action);
    default:
      return state;
  }
};

export default itemReducer;
