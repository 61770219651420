import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  jobTask: null,
  targetJobTask: null,
  jobTasks: [],
  filteredJobTasks: [],
  overdueJobTasks: [],
};

const JobTaskStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const JobTaskSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobTask: action.payload,
  });
};

const targetJobTaskSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    targetJobTask: action.payload,
  });
};

const JobTasksSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    jobTasks: action.payload,
  });
};

const FilteredJobTasksSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    filteredJobTasks: action.payload,
  });
};

const OverdueJobTasksSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    overdueJobTasks: action.payload,
  });
};

const JobTaskFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const jobTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.JOB_TASK_START:
      return JobTaskStart(state, action);
    case actionTypes.JOB_TASK_SUCCESS:
      return JobTaskSuccess(state, action);
    case actionTypes.TARGET_JOB_TASK_SUCCESS:
      return targetJobTaskSuccess(state, action);
    case actionTypes.JOB_TASKS_SUCCESS:
      return JobTasksSuccess(state, action);
    case actionTypes.FILTERED_JOB_TASK_SUCCESS:
      return FilteredJobTasksSuccess(state, action);
    case actionTypes.OVERDUE_JOB_TASK_SUCCESS:
      return OverdueJobTasksSuccess(state, action);
    case actionTypes.JOB_TASK_FAIL:
      return JobTaskFaild(state, action);
    default:
      return state;
  }
};

export default jobTaskReducer;
