import React, { Component } from 'react';
import './styles/image-card.css';
import { pink, blue } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';

export default class ImageCard extends Component {
  componentDidMount() {
    // console.log(this.props.document.document_image);
  }

  handleDeleteImage = () => {
    this.props.deleteImage();
  };
  handlePreviewImage = () => {
    this.props.openPreview();
  };
  render() {
    return (
      <div className="IMAGE_CARD_CONTAINER">
        <div className="IMAGE_CARD_DELETE_ICON">
          {this.props.deleteImage ? (
            <Tooltip title="Delete">
              <Avatar
                sx={{ bgcolor: pink[400] }}
                onClick={this.handleDeleteImage}
              >
                <DeleteIcon></DeleteIcon>
              </Avatar>
            </Tooltip>
          ) : null}
          <Tooltip title="Preview">
            <Avatar
              sx={{ bgcolor: blue[500], ml: 2 }}
              onClick={this.handlePreviewImage}
            >
              <VisibilityIcon></VisibilityIcon>
            </Avatar>
          </Tooltip>
        </div>
        {this.props.file ? (
          <div className="IMAGE_CONTAINER" onClick={this.handlePreviewImage}>
            <img src={this.props.file} alt="repair cocument"></img>
          </div>
        ) : null}
      </div>
    );
  }
}
