import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Tooltip from '@mui/material/Tooltip';
import NotificationMenuItem from './NotificationMenuItem';
import {
  getNotificationsByTarget,
  updateNotificationToRead,
} from '../../../../../store/actions/notification';
import { relativeTimeRounding } from 'moment';

class NotificationsMenu extends Component {
  state = {
    anchorEl: null,
    open: false,
    getJobCardStart: false,
    unreadNotificationsNo: 0,
  };

  componentDidMount() {
    if (this.props.notifications.length === 0 && this.props.isAuthenticated) {
      const username = this.props.userDetails.username;
      const token = this.props.token;
      this.props.getNotifications(token, username);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.notifications !== prevProps.notifications) {
      this.calculateUnreadNotificationsNumber();
    }
    if (this.props.notification !== prevProps.notification) {
      this.calculateUnreadNotificationsNumber();
    }
  }

  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      open: true,
    });
  };
  handleClose = () => {
    this.setState({ anchorEl: null, open: false });
  };

  calculateUnreadNotificationsNumber = () => {
    this.setState({ unreadNotificationsNo: 0 });
    this.props.notifications.map((notification, index) => {
      if (
        !notification.read &&
        notification.generated_to === this.props.userDetails.username
      ) {
        this.setState((prevState) => {
          return {
            ...prevState,
            unreadNotificationsNo: prevState.unreadNotificationsNo + 1,
          };
        });
      }
    });
  };

  handleNotificationClick = (notificationID) => {
    const token = this.props.token;
    const generated_to = this.props.userDetails.username;
    this.props.updateNotificationToRead(
      token,
      notificationID,
      true,
      generated_to
    );
  };

  render() {
    return (
      <React.Fragment>
        <Box
          sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}
        >
          <Tooltip title="Notifications">
            <IconButton
              onClick={this.handleClick}
              size="small"
              sx={{ ml: 2 }}
              aria-controls={this.state.open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={this.state.open ? 'true' : undefined}
            >
              <Box sx={{ width: 32, height: 32, pt: 2, color: '#EEEEEE' }}>
                <Badge
                  badgeContent={this.state.unreadNotificationsNo}
                  color="error"
                >
                  <NotificationsIcon color="inherit" />
                </Badge>
              </Box>
            </IconButton>
          </Tooltip>
        </Box>
        <Menu
          anchorEl={this.state.anchorEl}
          id="account-menu"
          open={this.state.open}
          onClose={this.handleClose}
          onClick={this.handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          MenuListProps={{
            sx: {
              display: 'block',
              minHeight: '300px',
              maxHeight: '350px',
              overflow: 'auto',
              borderRadius: '10px',
            },
          }}
        >
          {this.props.notifications && this.props.notifications.length > 0 ? (
            this.props.notifications.map((notification, index) =>
              notification.generated_to === this.props.userDetails.username ? (
                <MenuItem
                  key={notification.title + index}
                  onClick={() => this.handleNotificationClick(notification.id)}
                >
                  <NotificationMenuItem
                    notification={notification}
                  ></NotificationMenuItem>
                </MenuItem>
              ) : (
                <MenuItem></MenuItem>
              )
            )
          ) : (
            <MenuItem>You don't have any notifications</MenuItem>
          )}
        </Menu>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    userDataLoading: state.users.loading,
    isAuthenticated: state.users.token !== null,
    notification: state.notification.notification,
    notifications: state.notification.notifications,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (token, name) =>
      dispatch(getNotificationsByTarget(token, name)),
    updateNotificationToRead: (token, notification_id, read) =>
      dispatch(updateNotificationToRead(token, notification_id, read)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NotificationsMenu));
