import React, { Component } from 'react';
import { connect } from 'react-redux';
import './style.css';
import Header from '../header/Header';
import MemberCard from '../memberCard/MemberCard';
import {
  deleteTeamMemberPermission,
  getAllTeamMembers,
  getAllPermissions,
  addTeamMemberPermission,
  inviteTeamMember,
} from '../../../store/actions/teamMember';
import {
  getBrnchesDetails,
  getCompanyDetails,
} from '../../../store/actions/company';
import { Button } from '@mui/material';
import MemberForm from '../member-form/MemberForm';

class Teams extends Component {
  state = {
    addMember: false,
  };
  componentDidMount() {
    const token = this.props.token;
    this.props.getMembers(token);
    this.props.getPermissions(token);
    if (!this.props.company) {
      this.props.getCDetails(token);
    } else {
      this.props.getBranches(this.props.token, this.props.company.id);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.company !== prevProps.company && this.props.company) {
      this.props.getBranches(this.props.token, this.props.company.id);
    }
    if (
      this.props.teamMembers !== prevProps.teamMembers &&
      this.state.addMember
    ) {
      this.setState({ addMember: false });
    }
  }

  handleDeletePermission = (permissionID, memberID) => {
    const token = this.props.token;
    this.props.deletePermision(token, memberID, permissionID);
  };

  handleAddPermission = (permissionID, memberID) => {
    const token = this.props.token;
    this.props.addPermision(token, memberID, permissionID);
  };

  handleAddNewMember = (firstName, lastName, jobTitle, email, branch) => {
    const token = this.props.token;
    const companyId = this.props.company.id;
    const branchId = branch.id;
    const data = {
      companyId,
      branchId,
      firstName,
      lastName,
      jobTitle,
      email,
    };
    this.props.saveMember(token, data);
  };

  render() {
    return (
      <div className="DASHBOARD_WORKSAPCE_SUB_CONTAINER">
        <Header
          title="Company Team"
          description="View company employes data."
        />
        <div className="ADD_MEMBERS_CONTAINER">
          <Button
            variant="outlined"
            size="small"
            onClick={() =>
              this.setState((prevState) => ({
                addMember: !prevState.addMember,
              }))
            }
          >
            {this.state.addMember ? 'close form' : 'Add member'}
          </Button>
        </div>
        {this.state.addMember ? (
          <div className="MEMBER_FORM_CONTAINER">
            <MemberForm
              branches={this.props.branches}
              getBranches={() =>
                this.props.getBranches(this.props.token, this.props.company.id)
              }
              handleAddNewMember={this.handleAddNewMember}
              loading={this.props.loading}
            ></MemberForm>
          </div>
        ) : null}
        <div className="MEMBERS_CONTAINER">
          {this.props.teamMembers?.map((member) => (
            <MemberCard
              key={member.email}
              member={member}
              permissions={this.props.permissions}
              handleDeletePermission={(permissionID, memberID) =>
                this.handleDeletePermission(permissionID, memberID)
              }
              handleAddPermission={(permissionID, memberID) =>
                this.handleAddPermission(permissionID, memberID)
              }
              getPermissions={() => this.props.getPermissions(this.props.token)}
              loading={this.props.loading}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    teamMembers: state.teamMember.teamMembers,
    permissions: state.teamMember.permissions,
    company: state.company.companyDetails,
    branches: state.company.branchesDetails,
    loading: state.teamMember.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMembers: (token) => dispatch(getAllTeamMembers(token)),
    deletePermision: (token, memberID, permissionID) =>
      dispatch(deleteTeamMemberPermission(token, memberID, permissionID)),
    addPermision: (token, memberID, permissionID) =>
      dispatch(addTeamMemberPermission(token, memberID, permissionID)),
    getPermissions: (token) => dispatch(getAllPermissions(token)),
    getCDetails: (token) => dispatch(getCompanyDetails(token)),
    getBranches: (token, companyId) =>
      dispatch(getBrnchesDetails(token, companyId)),
    saveMember: (token, data) => dispatch(inviteTeamMember(token, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Teams);
