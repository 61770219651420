import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  damageReportItem: null,
  damageReportItems: [],
};

const getDamageReportItemStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getDamageReportItemSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    damageReportItem: action.payload,
  });
};

const getDamageReportItemsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    damageReportItems: action.payload,
  });
};

const getDamageReportItemFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const damageReportItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_DAMAGE_REPORT_ITEM_START:
      return getDamageReportItemStart(state, action);
    case actionTypes.GET_DAMAGE_REPORT_ITEM_SUCCESS:
      return getDamageReportItemSuccess(state, action);
    case actionTypes.GET_DAMAGE_REPORT_ITEMS_SUCCESS:
      return getDamageReportItemsSuccess(state, action);
    case actionTypes.GET_DAMAGE_REPORT_ITEM_FAIL:
      return getDamageReportItemFaild(state, action);
    default:
      return state;
  }
};

export default damageReportItemReducer;
