import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/dashboard.css';
import DashboardLNavigationBar from './DashboardLNavigationBar';
import Company from '../../components/dashboard/company/Company';
import Teams from '../../components/dashboard/teams/Teams';
import Clinets from '../../components/dashboard/clients/Clinets';
import Analysis from '../../components/dashboard/analysis/Analysis';

class Dashboard extends Component {
  state = {};

  componentDidMount() {
    if (this.props.match.params.form === undefined) {
      this.handleCompanyDetailsOpen();
    }
  }
  componentDidUpdate() {}

  handleCompanyDetailsOpen = () => {
    this.props.history.push('/dashboard/company-details');
    localStorage.setItem('prevPath', '/dashboard/company-details');
  };

  render() {
    const mode = this.props.mode;
    return (
      <div className="DASHBOARD_MAIN_CONTAINER" id={mode}>
        <div className="DASHBOARD_HEADER_MAIN_CONTAINER">
          <h1>DASHBOARD</h1>
        </div>
        <div className="DASHBOARD_BODY_MAIN_CONTAINER">
          <div className="DASHBOARD_LEFT_NAVIGATION_BAR_MAIN_CONTAINER">
            <DashboardLNavigationBar mode={mode} />
          </div>
          <div className="DASHBOARD_WORK_SPACE_MAIN_CONTAINER">
            {this.props.match.params.form === 'company-details' ? (
              <Company></Company>
            ) : null}
            {this.props.match.params.form === 'teams' ? <Teams></Teams> : null}
            {this.props.match.params.form === 'analysis' ? (
              <Analysis></Analysis>
            ) : null}
            {this.props.match.params.form === 'clients' ? (
              <Clinets></Clinets>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
