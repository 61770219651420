import { combineReducers } from 'redux';
import authReducer from './auth';
import jobCardReducer from './jobCard';
import documentReducer from './documents';
import jobTaskReducer from './jobTask';
import clientReducer from './client';
import collectedDeviceReducer from './collectDevices';
import jobCommentReducer from './jobComment';
import jobReportReducer from './jobReport';
import teamMemberReducer from './teamMember';
import taskStatusReducer from './taskStatus';
import notificationReducer from './notification';
import insuranceReducer from './insurance';
import orderReduser from './order';
import itemReducer from './item';
import invoiceReducer from './invoice';
import companyReducer from './company';
import productReducer from './inventory/product';
import damageReportReducer from './damageReport';
import damageReportItemReducer from './damageReportItem';
import quotationReducer from './quotation';
import financialAccountReducer from './financialAccounts';
import transactionReducer from './inventory/transaction';
import statementReducer from './AccountStatement';
import brandReducer from './inventory/brand';
import categoryReducer from './inventory/category';
import supplierReducer from './inventory/supplier';
import numericReducer from './insights/numeric';
import dailyJobsTasksReducer from './insights/dailyJobs';
import subscriptionReducer from './subscription';

export default combineReducers({
  users: authReducer,
  jobCard: jobCardReducer,
  document: documentReducer,
  jobTask: jobTaskReducer,
  client: clientReducer,
  collectedDevice: collectedDeviceReducer,
  jobComment: jobCommentReducer,
  jobReport: jobReportReducer,
  teamMember: teamMemberReducer,
  taskStatus: taskStatusReducer,
  notification: notificationReducer,
  insurance: insuranceReducer,
  order: orderReduser,
  item: itemReducer,
  invoice: invoiceReducer,
  company: companyReducer,
  product: productReducer,
  damageReport: damageReportReducer,
  damageReportItem: damageReportItemReducer,
  quotation: quotationReducer,
  financialAccount: financialAccountReducer,
  transaction: transactionReducer,
  accountStatement: statementReducer,
  brand: brandReducer,
  category: categoryReducer,
  supplier: supplierReducer,
  insights: numericReducer,
  jobsInsights: dailyJobsTasksReducer,
  subscription: subscriptionReducer,
});
