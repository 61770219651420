import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/job-task-search.css';
import moment from 'moment';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import {
  getFilteredJobTasks,
  getFilteredJobTasksByKeywork,
} from '../../store/actions/jobTask';
import { CircularProgress } from '@mui/material';
import JobTaskItem from './JobTaskItem';

class JobTasksearch extends Component {
  state = {
    startDate: null,
    endDate: null,
    search: '',
  };
  componentDidUpdate(prevProps) {
    if (this.props.filteredJobTasks !== prevProps.filteredJobTasks) {
      this.setState({ search: '' });
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  handleSearch = () => {
    const token = this.props.token;
    const keyword = this.state.search;
    if (this.state.startDate && this.state.endDate) {
      const startDate = moment(new Date(this.state.startDate)).format();
      const endDate = moment(new Date(this.state.endDate)).format();

      this.props.getFilteredJobTasks(token, startDate, endDate);
    } else if (this.state.search !== '') {
      this.props.getFilteredJobTasksByKeywork(token, keyword);
    }
  };

  render() {
    let jobTaskFiltered = this.props.filteredJobTasks.filter((job_task) => {
      return (
        job_task.task_number
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        job_task.assigned_to
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1 ||
        job_task.assigned_by
          .toLowerCase()
          .indexOf(this.state.search.toLowerCase()) !== -1
      );
    });

    const { value } = this.state;
    const mode = this.props.mode;
    return (
      <div className="RECEPTION_JOB_TASK_SEARCH_MAIN_CONTAINER">
        <div className="RECEPTION_JOB_CARD_SEARCH_HEADER_MAIN_CONTAINER">
          <div className="RECEPTION_JOB_CARD_SEARCH_CONTAINER">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="Start Date"
                value={this.state.startDate}
                onChange={(newValue) => {
                  this.setState({ startDate: newValue });
                }}
                fullWidth
                sx={{
                  svg:
                    mode === 'light'
                      ? { color: '#19376d' }
                      : { color: '#576cbc' },
                  input:
                    mode === 'light'
                      ? { color: '#17293e' }
                      : { color: '#ffffff' },
                  label:
                    mode === 'light'
                      ? {
                          color: '#182c43',
                          zIndex: 10,
                          background: '#ececec',
                        }
                      : {
                          color: '#b8b8b8',
                          zIndex: 10,
                          background: '#233044',
                        },
                  border: mode === 'light' ? '' : '1px solid #384f68',
                  width: '100%',
                  borderRadius: 2,
                  margin: 2,
                }}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                label="End Date"
                value={this.state.endDate}
                onChange={(newValue) => {
                  this.setState({ endDate: newValue });
                }}
                fullWidth
                sx={{
                  svg:
                    mode === 'light'
                      ? { color: '#19376d' }
                      : { color: '#576cbc' },
                  input:
                    mode === 'light'
                      ? { color: '#17293e' }
                      : { color: '#ffffff' },
                  label:
                    mode === 'light'
                      ? {
                          color: '#182c43',
                          zIndex: 10,
                          background: '#ececec',
                        }
                      : {
                          color: '#b8b8b8',
                          zIndex: 10,
                          background: '#233044',
                        },
                  border: mode === 'light' ? '' : '1px solid #384f68',
                  width: '100%',
                  borderRadius: 2,
                }}
              />
            </LocalizationProvider>

            <div className="RECEPTION_JOB_CARD_SEARCH_FIELD_CONTAINER">
              <InputBase
                sx={{
                  ml: 1,
                  mr: 2,
                  flex: 1,
                  color: mode === 'dark' ? '#dddddd' : '#1c2d43',
                }}
                placeholder="Search Job Cards"
                inputProps={{
                  'aria-label': 'search jobs',
                  className: 'RECEPTION_JOB_CARD_TEXT_SEARCH_INPUT_FIELD',
                }}
                name="search"
                value={this.state.search}
                onChange={this.onChange}
              />
              {/* <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon />
          </IconButton> */}
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: '10px' }}
                aria-label="directions"
                onClick={() => this.setState({ search: '' })}
              >
                <ClearIcon />
              </IconButton>
            </div>

            <div className="RECEPTION_JOB_CARD_SEARCH_BUTTON_CONTAINER">
              {this.props.loading ? (
                <CircularProgress></CircularProgress>
              ) : (
                <Button
                  size="large"
                  variant="contained"
                  sx={{ height: '45px', borderRadius: '10px' }}
                  onClick={this.handleSearch}
                >
                  Search
                </Button>
              )}
            </div>
          </div>
        </div>

        <div className="RECEPTION_JOB_TASK_SEARCH_ITEMS_CONTAINER">
          {this.props.filteredJobTasks ? (
            jobTaskFiltered.map((jobTask, index) => (
              <JobTaskItem jobTask={jobTask} key={index}></JobTaskItem>
            ))
          ) : (
            <CircularProgress></CircularProgress>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    filteredJobTasks: state.jobTask.filteredJobTasks,
    loading: state.jobTask.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFilteredJobTasks: (token, startDate, endDate) =>
      dispatch(getFilteredJobTasks(token, startDate, endDate)),
    getFilteredJobTasksByKeywork: (token, keyword) =>
      dispatch(getFilteredJobTasksByKeywork(token, keyword)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobTasksearch);
