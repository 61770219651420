import React, { Component } from 'react';
import { connect } from 'react-redux';
import './job-card-table.css';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ReportImageCard from '../image-card/ReportImageCard';
import JobDamageReport from '../job-damage-report/JobDamageReport';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import {
  deleteDamageReportItem,
  getDamageReportItemByJobCard,
  postDamageReportItemData,
  updateDamageReportItemData,
} from '../../../store/actions/damageReportItem';
import { getJobDamageReportByJobCard } from '../../../store/actions/jobReport';

class JobCardTable extends Component {
  state = {
    value: '0',
    selectedImages: [],
    damageReportItemImagesIDsList: [],
    damageReportItemStatus: '',
    damageReportItemCauseOfDamage: '',
    openImagePreview: false,
    previewFile: null,
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  componentDidMount() {
    if (this.props.jobCards[0] !== undefined) {
      this.handleJobCardSelect(
        this.props.jobCards[0].job_card_number,
        this.props.jobCards[0].id
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.jobCards !== prevProps.jobCards &&
      this.props.jobCards[0] !== undefined
    ) {
      this.handleJobCardSelect(
        this.props.jobCards[0].job_card_number,
        this.props.jobCards[0].id
      );
    }
    if (
      this.props.damageReportItem !== prevProps.damageReportItem &&
      this.props.damageReportItem.images
    ) {
      this.createDamageReportItemsImagesIDsList();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleChange = (e, newValue) => {
    this.setState({ value: newValue });
  };

  createJobCardsIDsList = () => {};

  handleJobCardSelect = (jobCardNumber, jobCardID) => {
    const token = this.props.token;
    this.setState({ selectedImages: [] });

    this.props.getDamageReportItemByJobCard(token, jobCardNumber);
    this.props.getJobDamageReportByJobCard(token, jobCardID);
  };

  createDamageReportItemsImagesIDsList = () => {
    let l = [];
    this.props.damageReportItem.images.map((item, index) => {
      l.push(item.id);
    });
    this.setState({ damageReportItemImagesIDsList: l, selectedImages: l });
  };

  onImageSelect = (imageID) => {
    let imageIdList = this.state.selectedImages;
    imageIdList.push(imageID);

    this.setState({ selectedImages: imageIdList });
  };
  onImageUnSelect = (imageID) => {
    let imageIdList = this.state.selectedImages;
    const filteredArray = imageIdList.filter((e) => e !== imageID);

    this.setState({ selectedImages: filteredArray });
  };

  saveDamageReportItem = (jobCard) => {
    // Handle unregistered damage report
    console.log(jobCard);
    const token = this.props.token;
    const damage_report = this.props.damageReport.id;
    const job_card = jobCard.id;
    const images = this.state.selectedImages;
    const report = jobCard.job_damage_report[0]
      ? jobCard.job_damage_report[0].report
      : '';
    const status = this.state.damageReportItemStatus;
    const cause_of_damage = this.state.damageReportItemCauseOfDamage;

    this.props.addDamageReportItem(
      token,
      damage_report,
      job_card,
      images,
      report,
      status,
      cause_of_damage
    );
  };

  updateDamageReportItem = (jobCard) => {
    console.log(jobCard);
    const token = this.props.token;
    const damageReportItemID = this.props.damageReportItem
      ? this.props.damageReportItem.id
      : null;
    const damage_report = this.props.damageReport.id;
    const job_card = jobCard.id;
    const images = this.state.selectedImages;
    const report = this.props.jobDamageReport
      ? this.props.jobDamageReport.report
      : '';
    const status = this.state.damageReportItemStatus;
    const cause_of_damage = this.state.damageReportItemCauseOfDamage;

    if (damageReportItemID) {
      this.props.updateDamageReportItem(
        token,
        damageReportItemID,
        damage_report,
        job_card,
        images,
        report,
        status,
        cause_of_damage
      );
    }
  };

  handleRemoveDamageReportItem = () => {
    const token = this.props.token;
    const damageReportID = this.props.damageReportItem.id;
    this.props.deleteDamageReportItem(token, damageReportID);
  };

  handleImagePreviewClose = () => {
    this.setState({ openImagePreview: false });
  };

  handleImagePreviewOpen = (file) => {
    console.log('opening preview');
    this.setState({ openImagePreview: true, previewFile: file });
  };

  render() {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: 1000,
      height: '80%',
      bgcolor: 'none',
      border: '2px solid #2c3e50',
      borderRadius: 4,
      boxShadow: 24,
      p: 2,
    };
    return (
      <div className="DASHBOARD_CONTROLLER_CONFIGS_CONTAINER">
        <div className="DASHBOARD_CONTROLLER_CONFIGS_HEADER_CONTAINER">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={this.state.value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  onChange={this.handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="lab API tabs example"
                >
                  {this.props.jobCards
                    ? this.props.jobCards.map((jobCard, index) => (
                        <Tab
                          key={index}
                          sx={{ color: '#BBE1FA' }}
                          label={jobCard.job_card_number}
                          value={`${index}`}
                          onClick={() =>
                            this.handleJobCardSelect(
                              jobCard.job_card_number,
                              jobCard.id
                            )
                          }
                        />
                      ))
                    : null}
                </TabList>
              </Box>
              {this.props.jobCards
                ? this.props.jobCards.map((jobCard, index) => (
                    <TabPanel value={`${index}`} key={index}>
                      <div className="DAMAGE_REPORT_JOB_CARD_IMAGES_MAIN_CONTAINER">
                        <div className="DAMAGE_REPORT_JOB_CARD_IMAGES_CONTAINER">
                          {jobCard.documet
                            ? jobCard.documet.map((document, index) => (
                                <ReportImageCard
                                  key={index}
                                  file={document.document_image}
                                  handleSelectImage={() =>
                                    this.onImageSelect(document.id)
                                  }
                                  handleUnSelectImage={() =>
                                    this.onImageUnSelect(document.id)
                                  }
                                  documentID={document.id}
                                  preSelectedImagesList={
                                    this.state.damageReportItemImagesIDsList
                                  }
                                  openPreview={() =>
                                    this.handleImagePreviewOpen(document)
                                  }
                                ></ReportImageCard>
                              ))
                            : null}
                        </div>
                      </div>
                      <div className="DAMAGE_REPORT_JOB_CARD_REPORT_MAIN_CONTAINER">
                        <div className="DAMAGE_REPORT_JOB_CARD_REPORT_CONTAINER">
                          <JobDamageReport jobCard={jobCard}></JobDamageReport>
                          <div className="DAMAGE_REPORT_FIELD_MAIN_CONTAINER">
                            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
                              <TextField
                                id="outlined-basic"
                                label="Status"
                                variant="outlined"
                                name="damageReportItemStatus"
                                placeholder="Defective"
                                fullWidth
                                value={this.state.damageReportItemStatus}
                                onChange={this.onChange}
                                // helperText="Please select your currency"
                                InputProps={{
                                  className: 'DAMAGE_REPORT_INPUT_FIELD',
                                }}
                                InputLabelProps={{
                                  // shrink: true,
                                  className: 'DAMAGE_REPORT_INPUT_FIELD_LABEL',
                                }}
                              ></TextField>
                            </div>

                            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
                              <TextField
                                id="outlined-basic"
                                label="Cause of Damage"
                                variant="outlined"
                                placeholder="Power Surge"
                                name="damageReportItemCauseOfDamage"
                                fullWidth
                                value={this.state.damageReportItemCauseOfDamage}
                                onChange={this.onChange}
                                // helperText="Please select your currency"
                                InputProps={{
                                  className: 'DAMAGE_REPORT_INPUT_FIELD',
                                }}
                                InputLabelProps={{
                                  // shrink: true,
                                  className: 'DAMAGE_REPORT_INPUT_FIELD_LABEL',
                                }}
                              ></TextField>
                            </div>
                          </div>
                        </div>
                        <div className="DAMAGE_REPORT_ITEM_ACTIONS_MAIN_CONTAINER">
                          {this.props.damageReportItem &&
                          this.props.damageReportItem.job_card
                            .job_card_number === jobCard.job_card_number ? (
                            this.props.damageReportItemLoading ? (
                              <CircularProgress></CircularProgress>
                            ) : (
                              <>
                                <Button
                                  variant="outlined"
                                  onClick={() =>
                                    this.updateDamageReportItem(jobCard)
                                  }
                                >
                                  Update
                                </Button>
                                <div>
                                  <Button
                                    sx={{ marginLeft: 5 }}
                                    color="error"
                                    variant="outlined"
                                    onClick={this.handleRemoveDamageReportItem}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              </>
                            )
                          ) : this.props.damageReportItemLoading ? (
                            <CircularProgress fontSize="small"></CircularProgress>
                          ) : (
                            <Button
                              variant="outlined"
                              onClick={() => this.saveDamageReportItem(jobCard)}
                            >
                              Save
                            </Button>
                          )}
                        </div>
                      </div>
                    </TabPanel>
                  ))
                : null}
            </TabContext>
          </Box>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openImagePreview}
          onClose={this.handleImagePreviewClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openImagePreview}>
            <Box sx={style}>
              <div className="PREVIEW_MODAL_IMAGE_HEADER">
                <Avatar
                  sx={{ bgcolor: '#393E46' }}
                  onClick={this.handleImagePreviewClose}
                >
                  <CloseIcon></CloseIcon>
                </Avatar>
              </div>
              <div className="PREVIEW_MODAL_IMAGE_CONTAINER">
                {this.state.previewFile ? (
                  <img
                    src={this.state.previewFile.document_image}
                    alt="repair document"
                  ></img>
                ) : null}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    token: state.users.token,
    damageReport: state.damageReport.damageReport,
    damageReportLoading: state.damageReport.loading,
    jobDamageReport: state.jobReport.jobDamageReport,
    damageReportItem: state.damageReportItem.damageReportItem,
    damageReportItemLoading: state.damageReportItem.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addDamageReportItem: (
      token,
      damage_report,
      job_card,
      images,
      report,
      status,
      cause_of_damage
    ) =>
      dispatch(
        postDamageReportItemData(
          token,
          damage_report,
          job_card,
          images,
          report,
          status,
          cause_of_damage
        )
      ),
    getDamageReportItemByJobCard: (token, jobCardNumber) =>
      dispatch(getDamageReportItemByJobCard(token, jobCardNumber)),
    updateDamageReportItem: (
      token,
      damageReportItem,
      damage_report,
      job_card,
      images,
      report,
      status,
      cause_of_damage
    ) =>
      dispatch(
        updateDamageReportItemData(
          token,
          damageReportItem,
          damage_report,
          job_card,
          images,
          report,
          status,
          cause_of_damage
        )
      ),
    getJobDamageReportByJobCard: (token, jobCard) =>
      dispatch(getJobDamageReportByJobCard(token, jobCard)),
    deleteDamageReportItem: (token, id) =>
      dispatch(deleteDamageReportItem(token, id)),
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(JobCardTable);
