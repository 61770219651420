import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  statement: null,
};

const getStatementStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getStatementSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    statement: action.payload,
  });
};

const getStatementsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    statements: action.payload,
  });
};

const getStatementFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const statementReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_STATEMENT_START:
      return getStatementStart(state, action);
    case actionTypes.GET_STATEMENT_SUCCESS:
      return getStatementSuccess(state, action);
    case actionTypes.GET_STATEMENTS_SUCCESS:
      return getStatementsSuccess(state, action);
    case actionTypes.GET_STATEMENT_FAIL:
      return getStatementFaild(state, action);
    default:
      return state;
  }
};

export default statementReducer;
