import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  error: null,
  loading: false,
  comment: null,
  comments: [],
};

const getJobCommentStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
  });
};

const getJobCommentSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    comment: action.payload,
  });
};

const getJobCommentsSuccess = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: false,
    comments: action.payload,
  });
};

const getJobCommentFaild = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const jobCommentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_JOB_COMMENT_START:
      return getJobCommentStart(state, action);
    case actionTypes.GET_JOB_COMMENT_SUCCESS:
      return getJobCommentSuccess(state, action);
    case actionTypes.GET_JOB_COMMENTS_SUCCESS:
      return getJobCommentsSuccess(state, action);
    case actionTypes.GET_JOB_COMMENT_FAIL:
      return getJobCommentFaild(state, action);
    default:
      return state;
  }
};

export default jobCommentReducer;
