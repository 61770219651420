import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/clients-accounts.css';
import ClientAccountsTable from './client-accounts-table/ClientAccountsTable';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { getClients } from '../../store/actions/client';
import { getInsurances } from '../../store/actions/insurance';
import {
  getFinancialAccounts,
  postFinancialAccountData,
  updateFinancialAccountData,
} from '../../store/actions/financialAccounts';
import { postStatementData } from '../../store/actions/accountStatement';

class ClientsAccounts extends Component {
  state = {
    clientName: '',
    insuranceName: '',
    financialAccountNumber: '',
    clientNameError: false,
    financialAccountError: false,
    updateFinancialAccount: false,
    saveFinancialAccountLoading: false,
    clientID: null,
    insuranceID: null,
    financialAccountID: null,
    popUpModalMessageTitle: '',
    popUpModalMessageBody: '',
    openPopUpModal: false,
    createStatementObj: false,
  };

  componentDidMount() {
    const token = this.props.token;
    this.props.getFinancialAccounts(token);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.financialAccount !== prevProps.financialAccount &&
      this.state.createStatementObj
    ) {
      this.setState({ createStatementObj: false });
      this.createAccountStatementObj();
    }
    if (this.props.financialAccounts !== prevProps.financialAccounts) {
      this.setState({
        clientName: '',
        insuranceName: '',
        financialAccountNumber: '',
        clientNameError: false,
        financialAccountError: false,
        updateFinancialAccount: false,
        saveFinancialAccountLoading: false,
        clientID: null,
        insuranceID: null,
        financialAccountID: null,
      });
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + `Error`]: false,
    });
  };

  validateData = () => {
    const { clientID, insuranceID, financialAccountNumber } = this.state;
    if (financialAccountNumber === '') {
      return false;
    } else if (
      (clientID === null && insuranceID === null) ||
      (clientID !== null && insuranceID !== null)
    ) {
      return false;
    } else {
      return true;
    }
  };

  handleSaveFinancialAccount = () => {
    const valid = this.validateData();
    console.log(valid);
    if (valid) {
      this.setState({
        saveFinancialAccountLoading: true,
        createStatementObj: true,
      });
      const token = this.props.token;
      console.log('save');
      const { clientID, insuranceID, financialAccountNumber } = this.state;
      this.props.saveFinancialAccount(
        token,
        clientID,
        insuranceID,
        financialAccountNumber
      );
    }
  };

  handleEditFinancialAccount = (financialAccount) => {
    this.setState({
      updateFinancialAccount: true,
      financialAccountID: financialAccount.id,
      clientName: financialAccount.client
        ? financialAccount.client.client_name
        : '',
      insuranceName: financialAccount.insurance
        ? financialAccount.insurance.insurance_company_name
        : '',
      financialAccountNumber: financialAccount.account_number,
    });
  };

  handleUpdateFinancialAccount = () => {
    const token = this.props.token;
    console.log('update');
    const { financialAccountID, financialAccountNumber } = this.state;
    this.props.updateFinancialAccount(
      token,
      financialAccountID,
      financialAccountNumber
    );
  };

  clearFinancialAccountDetails = () => {
    this.setState({
      clientName: '',
      insuranceName: '',
      financialAccountNumber: '',
      clientNameError: false,
      financialAccountError: false,
      updateFinancialAccountLoading: false,
      saveFinancialAccountLoading: false,
      clientID: null,
      insuranceID: null,
      financialAccountID: null,
    });
  };

  getClients = () => {
    const token = this.props.token;
    this.props.getClients(token);
  };

  onSelectClient = (e, option) => {
    console.log(option);

    if (e.target.innerText !== undefined) {
      this.setState({
        insuranceName: '',
        selectedInsurance: null,
        insuranceID: null,
        clientName: option ? option.client_name : '',
        selectedClient: option ? option : null,
        clientID: option ? option.id : null,
        financialAccountNumber:
          option && option.financial_account ? option.financial_account : '',
        updateFinancialAccount:
          option && option.financial_account ? true : false,
      });
    } else {
      this.setState({
        clientName: '',
        selectedClient: null,
        clientID: null,
      });
    }
  };

  getInsuranceCompanies = () => {
    const token = this.props.token;
    this.props.getInsurances(token);
  };

  onSelectInsurance = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        clientName: '',
        selectedClient: null,
        clientID: null,
        insuranceName: option ? option.insurance_company_name : '',
        insuranceID: option ? option.id : null,
        selectedInsurance: option ? option : null,
        financialAccountNumber:
          option && option.financial_account ? option.financial_account : '',
        updateFinancialAccount:
          option && option.financial_account ? true : false,
      });
    } else {
      this.setState({
        insuranceName: '',
        selectedInsurance: null,
        insuranceID: null,
      });
    }
  };

  handlePopUpClose = () => {
    this.setState({ openPopUpModal: false });
  };

  handlePopUpOpen = (title, body) => {
    this.setState({
      popUpModalMessageTitle: title,
      popUpModalMessageBody: body,
      openPopUpModal: true,
    });
  };

  createAccountStatementObj = () => {
    const token = this.props.token;
    const financial_account = this.props.financialAccount
      ? this.props.financialAccount.account_number
      : null;

    if (financial_account) {
      this.props.postStatementData(
        token,
        financial_account,
        parseFloat(0).toFixed(2),
        parseFloat(0).toFixed(2),
        parseFloat(0).toFixed(2),
        parseFloat(0).toFixed(2),
        parseFloat(0).toFixed(2),
        parseFloat(0).toFixed(2)
      );
    }
  };

  render() {
    const filterClientOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.client_name,
    });
    const filterInsuranceOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.insurance_company_name,
    });

    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    return (
      <div className="CLIENTS_ACCOUNTS_MAIN_CONTAINER">
        <div className="CLIENTS_ACCOUNTS_FORM_HEADER_CONTAINER">
          <h1>Customer's Accounts</h1>
        </div>
        <div className="CLIENTS_ACCOUNTS_FORM_CONTAINER">
          <div className="CLIENTS_ACCOUNTS_FORM_INPUT_GROUP">
            <div className="CLIENTS_ACCOUNTS_FORM_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-client"
                options={this.props.clients ? this.props.clients : []}
                getOptionLabel={(option) =>
                  option.client_name
                    ? option.client_name
                    : this.state.clientName
                }
                filterOptions={filterClientOptions}
                sx={{ width: '100%', color: '#fff' }}
                onOpen={() => {
                  this.getClients();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={
                  <CloseIcon
                    color="error"
                    onClick={() =>
                      this.setState({
                        financialAccountNumber: '',
                        updateFinancialAccount: false,
                      })
                    }
                  ></CloseIcon>
                }
                openOnFocus={true}
                onChange={(e, option) => this.onSelectClient(e, option)}
                value={this.state.clientName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Client"
                    name="clientName"
                    fullWidth
                    value={this.state.clientName}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.props.loadingClients ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'CLIENTS_ACCOUNTS_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'CLIENTS_ACCOUNTS_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div className="CLIENTS_ACCOUNTS_FORM_INPUT_FIELD_CONTAINER">
              <Autocomplete
                id="filter-insurance"
                options={
                  this.props.insuranceCompanies
                    ? this.props.insuranceCompanies
                    : []
                }
                getOptionLabel={(option) =>
                  option.insurance_company_name
                    ? option.insurance_company_name
                    : this.state.insuranceName
                }
                filterOptions={filterInsuranceOptions}
                sx={{ width: '100%', color: '#fff' }}
                onOpen={() => {
                  this.getInsuranceCompanies();
                }}
                onClose={() => {
                  console.log('close');
                }}
                clearIcon={
                  <CloseIcon
                    color="error"
                    onClick={() =>
                      this.setState({
                        financialAccountNumber: '',
                        updateFinancialAccount: false,
                      })
                    }
                  ></CloseIcon>
                }
                openOnFocus={true}
                onChange={(e, option) => this.onSelectInsurance(e, option)}
                value={this.state.insuranceName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Insurance Company"
                    name="insuranceName"
                    fullWidth
                    value={this.state.insuranceName}
                    onChange={this.onChange}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {this.state.loadingInsurances ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                      className: 'CLIENTS_ACCOUNTS_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'CLIENTS_ACCOUNTS_INPUT_FIELD_LABEL',
                    }}
                  />
                )}
              />
            </div>
            <div className="CLIENTS_ACCOUNTS_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Account Number"
                variant="outlined"
                fullWidth
                name="financialAccountNumber"
                required
                error={this.state.financialAccountError}
                value={this.state.financialAccountNumber}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'CLIENTS_ACCOUNTS_INPUT_FIELD',
                }}
                InputLabelProps={{
                  shrink: true,
                  className: 'CLIENTS_ACCOUNTS_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>

          <div className="CLIENTS_ACCOUNTS_FORM_INPUT_GROUP">
            {!this.props.loadingFinancialAccounts ? (
              this.state.updateFinancialAccount ? (
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    margin: 2,
                    backgroundColor: '#162447',
                    color: 'white',
                    width: '100px',
                  }}
                  onClick={this.handleUpdateFinancialAccount}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    margin: 2,
                    backgroundColor: '#162447',
                    color: 'white',
                    width: 150,
                  }}
                  onClick={this.handleSaveFinancialAccount}
                >
                  Save
                </Button>
              )
            ) : (
              <div className="CLIENTS_ACCOUNTS_BUTTON">
                <CircularProgress></CircularProgress>
              </div>
            )}
            {this.state.updateFinancialAccountLoading ? (
              <div
                className="CLIENTS_ACCOUNTS_BUTTON"
                onClick={this.clearClientDetails}
              >
                <div>Clear</div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="CLIENTS_ACCOUNTS_TABLE_MAIN_CONTAINER">
          <ClientAccountsTable
            financialAccounts={
              this.props.financialAccounts &&
              this.props.financialAccounts[0] !== undefined
                ? this.props.financialAccounts
                : []
            }
            editFinancialAccount={(financialAccount) =>
              this.handleEditFinancialAccount(financialAccount)
            }
            mode={this.props.mode}
          ></ClientAccountsTable>
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openPopUpModal}
          onClose={this.handleErrorMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openPopUpModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.popUpModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.popUpModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button
                  variant="outlined"
                  color="error"
                  onClick={this.handleDeleteClient}
                >
                  Confirm
                </Button>
                <Button variant="outlined" onClick={this.handlePopUpClose}>
                  Cancel
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    clients: state.client.clients,
    loadingClients: state.client.loading,
    insuranceCompanies: state.insurance.insurances,
    loadingInsurances: state.insurance.loading,
    financialAccounts: state.financialAccount.accounts,
    financialAccount: state.financialAccount.account,
    loadingFinancialAccounts: state.financialAccount.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getClients: (token) => dispatch(getClients(token)),
    getInsurances: (token) => dispatch(getInsurances(token)),
    getFinancialAccounts: (token) => dispatch(getFinancialAccounts(token)),
    saveFinancialAccount: (token, clientID, insuranceID, account_number) =>
      dispatch(
        postFinancialAccountData(token, clientID, insuranceID, account_number)
      ),
    updateFinancialAccount: (token, accountID, account_number) =>
      dispatch(updateFinancialAccountData(token, accountID, account_number)),
    postStatementData: (
      token,
      financial_account,
      current_balance,
      thirty_days_balance,
      sixty_days_balance,
      ninety_days_balance,
      hundred_twinty_plus_days_balance,
      total
    ) =>
      dispatch(
        postStatementData(
          token,
          financial_account,
          current_balance,
          thirty_days_balance,
          sixty_days_balance,
          ninety_days_balance,
          hundred_twinty_plus_days_balance,
          total
        )
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsAccounts);
