import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './styles/accounts-manager.css';
// import PieChart from './charts/pie-chart/PieChart';
// import LineChart from './charts/line-chart/LineChart';
// import HistogramChart from './charts/histogram-chart/HistogramChart';
import AccountStatement from '../../components/account-statment/AccountStatement';
import CapturePayment from '../../components/capture-payment/CapturePayment';
import InvoiceForm from '../../components/invoice-form/AccountsInvoiceForm';
import QuotationForm from '../../components/quotation-form/QuotationForm';
import ClientsAccounts from '../../components/clients-accounts/ClientsAccounts';
import { checkPermissions } from '../../tools/checkPermissions';

class AccountsManager extends Component {
  state = {
    approveAccess: false,
    approveAccessHandled: false,
  };

  componentDidMount() {
    if (this.props.match.params.form === undefined) {
      this.handleStatmentsOpen();
    }
    if (
      this.props.memberPermissions.length > 0 &&
      !this.state.approveAccessHandled
    ) {
      const permissions = checkPermissions(this.props.memberPermissions);
      console.log(!permissions.primaryPermissions.includes('accounting'));
      if (!permissions.primaryPermissions.includes('accounting')) {
        this.handleLoginOpen();
      } else {
        this.setState({ approveAccess: true, approveAccessHandled: true });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.userDetails !== prevProps.userDetails &&
      this.props.memberPermissions.length > 0
    ) {
      const permissions = checkPermissions(this.props.memberPermissions);
      console.log(!permissions.primaryPermissions.includes('accounting'));
      if (!permissions.primaryPermissions.includes('accounting')) {
        this.handleLoginOpen();
      } else {
        this.setState({ approveAccess: true });
      }
    }
  }

  handleStatmentsOpen = () => {
    this.props.history.push('/accounts-manager/statments');
    localStorage.setItem('prevPath', '/accounts-manager/statments');
  };

  handleInvoiceOpen = () => {
    this.props.history.push('/accounts-manager/invoice');
    localStorage.setItem('prevPath', '/accounts-manager/invoice');
  };

  handleQuoteOpen = () => {
    this.props.history.push('/accounts-manager/quotation');
    localStorage.setItem('prevPath', '/accounts-manager/quotation');
  };

  handleCapturePaymentOpen = () => {
    this.props.history.push('/accounts-manager/capture-payment');
    localStorage.setItem('prevPath', '/accounts-manager/capture-payment');
  };

  handleReceptionOpen = () => {
    this.props.history.push('/reception/book-a-device');
    localStorage.setItem('prevPath', 'reception/book-a-device');
  };

  handleClientsAccountsOpen = () => {
    this.props.history.push('/accounts-manager/clients-accounts');
    localStorage.setItem('prevPath', '/accounts-manager/clients-accounts');
  };

  handleInventoryOpen = () => {
    this.props.history.push('/inventory-manager/add-item');
    localStorage.setItem('prevPath', '/inventory-manager/add-item');
  };

  handleLoginOpen = () => {
    this.props.history.push('/login');
    localStorage.setItem('prevPath', '/login');
  };

  render() {
    const permissions = this.props.memberPermissions
      ? checkPermissions(this.props.memberPermissions)
      : [];
    const mode = this.props.mode;
    return (
      <div className="ACCOUNTS_MANAGER_MAIN_CONTAINER" id={mode}>
        {this.state.approveAccess ? (
          <div className="ACCOUNTS_MANAGER_CONTAINER">
            <div className="ACCOUNTS_MANAGER_ACTIONS_CONTAINER">
              <div
                className="ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'clients-accounts'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#c3cfd1' }
                    : {}
                }
                onClick={this.handleClientsAccountsOpen}
              >
                <span>Clients</span>
                <span>Accounts</span>
              </div>
              <div
                className="ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'statments'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#c3cfd1' }
                    : {}
                }
                onClick={this.handleStatmentsOpen}
              >
                <span>Statments</span>
              </div>
              <div
                className="ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'invoice'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#c3cfd1' }
                    : {}
                }
                onClick={this.handleInvoiceOpen}
              >
                <span>Invoice</span>
              </div>
              <div
                className="ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'quotation'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#c3cfd1' }
                    : {}
                }
                onClick={this.handleQuoteOpen}
              >
                <span>Quote</span>
              </div>
              <div
                className="ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER"
                style={
                  this.props.match.params.form === 'capture-payment'
                    ? mode === 'dark'
                      ? { background: '#233044' }
                      : { background: '#c3cfd1' }
                    : {}
                }
                onClick={this.handleCapturePaymentOpen}
              >
                <span>Capture</span>
                <span>Payment</span>
              </div>
              {permissions.primaryPermissions.includes('inventory') ? (
                <div
                  className="ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER"
                  onClick={this.handleInventoryOpen}
                >
                  <span>Inventory</span>
                  <span>Manager</span>
                </div>
              ) : null}
              {permissions.primaryPermissions.includes('reception') ? (
                <div
                  className="ACCOUNTS_MANAGER_ACTIONS_BUTTON_CONTAINER"
                  onClick={this.handleReceptionOpen}
                >
                  <span>Reception</span>
                </div>
              ) : null}
            </div>
            <div className="ACCOUNTS_MANAGER_FORMS_MAIN_CONTAINER">
              <div className="ACCOUNTS_MANAGER_FORMS_CONTAINER">
                {this.props.match.params.form === 'clients-accounts' ? (
                  <ClientsAccounts></ClientsAccounts>
                ) : null}
                {this.props.match.params.form === 'statments' ? (
                  <AccountStatement></AccountStatement>
                ) : null}
                {this.props.match.params.form === 'capture-payment' ? (
                  <CapturePayment></CapturePayment>
                ) : null}
                {this.props.match.params.form === 'invoice' ? (
                  <InvoiceForm
                    invoiceNumber={this.props.match.params.data}
                  ></InvoiceForm>
                ) : null}
                {this.props.match.params.form === 'quotation' ? (
                  <QuotationForm
                    quotationNumber={this.props.match.params.data}
                  ></QuotationForm>
                ) : null}
              </div>
            </div>
            <div className="ACCOUNTS_MANAGER_GRAPHS_CONTAINER">
              {/* <div>
                <PieChart
                  data={[
                    {
                      title: 'Paid',
                      value: 500,
                    },
                    {
                      title: 'Credit',
                      value: 1700,
                    },
                    {
                      title: 'Balance',
                      value: 15000,
                    },
                  ]}
                  describtion="........."
                ></PieChart>
              </div>
              <div>
                <LineChart describtion="................."></LineChart>
              </div>
              <div>
                <HistogramChart
                  describtion="............."
                  data={[
                    {
                      day: 'Sat',
                      cost: 2000,
                    },
                    {
                      day: 'Sun',
                      cost: 1500,
                    },
                    {
                      day: 'Mon',
                      cost: 3000,
                    },
                    {
                      day: 'Tus',
                      cost: 4000,
                    },
                    {
                      day: 'Wed',
                      cost: 5000,
                    },
                    {
                      day: 'Thu',
                      cost: 2500,
                    },
                    {
                      day: 'Fri',
                      cost: 2000,
                    },
                  ]}
                ></HistogramChart>
              </div> */}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetails: state.users.userDetails,
    mode: state.users.theme?.mode,
    memberPermissions: state.users.userDetails
      ? state.users.userDetails.team_member.permissions
      : [],
  };
};

export default connect(mapStateToProps, null)(withRouter(AccountsManager));
