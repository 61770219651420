import React from 'react';
import './histogram-chart.css';
import { Chart, Interval, Axis, Tooltip } from 'bizcharts';

export default function HistoChart({ data, target }) {
  // const data = [
  //   { country: 'Asia', year: '1750', value: 502 },
  //   { country: 'Africa', year: '1600', value: 635 },
  //   { country: 'Europe', year: '1500', value: 163 },
  //   { country: 'Amirica', year: '1800', value: 303 },
  // ];

  const scale = {
    value: {
      type: 'linear',
      tickCount: 1,
    },
  };
  return (
    <div className="CHART_1">
      <Chart
        padding="auto"
        autoFit
        height={320}
        width={1100}
        data={data}
        shadowColor="#333"
        scale={scale}
      >
        <Axis name="date" visible={true} />
        <Interval
          shape="smoth"
          color={target === 'jobs' ? '#2C74B3' : '#1F6E8C'}
          position={target === 'jobs' ? 'date*jobs_q' : 'date*tasks_q'}
        ></Interval>
        <Tooltip>
          {(title, items) => {
            return (
              <div>
                <span>
                  {target === 'jobs' ? 'Booked Jobs' : 'Assigned Tasks'}
                </span>
                <p>{items[0].data.date}</p>
                <p>
                  {target === 'jobs'
                    ? items[0].data.jobs_q
                    : items[0].data.tasks_q}
                </p>
              </div>
            );
          }}
        </Tooltip>
      </Chart>
    </div>
  );
}
