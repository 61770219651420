import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/technician.css';
import moment from 'moment';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import { pink } from '@mui/material/colors';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';
import DownloadingIcon from '@mui/icons-material/Downloading';
import TechnicianWorkSpace from '../../components/technician-work-space/TechnicianWorkSpace';
import { getJobCard, getJobCards } from '../../store/actions/jobCard';
import {
  getNJobTasksByEmployee,
  getJobTask,
  getOverdueJobTasks,
  getLastNJobTasks,
} from '../../store/actions/jobTask';
import JobTaskTicket from '../../components/job-task-ticket/JobTaskTicket';
import { getNotificationsByTarget } from '../../store/actions/notification';
import { CircularProgress } from '@mui/material';
import { checkPermissions } from '../../tools/checkPermissions';

class Technician extends Component {
  state = {
    approveAccess: false,
    approveAccessHandled: false,
    getJobCardStart: false,
    jobTaskSearch: '',
    intervalID: 0,
    showUrgentTasksOnTop: true,
    gettingMoreJobTasks: false,
    jobTasksNumberToGet: 15,
  };

  componentDidMount() {
    const token = this.props.token;
    if (this.props.userDetails) {
      const username = this.props.userDetails.username;
      this.props.getNTasksByTechnician(
        token,
        'technician',
        username,
        this.state.jobTasksNumberToGet
      );
      this.props.getNotifications(token, username);
      this.props.getOverdueJobTasks(token);

      const newIntervalId = setInterval(() => {
        this.props.getNTasksByTechnician(
          token,
          'technician',
          this.props.userDetails.username,
          this.state.jobTasksNumberToGet
        );
        this.props.getNotifications(token, this.props.userDetails.username);
        this.props.getOverdueJobTasks(token);
        console.log('getting new tasks');
        console.log('getting new notifications');
        console.log('getting overdue tasks');
      }, 180000);

      this.setState((prevState) => {
        return {
          ...prevState,
          intervalId: newIntervalId,
        };
      });
    }
  }
  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  componentDidUpdate(prevProps) {
    const token = this.props.token;
    if (this.props.jobTask !== prevProps.jobTask) {
      this.props.getJobCard(token, this.props.jobTask.job_card);
      this.setState({ getJobCardStart: false });
    }
    if (this.props.userDetails !== prevProps.userDetails) {
      const username = this.props.userDetails.username;
      this.props.getNTasksByTechnician(
        token,
        'technician',
        username,
        this.state.jobTasksNumberToGet
      );
    }
    if (
      this.props.jobTasks !== prevProps.jobTasks &&
      this.state.gettingMoreJobTasks
    ) {
      this.setState({ gettingMoreJobTasks: false });
    }

    if (
      this.props.memberPermissions.length > 0 &&
      !this.state.approveAccessHandled
    ) {
      const permissions = checkPermissions(this.props.memberPermissions);
      console.log(!permissions.primaryPermissions.includes('technician'));
      if (!permissions.primaryPermissions.includes('technician')) {
        this.handleLoginOpen();
      } else {
        this.setState({ approveAccess: true, approveAccessHandled: true });
      }
    }
  }

  getLastXJobTasksByTechnician = (number_of_jobtasks) => {
    this.setState({ gettingMoreJobTasks: true });
    this.props.getNTasksByTechnician(
      this.props.token,
      'technician',
      this.props.userDetails.username,
      number_of_jobtasks
    );
  };

  getTaskRelatedInfo = (task_id) => {
    const token = this.props.token;
    this.props.getActiveTaskDetails(token, task_id);
    this.setState({ getJobCardStart: true });
  };

  getMoreJobTasks = () => {
    if (!this.props.jobTaskLoading) {
      const { jobTasksNumberToGet } = this.state;
      this.getLastXJobTasksByTechnician(jobTasksNumberToGet + 10);
      this.setState({ jobTasksNumberToGet: jobTasksNumberToGet + 10 });
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleShowUrgentTasksOnTop = () => {
    this.setState((state) => ({
      showUrgentTasksOnTop: !state.showUrgentTasksOnTop,
    }));
  };

  handleLoginOpen = () => {
    this.props.history.push('/login');
    localStorage.setItem('prevPath', '/login');
  };

  render() {
    let jobTaskFiltered = this.props.jobTasks.filter((job_task) => {
      return (
        job_task.task_number
          .toLowerCase()
          .indexOf(this.state.jobTaskSearch.toLowerCase()) !== -1 ||
        job_task.assigned_to
          .toLowerCase()
          .indexOf(this.state.jobTaskSearch.toLowerCase()) !== -1 ||
        job_task.status.status
          .toLowerCase()
          .indexOf(this.state.jobTaskSearch.toLowerCase()) !== -1
      );
    });
    const mode = this.props.mode;
    return (
      <div className="TECHNICIAN_MAIN_CONTAINER" id={mode}>
        {this.state.approveAccess ? (
          <>
            <div className="TECHNICIAN_SIDE_BAR_CONTAINER">
              <div className="TECHNICIAN_SEARCH_BAR_CONTAINER">
                <InputBase
                  // sx={{ ml: 1, flex: 1 }}
                  placeholder="Search Job Tasks"
                  inputProps={{
                    'aria-label': 'search jobs',
                    className: 'TASK_SEARCH_INPUT_FIELD',
                  }}
                  value={this.state.jobTaskSearch}
                  name="jobTaskSearch"
                  onChange={this.onChange}
                />
                {/* <IconButton type="submit" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton> */}
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <IconButton
                  color="primary"
                  sx={{ p: '10px' }}
                  aria-label="directions"
                  onClick={() => this.setState({ jobTaskSearch: '' })}
                >
                  <ClearIcon />
                </IconButton>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        <Typography color="inherit">Load More Tasks</Typography>
                        <p style={{ fontSize: '0.7rem', maxWidth: '150px' }}>
                          Use this button to load more tasks, it loads 10 more
                          every time you use it
                        </p>
                      </div>
                    </React.Fragment>
                  }
                >
                  <IconButton
                    sx={{ p: '2px', color: '#4E9F3D', fontSize: '1rem' }}
                    onClick={this.getMoreJobTasks}
                  >
                    {!this.state.gettingMoreJobTasks ? (
                      <DownloadingIcon color="primary"></DownloadingIcon>
                    ) : (
                      <CircularProgress size={20} />
                    )}
                  </IconButton>
                </Tooltip>
                <Tooltip
                  title={
                    <React.Fragment>
                      <div>
                        <Typography color="inherit">
                          Show Urgent Tasks
                        </Typography>
                        <p style={{ fontSize: '0.7rem', maxWidth: '150px' }}>
                          Enable this CheckBox to show all urgent Tasks on top
                          of the list
                        </p>
                      </div>
                    </React.Fragment>
                  }
                >
                  <Checkbox
                    defaultChecked
                    onClick={this.handleShowUrgentTasksOnTop}
                    sx={{
                      color: pink[800],
                      '&.Mui-checked': {
                        color: pink[600],
                      },
                    }}
                  />
                </Tooltip>
              </div>
              <div className="TECH_JOB_TASKS_SIDE_CONTAINER">
                {this.props.overDueJobTasks
                  ? this.props.overDueJobTasks.length >= 1 &&
                    this.state.jobTaskSearch === '' &&
                    this.state.showUrgentTasksOnTop
                    ? this.props.overDueJobTasks.map((jobtask, index) => (
                        <JobTaskTicket
                          key={index}
                          reception={false}
                          task={jobtask}
                          getTaskRelatedInfo={() =>
                            this.getTaskRelatedInfo(jobtask.id)
                          }
                          overDue={true}
                        ></JobTaskTicket>
                      ))
                    : null
                  : null}
                {this.props.jobTasks && this.props.jobTasks.length >= 1
                  ? jobTaskFiltered.map((task, index) => (
                      <JobTaskTicket
                        key={index}
                        reception={false}
                        task={task}
                        getTaskRelatedInfo={() =>
                          this.getTaskRelatedInfo(task.id)
                        }
                        overDue={
                          task.due_date <= moment(new Date()).format() &&
                          task.status.status === 'pending'
                            ? true
                            : false
                        }
                      ></JobTaskTicket>
                    ))
                  : null}
              </div>
            </div>
            <div className="TECHNICIAN_WORKSPACE_MAIN_CONTAINER">
              <TechnicianWorkSpace
                task={
                  this.props.jobTask
                    ? this.props.jobTask
                    : this.props.jobTasks[0]
                }
                jobCard={this.props.jobCard}
              ></TechnicianWorkSpace>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    mode: state.users.theme?.mode,
    userDetails: state.users.userDetails,
    jobCard: state.jobCard.jobCard,
    jobCards: state.jobCard.jobCards,
    jobTask: state.jobTask.jobTask,
    jobTasks: state.jobTask.jobTasks,
    overDueJobTasks: state.jobTask.overdueJobTasks,
    jobTaskLoading: state.jobTask.loading,
    documents: state.document.documents,
    progress: state.document.progress,
    memberPermissions: state.users.userDetails
      ? state.users.userDetails.team_member.permissions
      : [],
  };
};

const mapDispatchToPros = (dispatch) => {
  return {
    getJobCards: (token) => dispatch(getJobCards(token)),
    getJobCard: (token, id) => dispatch(getJobCard(token, id)),
    getNTasksByTechnician: (token, employee, name, N) =>
      dispatch(getNJobTasksByEmployee(token, employee, name, N)),
    getLastNJobTasks: (token, N) => dispatch(getLastNJobTasks(token, N)),
    getActiveTaskDetails: (token, task_id) =>
      dispatch(getJobTask(token, task_id)),
    getNotifications: (token, name) =>
      dispatch(getNotificationsByTarget(token, name)),
    getOverdueJobTasks: (token) => dispatch(getOverdueJobTasks(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToPros)(Technician);
