import React, { Component } from 'react';
import { connect } from 'react-redux';
import './styles/job-images-form.css';
import ImageCard from '../image-card/ImageCard';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import * as documentAction from '../../store/actions/documents';
import ProgressCircle from '../../components/progress-circle/ProgressCircle';
import SaveIcon from '@mui/icons-material/Save';
import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Avatar from '@mui/material/Avatar';
import CloseIcon from '@mui/icons-material/Close';

class JobImagesForm extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
  }
  state = {
    showProgress: false,
    allowedFileTypes: 'png,jpeg',
    documents: [],
    documentsDetails: [],
    loading: false,
    openImagePreview: false,
    previewFile: null,
  };

  componentDidMount() {}
  componentDidUpdate(prevProps) {
    if (this.props.documents !== prevProps.documents) {
      this.setState({ showProgress: false });
    }
    if (this.props.activeJobCard !== prevProps.activeJobCard) {
      this.setState({
        documents: [],
        documentsDetails: [],
        showProgress: false,
        loading: false,
      });
    }
  }
  handleImagePreviewClose = () => {
    this.setState({ openImagePreview: false });
  };
  handleImagePreviewOpen = (file) => {
    console.log('opening preview');
    this.setState({ openImagePreview: true, previewFile: file });
  };

  onImgChange = (e) => {
    const image = e.target.files;

    const addedImageCount = this.state.documents.length;
    const imageCountCanBeAdded = 30 - addedImageCount;
    var imageNumberLimit = 0;
    if (image.length > imageCountCanBeAdded) {
      imageNumberLimit = imageCountCanBeAdded;
    } else if (image.length <= imageCountCanBeAdded) {
      imageNumberLimit = image.length;
    }

    for (var index = 0; index < imageNumberLimit; index++) {
      let reader = new FileReader();
      const img = image[index];
      console.log(img);
      if (
        // this.state.allowedFileTypes.includes(
        //   img.type.split('/')[1] || img.name.split('.')[1]  // use this to restrict file type
        // )
        img.type.split('/')[0] === 'image'
      ) {
        reader.readAsDataURL(img);
        reader.onloadend = () => {
          this.setState((state) => ({
            documents: [...state.documents, reader.result],
          }));
        };

        this.setState((state) => ({
          documentsDetails: [...state.documentsDetails, img],
        }));
      }
    }
  };

  handleSavingDocument = () => {
    if (
      this.state.documentsDetails &&
      this.state.documentsDetails.length === 1
    ) {
      this.saveDocument();
    } else if (
      this.state.documentsDetails &&
      this.state.documentsDetails.length > 1
    ) {
      this.saveAllDocuments();
    }
  };

  saveDocument = () => {
    this.setState({ showProgress: true });
    const { documentsDetails } = this.state;
    const token = this.props.token;
    const document_name = documentsDetails[0].name;
    const document_type = documentsDetails[0].type;
    const document_size = documentsDetails[0].size;
    const job_card_id = this.props.jobCard.id;

    if (document_type.includes('image')) {
      this.props.saveDocument(
        token,
        job_card_id,
        document_name,
        document_type,
        document_size,
        documentsDetails[0]
      );
    }
  };

  saveAllDocuments = () => {
    this.setState({ showProgress: true });
    const documentList = this.state.documentsDetails;
    const job_card_id = this.props.jobCard.id;
    const token = this.props.token;
    this.props.saveDocuments(token, job_card_id, documentList);
  };

  deleteImageFromState = (id) => {
    let documents = this.state.documents;
    let documentsDetails = this.state.documentsDetails;
    documents.splice(id, 1);
    documentsDetails.splice(id, 1);
    this.setState({ documents: documents });
    this.setState({ documentsDetails: documentsDetails });
    const node = this.fileInputRef.current;
    node.value = null;
  };

  deleteSavedImage = (id) => {
    this.setState({ loading: true });
    const token = this.props.token;
    const job_card_id = this.props.jobCard.id;

    this.props.deleteDocument(token, id, job_card_id);
  };

  render() {
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '80%',
      maxWidth: 1000,
      height: '80%',
      bgcolor: 'none',
      border: '2px solid #2c3e50',
      borderRadius: 4,
      boxShadow: 24,
      p: 2,
    };

    return (
      <div className="JOB_IMAGES_MAIN_CONTAINER">
        {}
        <div className="JOB_IMAGES_ADD_BUTTON_CONTAINER">
          {this.state.showProgress ? (
            <div className="PROGRESS_CIRCLE_CONTAINER">
              <ProgressCircle progress={this.props.progress}></ProgressCircle>
            </div>
          ) : (
            <div
              className="ADD_IMAGE_BUTTON"
              // data-toggle="modal"
              // data-target="#imgModal"
              // onClick={this.generateAllowedFileTypesList}
            >
              <input
                ref={this.fileInputRef}
                type="file"
                multiple={true}
                accept={this.state.allowedFileTypes}
                onChange={this.onImgChange}
              />
              <div className="UPLOAD_FILE_ICON">
                <AddPhotoAlternateIcon
                  fontSize="inherit"
                  color="inherit"
                ></AddPhotoAlternateIcon>
              </div>
            </div>
          )}
          <div className="SAVE_IMAGE_BUTTON">
            <div
              className="SAVE_UPLOAD_FILE_ICON"
              onClick={this.handleSavingDocument}
            >
              <SaveIcon fontSize="inherit" color="inherit"></SaveIcon>
            </div>
          </div>
        </div>
        {this.props.loadingActiveJobCard || this.state.loading ? (
          <div className="JOB_IMAGES_ADDED_SAVED_LOADING">
            <CircularProgress></CircularProgress>
          </div>
        ) : (
          <div className="JOB_IMAGES_ADDED_SAVED_CONTAINER">
            {this.state.documents
              ? this.state.documents.map((document, index) => (
                  <div
                    className="JOB_CARD_UPLOADED_IMAGE_CONTAINER"
                    key={index}
                  >
                    <ImageCard
                      file={document}
                      deleteImage={() => this.deleteImageFromState(index)}
                      openPreview={() => this.handleImagePreviewOpen(document)}
                    ></ImageCard>
                  </div>
                ))
              : null}

            {this.props.jobCard
              ? this.props.jobCard.documet.map((document, index) => (
                  <div
                    className="JOB_CARD_UPLOADED_IMAGE_CONTAINER"
                    key={index}
                  >
                    <ImageCard
                      file={document.document_image}
                      deleteImage={() => this.deleteSavedImage(document.id)}
                      openPreview={() =>
                        this.handleImagePreviewOpen(document.document_image)
                      }
                    ></ImageCard>
                  </div>
                ))
              : null}
          </div>
        )}

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openImagePreview}
          onClose={this.handleImagePreviewClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openImagePreview}>
            <Box sx={style}>
              <div className="PREVIEW_MODAL_IMAGE_HEADER">
                <Avatar
                  sx={{ bgcolor: '#393E46' }}
                  onClick={this.handleImagePreviewClose}
                >
                  <CloseIcon></CloseIcon>
                </Avatar>
              </div>
              <div className="PREVIEW_MODAL_IMAGE_CONTAINER">
                {this.state.previewFile ? (
                  <img src={this.state.previewFile} alt="repair cocument"></img>
                ) : null}
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    documents: state.document.documents,
    progress: state.document.progress,
    activeJobCard: state.jobCard.jobCard,
    loadingActiveJobCard: state.jobCard.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDocument: (token, id) =>
      dispatch(documentAction.getRegisteredDocument(token, id)),
    getDocuments: (token, workGroupID) =>
      dispatch(documentAction.getRegisteredDocuments(token, workGroupID)),
    saveDocument: (
      token,
      job_card,
      document_name,
      document_type,
      document_size,
      document_image
    ) =>
      dispatch(
        documentAction.registerDocument(
          token,
          job_card,
          document_name,
          document_type,
          document_size,
          document_image
        )
      ),
    saveDocuments: (token, job_card, documentList) =>
      dispatch(documentAction.registerDocuments(token, job_card, documentList)),
    deleteDocument: (token, id, job_card_id) =>
      dispatch(documentAction.deleteRegisteredDocument(token, id, job_card_id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobImagesForm);
