import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { HOST_URL } from '../../settings';
import './styles/invoice-form.css';
import moment from 'moment';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import SaveIcon from '@mui/icons-material/Save';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import {
  getLastOrder,
  getOrderByNumber,
  postOrderData,
  updateOrderData,
} from '../../store/actions/order';
import {
  deleteOrderItem,
  getItems,
  postOrderItemData,
} from '../../store/actions/item';
import { getClients } from '../../store/actions/client';
import { getClaims, getInsurances } from '../../store/actions/insurance';
import {
  clearInvoiceProps,
  deleteInvoice,
  getInvoiceByInvoiceNumber,
  getLastInvoice,
  partialUpdateInvoiceData,
  postInvoiceData,
} from '../../store/actions/invoice';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  getBankingDetails,
  updateBankingDetails,
} from '../../store/actions/company';

import DataDrawer from '../data-drawer/DataDrawer';
import { getJobCardByNumber } from '../../store/actions/jobCard';
import { getJobTaskByNumber } from '../../store/actions/jobTask';
import Edit from '@mui/icons-material/Edit';
import OrderItem from '../order-item/OrderItem';
import { Slide, Snackbar } from '@mui/material';

class InvoiceForm extends Component {
  state = {
    invoiceNumber: '',
    dueDate: new Date(),
    taxDate: new Date(),
    invoiceTerms: '',
    customer: '',
    customerVatReg: '',
    insuredCustomer: '',
    insuredItem: '',
    accountNumber: '',
    orderNumber: '',
    invoicedBy: '',
    product: '',
    itemCode: '',
    selectedProduct: null,
    selectedItem: null,
    description: '',
    extraDetails: '',
    rate: 0,
    quantity: 1,
    amount: 0,
    orderItems: [],
    subtotal: 0,
    jobCardNumber: '',
    vat: 15,
    vat_total: 0,
    total: 0,
    payments: 0,
    balanceDue: 0,
    clientName: '',
    claim: '',
    insuranceClaimNumber: '',
    selectedClaim: null,
    selectedClient: null,
    loadingClients: false,
    loadingClaims: false,
    loadingInsurances: false,
    loadingProducts: false,
    insuranceCompany: '',
    selectedInsurance: null,
    editBankingDetails: false,
    openMessageModal: false,
    openDeleteInvModal: false,
    openErrorMessageModal: false,
    bank: '',
    bankAccountName: '',
    bankAccountNumber: '',
    branchCode: '',
    note: '',
    generateInvoiceStart: false,
    updateInvoiceStart: false,
    taxInvoiceEnable: false,
    insuranceTaxInvoiceEnable: false,
    enableInvoicePrint: false,
    virtical: 'bottom',
    horizontal: 'left',
    alertOpen: false,
    alertMessage: '',
    alertBgColor: '#1B262C',
  };

  componentDidMount() {
    if (this.props.userDetails) {
      const token = this.props.token;
      this.props.getBankingDetails(token);
      this.props.getLastInvoice(token);
      this.props.getLastOrder(token);
    }
    console.log(this.props.match.params.data);
    if (this.props.match.params.data) {
      this.props.getActiveInvoice(
        this.props.token,
        this.props.match.params.data
      );
      this.props.getBankingDetails(this.props.token);
    }
    if (this.props.match.params.data) {
      this.setState({ jobCardNumber: this.props.match.params.data });
      this.props.getJobCard(this.props.token, this.props.match.params.data);
      this.props.getJobTask(this.props.token, this.props.match.params.data);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.clients !== prevProps.clients) {
      this.setState({ loadingClients: false });
    }
    if (this.props.insuranceCompanies !== prevProps.insuranceCompanies) {
      this.setState({ loadingInsurances: false });
    }
    if (this.props.claims !== prevProps.claims) {
      this.setState({ loadingClaims: false });
    }
    if (this.props.order !== prevProps.order) {
      console.log('initiating item saving');
      this.saveOrderItems();
    }
    if (
      this.props.orderItems !== prevProps.orderItems &&
      this.props.orderItems.length === this.state.orderItems.length // to be optimized
    ) {
      this.createInvoice();
    }

    if (this.props.orderItem !== prevProps.orderItem) {
      this.props.getActiveInvoice(
        this.props.token,
        this.props.match.params.data
      );
    }

    if (this.props.invoice !== prevProps.invoice) {
      if (this.props.invoice) {
        this.setState({
          enableInvoicePrint: true,
        });
        if (this.state.updateInvoiceStart === true) {
          this.setState({ updateInvoiceStart: false });
          this.handleAlertFeddback('Invoice has been updated.', '#17B794');
        }
        if (this.state.generateInvoiceStart === true) {
          this.setState({ generateInvoiceStart: false });
          this.handleAlertFeddback('Invoice has been created.', '#17B794');
        }
      } else {
        this.clearPreviousInvoiceData();
      }
    }
    if (
      this.props.invoice !== prevProps.invoice &&
      this.props.invoice &&
      this.state.generateInvoiceStart === false
    ) {
      this.updateStateInvoiceData();
    }

    if (
      this.props.bankingDetails !== prevProps.bankingDetails &&
      this.state.editBankingDetails
    ) {
      this.setState({ bankingDetails: false });
    }

    if (
      this.props.lastInvoice !== prevProps.lastInvoice &&
      this.props.lastInvoice
    ) {
      this.calculateNewInvoiceNumber();
    }

    if (this.props.match.params.data !== prevProps.match.params.data) {
      this.setState({ invoiceNumber: this.props.match.params.data });
      this.props.getJobCard(this.props.token, this.props.match.params.data);
      this.props.getJobTask(this.props.token, this.props.match.params.data);
      this.props.getActiveInvoice(
        this.props.token,
        this.props.match.params.data
      );
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  updateStateInvoiceData = () => {
    // when invoice number in url
    const invoice = this.props.invoice;
    if (invoice) {
      this.setState({
        invoiceNumber: invoice.invoice_number,
        invoicedBy: invoice.invoiced_by,
        note: invoice.invoice_note,
        dueDate: new Date(invoice.due_date),
        taxDate: new Date(invoice.tax_date),
        invoiceTerms: invoice.terms,
        accountNumber: invoice.financial_account.account_number,
        clientName: invoice.financial_account.client.client_name,
        orderNumber: invoice.order.order_number,
        subtotal: invoice.order.sub_total,
        vat_total: invoice.order.tax,
        total: invoice.order.total,
        balanceDue: invoice.order.grand_total,
        orderItems: invoice.order.order_item,
      });

      this.calculateAmounts(invoice.order.order_item);
    }
  };

  calculateAmounts = (items) => {
    let subtotal = parseFloat(0);
    let total = parseFloat(0);
    let balance = parseFloat(0);
    if (items) {
      items.map((item) => {
        subtotal = subtotal + parseFloat(item.price);
        total =
          this.state.taxQuoteEnable || this.state.insuranceTaxQuoteEnable
            ? parseFloat(subtotal) +
              parseFloat((subtotal * this.state.vat) / 100)
            : parseFloat(subtotal);

        balance = parseFloat(total);
      });
      this.setState({
        subtotal: subtotal,
        total: total,
        balanceDue: balance,
      });
    }
  };

  calculateNewInvoiceNumber = () => {
    if (!this.props.invoice || this.props.match.params.data === undefined) {
      if (this.props.lastInvoice) {
        const invoiceNumber = this.props.lastInvoice.invoice_number;
        const numbers = invoiceNumber.match(/\d+/g)[0];
        const newInvoiceNumber = 'INV' + (Number(numbers) + 1);
        console.log(numbers);
        console.log(newInvoiceNumber);
        this.setState({ invoiceNumber: newInvoiceNumber });
      } else {
        this.setState({ invoiceNumber: 'INV1001' });
      }
    }
    // TODO address edit invoice cases
    // } else if (this.props.match.params.data.includes('IN')) {
    //   this.setState({ invoiceNumber: this.props.match.params.data });
    // } else {
    //   this.setState({ invoiceNumber: this.props.invoice?.invoice_number });
    // }
  };

  calculateNewOrderNumber = () => {
    if (this.props.lastOrder) {
      const lastOrderNumber = this.props.lastOrder.order_number;
      const newOrderNumber = Number(lastOrderNumber) + 1;
      console.log(newOrderNumber);
      this.setState({ orderNumber: newOrderNumber });
      return newOrderNumber;
    } else {
      this.setState({ orderNumber: 1001 });
      return Number(1001);
    }
  };

  handleTaxInvoiceEnabled = (e) => {
    const vat_total = parseFloat((this.state.subtotal * this.state.vat) / 100);
    const total = this.state.subtotal + vat_total;
    const balance = parseFloat(total) - parseFloat(this.state.payments);
    if (e.target.checked) {
      this.setState({
        taxInvoiceEnable: e.target.checked,
        insuranceTaxInvoiceEnable: false,
        vat_total: vat_total,
        total: total,
        balanceDue: balance,
      });
    } else {
      const balance =
        parseFloat(total) - parseFloat(this.state.payments) - vat_total;
      this.setState({
        taxInvoiceEnable: e.target.checked,
        insuranceTaxInvoiceEnable: false,
        vat_total: 0,
        total: total - vat_total,
        balanceDue: balance,
      });
    }
  };

  handleInsuranceTaxInvoiceEnabled = (e) => {
    if (this.state.selectedClaim && e.target.checked) {
      const account_number =
        this.state.selectedClaim.insurance_company.financial_account;
      this.setState({ accountNumber: account_number });
    } else if (this.state.selectedClaim && !e.target.checked) {
      const account_number = this.state.selectedClient.financial_account;
      this.setState({ accountNumber: account_number });
    }
    const vat_total = parseFloat((this.state.subtotal * this.state.vat) / 100);
    const total = this.state.subtotal + vat_total;
    const balance = parseFloat(total) - parseFloat(this.state.payments);
    if (e.target.checked) {
      this.setState({
        insuranceTaxInvoiceEnable: e.target.checked,
        taxInvoiceEnable: false,
        vat_total: vat_total,
        total: total,
        balanceDue: balance,
      });
    } else {
      const balance =
        parseFloat(total) - parseFloat(this.state.payments) - vat_total;
      this.setState({
        insuranceTaxInvoiceEnable: e.target.checked,
        taxInvoiceEnable: false,
        vat_total: 0,
        total: total - vat_total,
        balanceDue: balance,
      });
    }
  };

  handleMessageOpen = (title, body) => {
    this.setState({
      messageModalMessageTitle: title,
      messageModalMessageBody: body,
      openMessageModal: true,
    });
  };

  handleDeleteInvModalClose = () => {
    this.setState({ openDeleteInvModal: false });
  };

  handleMessageClose = () => {
    this.setState({ openMessageModal: false });
  };

  handleErrorMessageOpen = (title, body) => {
    this.setState({
      errorMessageModalMessageTitle: title,
      errorMessageModalMessageBody: body,
      openErrorMessageModal: true,
    });
  };

  handleErrorMessageClose = () => {
    this.setState({ openErrorMessageModal: false });
  };

  validateOrderItem = () => {
    const { itemCode, description, rate, quantity } = this.state;

    if (itemCode === '' || description === '' || rate === 0 || quantity === 0) {
      return false;
    } else {
      return true;
    }
  };

  addOrderItem = () => {
    const orderitemValid = this.validateOrderItem();
    console.log('Add Order Item');
    if (orderitemValid) {
      var orderItems = this.state.orderItems;
      var orderItem = {};

      orderItem.description = this.state.description;
      orderItem.item = this.state.selectedItem.item_code;
      orderItem.order = null;
      orderItem.sku = '';
      orderItem.discount = 0;
      orderItem.price = this.state.rate;
      orderItem.quantity = this.state.quantity;
      orderItem.amount = this.state.amount;
      orderItem.content = this.state.extraDetails;
      orderItems.push(orderItem);

      this.setState({ orderItems: orderItems });
      const subtotal =
        parseFloat(this.state.subtotal) + parseFloat(this.state.rate);
      const total =
        this.state.taxInvoiceEnable || this.state.insuranceTaxInvoiceEnable
          ? parseFloat(subtotal) + parseFloat((subtotal * this.state.vat) / 100)
          : parseFloat(subtotal);

      const balance = parseFloat(total) - parseFloat(this.state.payments);

      this.setState({
        subtotal: subtotal,
        total: total,
        itemCode: '',
        balanceDue: balance,
        description: '',
        extraDetails: '',
        rate: 0,
        quantity: 1,
        amount: 0,
      });
    } else {
      this.handleErrorMessageOpen(
        'Error',
        'Invalid order item fields. One or more of the fields are empty or has invalid value.'
      );
    }
  };

  // This function is called when updating the invoice only
  addOrderItemToOrder = () => {
    const orderitemValid = this.validateOrderItem();
    console.log('Add Order Item');
    if (orderitemValid) {
      const token = this.props.token;
      this.props.createOrderItem(
        token,
        this.state.description,
        this.state.extraDetails,
        this.state.selectedItem.item_code,
        this.props.invoice.order.id,
        '',
        0,
        this.state.rate,
        this.state.quantity,
        parseFloat(this.state.rate * this.state.quantity)
      );

      const subtotal =
        parseFloat(this.state.subtotal) + parseFloat(this.state.rate);
      const total =
        this.state.taxQuoteEnable || this.state.insuranceTaxQuoteEnable
          ? parseFloat(subtotal) + parseFloat((subtotal * this.state.vat) / 100)
          : parseFloat(subtotal);

      const balance = parseFloat(total);

      this.setState({
        subtotal: subtotal,
        total: total,
        balanceDue: balance,
        description: '',
        extraDetails: '',
        rate: 0,
        amount: 0,
      });
    } else {
      this.handleErrorMessageOpen(
        'Error',
        'Invalid order item fields. One or more of the fields are empty or has invalid value.'
      );
    }
  };

  removeOrderItem = (index) => {
    var orderItems = this.state.orderItems;

    let subtotal =
      parseFloat(this.state.subtotal) -
      parseFloat(this.state.orderItems[index].price);
    let total =
      parseFloat(subtotal) + parseFloat((subtotal * this.state.vat) / 100);
    let balance = parseFloat(total) - parseFloat(this.state.payments);

    this.setState({ subtotal: subtotal, total: total, balanceDue: balance });

    orderItems.splice(index, 1);
    this.setState({ orderItems: orderItems });
  };

  getClients = () => {
    this.setState({ loadingClients: true });
    const token = this.props.token;
    this.props.getClients(token);
  };

  onSelectClient = (e, option) => {
    if (e.target.innerText !== undefined) {
      this.setState({
        clientName: option ? option.client_name : '',
        selectedClient: option ? option : null,
        accountNumber:
          option &&
          option.financial_account &&
          !this.state.insuranceTaxInvoiceEnable
            ? option.financial_account
            : this.state.accountNumber,
      });
    } else {
      this.setState({
        clientName: '',
      });
    }
  };

  getInsuranceClaims = () => {
    this.setState({ loadingClaims: true });
    const token = this.props.token;
    this.props.getClaims(token);
  };

  onSelectClaim = (e, option) => {
    if (e.target.innerText !== undefined) {
      this.setState({
        insuranceClaimNumber: option ? option.claim_number : '',
        selectedClaim: option ? option : null,
        insuranceCompany: option
          ? option.insurance_company.insurance_company_name
          : '',
        accountNumber:
          option && option.insurance_company.financial_account
            ? option.insurance_company.financial_account
            : '',
      });
    } else {
      this.setState({
        insuranceClaimNumber: '',
        insuranceCompany: '',
        selectedClaim: null,
      });
    }
  };

  getInsuranceCompanies = () => {
    this.setState({ loadingInsurances: true });
    const token = this.props.token;
    this.props.getInsurances(token);
  };

  onSelectInsurance = (e, option) => {
    if (e.target.innerText !== undefined) {
      this.setState({
        insuranceCompany: option ? option.insurance_company_name : '',
        selectedInsurance: option ? option : null,
        accountNumber:
          option && option.financial_account ? option.financial_account : '',
      });
    } else {
      this.setState({
        insuranceCompany: '',
        selectedInsurance: null,
      });
    }
  };

  getItems = () => {
    const token = this.props.token;
    this.props.getItems(token);
  };

  onSelectItem = (e, option) => {
    console.log(option);
    if (e.target.innerText !== undefined) {
      this.setState({
        itemCode: option ? option.item_code : '',
        selectedItem: option ? option : null,
      });
    } else {
      this.setState({
        itemCode: '',
        selectedItem: null,
      });
    }
  };

  // This is the VAT value
  calculateTax = () => {
    const { vat, subtotal, taxInvoiceEnable, insuranceTaxInvoiceEnable } =
      this.state;
    const tax = parseFloat((subtotal * vat) / 100);
    if (taxInvoiceEnable || insuranceTaxInvoiceEnable) {
      return tax;
    } else {
      return 0;
    }
  };

  // Create an order
  createOrder = () => {
    const token = this.props.token;
    const order_number = this.calculateNewOrderNumber();
    const accountNumber = this.state.accountNumber;
    const job_card_number = this.state.jobCardNumber;
    const order_type = 'customer order';
    const status = 'unpaid';
    const sub_total = this.state.subtotal;
    const item_discount = parseFloat(0);
    const tax = this.calculateTax();
    const shipping = parseFloat(0);
    const total = this.state.total;
    const grand_total = this.state.balanceDue;
    const promo = '';
    const content = '';

    this.props.createOrder(
      token,
      order_number,
      accountNumber,
      job_card_number,
      order_type,
      status,
      sub_total,
      item_discount,
      tax,
      shipping,
      total,
      promo,
      grand_total,
      content
    );
  };

  // Save order items
  saveOrderItems = () => {
    const token = this.props.token;
    const order = this.props.order ? this.props.order.id : null;

    this.state.orderItems.map((item, index) =>
      this.props.createOrderItem(
        token,
        item.description,
        item.content,
        item.item,
        order,
        item.sku,
        item.discount,
        item.price,
        item.quantity,
        parseFloat(item.price * item.quantity)
      )
    );
  };

  // Generate an invoice
  createInvoice = () => {
    const token = this.props.token;
    console.log('Creating invoice');
    if (this.props.order && this.state.orderItems.length > 0) {
      console.log('Creating invoice starting ...');
      const invoiceType = () => {
        if (this.state.taxInvoiceEnable) {
          return 'tax invoice';
        } else if (this.state.insuranceTaxInvoiceEnable) {
          return 'insurance tax invoice';
        } else {
          return 'normal';
        }
      };
      const order = this.props.order.id;
      const pop_email = ''; // to be removed later cause it's included in the notes;
      const terms_and_conditions = ''; // to be removed later cause it's included in the company data;
      const invoicedBy = this.props.userDetails.username;
      const {
        invoiceNumber,
        accountNumber,
        insuranceClaimNumber,
        invoiceTerms,
        dueDate,
        taxDate,
        note,
        selectedClient,
        jobCardNumber,
      } = this.state;

      this.props.createInvoice(
        token,
        invoiceNumber,
        jobCardNumber,
        accountNumber,
        selectedClient.id,
        insuranceClaimNumber,
        invoiceTerms,
        order,
        invoicedBy,
        moment(taxDate).format('YYYY-MM-DD'),
        moment(dueDate).format('YYYY-MM-DD'),
        pop_email,
        terms_and_conditions,
        note,
        invoiceType()
      );
    } else {
      console.log('can not generate the invoice');
    }
  };

  validateInvoiceFields = () => {
    const {
      invoiceNumber,
      clientName,
      insuranceClaimNumber,
      orderItems,
      insuranceCompany,
      balanceDue,
      insuranceTaxInvoiceEnable,
    } = this.state;

    if (
      (invoiceNumber === '' ||
        clientName === '' ||
        insuranceClaimNumber === '' ||
        insuranceCompany === '' ||
        orderItems.length === 0 ||
        balanceDue === 0) &&
      insuranceTaxInvoiceEnable
    ) {
      return false;
    } else if (
      (invoiceNumber === '' ||
        clientName === '' ||
        orderItems.length === 0 ||
        balanceDue === 0) &&
      !insuranceTaxInvoiceEnable
    ) {
      return false;
    } else {
      return true;
    }
  };

  // Initiating Invoice creating
  initiateInvoiceGeneration = () => {
    console.log('initiating invoice process');

    this.setState({ openMessageModal: false });
    const valid = this.validateInvoiceFields();
    if (valid) {
      this.setState({ generateInvoiceStart: true });
      const token = this.props.token;
      const orderNumber = this.state.orderNumber;
      console.log('Handling Order Creating');
      if (this.state.orderNumber !== '') {
        console.log('Existing order');
        this.props.getOrder(token, orderNumber);
      } else {
        this.createOrder();
      }
    } else if (this.state.orderItems.length === 0) {
      this.handleErrorMessageOpen(
        'Error',
        'Please make sure you add the order item be able to generate the invoice.'
      );
    } else {
      this.handleErrorMessageOpen(
        'Error',
        'Please fill the necessary fields for in order to be able to generate the invoice.'
      );
    }
  };

  initiateInvoiceUpdate = () => {
    console.log('initiating invoice update process');
    this.setState({ openMessageModal: false });
    const valid = this.validateInvoiceFields();
    if (valid) {
      this.setState({ updateInvoiceStart: true });

      // this.handleUpdateOrder();
      this.handleUpdateInvoice();
    }
  };

  handleUpdateOrder = () => {
    const token = this.props.token;
    const order_id = this.props.invoice.order.id;

    const { subtotal, vat_total, total, balanceDue } = this.state;

    this.props.updateOrderData(
      token,
      order_id,
      subtotal,
      vat_total,
      total,
      balanceDue
    );
  };

  handleUpdateInvoice = () => {
    const token = this.props.token;
    const invoice_id = this.props.invoice.id;
    const { dueDate, taxDate, invoiceTerms, note, invoiceNumber } = this.state;

    this.props.pdateInvoiceData(
      token,
      invoice_id,
      invoiceNumber,
      moment(dueDate).format('YYYY-MM-DD'),
      moment(taxDate).format('YYYY-MM-DD'),
      note,
      invoiceTerms
    );
  };

  // Checking Order availablity before initiating the invoice creating
  generateInvoice = () => {
    const orderNumber = this.state.orderNumber;

    if (orderNumber === '') {
      this.handleMessageOpen(
        'Warning',
        'Please insert order number if this invoice is related to one. Click Ignore if not, and a new order will be generated automatically.'
      );
    } else {
      this.initiateInvoiceGeneration();
    }
  };

  prepareBankingDetailsEdit = () => {
    const { bank, account_name, account_number, branch_code } =
      this.props.bankingDetails;
    this.setState({
      bank: bank,
      bankAccountName: account_name,
      bankAccountNumber: account_number,
      branchCode: branch_code,
      editBankingDetails: true,
    });
  };

  updateBankingDetails = () => {
    const token = this.props.token;
    const id = this.props.bankingDetails.id;

    const { bank, bankAccountName, bankAccountNumber, branchCode } = this.state;

    this.props.updateBankingDetails(
      token,
      id,
      bank,
      bankAccountName,
      bankAccountNumber,
      branchCode
    );
  };

  invoiceRedirect = () => {
    if (this.props.location.pathname.includes('accounts-manager')) {
      this.props.history.push('/accounts-manager/invoice/');
      localStorage.setItem('prevPath', '/accounts-manager/invoice/');
    } else if (this.props.location.pathname.includes('reception')) {
      this.props.history.push('/reception/create-invoice/');
      localStorage.setItem('prevPath', '/reception/create-invoice/');
    }
  };

  handleDeleteInvoice = () => {
    const token = this.props.token;
    const invoiceID = this.props.invoice.id;
    this.setState({ openDeleteInvModal: false });
    this.props.deleteInvoice(token, invoiceID);
  };

  handleNewInvoiceStart = () => {
    this.clearPreviousInvoiceData();
    this.invoiceRedirect();
  };

  clearPreviousInvoiceData = () => {
    const token = this.props.token;
    this.setState({
      amount: 0,
      orderItems: [],
      rate: 0,
      subtotal: 0,
      vat: 15,
      vat_total: 0,
      total: 0,
      payments: 0,
      balanceDue: 0,
      clientName: '',
      selectedClient: null,
      selectedClaim: null,
      itemCode: null,
      insuranceClaimNumber: '',
      selectedInsurance: null,
      insuranceCompany: '',
      accountNumber: '',
      orderNumber: '',
      dueDate: new Date(),
      taxDate: new Date(),
    });
    this.props.clearInvoiceData();
    this.props.getLastInvoice(token);
    this.props.getLastOrder(token);
  };

  handleAlertFeddback = (message, backgroundColor) => {
    this.setState({
      alertOpen: true,
      alertMessage: message,
      alertBgColor: backgroundColor,
    });
    setTimeout(
      () =>
        this.setState({
          alertOpen: false,
          alterMessage: '',
          alertBgColor: '#1B262C',
        }),
      3000
    );
  };

  handleAlertClose = () => {
    this.setState({ alertOpen: false });
  };

  TransitionLeft = (props) => {
    return <Slide {...props} direction="right" />;
  };

  render() {
    const filterClaimOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.claim_number,
    });

    const filterOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.insurance_company_name,
    });

    const filterClientOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.client_name,
    });

    const filterItemsOptions = createFilterOptions({
      matchFrom: 'start',
      stringify: (option) => option.item_code,
    });

    const error_meg_style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: '#1e272e',
      color: '#DDDDDD',
      border: '2px solid #000',
      borderRadius: 4,
      boxShadow: 24,
      p: 4,
    };
    const mode = this.props.mode;
    const editable = !!(
      this.props.match.params.data &&
      this.props.invoice?.job_card?.job_card_number
    );

    const { virtical, horizontal } = this.state;

    return (
      <div className="INVOICE_FORM_MAIN_CONTAINER">
        <Snackbar
          // anchorOrigin={{ virtical, horizontal }}
          open={this.state.alertOpen}
          onClose={this.handleAlertClose}
          message={this.state.alertMessage}
          key={virtical + horizontal}
          TransitionComponent={this.TransitionLeft}
          ContentProps={{
            sx: { backgroundColor: this.state.alertBgColor },
          }}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={this.handleAlertClose}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        />
        <div className="INVOICE_FORM_HEADER">
          <h2>GENERATE INVOICE</h2>
          {this.props.match.params.data ? (
            <Button
              variant="outlined"
              size="small"
              color="error"
              sx={{ ml: 2 }}
              onClick={() => this.setState({ openDeleteInvModal: true })}
            >
              delete
            </Button>
          ) : null}
          <div style={{ marginLeft: 'auto' }}>
            {this.props.match.params.data ? (
              <DataDrawer
                mode={this.props.mode}
                jobCard={this.props.jobCard}
                jobTask={this.props.jobTask}
              />
            ) : null}
          </div>
        </div>
        <div className="INVOICE_FORM_INFO_CONTAINER">
          <div className="INVOICE_FORM_INVOICE_IDENTITY">
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Invoice Number"
                variant="outlined"
                name="invoiceNumber"
                fullWidth
                value={this.state.invoiceNumber}
                onChange={(e) => this.onChange(e, editable)}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="job-card-number"
                label="Job Card No"
                variant="outlined"
                name="jobCardNumber"
                fullWidth
                value={this.state.jobCardNumber}
                onChange={(e) => this.onChange(e, !editable)}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Due Date"
                  value={this.state.dueDate}
                  onChange={(newValue) => {
                    this.setState({ dueDate: newValue });
                  }}
                  fullWidth
                  sx={{
                    svg:
                      mode === 'light'
                        ? { color: '#19376d' }
                        : { color: '#576cbc' },
                    input:
                      mode === 'light'
                        ? { color: '#17293e' }
                        : { color: '#ffffff' },
                    label:
                      mode === 'light'
                        ? {
                            color: '#182c43',
                            zIndex: 10,
                            background: '#c3cfd1',
                          }
                        : {
                            color: '#b8b8b8',
                            zIndex: 10,
                            background: '#111827',
                          },
                    border:
                      mode === 'light'
                        ? '1px solid #bec3c7'
                        : '1px solid #384f68',
                    width: '100%',
                    borderRadius: 2,
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Tax Date"
                  value={this.state.taxDate}
                  onChange={(newValue) => {
                    this.setState({ taxDate: newValue });
                  }}
                  fullWidth
                  sx={{
                    svg:
                      mode === 'light'
                        ? { color: '#19376d' }
                        : { color: '#576cbc' },
                    input:
                      mode === 'light'
                        ? { color: '#17293e' }
                        : { color: '#ffffff' },
                    label:
                      mode === 'light'
                        ? {
                            color: '#182c43',
                            zIndex: 10,
                            background: '#c3cfd1',
                          }
                        : {
                            color: '#b8b8b8',
                            zIndex: 10,
                            background: '#111827',
                          },
                    border:
                      mode === 'light'
                        ? '1px solid #bec3c7'
                        : '1px solid #384f68',
                    width: '100%',
                    borderRadius: 2,
                  }}
                />
              </LocalizationProvider>
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="invoice-terms"
                label="Invoice Terms"
                variant="outlined"
                name="invoiceTerms"
                fullWidth
                placeholder="On receipt"
                value={this.state.invoiceTerms}
                onChange={(e) => this.onChange(e, editable)}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
          </div>
          <div className="INVOICE_FORM_ORDER_DETAILS">
            {/* <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Invoiced By"
                variant="outlined"
                name="invoicedBy"
                fullWidth
                value={
                  this.props.userDetails
                    ? this.props.userDetails.username
                    : this.state.invoicedBy
                }
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div> */}
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              {editable ? (
                <TextField
                  id="client_name"
                  label="Client"
                  variant="outlined"
                  name="clientName"
                  fullWidth
                  value={this.state.clientName}
                  contentEditable={false}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'INVOICE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              ) : (
                <Autocomplete
                  id="filter-client"
                  freeSolo
                  options={this.props.clients ? this.props.clients : []}
                  getOptionLabel={(option) =>
                    option.client_name
                      ? option.client_name
                      : this.state.clientName
                  }
                  filterOptions={filterClientOptions}
                  sx={{ width: '100%', color: '#fff' }}
                  onOpen={() => {
                    this.getClients();
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  clearIcon={<CloseIcon color="error"></CloseIcon>}
                  openOnFocus={true}
                  onChange={(e, option) => this.onSelectClient(e, option)}
                  value={this.state.clientName}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Client"
                      name="clientName"
                      fullWidth
                      value={this.state.clientName}
                      onChange={(e) => this.onChange(e, !editable)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loadingClients ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        className: 'INVOICE_FORM_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              )}
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="account-number"
                label="Account No"
                variant="outlined"
                name="accountNumber"
                fullWidth
                value={this.state.accountNumber}
                onChange={(e) => this.onChange(e, !editable)}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="order-number"
                label="Order Number"
                variant="outlined"
                name="orderNumber"
                fullWidth
                value={this.state.orderNumber}
                onChange={(e) => this.onChange(e, !editable)}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <div className="INVOICE_FORM_INPUT_CHECK_CONTAINER">
                <span style={{ color: '#3282B8' }}>TAX Invoice</span>
                <Switch
                  checked={this.state.taxInvoiceEnable}
                  onChange={(e) => this.handleTaxInvoiceEnabled(e)}
                  disabled={editable}
                  inputProps={{ 'aria-label': 'tax' }}
                />
              </div>
            </div>
          </div>
          <div className="INVOICE_FORM_INVOICE_TO_DETAILS">
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              {editable ? (
                <TextField
                  id="claim_number"
                  label="Claim Number"
                  variant="outlined"
                  name="insuranceClaimNumber"
                  fullWidth
                  value={this.state.insuranceClaimNumber}
                  contentEditable={false}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'INVOICE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              ) : (
                <Autocomplete
                  id="filter-claims"
                  freeSolo
                  options={this.props.claims ? this.props.claims : []}
                  getOptionLabel={(option) =>
                    option.claim_number
                      ? option.claim_number
                      : this.state.insuranceClaimNumber
                  }
                  filterOptions={filterClaimOptions}
                  sx={{ color: '#fff' }}
                  onOpen={() => {
                    this.getInsuranceClaims();
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  clearIcon={<CloseIcon color="error"></CloseIcon>}
                  openOnFocus={true}
                  value={this.state.insuranceClaimNumber}
                  onChange={(e, option) => this.onSelectClaim(e, option)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Claim Number"
                      name="insuranceClaimNumber"
                      fullWidth
                      value={this.state.insuranceClaimNumber}
                      onChange={(e) => this.onChange(e, !editable)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loadingClaims ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        className: 'INVOICE_FORM_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              )}
            </div>
            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              {editable ? (
                <TextField
                  id="insurance-company"
                  label="Insurance Company"
                  variant="outlined"
                  name="insuranceCompany"
                  fullWidth
                  value={this.state.insuranceCompany}
                  contentEditable={false}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'INVOICE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              ) : (
                <Autocomplete
                  id="filter-insurance"
                  freeSolo
                  options={
                    this.props.insuranceCompanies
                      ? this.props.insuranceCompanies
                      : []
                  }
                  getOptionLabel={(option) =>
                    option.insurance_company_name
                      ? option.insurance_company_name
                      : this.state.insuranceCompany
                  }
                  filterOptions={filterOptions}
                  sx={{ width: '100%', color: '#fff' }}
                  onOpen={() => {
                    this.getInsuranceCompanies();
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  clearIcon={<CloseIcon color="error"></CloseIcon>}
                  openOnFocus={true}
                  editable={`${!editable}`}
                  onChange={(e, option) => this.onSelectInsurance(e, option)}
                  value={this.state.insuranceCompany}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Insurance Company"
                      name="insuranceCompany"
                      fullWidth
                      editable={`${!editable}`}
                      value={this.state.insuranceCompany}
                      onChange={(e) => this.onChange(e, !editable)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.state.loadingInsurances ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        className: 'INVOICE_FORM_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              )}
            </div>

            {/* <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <TextField
                id="outlined-basic"
                label="Customer Vat Reg"
                variant="outlined"
                name="customerVatReg"
                fullWidth
                value={this.state.customerVatReg}
                onChange={this.onChange}
                // helperText="Please select your currency"
                InputProps={{
                  className: 'INVOICE_FORM_INPUT_FIELD',
                }}
                InputLabelProps={{
                  // shrink: true,
                  className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                }}
              ></TextField>
            </div> */}

            <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
              <div className="INVOICE_FORM_INPUT_CHECK_CONTAINER">
                <span style={{ color: '#3282B8' }}>Insurance TAX Invoice</span>
                <Switch
                  checked={this.state.insuranceTaxInvoiceEnable}
                  onChange={(e) => this.handleInsuranceTaxInvoiceEnabled(e)}
                  disabled={editable}
                  inputProps={{ 'aria-label': 'insurance-tax' }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="INVOICE_FORM_ITEMS_MAIN_CONTAINER">
          <div className="INVOICE_FORM_ITEMS_CONTAINER">
            <div className="INVOICE_FORM_ITEM_ADD">
              <Tooltip title="Add Item">
                {
                  // this.props.match.params.data ? (
                  //   this.props.orderItemsLoading ? (
                  //     <CircularProgress size={30}></CircularProgress>
                  //   ) : (
                  //     <IconButton
                  //       aria-label="add"
                  //       onClick={this.addOrderItemToOrder}
                  //     >
                  //       <AddCircleOutlineOutlinedIcon
                  //         sx={{ fontSize: 40, color: '#6E85B2' }}
                  //       />
                  //     </IconButton>
                  //   )
                  // ) :
                  this.props.orderItemsLoading ? (
                    <CircularProgress size={30}></CircularProgress>
                  ) : (
                    <IconButton aria-label="add" onClick={this.addOrderItem}>
                      <AddCircleOutlineOutlinedIcon
                        sx={{ fontSize: 40, color: '#6E85B2' }}
                      />
                    </IconButton>
                  )
                }
              </Tooltip>
            </div>
            <div className="INVOICE_FORM_ITEM_DESCRIPTION">
              <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
                <Autocomplete
                  id="filter-products"
                  freeSolo
                  options={this.props.items ? this.props.items : []}
                  getOptionLabel={(option) =>
                    option.item_code ? option.item_code : this.state.itemCode
                  }
                  filterOptions={filterItemsOptions}
                  sx={{ width: '100%', color: '#fff' }}
                  onOpen={() => {
                    this.getItems();
                  }}
                  onClose={() => {
                    console.log('close');
                  }}
                  clearIcon={<CloseIcon color="error"></CloseIcon>}
                  openOnFocus={true}
                  onChange={(e, option) => this.onSelectItem(e, option)}
                  value={this.state.itemCode}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Choose Item"
                      name="itemCode"
                      fullWidth
                      value={this.state.itemCode}
                      onChange={this.onChange}
                      required
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {this.props.loadingItems ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                        className: 'INVOICE_FORM_INPUT_FIELD',
                      }}
                      InputLabelProps={{
                        // shrink: true,
                        className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                      }}
                    />
                  )}
                />
              </div>

              <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="item-desc"
                  label="Item Description"
                  variant="outlined"
                  name="description"
                  fullWidth
                  type="text"
                  value={this.state.description}
                  onChange={this.onChange}
                  required
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'INVOICE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
              <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="item-extra-details"
                  label="Extra Details"
                  multiline
                  rows={6}
                  variant="outlined"
                  // color="secondary"
                  type="text"
                  name="extraDetails"
                  fullWidth
                  required
                  // error={this.state.questionError ? true : false}
                  value={this.state.extraDetails}
                  onChange={this.onChange}
                  InputProps={{
                    className: 'INVOICE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                  }}
                />
              </div>
            </div>
            <div className="INVOICE_FORM_ITEM_RATE">
              <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="item-rate"
                  label="Rate"
                  variant="outlined"
                  name="rate"
                  fullWidth
                  value={this.state.rate}
                  onChange={this.onChange}
                  required
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'INVOICE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="INVOICE_FORM_ITEM_QTY">
              <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="quantity"
                  label="Quantity"
                  variant="outlined"
                  name="quantity"
                  fullWidth
                  value={this.state.quantity}
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'INVOICE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
            <div className="INVOICE_FORM_ITEM_AMOUNT">
              <div className="INVOICE_FORM_INPUT_FIELD_CONTAINER">
                <TextField
                  id="amount"
                  label="Amount"
                  variant="outlined"
                  name="amount"
                  fullWidth
                  value={
                    this.state.rate !== 0
                      ? this.state.rate * this.state.quantity
                      : 0
                  }
                  onChange={this.onChange}
                  // helperText="Please select your currency"
                  InputProps={{
                    className: 'INVOICE_FORM_INPUT_FIELD',
                  }}
                  InputLabelProps={{
                    // shrink: true,
                    className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                  }}
                ></TextField>
              </div>
            </div>
          </div>

          {this.state.orderItems
            ? this.state.orderItems.map((item, index) => (
                <div className="INVOICE_FORM_SAVED_ITEMS_CONTAINER" key={index}>
                  <div className="INVOICE_FORM_SAVED_ITEM_DELETE">
                    <Tooltip title="Remove Item">
                      <IconButton
                        aria-label="delete"
                        onClick={() => this.removeOrderItem(index)}
                      >
                        <RemoveCircleOutlineOutlinedIcon
                          sx={{ fontSize: 30, color: '#f36767' }}
                        />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Edit Item">
                      <IconButton
                        aria-label="delete"
                        onClick={() => console.log(index)}
                      >
                        <Edit sx={{ fontSize: 30, color: '#6E85B2' }} />
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className="INVOICE_FORM_SAVED_ITEM_DESCRIPTION">
                    <span>{item.description}</span>
                    <p>{item.content}</p>
                  </div>
                  <div className="INVOICE_FORM_SAVED_ITEM_RATE">
                    {item.price}
                  </div>
                  <div className="INVOICE_FORM_SAVED_ITEM_QTY">
                    {item.quantity}
                  </div>
                  <div className="INVOICE_FORM_SAVED_ITEM_AMOUNT">
                    {item.price * item.quantity}
                  </div>
                </div>
              ))
            : this.props.match.params.data !== undefined &&
              this.props.invoice &&
              this.props.invoice.order.order_item
            ? this.props.invoice.order.order_item.map((item, index) => (
                <OrderItem item={item} key={index} />
              ))
            : null}
        </div>
        <div className="INVOICE_FORM_PAYMENT_AMOUNTS_CONTAINERS">
          <div className="INVOICE_FORM_BANKING_DETAILS">
            <div className="INVOICE_FORM_BANKING_DETAILS_BUTTONS">
              {this.state.editBankingDetails ? (
                <>
                  <IconButton
                    aria-label="edit"
                    sx={{ color: '#DA0037' }}
                    onClick={() => this.setState({ editBankingDetails: false })}
                  >
                    <ClearIcon></ClearIcon>
                  </IconButton>
                  <IconButton
                    aria-label="edit"
                    sx={{ color: '#88baed' }}
                    onClick={() => this.updateBankingDetails()}
                  >
                    <SaveIcon></SaveIcon>
                  </IconButton>
                </>
              ) : (
                <IconButton
                  aria-label="edit"
                  sx={{ color: '#88baed' }}
                  onClick={this.prepareBankingDetailsEdit}
                >
                  <EditIcon></EditIcon>
                </IconButton>
              )}
            </div>
            <div className="INVOICE_FORM_BANK">
              <div className="INVOICE_FORM_BANKING_DETAILS_LABLE">Bank</div>
              {this.state.editBankingDetails ? (
                <div className="INVOICE_FORM_INPUT_BANKING_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Bank Name"
                    placeholder="First National Bank"
                    variant="outlined"
                    name="bank"
                    fullWidth
                    value={this.state.bank}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'INVOICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
              ) : (
                <div className="INVOICE_FORM_BANKING_DETAILS_VALUE">
                  {this.props.bankingDetails
                    ? this.props.bankingDetails.bank
                    : ''}
                </div>
              )}
            </div>
            <div className="INVOICE_FORM_ACCOUNT_NAME">
              <div className="INVOICE_FORM_BANKING_DETAILS_LABLE">
                Account Name
              </div>
              {this.state.editBankingDetails ? (
                <div className="INVOICE_FORM_INPUT_BANKING_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Bank Account Name"
                    variant="outlined"
                    name="bankAccountName"
                    fullWidth
                    value={this.state.bankAccountName}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'INVOICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
              ) : (
                <div className="INVOICE_FORM_BANKING_DETAILS_VALUE">
                  {this.props.bankingDetails
                    ? this.props.bankingDetails.account_name
                    : ''}
                </div>
              )}
            </div>
            <div className="INVOICE_FORM_ACCOUNT_NO">
              <div className="INVOICE_FORM_BANKING_DETAILS_LABLE">
                Account No
              </div>
              {this.state.editBankingDetails ? (
                <div className="INVOICE_FORM_INPUT_BANKING_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Account Number"
                    variant="outlined"
                    name="bankAccountNumber"
                    fullWidth
                    value={this.state.bankAccountNumber}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'INVOICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
              ) : (
                <div className="INVOICE_FORM_BANKING_DETAILS_VALUE">
                  {this.props.bankingDetails
                    ? this.props.bankingDetails.account_number
                    : ''}
                </div>
              )}
            </div>
            <div className="INVOICE_FORM_BRANCH_NO">
              <div className="INVOICE_FORM_BANKING_DETAILS_LABLE">
                Branch Code
              </div>
              {this.state.editBankingDetails ? (
                <div className="INVOICE_FORM_INPUT_BANKING_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Branch Code"
                    variant="outlined"
                    name="branchCode"
                    placeholder="123456"
                    fullWidth
                    value={this.state.branchCode}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'INVOICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
              ) : (
                <div className="INVOICE_FORM_BANKING_DETAILS_VALUE">
                  {this.props.bankingDetails
                    ? this.props.bankingDetails.branch_code
                    : ''}
                </div>
              )}
            </div>
            {/* <div className="INVOICE_FORM_BANK_NOTE">
              <div className="INVOICE_FORM_BANKING_DETAILS_LABLE">
                Reference
              </div>
              <div className="INVOICE_FORM_BANKING_DETAILS_VALUE">5205</div>
            </div> */}
            <div className="INVOICE_FORM_BANK_NOTE">
              <div className="INVOICE_FORM_BANKING_DETAILS_LABLE">Note</div>
              {this.state.editBankingDetails ? (
                <div className="INVOICE_FORM_INPUT_BANKING_FIELD_CONTAINER">
                  <TextField
                    id="outlined-basic"
                    label="Invoice Note"
                    variant="outlined"
                    name="note"
                    multiline
                    fullWidth
                    rows={6}
                    value={this.state.note}
                    onChange={this.onChange}
                    // helperText="Please select your currency"
                    InputProps={{
                      className: 'INVOICE_FORM_INPUT_FIELD',
                    }}
                    InputLabelProps={{
                      // shrink: true,
                      className: 'INVOICE_FORM_INPUT_FIELD_LABEL',
                    }}
                  ></TextField>
                </div>
              ) : (
                <div className="INVOICE_FORM_BANKING_DETAILS_VALUE">
                  {this.state.note}
                </div>
              )}
            </div>
          </div>
          <div className="INVOICE_FORM_PAYMENT_AMOUNTS">
            <div className="INVOICE_FORM_SUBTOTAL">
              <div className="INVOICE_FORM_AMOUNT_LABLE">Subtotal</div>
              <div className="INVOICE_FORM_AMOUNT_VALUE">
                {parseFloat(this.state.subtotal).toFixed(2)}
              </div>
            </div>
            <div className="INVOICE_FORM_VAT_TOTAL">
              <div className="INVOICE_FORM_AMOUNT_LABLE">VAT Total</div>
              <div className="INVOICE_FORM_AMOUNT_VALUE">
                {this.state.taxInvoiceEnable ||
                this.state.insuranceTaxInvoiceEnable
                  ? parseFloat((this.state.subtotal * this.state.vat) / 100)
                  : 0.0}
              </div>
            </div>
            <div className="INVOICE_FORM_TOTAL">
              <div className="INVOICE_FORM_AMOUNT_LABLE">Total</div>
              <div className="INVOICE_FORM_AMOUNT_VALUE">
                {parseFloat(this.state.total).toFixed(2)}
              </div>
            </div>
            <div className="INVOICE_FORM_PAYMENTS">
              <div className="INVOICE_FORM_AMOUNT_LABLE">Payments</div>
              <div className="INVOICE_FORM_AMOUNT_VALUE">
                {parseFloat(this.state.payments).toFixed(2)}
              </div>
            </div>
            <div className="INVOICE_FORM_BALANCE_DUE">
              <div className="INVOICE_FORM_AMOUNT_LABLE">Balance Due</div>
              <div className="INVOICE_FORM_AMOUNT_VALUE">
                {parseFloat(this.state.balanceDue).toFixed(2)}
              </div>
            </div>
          </div>
        </div>
        <div className="INVOICE_FORM_TERMS_CONDITIONS_CONTAINER">
          <div className="INVOICE_FORM_TERMS_CONDITIONS"></div>
        </div>
        <div className="INVOICE_FORM_ACTIONS">
          <div className="INVOICE_FORM_GENERATE_BUTTON">
            {this.props.loadingInvoice ||
            this.props.orderLoading ||
            this.props.orderItemsLoading ? (
              <CircularProgress></CircularProgress>
            ) : !editable ? (
              <Tooltip title="Generate Invoice">
                <Button
                  size="medium"
                  sx={{ width: 125 }}
                  onClick={this.generateInvoice}
                  variant="contained"
                >
                  Generate
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="Update Invoice">
                <Button
                  size="medium"
                  sx={{ width: 125 }}
                  onClick={this.initiateInvoiceUpdate}
                  variant="contained"
                >
                  Update
                </Button>
              </Tooltip>
            )}
          </div>
          {/* <div className="INVOICE_FORM_PRINT_BUTTON">
            <LoadingButton
              size="medium"
              onClick={() => console.log('generate')}
              endIcon={<LocalPrintshopOutlinedIcon sx={{ color: '#787A91' }} />}
              loading={false}
              loadingPosition="end"
              variant="outlined"
              disabled={!this.state.enableInvoicePrint}
            >
              Print
            </LoadingButton>
          </div> */}
          {this.state.enableInvoicePrint ? (
            <div className="INVOICE_FORM_PRINT_BUTTON">
              <Link
                underline="none"
                href={
                  this.props.invoice
                    ? `${HOST_URL}/invoices/invoice-to-pdf/${this.props.invoice.id}/${this.props.userDetails.team_member.key}/`
                    : '#!'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <PrintIcon
                  sx={{ color: '#719FB0', fontSize: 35, marginTop: 1 }}
                />
              </Link>
              <Button
                variant="outlined"
                color="primary"
                sx={{ marginInline: 2, width: 125 }}
                onClick={this.handleNewInvoiceStart}
              >
                New Invoice
              </Button>
            </div>
          ) : (
            <div className="INVOICE_FORM_PRINT_BUTTON">
              <IconButton
                aria-label="print"
                disabled={!this.state.enableInvoicePrint}
              >
                <PrintIcon sx={{ color: '#27496D', fontSize: 35 }} />
              </IconButton>
            </div>
          )}
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openMessageModal}
          onClose={this.handleMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.messageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.messageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button variant="outlined" onClick={this.handleMessageClose}>
                  Add Order Number
                </Button>
                <Button
                  variant="outlined"
                  onClick={this.initiateInvoiceGeneration}
                >
                  Ignore
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={this.state.openErrorMessageModal}
          onClose={this.handleErrorMessageClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openErrorMessageModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                {this.state.errorMessageModalMessageTitle}
              </Typography>
              <Typography
                id="transition-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                {this.state.errorMessageModalMessageBody}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={this.handleErrorMessageClose}
                >
                  OKAY
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
        <Modal
          aria-labelledby="delete-invoice-modal-title"
          aria-describedby="delete-invoice-modal-description"
          open={this.state.openDeleteInvModal}
          onClose={this.handleDeleteInvModalClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={this.state.openDeleteInvModal}>
            <Box sx={error_meg_style}>
              <Typography
                id="delete-invoice-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  textAlign: 'center',
                  fontSize: '2rem',
                  color: '#F05454',
                }}
              >
                WARNING
              </Typography>
              <Typography
                id="delete-invoice-modal-description"
                sx={{ mt: 2, textAlign: 'center', fontFamily: 'Roboto' }}
              >
                Are you sure you want to delete this invoice?
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  width: '100%',
                  paddingTop: 5,
                }}
              >
                <Button
                  variant="outlined"
                  onClick={this.handleDeleteInvModalClose}
                >
                  Cancel
                </Button>
                <Button variant="outlined" onClick={this.handleDeleteInvoice}>
                  Confirm
                </Button>
              </Box>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.users.token,
    userDetails: state.users.userDetails,
    mode: state.users.theme?.mode,
    clients: state.client.clients,
    insuranceCompanies: state.insurance.insurances,
    claims: state.insurance.claims,
    items: state.item.items,
    loadingItems: state.item.loading,
    products: state.product.products,
    order: state.order.order,
    orderLoading: state.order.loading,
    orderItem: state.item.orderItem,
    orderItems: state.item.orderItems,
    orderItemsLoading: state.item.loading,
    bankingDetails: state.company.bankingDetails,
    invoice: state.invoice.invoice,
    lastInvoice: state.invoice.lastInvoice,
    loadingInvoice: state.invoice.loading,
    invoiceError: state.invoice.error,
    lastOrder: state.order.lastOrder,
    jobCard: state.jobCard.targetJobCard,
    jobTask: state.jobCard.targetJobTask,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrder: (token, orderNumber) =>
      dispatch(getOrderByNumber(token, orderNumber)),
    createOrder: (
      token,
      order_number,
      financial_account,
      job_card_number,
      order_type,
      status,
      sub_total,
      item_discount,
      tax,
      shipping,
      total,
      promo,
      grand_total,
      content
    ) =>
      dispatch(
        postOrderData(
          token,
          order_number,
          financial_account,
          job_card_number,
          order_type,
          status,
          sub_total,
          item_discount,
          tax,
          shipping,
          total,
          promo,
          grand_total,
          content
        )
      ),
    createOrderItem: (
      token,
      description,
      content,
      item_code,
      order,
      sku,
      discount,
      price,
      quantity,
      amount
    ) =>
      dispatch(
        postOrderItemData(
          token,
          description,
          content,
          item_code,
          order,
          sku,
          discount,
          price,
          quantity,
          amount
        )
      ),
    createInvoice: (
      token,
      invoice_number,
      jobCardNumber,
      account_number,
      client,
      claim,
      terms,
      order,
      invoiced_by,
      tax_date,
      due_date,
      pop_email,
      terms_and_conditions,
      note,
      invoice_type
    ) =>
      dispatch(
        postInvoiceData(
          token,
          invoice_number,
          jobCardNumber,
          account_number,
          client,
          claim,
          terms,
          order,
          invoiced_by,
          tax_date,
          due_date,
          pop_email,
          terms_and_conditions,
          note,
          invoice_type
        )
      ),
    getClients: (token) => dispatch(getClients(token)),
    getInsurances: (token) => dispatch(getInsurances(token)),
    getClaims: (token) => dispatch(getClaims(token)),
    getBankingDetails: (token) => dispatch(getBankingDetails(token)),
    getItems: (token) => dispatch(getItems(token)),
    updateBankingDetails: (
      token,
      id,
      bank,
      account_name,
      account_number,
      branch_code
    ) =>
      dispatch(
        updateBankingDetails(
          token,
          id,
          bank,
          account_name,
          account_number,
          branch_code
        )
      ),
    getLastInvoice: (token) => dispatch(getLastInvoice(token)),
    getLastOrder: (token) => dispatch(getLastOrder(token)),
    getActiveInvoice: (token, jobCardNumber) =>
      dispatch(getInvoiceByInvoiceNumber(token, jobCardNumber)),
    deleteInvoice: (token, id) => dispatch(deleteInvoice(token, id)),
    deleteOrderItem: (token, orderItemID) =>
      dispatch(deleteOrderItem(token, orderItemID)),
    pdateInvoiceData: (
      token,
      invoice_id,
      invoice_number,
      due_date,
      tax_date,
      note,
      terms
    ) =>
      dispatch(
        partialUpdateInvoiceData(
          token,
          invoice_id,
          invoice_number,
          due_date,
          tax_date,
          note,
          terms
        )
      ),
    updateOrderData: (
      token,
      order_id,
      subtotal,
      vat_total,
      total,
      balanceDue
    ) =>
      dispatch(
        updateOrderData(token, order_id, subtotal, vat_total, total, balanceDue)
      ),
    clearInvoiceData: () => dispatch(clearInvoiceProps()),
    getJobCard: (token, jobCardNumber) =>
      dispatch(getJobCardByNumber(token, jobCardNumber)),
    getJobTask: (token, jobTaskNumber) =>
      dispatch(getJobTaskByNumber(token, jobTaskNumber)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InvoiceForm));
